import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	BackendWrapper,
	isValidEmail,
	NavigationWrapper,
	SessionWrapper,
	UiWrapper,
	Button,
	Text,
	View,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import InputLabel from 'components/InputLabel/InputLabel';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';

export const LoginAgent = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading, showAlert } = UiWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		clearSession();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const doLogin = async () => {
		if (!email || !email.trim() || !password || !password.trim() || !isValidEmail(email)) {
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>
							{isValidEmail(email)
								? 'Por favor, rellene todos los campos'
								: 'Introduzca un email válido'}
						</Text>
					</ErrorModal>
				),
				{
					disableClickAway: false,
					closeButton: true,
					modalClass: 'modal-main',
					key: 'modal-LoginAgent',
				},
			);
			return;
		}

		const data = {
			identifier: email.trim(),
			password: password.trim(),
		};
		try {
			const response = await getInstanceApi().loginUser(data);
			const token = response && response.accessToken;
			if (token) {
				const loginAgent = await startSession({ token }, ['AGENT']);
				if (loginAgent) {
					console.log('ROLE LOGGED', 'AGENT');
				} else {
					const globalAdminLogin = await startSession({ token }, ['GLOBAL_AGENT']);
					if (globalAdminLogin) {
						console.log('ROLE LOGGED', 'GLOBAL_AGENT');
					} else {
						showAlert('No tienes permisos suficientes para acceder a esta sección. 🔒');
						return;
					}
				}
				localStorage.removeItem('B2Flow');
				localStorage.setItem('B2Flow', 'B2B');
				CONFIG.routing.loginAgent.next(navigateTo);
			}
		} catch (e) {
			//console.log(" ERROR LOGIN ", e);
			let msg = 'Usuario o contraseña incorrectos';
			// if (e.status === 404) {
			// 	msg = 'Usuario no encontrado';
			// }
			// if (e.status === 401) {
			// 	msg = 'Contraseña incorrecta';
			// }
			// if (e.message === 'Failed to fetch') {
			// 	msg = 'Error conectando con el servidor';
			// }
			// if (!msg) {
			// 	msg = 'Error desconocido: ' + e.status;
			// }
			// openModal(msg);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{msg}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: 'modal-main',
					key: 'modal-LoginAgent',
				},
			);
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={true} />, []);
	return (
		<Wrapper>
			<Text headingPrimarySt>Login</Text>
			<Text headingSecondarySt>Login para agentes</Text>
			<View>
				<InputLabel
					value={email}
					onChange={e => {
						setEmail(e.target.value);
					}}
					label='Email'
				/>

				<InputLabel
					value={password}
					onChange={e => {
						setPassword(e.target.value);
					}}
					type='password'
					label='Contraseña'
				/>
			</View>
			<Button
				selectedSt
				disabled={!email || !password}
				onClick={doLogin}
				style={{ marginTop: '40px', width: '140px' }}
			>
				Entrar
			</Button>
		</Wrapper>
	);
};
