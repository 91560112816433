import { useEffect, useState } from 'react';

import { OperationsWrapper, InternationalizationWrapper } from 'coinscrap-webapp-core';
import moment from 'moment';

import { getCurrency } from 'libs/Currency';

export const useSimulation = (
	craInstitution,
	targetType,
	creationData,
	user,
	capitalMaximum,
	capitalMinimum,
	onError,
) => {
	const { createOperation } = OperationsWrapper.use();
	const { t } = InternationalizationWrapper.use();
	// PERIOD
	const [period, setPeriod] = useState(creationData?.periodicity || 'anual');

	// DISABILITY COVERAGE
	//cobertura por incapacidad permanente absoluta
	const [disabilityCoverage, setDisabilityCoverage] = useState(
		creationData?.disabilityCoverage || false,
	);

	//cobertura por incapacidad permanente absoluta por accidente
	const [disabilityCoverageAccident, setDisabilityCoverageAccident] = useState(
		creationData?.disabilityCoverageAccident || false,
	);

	// CAPITAL
	const [capital, setCapital] = useState(creationData?.price || 80000);

	useEffect(() => {
		if (capital > capitalMaximum) {
			setCapital(capitalMaximum);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isValidCapital, setIsValidCapital] = useState(true);
	const [invalidCapitalReason, setInvalidCapitalReason] = useState(
		t('simulation.errors.error1') + getCurrency(capitalMaximum, 0),
	);

	// RECIBO
	const [invoice, setInvoice] = useState(creationData?.periodicContribution || 0);

	const [valueChanged, setValueChanged] = useState({ type: undefined });

	const [loading, setLoading] = useState('miscellaneous.estimatingAmount');
	const [credentialsValid, setCredentialsValid] = useState();
	const [institutionAccountId, setInstitutionAccountId] = useState(undefined);

	// Crear la institution si no existe
	useEffect(() => {
		if (!institutionAccountId) {
			(async () => {
				const operation = await createOperation(
					'CREATE_INSTITUTION_ACCOUNT',
					{
						institutionIdentifier: craInstitution.identifier,
					},
					undefined,
					undefined,
					{ userId: user.id },
				);
				console.log('🧿 CREATE_INSTITUTION_ACCOUNT', operation);
				setInstitutionAccountId(operation.institutionAccountId);
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Validar credenciales
	useEffect(() => {
		if (institutionAccountId) {
			(async () => {
				const operation = await createOperation(
					'VALIDATE_CREDENTIALS',
					{
						institutionAccountId: institutionAccountId,
						payload: {
							value: {
								authenticationMethodIdentifier: 'none',
								authenticationMethodFieldsValue: {},
							},
						},
					},
					undefined,
					undefined,
					{ userId: user.id },
				);
				console.log('🧿 VALIDATE_CREDENTIALS', operation);
				setCredentialsValid(true);
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [institutionAccountId]);

	const doSimulation = async institutionAccountId => {
		if (!institutionAccountId) return;
		try {
			setLoading('miscellaneous.estimatingAmount');

			const periodMap = {
				mensual: 'MONTHLY',
				trimestral: 'QUARTERLY',
				semestral: 'SEMIANNUALLY',
				anual: 'YEARLY',
			};
			const policyConfiguration = {
				chargePeriod: periodMap[period],
				additionalCoverages: [],
			};

			if (disabilityCoverage) {
				policyConfiguration.additionalCoverages.push(98); // 98 → Código cobertura de Caser para invalidez.
			}

			if (disabilityCoverage && disabilityCoverageAccident) {
				policyConfiguration.additionalCoverages.push(99); // 99 → Código cobertura de Caser para invalidez por accidente.
			}

			if (valueChanged.type === 'invoice') {
				policyConfiguration.periodicContribution = Number(
					invoice.toString().replace('.', '').replace(',', '.'),
				);
			} else {
				policyConfiguration.amountToCover = Number(
					capital.toString().replace('.', '').replace(',', '.'),
				);
			}

			let operation;
			try {
				operation = await createOperation(
					'SIMULATE_PRODUCT',
					{
						institutionAccountId: institutionAccountId,
						productDefinitionIdentifier: targetType?.metadata?.productSupported,
						payload: {
							value: {
								productSimulationRequest: {
									type: 'insurance',
									additionalData: {
										policyConfiguration,
										userData: {
											birthDate: moment(user?.metadata?.birthDate).toISOString(),
											gender: user?.metadata?.gender === 'Male' ? 'Male' : 'Female',
											height: Number(user?.metadata?.height),
											weight: Number(user?.metadata?.weight),
											profession: user?.metadata?.professionCode || '200000',
											smoke: user?.metadata?.smoke === 'no' ? false : true,
											smokeQuantity: Number(user?.metadata?.smokeQuantity),
											smokeType: user?.metadata?.smokeType,
											alcohol: user?.metadata?.alcohol === 'no' ? false : true,
											alcoholQuantity: Number(user?.metadata?.alcoholQuantity),
											alcoholType: user?.metadata?.alcoholType,
										},
									},
								},
							},
						},
					},
					undefined,
					undefined,
					{ userId: user.id },
				);
			} catch (e) {
				console.log('❌ ERROR', e);

				setCapital(policyConfiguration?.amountToCover || capitalMaximum);
				setInvoice(policyConfiguration?.periodicContribution || 0);

				onError && onError(e);
				return;
			}

			console.log('✅ SIMULATE_PRODUCT', operation);

			// CAPITAL
			setCapital(
				getCurrency(
					operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
						?.amountToCover,
					0,
					true,
				) || getCurrency(capitalMaximum, 0, true),
			);

			// IS VALID CAPITAL
			setIsValidCapital(
				operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
					?.amountToCover !== '' &&
					operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
						?.amountToCover <= capitalMaximum &&
					operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
						?.amountToCover >= capitalMinimum,
			);

			// INVALID REASON
			if (
				operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
					?.amountToCover > capitalMaximum
			) {
				setInvalidCapitalReason(
					t('simulation.errors.error1') + ' ' + getCurrency(capitalMaximum, 0),
				);
			} else if (
				operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
					?.amountToCover === '' ||
				operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
					?.amountToCover < capitalMinimum
			) {
				setInvalidCapitalReason(
					t('simulation.errors.error2') + ' ' + getCurrency(capitalMinimum, 0),
				);
			}

			setInvoice(
				getCurrency(
					operation?.currentAction?.output?.payload?.additionalData?.policyConfiguration
						?.periodicContribution,
					2,
					true,
				) || getCurrency(0, 0, true),
			);
		} catch (e) {
			onError && onError(e);
		} finally {
			setLoading(undefined);
		}
	};

	useEffect(() => {
		if (credentialsValid && institutionAccountId) {
			const timeOutId = setTimeout(() => {
				doSimulation(institutionAccountId);
			}, 800);
			return () => clearTimeout(timeOutId);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueChanged, credentialsValid, institutionAccountId]);

	return [
		loading,
		newLoading => {
			console.log('SET loading', newLoading);
			setLoading(newLoading);
		},
		invoice,
		newInvoice => {
			console.log('SET invoice', +newInvoice);
			setInvoice(+newInvoice);
			setValueChanged({ type: 'invoice' });
		},
		capital,
		newCapital => {
			console.log('SET capital', +newCapital);
			setCapital(+newCapital);
			setValueChanged({ type: 'capital' });
		},
		isValidCapital,
		newValidCapital => {
			console.log('SET newValidCapital', newValidCapital);
			setIsValidCapital(newValidCapital);
		},
		invalidCapitalReason,
		newInvalidCapitalReason => {
			console.log('SET invalidCapitalReason', newInvalidCapitalReason);
			setInvalidCapitalReason(newInvalidCapitalReason);
		},
		period,
		newPeriod => {
			console.log('SET period', newPeriod);
			setPeriod(newPeriod);
			setValueChanged({ type: 'period' });
		},
		disabilityCoverage,
		newDisabilityCoverage => {
			console.log('SET disabilityCoverage', newDisabilityCoverage);
			setDisabilityCoverage(newDisabilityCoverage);
			setValueChanged({ type: 'disabilityCoverage' });
		},
		disabilityCoverageAccident,
		newDisabilityCoverageAccident => {
			console.log('SET disabilityCoverageAccident', newDisabilityCoverageAccident);
			setDisabilityCoverageAccident(newDisabilityCoverageAccident);
			setValueChanged({ type: 'disabilityCoverageAccident' });
		},
	];
};
