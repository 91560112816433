export default {
	p0St: {
		padding: 0,
	},
	p1St: {
		padding: 8,
	},
	p2St: {
		padding: 16,
	},
	p3St: {
		padding: 24,
	},
	p4St: {
		padding: 32,
	},
	p5St: {
		padding: 40,
	},
	p6St: {
		padding: 48,
	},
	pt1St: {
		paddingTop: 8,
	},
	pt2St: {
		paddingTop: 16,
	},
	pt3St: {
		paddingTop: 24,
	},
	pt4St: {
		paddingTop: 32,
	},
	pt5St: {
		paddingTop: 40,
	},
	pt6St: {
		paddingTop: 48,
	},
	pr1St: {
		paddingRight: 8,
	},
	pr2St: {
		paddingRight: 16,
	},
	pr3St: {
		paddingRight: 24,
	},
	pr4St: {
		paddingRight: 32,
	},
	pr5St: {
		paddingRight: 40,
	},
	pr6St: {
		paddingRight: 48,
	},
	pb1St: {
		paddingBottom: 8,
	},
	pb2St: {
		paddingBottom: 16,
	},
	pb3St: {
		paddingBottom: 24,
	},
	pb4St: {
		paddingBottom: 32,
	},
	pb5St: {
		paddingBottom: 40,
	},
	pb6St: {
		paddingBottom: 48,
	},
	pl1St: {
		paddingLeft: 8,
	},
	pl2St: {
		paddingLeft: 16,
	},
	pl3St: {
		paddingLeft: 24,
	},
	pl4St: {
		paddingLeft: 32,
	},
	pl5St: {
		paddingLeft: 40,
	},
	pl6St: {
		paddingLeft: 48,
	},
};
