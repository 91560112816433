export const smokeTypesRU = [
	{ label: 'Трубка для курения', value: 'Pipas' },
	{ label: 'Сигары', value: 'Puros' },
	{ label: 'Сигареты', value: 'Cigarrillos' },
	{ label: 'Электронная сигарета', value: 'CigarrilloElectronico' },
];

export const alcoholTypesRU = [
	{ label: 'Шерри', value: 'Jerez' },
	{ label: 'Вино', value: 'Vino' },
	{ label: 'Пиво', value: 'Cerveza' },
	{ label: 'Виски', value: 'Whisky' },
	{ label: 'Ликер', value: 'Licor' },
];
