import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/globalStyle';

import { isMobile } from 'react-device-detect';

export const InnerWrapper = styled.div`
	${verticalFlex}
	flex-grow: 1;
	width: 90%;

	.title {
		margin-top: 40px;
	}
`;

export const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 20px;
`;
export const StepWrapperItem = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	justify-content: flex-start;
	margin-bottom: ${isMobile ? '15px' : '40px'};
`;
export const StepCircle = styled.div`
	height: 36px;
	width: 36px;
	background-color: ${CONFIG.colors.primary};
	border-radius: 50%;
	margin-right: 15px;
	font-size: ${isMobile ? '16px' : '17px'};
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
`;
