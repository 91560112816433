/*
Archivo de configuración donde se asocia una acción [key] con el nombre [valor]
Los valores que asignemos simplemente son nombres cortos de las pantallas para que la app las use internamente,
Las navegaciones no se realizan en base a paths completos si no a nombres cortos
*/

//BLOQUES DE RUTAS
//  1→ AGENTE
//  2→ ACCESO B2B PARA CLIENTE DESDE SMS o EMAIL DE CONTINUE ENVIADO POR EL AGENTE
//  3→ ACCESO B2C PARA CLIENTE DIRECTO
//  4→ CLIENTE

const routes = {
	loginAgent: 'loginAgent',
	dashboard: 'dashboard',
	simulationDataAgent: 'simulationDataAgent',
	clientDataAgent: 'clientDataAgent',
	simulationAgent: 'simulationAgent',
	followUp: 'followUp',

	continue: 'continue',
	loadTarget: 'loadTarget',

	loginClient: 'loginClient',
	landing: 'landing',
	upsertProduct: 'upsertProduct',

	simulationData: 'simulationData',
	simulation: 'simulation',

	clientData: 'clientData',

	pbc: 'pbc',
	health: 'health',

	bankAccount: 'bankAccount',

	identityBiometricSteps: 'identityBiometricSteps',
	identityBiometricUpsert: 'identityBiometricUpsert',
	identityBiometricSelectDoc: 'identityBiometricSelectDoc',
	identityBiometric: 'identityBiometric',
	IdentityBiometricAttach: 'IdentityBiometricAttach',
	identityBiometricExtraDocs: 'identityBiometricExtraDocs',

	activateProduct: 'activateProduct',

	signatureSteps: 'signatureSteps',
	signatureRead: 'signatureRead',
	signatureContract: 'signatureContract',

	productCreationFinishedB2C: 'productCreationFinishedB2C',

	productCreationFinishedManual: 'productCreationFinishedManual',
	cannotConfirm: 'cannotConfirm',
};

export default routes;

export const routing = {
	loginAgent: {
		next: nav => {
			navigate(nav, routes.dashboard, { handleRedirection: true });
		},
	},
	dashboard: {
		nextVinculationDetails: (nav, vinculationId, clientId, simulationId) => {
			navigate(nav, routes.simulationDataAgent, {
				routeParams: { vinculationId, clientId, simulationId },
			});
		},
		nextFollowUp: (nav, vinculationId, clientId, simulationId) => {
			navigate(nav, routes.followUp, {
				routeParams: { vinculationId, clientId, simulationId },
			});
		},
	},
	simulationDataAgent: {
		next: nav => {
			navigate(nav, routes.clientDataAgent);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	clientDataAgent: {
		next: nav => {
			navigate(nav, routes.simulationAgent);
		},
		back: nav => {
			navigate(nav, routes.simulationDataAgent);
		},
	},
	simulationAgent: {
		next: nav => {
			navigate(nav, routes.followUp);
		},
		back: nav => {
			navigate(nav, routes.clientDataAgent);
		},
	},
	followUp: {
		next: nav => {
			navigate(nav, routes.dashboard);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	continue: {
		next: nav => {
			navigate(nav, routes.loadTarget, {
				clearItems: ['uniqueIdentifier', 't'],
				handleRedirection: true,
			});
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	loadTarget: {
		next: (nav, route, params) => {
			navigate(nav, route, params);
		},
	},
	loginClient: {
		next: nav => {
			navigate(nav, routes.landing, {
				clearItems: ['source', 'SOURCE', 'lang', 'LANG'],
			});
		},
	},
	landing: {
		next: nav => {
			navigate(nav, routes.upsertProduct);
		},
		reLogin: nav => {
			navigate(nav, routes.loginClient);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'CALL',
				},
			});
		},
	},
	upsertProduct: {
		next: (nav, targetId) => {
			navigate(nav, routes.simulationData, {
				routeParams: { targetId },
			});
		},
	},
	simulationData: {
		next: nav => {
			navigate(nav, routes.simulation);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'SIMULATION_DETAILS',
				},
			});
		},
	},
	simulation: {
		next: (nav, isB2B) => {
			isB2B ? navigate(nav, routes.pbc) : navigate(nav, routes.clientData);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	clientData: {
		next: (nav, isB2B) => {
			isB2B ? navigate(nav, routes.simulation) : navigate(nav, routes.pbc);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	pbc: {
		next: nav => {
			navigate(nav, routes.health);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'PBC',
				},
			});
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	health: {
		next: nav => {
			navigate(nav, routes.bankAccount);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'SALUD',
				},
			});
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	bankAccount: {
		next: nav => {
			navigate(nav, routes.identityBiometricSteps);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	identityBiometricSteps: {
		next: nav => {
			navigate(nav, routes.identityBiometricSelectDoc);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	identityBiometricSelectDoc: {
		next: nav => {
			navigate(nav, routes.identityBiometricUpsert);
		},
		back: goBack => {
			navigateBack(goBack);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				routeParams: 'NO_ESP_DOC',
			});
		},
	},
	identityBiometricUpsert: {
		nextAlreadyAdded: nav => {
			navigate(nav, routes.activateProduct);
		},
		nextAddNew: nav => {
			navigate(nav, routes.identityBiometric);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	identityBiometric: {
		next: nav => {
			navigate(nav, routes.activateProduct);
		},
		review: nav => {
			navigate(nav, routes.clientData);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				routeParams: 'DNI',
			});
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	IdentityBiometricAttach: {
		next: nav => {
			navigate(nav, routes.identityBiometricExtraDocs);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	identityBiometricExtraDocs: {
		next: nav => {
			navigate(nav, routes.activateProduct);
		},
		nextNoNeeded: nav => {
			navigate(nav, routes.activateProduct);
		},
		nextAlreadyAdded: nav => {
			navigate(nav, routes.activateProduct);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	activateProduct: {
		next: (nav, envelopeId) => {
			navigate(nav, routes.signatureSteps, {
				mode: 'replace',
				routeParams: { envelopeId },
			});
		},
		koBlackList: nav => {
			navigate(nav, routes.cannotConfirm, {
				routeParams: 'ACTIVATE_PRODUCT',
			});
		},
		koManualFinish: nav => {
			navigate(nav, routes.productCreationFinishedManual);
		},
		koReviewData: nav => {
			navigate(nav, routes.simulationData);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	signatureSteps: {
		next: nav => {
			navigate(nav, routes.signatureRead);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	signatureRead: {
		next: nav => {
			navigate(nav, routes.signatureContract);
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	signatureContract: {
		nextB2C: nav => {
			navigate(nav, routes.productCreationFinishedB2C);
		},
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'SIGN_CONTRACT',
				},
			});
		},
		back: goBack => {
			navigateBack(goBack);
		},
	},
	productCreationFinishedB2C: {},
	productCreationFinishedManual: {},
	cannotConfirm: {
		back: goBack => {
			navigateBack(goBack);
		},
	},
	header: {
		ko: nav => {
			navigate(nav, routes.cannotConfirm, {
				queryParams: {
					from: 'CALL',
				},
			});
		},
		back: nav => {
			navigate(nav, routes.dashboard);
		},
	},
	footer: {
		nextPrivacity: nav => {
			navigate(nav, routes.faq);
		},
		nextConditions: nav => {
			navigate(nav, routes.faq);
		},
		nextFaq: nav => {
			navigate(nav, routes.faq);
		},
	},
};

const navigate = (nav, ruta, params = {}) => {
	logNavigation(ruta);
	nav(ruta, params);
};

const navigateBack = goBack => {
	logNavigation('BACK');
	goBack();
};

const logNavigation = ruta => {
	console.log(`🚀 NAVIGATING TO ${ruta}`);
};
