import { CONFIG } from 'config/config';
import React, { useEffect, useMemo, useState } from 'react';

import {
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	InternationalizationWrapper,
	UserWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { set } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const IdentityBiometricUpsert = PickerHOC({
	dniDocuments: {
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper,
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
})(() => {
	const {
		createIdentityDocument,
		changeIdentityDocumentValidationMode,
	} = IdentityDocumentsWrapper.use();

	const { user, dniDocuments } = PickerHOC.use();
	const mainUserDni = dniDocuments.find(d => d.main);
	const mainUserDniId = mainUserDni?.id;

	console.log('dniDocuments', dniDocuments);

	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('IdentityBiometricUpsert');
	const [loading, setLoading] = useState();

	const { t, currentLang } = InternationalizationWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const docToScan = useMemo(() => user?.metadata?.documentTypeIDS || 'dni', [user]);

	useEffect(() => {
		if (!mainUserDniId || mainUserDni.type !== docToScan) {
			(async () => {
				try {
					const dniHanlded = await createIdentityDocument(docToScan, true);
					await changeIdentityDocumentValidationMode(dniHanlded.id, true);
					await set();
					await next();
				} catch (e) {
					console.error('ERROR', e);
					let messageError = e.message.toString();
					messageError = t('miscellaneous.temporalError');
					setLoading(undefined);
					openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
						disableClickAway: true,
						closeButton: true,
					});
				}
			})();
		} else {
			(async () => {
				try {
					console.log('mainUserDniId', mainUserDniId);
					await next();
				} catch (e) {
					console.error('ERROR', e);
				}
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainUserDniId]);

	useEffect(() => {
		(async () => {
			if (mainUserDni && mainUserDni?.number && mainUserDni?.filled) {
				setLoading('El usuario ya tiene ha añadido su Documento...');
				CONFIG.routing.identityBiometricUpsert.nextAlreadyAdded(navigateTo);
			}
		})();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainUserDni, mainUserDniId, navigateTo]);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.identityBiometricUpsert.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('dniBiometric.starting');
			CONFIG.routing.identityBiometricUpsert.nextAddNew(navigateTo, mainUserDniId);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e.message.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useEffect(() => {
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return <Wrapper></Wrapper>;
});
