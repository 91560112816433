import { CONFIG } from 'config/config';
import componentsConfig from './components';

export default {
	...componentsConfig,
	fontFamily: CONFIG.fonts.regular,
	colors: {
		defaultFontColor: CONFIG.colors.primary,
		main: CONFIG.colors.primary,
		secondary: CONFIG.colors.secondary,
	},
};
