import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import { routing } from './routes';
import translations from './translations';

export const CONFIG = {
	colors: { ...colors, loadingModalBackground: colors.primaryLighter },
	fonts: { ...fonts },
	fontSizes: { ...fontSizes },
	routing: { ...routing },
	translations: { ...translations },
};
