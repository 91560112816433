export const professionalSituationListUA = [
	{
		value: 'CUENTA_AJENA',
		label: 'Найманий працівник/робітник',
	},
	{
		value: 'CUENTA_PROPIA',
		label: 'Приватний підприємець /Самозайнята особа',
	},
	{
		value: 'DESEMPLEADO',
		label: 'Безробітний',
	},
	{
		value: 'PENSIONISTA',
		label: 'Пенсіонер',
	},
	{
		value: 'RENTISTA',
		label: 'Рантьє / Інвестор',
	},
	{
		value: 'ESTUDIANTE',
		label: 'Студент',
	},
	{
		value: 'OTROS',
		label: 'Інший',
	},
];
