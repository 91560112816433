import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	UiWrapper,
	UserWrapper,
	View,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import TooltipSimple from 'config/components/tooltipSimple';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';
import ExtraDataModal from '../../components/ExtraDataModal/ExtraDataModal';

export const SimulationData = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { saveTargetProperty } = TargetsWrapper.use();
	const { navigateTo, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('SimulationData');
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { target, user } = PickerHOC.use();
	const { currentLang, t } = InternationalizationWrapper.use();
	const [loading, setLoading] = useState();

	console.log(user);

	// CREATION DATA
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData')?.value;

	const [birthDate, setBirthDate] = useState(
		user?.metadata?.birthDate || moment().subtract(18, 'years'),
	);
	const [gender, setGender] = useState(user?.metadata?.gender || '');
	const [weight, setWeight] = useState(user?.metadata?.weight || '');
	const [height, setHeight] = useState(user?.metadata?.height || '');

	const [profession, setProfession] = useState(user?.metadata?.profession || '');
	const [professionList, setProfessionList] = useState(
		CONFIG.translations[currentLang].professionaList.professions,
	);
	// FUMAS?
	const [smoke, setSmoke] = useState(user?.metadata?.smoke || 'no');
	const [smokeQuantity, setSmokeQuantity] = useState(user?.metadata?.smokeQuantity || null);
	const [smokeType, setSmokeType] = useState(user?.metadata?.smokeType || 'Cigarrillos');
	const [smokeList, setSmokeList] = useState(CONFIG.translations[currentLang].drugs.smoke);
	// ALCOHOL?
	const [alcohol, setAlcohol] = useState(user?.metadata?.alcohol || 'no');
	const [alcoholQuantity, setAlcoholQuantity] = useState(user?.metadata?.alcoholQuantity || null);
	const [alcoholType, setAlcoholType] = useState(user?.metadata?.alcoholType || 'Cerveza');
	const [alcoholList, setAlcoholList] = useState(CONFIG.translations[currentLang].drugs.alcohol);

	// NAVIGATION
	const next = async (name, surname, email, phoneNumber) => {
		try {
			const currentAge = moment().diff(moment(birthDate), 'years');

			const professionESP = CONFIG.translations.es.professionaList.professions.find(
				espProf => espProf.value === professionList?.find(p => p?.label === profession)?.value,
			).label;

			setLoading('miscellaneous.saving');
			await updateUser({
				username: {
					name,
					surname,
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionESP,
					professionCode: professionList?.find(p => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false,
				},
			});
			//await new Promise((r) => setTimeout(r, 10000));
			setLoading('miscellaneous.updating');

			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionESP,
					professionCode: professionList?.find(p => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: false,
				},
			});

			if (currentAge > 69) {
				CONFIG.routing.simulationData.ko(navigateTo);
			} else {
				CONFIG.routing.simulationData.next(navigateTo);
			}
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e?.message?.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>{messageError}</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-SimulationDetailsError',
				},
			);
		}
	};

	const isDisabled = () => {
		return (
			!birthDate ||
			moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
			!profession ||
			!gender ||
			!weight ||
			!height ||
			(smoke === 'yes' && (smokeQuantity === null || smokeType === null)) ||
			(alcohol === 'yes' && (alcoholQuantity === null || alcoholType === null))
		);
	};

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// CAMBIO DE IDIOMA
	useEffect(() => {
		setAlcoholList(CONFIG.translations[currentLang].drugs.alcohol);
		setSmokeList(CONFIG.translations[currentLang].drugs.smoke);
		setProfessionList(CONFIG.translations[currentLang].professionaList.professions);
		console.log(CONFIG.translations[currentLang]);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper width={'60%'}>
			<HorizontalProgressBar step={1} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text headingPrimarySt id='simulationData.title' label='simulationData.title'>
				{t('simulationData.title')}
			</Text>
			<Text headingSecondarySt id='simulationData.description' label='simulationData.description'>
				{t('simulationData.description')}
			</Text>
			<View inputRowSt>
				<InputLabel
					value={birthDate}
					onChange={e => {
						//console.log(e);
						setBirthDate(moment(e).format('YYYY-MM-DD'));
					}}
					label={t('simulationData.inputDate')}
					type='date'
					errorMsg={
						(moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
							t('simulationData.error.inputDate')) ||
						(moment(birthDate).unix() < moment().subtract(65, 'years').unix() &&
							t('simulationData.error.inputDate2'))
					}
				/>
				<InputLabel type='custom' label={t('simulationData.inputGender')}>
					<View rowSt spaceBetweenSt>
						{/* //TODO: refactor */}
						<S.Button
							onClick={() => {
								setGender('Male');
							}}
							selected={gender === 'Male'}
						>
							{t('simulationData.male')}
						</S.Button>
						<S.Button
							selected={gender === 'Female'}
							onClick={() => {
								setGender('Female');
							}}
						>
							{t('simulationData.female')}
						</S.Button>
					</View>
				</InputLabel>
			</View>
			<View inputRowSt alignItemsSt>
				<InputLabel
					value={weight}
					onChange={e => {
						if (isNaN(+e.target.value) || '') return;
						setWeight(parseInt(e.target.value || 0));
					}}
					label={t('simulationData.inputWeigth')}
				/>
				<InputLabel
					value={height}
					onChange={e => {
						if (isNaN(+e.target.value) || '') return;

						setHeight(parseInt(e.target.value || 0));
					}}
					label={t('simulationData.inputHeigth')}
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={profession || ''}
					onChange={value => {
						setProfession(professionList?.find(p => p?.value === value)?.label);
					}}
					label={t('simulationData.inputProfession')}
					type='autoSuggest'
					selectOptions={professionList}
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={smoke || 'no'}
					onChange={e => {
						setSmoke(e.target.value);
					}}
					label={t('simulationData.inputSmoke')}
					tooltip={
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								<Text tooltipTextSt>{'simulationData.tooltips.tooltip1'}</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: t('simulationData.yes'), value: 'yes' },
						{ label: t('simulationData.no'), value: 'no' },
					].map(item => {
						return {
							label: item.label,
							value: item.value,
						};
					})}
				/>
				{smoke === 'yes' && (
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeQuantity}
							onChange={e => {
								//console.log('10 Quantity Change', e.target.value);
								setSmokeQuantity(+e.target.value);
							}}
							label={t('simulationData.dailyQuantity')}
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeType}
							onChange={e => {
								setSmokeType(e.target.value);
							}}
							label={t('simulationData.smokingType')}
							type='select'
							selectOptions={smokeList.map(item => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>
			<View inputRowSt>
				<InputLabel
					value={alcohol || 'no'}
					onChange={e => {
						setAlcohol(e.target.value);
					}}
					label={t('simulationData.inputAlcohol')}
					tooltip={
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								<Text tooltipTextSt>{'simulationData.tooltips.tooltip2'}</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: t('simulationData.yes'), value: 'yes' },
						{ label: t('simulationData.no'), value: 'no' },
					].map(item => {
						return {
							label: item.label,
							value: item.value,
						};
					})}
				/>
				{alcohol === 'yes' && (
					//TODO: refactor OptionsSmokeAndAlcohol
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholQuantity}
							onChange={e => {
								//console.log('11 Quantity Change', e.target.value);
								setAlcoholQuantity(+e.target.value);
							}}
							label={t('simulationData.dailyQuantity')}
							errorMsg={true}
							type={'number'}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholType}
							onChange={e => {
								setAlcoholType(e.target.value);
							}}
							label={t('simulationData.alcoholType')}
							type='select'
							selectOptions={alcoholList.map(item => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>
			<BackNextButton
				onClickNext={() =>
					openModal(
						close => {
							return (
								<ExtraDataModal
									close={close}
									initialName={user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || ''}
									initialSurname={user?.surname}
									initialPhoneNumber={user?.phoneNumber}
									initialEmail={user?.email}
									next={next}
								/>
							);
						},
						{
							disableClickAway: true,
							closeButton: false,
							modalClass: isMobile ? 'modal-xl' : 'modal-md',
							key: 'modal-EmailAndPhone',
						},
					)
				}
				nextText={'simulationData.simulate'}
				isBackShown={false}
				isBackDisabled={false}
				isNextDisabled={isDisabled()}
				justifyContent={'center'}
			/>
		</Wrapper>
	);
});
