import { CONFIG } from 'config/config';
import { createGlobalStyle, css } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${CONFIG.fonts.regular};
}

//MATERIAL UI
// Fuente base
.MuiInputBase-input {
  font-family: ${CONFIG.fonts.regular} !important;
}

// Padding para todos los inputs
.MuiFilledInput-input {
  padding-left: 0 !important;
}

// Background de iconos en inputs
.MuiFilledInput-root:hover {
  background: none !important;
}
.MuiFilledInput-root.Mui-focused {
  background: none !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: ${CONFIG.colors.primary} !important;
}

// Borde extra en algunos inputs
.MuiInput-underline.MuiInputBase-root:before {
    border-bottom: 0px solid #002C90 !important;
}

// Color Circular Progress
.MuiCircularProgress-colorPrimary {
  color: ${CONFIG.colors.primary} !important;
}

// Background de input select al hacer focus
.MuiSelect-select:focus {
  background: none !important;
}

//Opciones select
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.MuiListItem-button.Mui-selected {
  background: ${CONFIG.colors.primaryLighter} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-selected > p {
  color: ${CONFIG.colors.black} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-disabled {
  background: ${CONFIG.colors.primary} !important;
	opacity: 1 !important;
}
.MuiListItem-button.Mui-disabled > p {
	padding-top: 4px;
	padding-bottom: 4px;
  color: ${CONFIG.colors.white} !important;
}

// Opciones autocomplete
.suggestions {
  background: ${CONFIG.colors.white};
	border: 1px solid ${CONFIG.colors.primary};
  list-style: none;
  margin-top: 0;
  max-height: 264px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  top: 105%;
  z-index: 150;
}
.suggestions li {
  padding: 0.7rem;
  font-size: 0.9rem;
}
.suggestion-active,
.suggestions li:hover {
  background: ${CONFIG.colors.primary};
  color: ${CONFIG.colors.white};
  cursor: pointer;
  font-weight: 700;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid ${CONFIG.colors.primary};
}

//REACT-PHONE-NUMBER-INPUT
.PhoneInputInput {
  border: 1px solid #B1B1B1;
  border-radius: 1px;
  height: 50px;
  color: ${CONFIG.colors.primary};
  font-size: 18px;
  padding: 23px 21px;
  ::placeholder {
    color: #B1B1B1;
  }
}

//DOC VIEWER --------------------------------------------------
.docViewDoc {
	text-align: center;
	margin-top: 20px
}
.docViewPage canvas {
	margin: 0 auto;
-webkit-box-shadow: 5px 10px 8px 3px rgba(0,0,0,0.59);
box-shadow: 5px 10px 8px 3px rgba(0,0,0,0.59);
}

//IMFINITE SCROLL --------------------------------------------------
.infinite-scroll-component__outerdiv {
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
}
`;

export const verticalFlex = css`
	align-items: center;
	display: flex;
	flex-direction: column;
`;
