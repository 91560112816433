import { CONFIG } from 'config/config';
import React from 'react';
import * as S from './styles';

import { Text } from 'coinscrap-webapp-core';

import { Modal } from 'components/Modal/Modal';

import ErrorIcon from '@material-ui/icons/Error';

export const ErrorModal = ({ children }) => {
	return (
		<Modal>
			<S.ModalWrapper>
				<ErrorIcon
					style={{ marginBottom: 15, color: CONFIG.colors.primary, height: 45, width: 45 }}
				/>
				<Text noT errorModalSt>
					{children}
				</Text>
			</S.ModalWrapper>
		</Modal>
	);
};
