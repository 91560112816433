export const docNamesES = {
	CONDICIONES_GENERALES: 'Solicitud de seguro',
	SEPA: 'Mandato SEPA',
	DDF: 'Datos fundamentales',
	PROMOTION: 'Condiciones de promoción',
	CCPP: 'Boletín',
	caser_pbc: 'Formulario de conocimiento de cliente',
	documento_identidad_01: 'Documento de identidad 1',
	documento_identidad_02: 'Documento de identidad 2',
};
