import React from 'react';

import { View, Button, Text, Var } from 'coinscrap-webapp-core';

import TooltipSimple from 'config/components/tooltipSimple';

const QuestionnaireBox = ({
	text,
	tooltipText,
	onChange,
	value = null,
	listWithTooltips = false,
}) => {
	return (
		<View questionnariesSt>
			<View wrapperQuestionnariesSt>
				<Text boxQuestionnariesSt noT>
					<View wrapperTextQuestionnariesSt style={{ width: listWithTooltips ? '90%' : '100%' }}>
						<Var>{text}</Var>
					</View>
					{tooltipText && (
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								<Text tooltipTextSt noT>
									{tooltipText}
								</Text>
							</TooltipSimple>
						</View>
					)}
				</Text>
			</View>
			<View wrapperButtonsQuestionnariesSt>
				<Button
					questionarySt={value}
					noSt={!value}
					onClick={() => {
						//console.log('CLICK YES');
						onChange(true);
					}}
					label='simulationData.yes'
				/>
				<Button
					questionarySt={!value}
					noSt={value || value == null}
					sugestedSt={value || value == null}
					onClick={() => {
						//console.log('CLICK NO');
						onChange(false);
					}}
					label='simulationData.no'
				/>
			</View>
		</View>
	);
};

export default QuestionnaireBox;
