export const professionalSituationListES = [
	{
		value: 'CUENTA_AJENA',
		label: 'Cuenta ajena',
	},
	{
		value: 'CUENTA_PROPIA',
		label: 'Cuenta propia-autónomo',
	},
	{
		value: 'DESEMPLEADO',
		label: 'Desempleado',
	},
	{
		value: 'PENSIONISTA',
		label: 'Pensionista',
	},
	{
		value: 'RENTISTA',
		label: 'Rentista',
	},
	{
		value: 'ESTUDIANTE',
		label: 'Estudiante',
	},
	{
		value: 'OTROS',
		label: 'Otros',
	},
];
