import { CONFIG } from 'config/config';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputCheck', {
	styles: {
		default: {
			containerComponent: {
				width: 'auto',
				flexDirection: 'row',
			},
			labelComponent: {
				flexDirection: 'row',
			},
			checkComponent: {
				color: '#002C90',
				backgroundColor: CONFIG.colors.white,
				padding: 4,
			},
			checkedMode: {
				checkComponent: {
					color: '#002C90',
					backgroundColor: CONFIG.colors.white,
					padding: 4,
				},
			},
		},
	},
});
