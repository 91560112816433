import { PickerHOC, Text, View } from 'coinscrap-webapp-core';
import React, { useContext, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as S from '../../styles';
import { DashboardFiltersContext } from '../../contexts/DashboardFilters';
import { DashboardContext } from '../../contexts/Dashboard';
import { useFetchGlobalAgentDash } from '../../../../libs/hooks/useFetchGlobalAgentDash';
import { SimulationRow } from '../SimulationRow/SimulationRow';

export const GlobalAgent = PickerHOC()(({ accessSimulation }) => {
	const { dashboard, setDataFetch, setDataValues, setPaginationValues, fetchNextData } = useContext(
		DashboardContext,
	);

	const { dashboardFilters } = useContext(DashboardFiltersContext);

	// console.log('dashboard', dashboard);
	// console.log('dashboardFilters', dashboardFilters);

	// Llamada API / VINCULATIONS
	const {
		// eslint-disable-next-line no-unused-vars
		status: fetchVinculationsStatus,
		data: fetchedVinculations,
		error: fetchVinculationsError,
		isFetching: isFetchingVinculations,
	} = useFetchGlobalAgentDash(
		dashboard.data.fetch,
		dashboard.pagination.currentPage,
		dashboard.pagination.itemsPerPage,
		dashboardFilters.filters.status.value,
		dashboardFilters.filters.dates.value,
	);

	useEffect(() => {
		// console.log('fetchVinculations', fetchVinculationsStatus);
		// console.log('isFetchingVinculations', isFetchingVinculations);
		if (!isFetchingVinculations) {
			// console.log('fetchedVinculations', fetchedVinculations);
			if (fetchVinculationsError || fetchedVinculations?.hasOwnProperty('error')) {
				// console.log('fetchVinculationsError', fetchVinculationsError);
				setDataFetch(false);
				setDataValues([]);
				setPaginationValues('hasMorePages', false);
			} else if (fetchedVinculations !== undefined) {
				if (
					fetchedVinculations.length === 0 ||
					fetchedVinculations.length < dashboard.pagination.itemsPerPage
				) {
					setPaginationValues('hasMorePages', false);
				}

				if (dashboard.data.fetch) {
					const totalVinculations = dashboard.data.data.concat(fetchedVinculations);
					setDataValues(totalVinculations);
					setDataFetch(false);
				}
			}
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedVinculations]);

	return (
		<View style={{ overflow: 'auto', height: 700, width: '95%' }} justifyStartSt id='scrollable'>
			<S.TableRowHeading isGlobalAgent>
				<Text>Nombre</Text>
				<Text>Estado</Text>
				<Text>Solicitud </Text>
				<Text>Prima</Text>
				<Text>Capital</Text>
				<Text>Owner</Text>
				<Text style={{ textAlign: 'center' }}>Fuente</Text>
				<Text style={{ textAlign: 'center' }}>Acciones</Text>
			</S.TableRowHeading>
			<InfiniteScroll
				height={550}
				dataLength={dashboard.data.data.length}
				next={fetchNextData}
				hasMore={dashboard.pagination.hasMorePages}
				loader={<Text>Cargando...</Text>}
				endMessage={<Text>- No hay más resultados -</Text>}
				scrollThreshold={1}
				scrollableTarget='scrollable'
			>
				{dashboard.data.data.map(element => {
					return (
						console.log() || (
							<View agentTableRowSt key={element.simulation.id} isGlobalAgentTableRowSt>
								<SimulationRow
									isGlobalAgent
									apiInstance={element?.apiInstance}
									vinculation={element?.vinculation}
									simulation={element?.simulation}
									agent={element?.agent}
									client={element?.client}
									authRequests={element?.clientAuthRequests}
									dniDocuments={element?.clientDniDocuments}
									testCompletions={element?.clientTestCompletions}
									currentStep={element?.currentStep}
									onContinue={simulationId =>
										accessSimulation(
											element.vinculation.vinculationId,
											element.vinculation.userId,
											simulationId,
										)
									}
								/>
							</View>
						)
					);
				})}
			</InfiniteScroll>
		</View>
	);
});
