import { CONFIG } from 'config/config';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

import {
	EnvelopesWrapper,
	UserWrapper,
	InputCheck,
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DownloadIcon from 'assets/images/svgs/DownloadIcon';
import PdfIcon from 'assets/images/svgs/PdfIcon';

import DocView from 'components/MyDocView/MyDocView';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const SignatureRead = PickerHOC(
	{
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { envelope, user } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const [loading, setLoading] = useState();

	const [documents, setDocuments] = useState(envelope.documents);

	useEffect(() => {
		let docs = envelope.documents;
		const existsIdentityDocument01 = docs.find(doc => doc.name === 'documento_identidad_01');
		if (existsIdentityDocument01) {
			docs = docs.filter(d => d.name !== 'documento_identidad_01');
		}
		const existsIdentityDocument02 = docs.find(doc => doc.name === 'documento_identidad_02');
		if (existsIdentityDocument02) {
			docs = docs.filter(d => d.name !== 'documento_identidad_02');
		}
		setDocuments(docs);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [currentDoc, setCurrentDoc] = useState(undefined);
	const [modalDocOpen, setModalDocOpen] = useState(false);
	const [read, setRead] = useState(
		(documents || []).reduce(
			(acc, act) => ({
				...acc,
				[act.id]: envelope.signStatus !== 'PENDING' ? true : false,
			}),
			{},
		),
	);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('SignatureRead');

	const { t, currentLang } = InternationalizationWrapper.use();

	const [docsList, setDocsList] = useState(CONFIG.translations[currentLang].documentsSign.docNames);

	const downloadDoc = url => {
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `doc.pdf`);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const isDisabled = useCallback(() => {
		return documents && documents.find(d => !read?.[d.id]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [read]);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.signatureRead.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('miscellaneous.saving');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					docsRead: true,
				},
			});
			CONFIG.routing.signatureRead.next(navigateTo);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e?.message?.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useEffect(() => {
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelope]);

	useEffect(() => {
		console.log('🧿 DOCUMENTS READ ', read);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [read]);

	useEffect(() => {
		setDocsList(CONFIG.translations[currentLang].documentsSign.docNames);
		return () => {};
	}, [currentLang]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);
	return (
		<Wrapper width={'1000px'}>
			<HorizontalProgressBar
				step={6}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Firma' : ''}
			/>
			<Text noT headingPrimarySt isB2B>
				{t('docRead.title')}
			</Text>
			<S.InnerWrapper>
				{(documents || []).map(d => (
					<S.DocumentWrapper key={d.id}>
						<S.DocumentWrapperText>{docsList[d.name] || d.name}</S.DocumentWrapperText>
						<S.DocumentWrapperButtons>
							<PdfIcon
								style={{
									cursor: 'pointer',
									fill: read?.[d.id] ? CONFIG.colors.primary : CONFIG.colors.grey,
								}}
								onClick={() => {
									//OPEN IN MODAL
									setCurrentDoc(d);
									setModalDocOpen(true);
								}}
							/>
							<DownloadIcon
								style={{
									cursor: 'pointer',
									fill: read?.[d.id] ? CONFIG.colors.primary : CONFIG.colors.grey,
								}}
								onClick={() => downloadDoc(d.fileUrl)}
							/>
							<InputCheck
								style={{
									padding: 4,
								}}
								//disabled={true}
								checkedIcon={<CheckBoxOutlinedIcon />}
								value={read?.[d.id]}
								onChange={() => {
									//OPEN IN MODAL
									setCurrentDoc(d);
									setModalDocOpen(true);
								}}
							/>
						</S.DocumentWrapperButtons>
					</S.DocumentWrapper>
				))}
			</S.InnerWrapper>
			{modalDocOpen && (
				<DocView
					open={modalDocOpen}
					title={docsList[currentDoc.name] || currentDoc.name}
					onClose={() => {
						setModalDocOpen(false);
						setRead(prevState => {
							return { ...prevState, [currentDoc.id]: true };
						});
					}}
					url={currentDoc.fileUrl}
					styleType='white'
					disk
				/>
			)}
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={'buttons.next'}
				backText={'buttons.back'}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
