import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	UiWrapper,
	UserWrapper,
	View,
	Button,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import BeneficiariesModal from 'components/BeneficiariesModal/BeneficiariesModal';
import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import BeneficiaryBox from 'components/BeneficiaryBox/BeneficiaryBox';

import TooltipSimple from 'config/components/tooltipSimple';

import { countriesES } from 'data/countries';
import { municipalities } from 'data/municipalities';
import { provinces } from 'data/provinces';

import isEmail from 'validator/lib/isEmail';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const ClientData = PickerHOC(
	{
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('ClientData');
	const { saveTargetProperty } = TargetsWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { target, user } = PickerHOC.use();
	const { t, currentLang } = InternationalizationWrapper.use();
	const [loading, setLoading] = useState();

	console.log(user);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	// CREATION DATA
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData')?.value;

	const [name, setName] = useState(
		user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || '',
	);
	const [surname, setSurname] = useState(user?.surname?.trim() || '');
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [email, setEmail] = useState(user?.email?.trim() || '');
	const [nationality, setNationality] = useState(
		user?.metadata?.nationality?.trim() ||
			countriesES.find(co => co.alpha2 === currentLang)?.alpha3 ||
			'esp',
	);
	const [dni, setDni] = useState(user?.metadata.dni || '');

	const [professionalSituation, setProfessionalStatus] = useState(
		user?.metadata?.professionalSituation || '',
	);
	const [otherProfessionalSituation, setOtherProfessionalSituation] = useState(
		user?.metadata?.otherProfessionalSituation || '',
	);
	const [professionalSituationListTranslation, setProfessionalSituationListTraslation] = useState(
		CONFIG.translations[currentLang].professionaList.professionalListSituation,
	);
	const [address, setAddress] = useState(user?.metadata?.address || '');
	const [postalCode, setPostalCode] = useState(user?.metadata?.postalCode || '');
	const [province, setProvince] = useState(user?.metadata?.province);
	// eslint-disable-next-line no-unused-vars
	const [country, setCountry] = useState(user?.metadata?.country || 'España');
	const [municipality, setMunicipality] = useState(user?.metadata?.municipality || 0);
	const [beneficiaryType, setBeneficiaryType] = useState(
		creationData?.details?.beneficiaryType || 'default',
	);

	const [beneficiaries, setBeneficiaries] = useState(
		creationData?.details?.beneficiaries ? creationData?.details?.beneficiaries : [],
	);

	const [showBeneficiaries, setShowBeneficiaries] = useState(
		beneficiaries?.length > 0 ? true : false,
	);

	const totalPercentage = beneficiaries.reduce(function (acc, ben) {
		return acc + ben.percentage;
	}, 0);

	// CHECKS
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [isPostalCorrect, setIsPostalCorrect] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);

	const [existsDNI, setExistsDNI] = useState(false);
	const [invalidDNIreason, setInvalidDNIreason] = useState('');

	useEffect(() => {
		const checkIfExist = beneficiaries.some(ben => ben.dni === dni);
		if (checkIfExist) {
			setExistsDNI(false);
			setInvalidDNIreason('El DNI/NIE ya ha sido declarado');
		}
		if (!checkIfExist) {
			setExistsDNI(true);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dni, beneficiaries.length, invalidDNIreason, existsDNI]);

	// SELECT OPTIONS
	let listMunicipalities = [];

	useEffect(() => {
		const findProvince = provinces.find(
			item => item.value.toString().substr(0, 2) === postalCode.toString().substr(0, 2),
		);
		if (findProvince) {
			setProvince(findProvince.label);
		} else {
			setProvince(null);
		}
		return () => {};
	}, [postalCode]);

	if (province) {
		const findProvince = provinces.find(item => item.label === province);
		listMunicipalities = municipalities.filter(item => item.CPRO === findProvince.value);
	}

	useEffect(() => {
		setShowBeneficiaries(beneficiaries.length > 0 ? true : false);
		return () => {};
	}, [beneficiaries.length]);

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.clientData.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('miscellaneous.saving');

			const professionalSituationNameESP = CONFIG.translations.es.professionaList.professionalListSituation.find(
				espSit =>
					espSit.value ===
					professionalSituationListTranslation.find(pS => pS.value === professionalSituation).value,
			).label;

			await updateUser({
				username: {
					name,
					surname,
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					professionalSituation,
					professionalSituationName: professionalSituationListTranslation.find(
						pS => pS.value === professionalSituation,
					).label,
					professionalSituationNameESP,
					dni,
					otherProfessionalSituation,
					address,
					postalCode,
					province,
					municipality,
					country,
					nationality,
					nationalityName: countriesES.find(country => country.value === nationality).label,
				},
			});
			//await new Promise((r) => setTimeout(r, 10000));
			setLoading('miscellaneous.updating');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				details: {
					...creationData?.details,
					beneficiaryType,
					beneficiaries,
				},
			});
			CONFIG.routing.clientData.next(navigateTo, user.metadata.isB2B);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e?.message?.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text noT headingPrimarySt>
							{messageError}
						</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-ClientDetailsError',
				},
			);
		}
	};

	const isDisabled = () => {
		return (
			!name ||
			!surname ||
			!phoneNumber ||
			phoneNumber.length < 12 ||
			!email ||
			!dni ||
			!professionalSituation ||
			(professionalSituation === 'OTROS' && !otherProfessionalSituation) ||
			!address ||
			!postalCode ||
			postalCode.length !== 5 ||
			!province ||
			!municipality ||
			!nationality ||
			!existsDNI ||
			!beneficiaryType ||
			(beneficiaryType === 'designed' && beneficiaries.length === 0) ||
			(beneficiaryType !== 'default' && totalPercentage < 100)
		);
	};

	// CAMBIO DE IDIOMA
	useEffect(() => {
		setProfessionalSituationListTraslation(
			CONFIG.translations[currentLang].professionaList.professionalListSituation,
		);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper width={'60%'}>
			<HorizontalProgressBar step={2} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text noT headingPrimarySt>
				{t('clientData.title')}
			</Text>

			<View inputRowSt>
				<InputLabel
					value={name}
					onChange={e => {
						setName(e.target.value);
					}}
					label={t('clientData.inputName')}
				/>
				<InputLabel
					value={surname}
					onChange={e => {
						setSurname(e.target.value);
					}}
					label={t('clientData.inputSurname')}
				/>
			</View>

			<View inputRowSt>
				<InputLabel
					value={phoneNumber}
					type={'phone'}
					onChange={value => {
						setPhoneNumber(value);
						setIsPhoneCorrect(() => (phoneNumber?.length >= 12 ? false : true));
					}}
					label={t('clientData.inputMobile')}
					// errorMsg={isPhoneCorrect ? null : 'este numero tiene demasiados caracteres'}
				/>

				<InputLabel
					onBlur={() => {
						setIsEmailCorrect(isEmail(email));
					}}
					value={email}
					onChange={e => {
						if (!isEmailCorrect) {
							setIsEmailCorrect(isEmail(e.target.value));
						}
						setEmail(e.target.value);
					}}
					label={t('clientData.inputEmail')}
					errorMsg={!isEmailCorrect && t('miscellaneous.wrongEmail')}
				/>
			</View>

			<View inputRowSt>
				<InputLabel
					value={professionalSituation || 0}
					onChange={e => {
						setProfessionalStatus(e.target.value);
					}}
					label={t('clientData.inputProffesionalContext')}
					type='select'
					selectOptions={professionalSituationListTranslation}
				/>
				{professionalSituation === 'OTROS' && (
					<View>
						<InputLabel
							value={otherProfessionalSituation}
							onChange={e => {
								setOtherProfessionalSituation(e.target.value);
							}}
							label={t('miscellaneous.anotherProffesionalContext')}
						/>
					</View>
				)}
				<InputLabel
					value={dni}
					onChange={e => {
						setDni(e.target.value);
					}}
					label={t('clientData.inputDNI')}
					errorMsg={!existsDNI && invalidDNIreason}
				></InputLabel>
			</View>

			<View inputRowSt>
				<InputLabel
					value={nationality || 0}
					onChange={e => {
						setNationality(e.target.value);
					}}
					label={t('clientData.inputNacionality')}
					type='selectCountry'
					selectOptions={countriesES.map(item => {
						return {
							label: item.label,
							value: item.value,
							alpha2: item.alpha2,
						};
					})}
				/>
				<InputLabel
					value={address}
					onChange={e => {
						setAddress(e.target.value);
					}}
					label={t('clientData.inputAdress')}
				/>
			</View>

			<View inputRowSt>
				<InputLabel
					value={postalCode}
					onChange={e => {
						if (isNaN(+e.target.value) || e.target.value.length > 5) return;

						if (!isPostalCorrect) {
							setIsPostalCorrect(e.target.value.length === 5);
						}
						setPostalCode(e.target.value);
					}}
					onBlur={() => {
						setIsPostalCorrect(postalCode.length === 5);
					}}
					label={t('clientData.inputCP')}
				/>
				<InputLabel
					value={province || ''}
					disabled
					onChange={e => {
						// console.log(e.target.value);
						// setProvince(e.target.value);
					}}
					label={t('clientData.inputProvince')}
					// type="select"
					//selectOptions={provinces}
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={municipality || 0}
					onChange={e => {
						setMunicipality(e.target.value);
					}}
					label={t('clientData.inputMunicipality')}
					type='select'
					selectOptions={listMunicipalities.map(item => {
						return {
							label: item.NOMBRE,
							value: item.NOMBRE,
						};
					})}
				/>

				<InputLabel
					value={beneficiaryType}
					onChange={e => {
						setBeneficiaryType(e.target.value);
						if (e.target.value === 'default') {
							setBeneficiaries([]);
						} else if (e.target.value === 'designed') {
							openModal(
								close => (
									<BeneficiaryModal
										close={close}
										beneficiaries={beneficiaries}
										setBeneficiaries={setBeneficiaries}
									/>
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-first-beneficiary',
								},
							);
						}
					}}
					label={t('clientData.inputBeneficiary')}
					tooltip={
						<View
							style={{ width: '10%' }}
							onClick={() => {
								openModal(
									close => (
										<BeneficiariesModal
											onClick={() => console.log('true')}
											isOpen={true}
											onClose={close}
										/>
									),
									{
										mode: 'old',
									},
								);
							}}
						>
							<TooltipSimple>
								<Text noT tooltipTitleSt>
									{t('miscellaneous.setBeneficiariesInfo')}
								</Text>
								<Text noT tooltipTextSt>
									{t('miscellaneous.clickToSeeMoreInfo')}
								</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: t('clientData.beneficiaryAppoint'), value: 'designed' },
						{ label: t('clientData.beneficiaryDefault'), value: 'default' },
					]}
				/>
			</View>
			{showBeneficiaries && (
				<View beneficiarySt>
					<Text noT labelInputSt style={{ alignSelf: 'flex-start' }}>
						{t('clientData.beneficiaryAdded')}
						{totalPercentage < 100 && <Text noT>{t('miscellaneous.mustAssign')}</Text>}
					</Text>
					{beneficiaries.map((ben, index) => {
						return (
							console.log() || (
								<BeneficiaryBox
									key={`ben-${ben.dni}`}
									ben={ben}
									onDelete={() => {
										setBeneficiaries(
											beneficiaries.filter(function (benf) {
												return benf.dni !== ben.dni;
											}),
										);
									}}
									onEdit={() => {
										openModal(
											close => (
												<BeneficiaryModal
													close={close}
													beneficiaries={beneficiaries}
													setBeneficiaries={setBeneficiaries}
													beneficiary={ben}
												/>
											),
											{
												disableClickAway: true,
												closeButton: false,
												modalClass: isMobile ? 'modal-xl' : 'modal-main',
												key: 'modal-anyadir-beneficiaries',
											},
										);
									}}
								/>
							)
						);
					})}
				</View>
			)}
			{showBeneficiaries && totalPercentage < 100 && (
				<View bottomSt style={{ marginTop: 10, alignItems: 'center' }}>
					<Button
						smallSt
						disabled={beneficiaryType === 'default'}
						onClick={() => {
							openModal(
								close => (
									<BeneficiaryModal
										close={close}
										beneficiaries={beneficiaries}
										setBeneficiaries={setBeneficiaries}
									/>
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-new-beneficiary',
								},
							);
						}}
					>
						{t('clientData.addAnotherBeneficiary')}
					</Button>
				</View>
			)}
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={'buttons.next'}
				backText={'buttons.back'}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
