import { CONFIG } from '../../config/config';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: ${isMobile ? ' 0 25px 20px 25px' : null};
	max-width: 100%;
`;

export const Close = styled.i`
	align-self: flex-end;
	color: ${CONFIG.colors.primary};
	cursor: pointer;
	display: flex;
	font-family: 'Montserrat-Bold';
	font-size: 18px;
	justify-content: flex-end;
	margin-top: 5px;
`;
