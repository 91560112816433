import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';

export const InputWrapper = styled.input`
	border: ${props => {
		if (!props.value) {
			return `1px solid ${CONFIG.colors.grey}`;
		} else {
			return `1px solid ${CONFIG.colors.primary}`;
		}
	}};
	border-radius: 1px;
	color: ${CONFIG.colors.primaryDark};
	height: 50px;
	font-family: ${CONFIG.fonts.light};
	font-size: ${isMobile ? '14px' : '18px'};
	padding: 23px 21px;

	::placeholder {
		color: ${CONFIG.colors.grey};
	}
`;
