/* eslint-disable no-undef */
import { cloneDeep } from 'lodash';
import React, { createContext, useState } from 'react';

export const DashboardFiltersContext = createContext();

const dashboardFiltersInitialContext = {
	filters: {
		shown: false,
		status: {
			active: false,
			value: [
				'PENDING',
				'PENDING_PROCESSING',
				'PENDING_FAILED',
				'ACTIVATING_PROCESSING',
				'ACTIVATION_FAILED',
				'ACTIVATING',
				'ACTIVE',
				'CANCELLED',
			],
		},
		dates: {
			active: false,
			value: {
				startDate: new Date('2022/01/01'),
				endDate: new Date(),
				key: 'selection',
			},
		},
	},
};

const DashboardFiltersProvider = ({ children }) => {
	const initialContext = cloneDeep(dashboardFiltersInitialContext);

	// FILTERS
	const setFilterShown = value => {
		setDashboardFilters(prevState => {
			const data = { ...prevState };
			data.dashboardFilters.filters.shown = value;
			return data;
		});
	};

	const setFilterValues = (key, value) => {
		setDashboardFilters(prevState => {
			const data = { ...prevState };
			data.dashboardFilters.filters[key].value = value;
			return data;
		});
	};

	const setCheckFilterValues = (key, value) => {
		setDashboardFilters(prevState => {
			const data = { ...prevState };

			const newStatus = value.target.name;
			const selectedCheckboxes = [...data.dashboardFilters.filters[key].value];

			if (!selectedCheckboxes.includes(newStatus)) {
				selectedCheckboxes.push(newStatus);
			} else {
				selectedCheckboxes.splice(selectedCheckboxes.indexOf(newStatus), 1);
			}
			data.dashboardFilters.filters[key].value = selectedCheckboxes;

			return data;
		});
	};

	const setCheckFilterActive = (key, value) => {
		setDashboardFilters(prevState => {
			const data = { ...prevState };
			if (value !== undefined) {
				data.dashboardFilters.filters[key].active = value;
			} else {
				if (!data.dashboardFilters.filters[key].value.length) {
					data.dashboardFilters.filters[key].active = false;
				} else {
					data.dashboardFilters.filters[key].active = true;
				}
			}
			return data;
		});
	};

	const setFilterDatesActive = (key, value) => {
		setDashboardFilters(prevState => {
			const data = { ...prevState };
			if (value !== undefined) {
				data.dashboardFilters.filters.dates.active = value;
			} else {
				if (
					new Date(data.dashboardFilters.filters.dates.value.startDate).toDateString() ===
						new Date('2022/01/01').toDateString() &&
					new Date(data.dashboardFilters.filters.dates.value.endDate).toDateString() ===
						new Date().toDateString()
				) {
					data.dashboardFilters.filters.dates.active = false;
				} else {
					data.dashboardFilters.filters.dates.active = true;
				}
			}
			return data;
		});
	};

	const resetFilterValues = () => {
		setDashboardFilters(prevState => {
			const initialContext = cloneDeep(dashboardFiltersInitialContext);
			const data = { ...prevState };
			data.dashboardFilters.filters = initialContext.filters;
			data.dashboardFilters.filters.status.value = [];
			return data;
		});
	};

	const dashboardFiltersState = {
		dashboardFilters: initialContext,
		setFilterShown,
		setFilterValues,
		setCheckFilterValues,
		setCheckFilterActive,
		setFilterDatesActive,
		resetFilterValues,
	};

	const [dashboardFilters, setDashboardFilters] = useState(dashboardFiltersState);

	// console.log('dashboardFilters', dashboardFilters);

	return (
		<DashboardFiltersContext.Provider value={dashboardFilters}>
			{children}
		</DashboardFiltersContext.Provider>
	);
};

export default DashboardFiltersProvider;
