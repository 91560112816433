import React from 'react';
import { CONFIG } from 'config/config';

import { EntityWrappers } from './WrapEntitiesHandlers';
import {
	ApplicationWrapper,
	NavigationWrapper,
	BackendWrapper,
	SessionWrapper,
	UiWrapper,
	InternationalizationWrapper,
} from 'coinscrap-webapp-core';

import config from '../config';
import routes from 'config/routes';

const WrappersComponent = ({ children, lang }) => {
	return (
		<ApplicationWrapper>
			<InternationalizationWrapper
				languageResources={{
					es: {
						translation: CONFIG.translations.es.main,
					},
					ru: {
						translation: CONFIG.translations.ru.main,
					},
					ua: {
						translation: CONFIG.translations.ua.main,
					},
				}}
				defaultLang={lang || 'es'}
				options={{
					useLanguageDetector: true, // true || false
					nsSeparator: undefined, // false || undefined
					keySeparator: undefined, // false || undefined
					fallbackLng: 'es', // 'lang' || undefined
					debug: false, // true || false
					debugMissing: false, // true || false
				}}
			>
				<BackendWrapper>
					<UiWrapper config={config} showDesktopScrollbarFromPixels={300}>
						<NavigationWrapper>
							<SessionWrapper
								entryPoint={{
									pageIfValid: routes.loginAgent,
									pageIfInvalid: routes.loginAgent,
								}}
							>
								<EntityWrappers>{children}</EntityWrappers>
							</SessionWrapper>
						</NavigationWrapper>
					</UiWrapper>
				</BackendWrapper>
			</InternationalizationWrapper>
		</ApplicationWrapper>
	);
};

export default WrappersComponent;
