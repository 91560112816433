import { useEffect, useRef } from 'react';

export const useInterval = (callBack, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callBack;
		return () => {};
	}, [callBack]);
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => {
				clearInterval(id);
			};
		}
		return () => {};
	}, [callBack, delay]);
};
