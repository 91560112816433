import { CONFIG } from 'config/config';
import React, { useEffect, useMemo, useState } from 'react';

import {
	EnvelopesWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	UiWrapper,
	Text,
	InternationalizationWrapper,
	UserWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { DocuSign } from 'components/DocuSign/DocuSign';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import Header from 'layout/components/Header/Header';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

/* eslint-disable no-console */
// eslint-disable-next-line react/prop-types
export const SignatureContract = PickerHOC({
	target: {
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id',
		},
		sourcePath: 'targets',
		sourceWrapper: TargetsWrapper,
	},
	envelope: {
		sourcePath: 'envelopes',
		sourceWrapper: EnvelopesWrapper,
		arrayItemMatcher: {
			type: 'param',
			paramName: 'envelopeId',
			itemPropertyPath: 'id',
		},
	},
	user: {
		sourceWrapper: UserWrapper,
		sourcePath: 'user',
	},
})(props => {
	const { target, envelope } = PickerHOC.use();
	const { refresh, getSignUrl } = EnvelopesWrapper.use();
	const [docuSignUrl, setDocuSignUrl] = useState(null);
	const [loading, setLoading] = useState();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('SignatureContract');
	const { loadTargets } = TargetsWrapper.use();
	const { t, currentLang } = InternationalizationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();

	// TARGET CREATION DATA
	const creationData = useMemo(
		() => target?.targetProperties?.find(x => x.name === 'creationData')?.value,
		[target],
	);

	const [finished, setFinished] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.signatureContract.back(goBack);
	};

	const next = async () => {
		envelope?.signStatus === 'SIGNED' && target.status === 'ACTIVE'
			? CONFIG.routing.signatureContract.nextB2C(navigateTo)
			: CONFIG.routing.signatureContract.ko(navigateTo);
	};

	// EFFECTS
	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 target', target);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (finished) {
			next();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished]);

	useEffect(() => {
		console.log('🧿 envelope', envelope);
		const signUrl = getSignUrl(envelope.id);
		setDocuSignUrl(signUrl);
		console.log('🧿 SIGN URL', signUrl);
		return () => {};
	}, [getSignUrl, target.id, envelope]);

	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);
	useLoading(!docuSignUrl || loading, loading && { loadingMessage: loading });

	return docuSignUrl ? (
		<Wrapper width={'1000px'}>
			<HorizontalProgressBar
				step={6}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Firma' : ''}
			/>
			<Text headingPrimarySt isB2B noT>
				{t('signatureContract.oneSignature')}
			</Text>

			{docuSignUrl && (
				<DocuSign
					url={docuSignUrl}
					onProcessFinished={async () => {
						setLoading('miscellaneous.updating');
						try {
							await refresh();
						} catch (e) {
							console.error('ERROR', e);
							let messageError = e?.message?.toString();
							messageError = t('miscellaneous.temporalError');
							setLoading(undefined);
							openModal(
								() => (
									<>
										<ErrorModal>{messageError}</ErrorModal>
									</>
								),
								{
									disableClickAway: true,
									closeButton: true,
								},
							);
						}
						try {
							await loadTargets([target.id]);
						} catch (e) {
							console.log('❌ ERROR', e);
						}
						setLoading(undefined);
						setFinished(true);
					}}
				/>
			)}
		</Wrapper>
	) : null;
});
