import { isMobile } from 'react-device-detect';

export default {
	small: isMobile ? '12px' : '14px',
	medium: isMobile ? '14px' : '18px',
	large: isMobile ? '22px' : '30px',

	labelMedium: isMobile ? '16px' : '20px',

	spanLarge: isMobile ? '20px' : '24px',
};
