import routes from 'config/routes';

export default (user, target, testCompletions, identityDocuments) => {
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData');
	const signData = target?.targetProperties?.find(x => x.name === 'signData');
	const mainUserDni = identityDocuments.find(d => d.main);
	const differentTestCompletions = [...new Set(testCompletions.map(tC => tC.testId))];

	//console.log('⭕ user', user);
	//console.log('⭕ target', target);
	//console.log('⭕ testCompletions', testCompletions);
	//console.log('⭕ differentTestCompletions', differentTestCompletions);
	//console.log('⭕ identityDocuments', identityDocuments);
	//console.log('⭕ creationData', creationData);
	//console.log('⭕ signData', signData);
	//console.log('⭕ mainUserDni', mainUserDni);

	const params = {
		routeParams: {
			targetId: target?.id,
		},
	};

	// USUARIO B2C NUEVO SIN DATOS (No debería darse el caso)
	// PASO 0 - simulationData
	if (!user?.metadata) {
		return {
			step: 0,
			redirectRoute: routes.simulationData,
			params,
		};
	}

	// PASO 1 B2C - simulation
	if (!creationData?.value?.price) {
		return {
			step: 1,
			redirectRoute: routes.simulation,
			params,
		};
	}

	// PASO 2 - clientData
	if (
		user?.name === 'NEW_USER' ||
		!user?.name ||
		!user?.surname ||
		!user?.phoneNumber ||
		!user?.email ||
		!user?.metadata?.postalCode
	) {
		return {
			step: 2,
			redirectRoute: routes.clientData,
			params,
		};
	}

	// PASO 3 - pbc
	if (differentTestCompletions?.length < 1) {
		return {
			step: 3,
			redirectRoute: routes.pbc,
			params,
		};
	}

	// PASO 4 - health
	if (differentTestCompletions?.length < 2) {
		return {
			step: 4,
			redirectRoute: user?.metadata?.pbcOK ? routes.health : routes.pbc,
			params,
		};
	}

	// PASO 5 - bankAccount
	if (!creationData?.value?.chargeAccount) {
		return {
			step: 5,
			redirectRoute: user?.metadata?.healthOK ? routes.bankAccount : routes.health,
			params,
		};
	}

	// PASO 6 - identityBiometricUpsert
	if (!mainUserDni || !mainUserDni?.number || mainUserDni?.verificationStatus !== 'VALID') {
		return {
			step: 6,
			redirectRoute: routes.identityBiometricUpsert,
			params,
		};
	}

	// PASO 7 - activateProduct
	if (
		target?.status === 'TEMPORARY' ||
		target?.status === 'PENDING_PROCESSING' ||
		target?.status === 'ACTIVATING_PROCESSING' ||
		target?.status === 'ACTIVATION_FAILED' ||
		target?.status === 'PENDING_FAILED'
	) {
		return {
			step: 7,
			redirectRoute: routes.activateProduct,
			params,
		};
	}

	// PASO 8 - signatureSteps
	if (target?.status === 'ACTIVATING' && !user?.metadata?.docsRead) {
		params.routeParams.envelopeId = signData.value.envelopeId;
		return {
			step: 8,
			redirectRoute: routes.signatureSteps,
			params,
		};
	}

	// PASO 9 - signatureContract
	if (
		(target?.status === 'ACTIVATING' || target?.status === 'CANCELLED') &&
		user?.metadata?.docsRead
	) {
		params.routeParams.envelopeId = signData.value.envelopeId;
		return {
			step: 9,
			redirectRoute: routes.signatureContract,
			params,
		};
	}

	// PASO 10 - productCreationFinishedB2C
	if (target?.status === 'ACTIVE') {
		return {
			step: 10,
			redirectRoute: routes.productCreationFinishedB2C,
			params,
		};
	}

	return {
		step: 0,
		redirectRoute: routes.loginClient,
		params,
	};
};
