import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	Slider,
	TargetsUtilsWrapper,
	TargetsWrapper,
	UiWrapper,
	UserWrapper,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SmallButton } from 'components/SmallButton/SmallButton';

import { getCurrency } from 'libs/Currency';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const ActivateProduct = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		useBackRoute,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('ActivateProduct');
	const [trigger, setTrigger] = useState(0);
	const [trigger2, setTrigger2] = useState(0);
	const pickerValues = PickerHOC.use();
	const { activate, postTargetCustom } = TargetsUtilsWrapper.use();
	const { target, user } = pickerValues;
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData')?.value;
	const [createdNow, setCreatedNow] = useState();
	const [finishedNow, setFinishedNow] = useState();
	const { t, currentLang } = InternationalizationWrapper.use();

	const targetStatus = target.status;
	const envelopeId = target?.signData?.envelopeId;

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	useBackRoute(undefined);

	useEffect(() => {
		console.log('🧿 CURRENT STATUS FOR CREATE', targetStatus);
		if (targetStatus === 'TEMPORARY' || targetStatus === 'PENDING_FAILED') {
			(async () => {
				try {
					setCreatedNow(true);
					await activate(target.id);
				} catch (e) {
					let messageError = e?.message?.toString();
					let status = e?.status;
					let extraMessage = '';
					console.log('❌ messageError', messageError, e);
					if (messageError.indexOf("Expected response 'output' and received '-'") !== -1) {
						messageError = 'Ha ocurrido un error desconocido';
						extraMessage = 'Revisa tus datos y vuelve a intentarlo';
						console.log('❌ messageError extraMessage', messageError, extraMessage);
					}
					if (status === 400 && messageError.indexOf('The blacklist check was triggered') >= 0) {
						// sin modal, directamente para la pantalla de KO
						CONFIG.routing.activateProduct.koBlackList(navigateTo);
						/*openModal(close => (
							<ErrorModal>
								<Text noT headingPrimarySt>
								Lo sentimos, no podemos continuar con la contratación, por favor completa el formulario a continuación y un agente se pondrá en contacto contigo lo antes posible para completar el proceso.
								</Text>
								<SmallButton
									onClick={() => {
										CONFIG.routing.activateProduct.koBlackList(navigateTo);
										close();
									}}
								>
									Contactar con agente
								</SmallButton>
							</ErrorModal>
						));*/
					} else {
						openModal(
							close => (
								<ErrorModal>
									<Text noT headingPrimarySt>
										{messageError || 'Error desconocido'}
									</Text>
									{extraMessage && (
										<Text noT headingSecondarySt>
											{extraMessage || 'Error desconocido'}
										</Text>
									)}
									<SmallButton
										onClick={() => {
											CONFIG.routing.activateProduct.koReviewData(navigateTo);
											close();
										}}
									>
										Revisar Datos
									</SmallButton>
									<SmallButton
										onClick={() => {
											setSlideIndex(1);
											setTrigger(t => t + 1);
											close();
										}}
									>
										{t('activateProduct.retry')}
									</SmallButton>
									<SmallButton
										onClick={() => {
											CONFIG.routing.activateProduct.koManualFinish(navigateTo);
											close();
										}}
									>
										Validación manual
									</SmallButton>
								</ErrorModal>
							),
							{
								disableClickAway: true,
								closeButton: false,
								key: 'modal-SimulationCreationError',
							},
						);
					}
				}
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, trigger]);

	useEffect(() => {
		console.log('🧿 CURRENT STATUS FOR SIGN', targetStatus, envelopeId, createdNow);
		if (
			(!envelopeId || createdNow || (envelopeId && targetStatus === 'ACTIVATION_FAILED')) &&
			(targetStatus === 'PENDING' || targetStatus === 'ACTIVATION_FAILED')
		) {
			(async () => {
				try {
					await postTargetCustom(target.id, 'start-activation', {});
					setFinishedNow(true);
				} catch (e) {
					openModal(
						close => (
							<ErrorModal>
								<Text noT headingPrimarySt>
									{t('activateProduct.temporalError')}
								</Text>
								<SmallButton
									onClick={() => {
										setSlideIndex(1);
										setTrigger2(t => t + 1);
										close();
									}}
								>
									{t('activateProduct.retry')}
								</SmallButton>
								<SmallButton
									onClick={() => {
										CONFIG.routing.activateProduct.koManualFinish(navigateTo);
										close();
									}}
								>
									{t('miscellaneous.signLater')}
								</SmallButton>
							</ErrorModal>
						),
						{
							disableClickAway: true,
							closeButton: false,
							key: 'modal-EnvelopesCreationError',
						},
					);
				}
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetStatus, envelopeId, createdNow, trigger2]);

	const [slideIndex, setSlideIndex] = useState(1);
	const [loading, setLoading] = useState(false);

	// NAVIGATION
	const next = async () => {
		try {
			CONFIG.routing.activateProduct.next(navigateTo, envelopeId);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e.message.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	const isDisabled = () => {
		return (
			(targetStatus !== 'ACTIVE' && targetStatus !== 'ACTIVATING') ||
			(createdNow ? !finishedNow || !envelopeId : !envelopeId) ||
			slideIndex < 4
		);
	};

	const textPeriodicidadLong = period => {
		switch (period) {
			case 'mensual':
				return t('simulation.monthly');
			case 'trimestral':
				return t('simulation.quarterly');
			case 'semestral':
				return t('simulation.semiannual');
			case 'anual':
				return t('simulation.yearly');
			default:
				return '';
		}
	};

	const textPeriodicidadLongTranslate = period => {
		switch (period) {
			case 'mensual':
				return t('simulation.mes');
			case 'trimestral':
				return t('simulation.trimestre');
			case 'semestral':
				return t('simulation.semestre');
			case 'anual':
				return t('simulation.anho');
			default:
				return '';
		}
	};

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 user', user);
		console.log('📌 target', target);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log('🧿 slideIndex', slideIndex);
		if (slideIndex < 4) {
			const timeOutId = setTimeout(() => {
				setSlideIndex(slideIndex + 1);
			}, 3500);
			return () => clearTimeout(timeOutId);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slideIndex]);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.activateProduct.back(goBack);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			{!isB2B && <HorizontalProgressBar step={5} total={6} styleWrapper={{ maxWidth: 450 }} />}
			{
				{
					1: (
						<Text noT headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							{t('activateProduct.almostDone')}
						</Text>
					),
					2: (
						<Text noT headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							{t('activateProduct.yourResume')}
						</Text>
					),
					3: (
						<Text noT headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							{t('activateProduct.quality')}
						</Text>
					),
					4: (
						<Text noT headingPrimarySt isB2B style={{ marginTop: 30, maxWidth: 550 }}>
							{t('activateProduct.quality2')}
						</Text>
					),
				}[slideIndex]
			}
			<S.InnerWrapper style={{ paddingTop: 15 }}>
				<Slider>
					{
						{
							1: (
								<S.SliderItem>
									<Text noT headingSecondarySt style={{ marginTop: 10 }}>
										{t('activateProduct.weAreWorking')}
									</Text>
								</S.SliderItem>
							),
							2: (
								<S.SliderItem>
									<Text noT headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
										{t('activateProduct.lifeEnsuranceResume')}{' '}
										{getCurrency(creationData?.periodicContribution, 2)}{' '}
										{textPeriodicidadLongTranslate(creationData?.periodicity)}{' '}
										{t('activateProduct.lifeEnsuranceResume2')}{' '}
										{getCurrency(creationData?.price, 0)}
									</Text>
									<Text noT headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
										{t('activateProduct.periocity')}{' '}
										{textPeriodicidadLong(creationData?.periodicity)}{' '}
									</Text>
									{creationData?.details?.beneficiaryType === 'default' ? (
										<Text noT headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
											{t('activateProduct.beneficiary')} {user?.name} {user?.surname}
										</Text>
									) : (
										<Text noT headingSecondarySt style={{ marginTop: 10, maxWidth: 900 }}>
											{t('activateProduct.beneficiaries')}{' '}
											{creationData?.details?.beneficiaries.map((beneficiary, index, array) => {
												if (index === 0) {
													return `${beneficiary?.name} ${beneficiary?.surname}`;
												} else if (index > 0 && index === array.length - 1) {
													return ` y ${beneficiary?.name} ${beneficiary?.surname}`;
												} else {
													return `, ${beneficiary?.name} ${beneficiary?.surname}`;
												}
											})}
										</Text>
									)}
								</S.SliderItem>
							),
							3: (
								<S.SliderItem>
									<Text noT headingSecondarySt style={{ marginTop: 10 }}>
										{t('activateProduct.feature1')}
									</Text>
								</S.SliderItem>
							),
							4: (
								<S.SliderItem>
									<Text noT headingSecondarySt style={{ marginTop: 10 }}>
										{t('activateProduct.feature3')}
									</Text>
								</S.SliderItem>
							),
						}[slideIndex]
					}
				</Slider>
				<S.SliderDots>
					<S.SliderDot selected={slideIndex === 1} />
					<S.SliderDot selected={slideIndex === 2} />
					<S.SliderDot selected={slideIndex === 3} />
					<S.SliderDot selected={slideIndex === 4} />
				</S.SliderDots>
			</S.InnerWrapper>
			<BackNextButton
				onClickNext={next}
				backText={'buttons.back'}
				isBackShown={false}
				isNextClickable={!isDisabled()}
				isNextLoading={isDisabled()}
				isNextDisabled={isDisabled()}
				marginBetween={0}
				nextText={'activateProduct.sign'}
			/>
		</Wrapper>
	);
});
