export default {
	primary: '#002C90',
	primaryLight: '#5889DD',
	primaryLighter: '#e4f0ff',
	primaryDark: '#001967',
	primaryDarker: '#000C45',

	secondary: '#ffffff',
	secondaryLight: '#ffffff',
	secondaryLighter: '#ffffff',
	secondaryDark: '#ffffff',
	secondaryDarker: '#ffffff',

	tertiary: '#333333',
	tertiaryLight: '#C1C1C1',
	tertiaryLighter: '#F4F4F4',
	tertiaryDark: '#24191B',
	tertiaryDarker: '#18090E',

	white: '#FFFFFF',
	greyLight: '#D4D4D7',
	grey: '#818181',
	greyDark: '#4a4a4a',
	black: '#20252B',

	danger: '#FF0E54',
	success: '#649544',
};
