import { CONFIG } from 'config/config';
import React, { useRef, useCallback, useState } from 'react';
import * as S from './styles';

import { UiWrapper, View, Button, Text, InternationalizationWrapper } from 'coinscrap-webapp-core';
// components
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import arrowRigth from 'assets/images/svgs/arrowRigth.svg';
import Modal from '@material-ui/core/Modal';
// configs
import CloseButton from 'components/CloseButton/CloseButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';

import { Wrapper } from 'layout/Wrapper';

const BeneficiariesModal = ({ isOpen, onClose, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	useBackground(CONFIG.colors.white);
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const { t } = InternationalizationWrapper.use();

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		node => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver(entries => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer],
	);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<View fullHeightSt style={{ backgroundColor: CONFIG.colors.white, overflowY: 'auto' }}>
				<View fullHeightSt style={{ justifyContent: 'flex-start' }}>
					<View startSt fullHeightSt>
						<View horizontalSt endSt style={{ marginTop: 10 }}>
							<CloseButton
								color={CONFIG.colors.black}
								action={() => {
									onClose();
								}}
							/>
						</View>

						<View totalCenterSt>
							<Wrapper>
								<HorizontalProgressBar step={2} total={6} styleWrapper={{ maxWidth: 450 }} />
								<Text headingPrimarySt>{t('beneficiariesModal.title')}</Text>
								<Text headingSecondarySt>{t('beneficiariesModal.description')}</Text>
								<S.StepWrapper>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>{t('beneficiariesModal.feature1')}</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>{t('beneficiariesModal.feature2')}</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>{t('beneficiariesModal.feature3')}</Text>
									</S.StepWrapperItem>
									<S.StepWrapperItem>
										<S.StepCircle>
											<img src={arrowRigth} alt={'arrow icon'} />
										</S.StepCircle>
										<Text stepsSt>{t('beneficiariesModal.feature4')}</Text>
									</S.StepWrapperItem>
								</S.StepWrapper>
								<View ref={lastPlanElementRef} style={{ height: 10 }}>
									<View ref={faqRef} />
								</View>
							</Wrapper>
						</View>

						<ArrowForwardIosIcon
							onClick={() => {
								faqRef.current.scrollIntoView({ behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: 15,
								right: 15,
								width: 20,
								height: 'auto',
								transform: 'rotate(90deg)',
								opacity: isScroll ? 1 : 0,
								cursor: 'pointer',
							}}
						></ArrowForwardIosIcon>
						<Button
							termsSt
							disabled={!!buttonDisabled}
							onClick={() => {
								onClick();
								onClose();
							}}
						>
							{t('buttons.back')}
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	);
};

export default BeneficiariesModal;
