export const docNamesUA = {
	CONDICIONES_GENERALES: 'заявка на страхование',
	SEPA: 'Авторизація банку',
	DDF: 'Datos fundamentales',
	PROMOTION: 'Condiciones de promoción',
	CCPP: 'Офіційне видання / Бюлетень',
	caser_pbc: 'Лист підтвердження',
	documento_identidad_01: 'Documento de identidad 1',
	documento_identidad_02: 'Documento de identidad 2',
};
