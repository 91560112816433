import { CONFIG } from 'config/config';
import {
	NavigationWrapper,
	SoftSwitcher,
	TargetsWrapper,
	UserWrapper,
	TestResponsesWrapper,
	IdentityDocumentsWrapper,
	UiWrapper,
	PickerHOC,
	Text,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import React, { useEffect, useState } from 'react';
import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import getRedirectRoute from 'libs/redirectRoute';
import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../../libs/hooks/useCoinscrapPageView';

export const LoadTarget = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
		targets: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
		},
		testCompletions: {
			sourceWrapper: TestResponsesWrapper,
			sourcePath: 'testCompletions',
		},
		identityDocuments: {
			sourceWrapper: IdentityDocumentsWrapper,
			sourcePath: 'identityDocuments',
		},
	},
	LoadingScreen,
)(() => {
	const { user, targets, testCompletions, identityDocuments } = PickerHOC.use();

	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('LoadTarget');
	const [targetId, setTargetId] = useState(undefined);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();

	useEffect(() => {
		const target = targets?.[0];
		const targetId = target?.id;

		if (user && target) {
			if (user?.metadata?.isB2B) {
				localStorage.setItem('B2Flow', 'B2B');
			} else {
				localStorage.setItem('B2Flow', 'B2C');
			}

			if (targetId) {
				setTargetId(targetId);
				const { redirectRoute, params } = getRedirectRoute(
					user,
					target,
					testCompletions,
					identityDocuments,
				);
				CONFIG.routing.loadTarget.next(navigateTo, redirectRoute, params);
			}
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, targets]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={user?.metadata?.isB2B} />, []);

	const ev = !targets ? 0 : !targetId ? 1 : 2;

	return (
		<Wrapper>
			<SoftSwitcher evaluate={ev}>
				<>
					<Text headingPrimarySt>Obteniendo Datos</Text>
					<Text headingSecondarySt>Espere mientras obtenemos los datos de su simulación.</Text>
				</>
				<>
					<Text headingPrimarySt>Sin simulación</Text>
					<Text headingSecondarySt>
						No se han encontrado datos de simulación existentes. Por favor, póngase en contacto con
						su agente.
					</Text>
				</>
				<>
					<Text headingPrimarySt>Redireccionando</Text>
				</>
			</SoftSwitcher>
		</Wrapper>
	);
});
