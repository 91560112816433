export default {
	m0St: {
		margin: 0,
	},
	m1St: {
		margin: 8,
	},
	m2St: {
		margin: 16,
	},
	m3St: {
		margin: 24,
	},
	m4St: {
		margin: 32,
	},
	m5St: {
		margin: 40,
	},
	m6St: {
		margin: 48,
	},
	mt0St: {
		marginTop: 0,
	},
	mt1St: {
		marginTop: 8,
	},
	mt2St: {
		marginTop: 16,
	},
	mt3St: {
		marginTop: 24,
	},
	mt4St: {
		marginTop: 32,
	},
	mt5St: {
		marginTop: 40,
	},
	mt6St: {
		marginTop: 48,
	},
	mr0St: {
		marginRight: 0,
	},
	mr1St: {
		marginRight: 8,
	},
	mr2St: {
		marginRight: 16,
	},
	mr3St: {
		marginRight: 24,
	},
	mr4St: {
		marginRight: 32,
	},
	mr5St: {
		marginRight: 40,
	},
	mr6St: {
		marginRight: 48,
	},
	mb0St: {
		marginBottom: 0,
	},
	mb1St: {
		marginBottom: 8,
	},
	mb2St: {
		marginBottom: 16,
	},
	mb3St: {
		marginBottom: 24,
	},
	mb4St: {
		marginBottom: 32,
	},
	mb5St: {
		marginBottom: 40,
	},
	mb6St: {
		marginBottom: 48,
	},
	ml0St: {
		marginLeft: 0,
	},
	ml1St: {
		marginLeft: 8,
	},
	ml2St: {
		marginLeft: 16,
	},
	ml3St: {
		marginLeft: 24,
	},
	ml4St: {
		marginLeft: 32,
	},
	ml5St: {
		marginLeft: 40,
	},
	ml6St: {
		marginLeft: 48,
	},
};
