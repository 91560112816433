import { CONFIG } from 'config/config';
import 'aliceonboarding/dist/aliceonboarding.css';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './styles';

import {
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	UserWrapper,
	TargetsWrapper,
	Text,
	Button,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile, isIOS } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

//import IframeResizer from 'iframe-resizer-react';

export const IdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true,
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper,
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id',
		},
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
})(() => {
	const { dniData, user, target } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('IdentityBiometric');
	const issuingCountry = user?.metadata?.nationalityIDS?.toUpperCase() || 'ESP';
	// eslint-disable-next-line no-unused-vars
	const [isB2B, _] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);
	const { t, currentLang } = InternationalizationWrapper.use();

	const [intentos, setIntentos] = useState(0);
	const scanManaggerReference = useRef();

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.identityBiometric.back(goBack);
	};

	let currentLanguage = 'es';

	useEffect(() => {
		console.log('📌 user', user);
		if (user.metadata.locale) {
			console.log('👉 user.metadata.locale', user.metadata.locale);
			console.log('👉 currentLang', currentLanguage);
			if (user.metadata.locale !== currentLanguage) {
				(async () => {
					await updateUser({
						...user,
						metadata: {
							...user?.metadata,
							locale: currentLanguage,
						},
					});
					console.log('👉 localeUpdated', currentLanguage);
				})();
			}
		} else {
			(async () => {
				await updateUser({
					...user,
					metadata: {
						...user?.metadata,
						locale: currentLanguage,
					},
				});
				console.log('👉 localeAdded', currentLanguage);
			})();
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	useLoading(false);
	return (
		<Wrapper>
			<HorizontalProgressBar step={4} total={6} styleWrapper={{ maxWidth: 450 }} label='' />
			<IdentityDocumentsWrapper.ScanManager
				ref={scanManaggerReference}
				style={{
					width: '100%',
					height: '100%',
				}}
				identityDocumentId={dniData?.id}
				scanConfig={{
					additionalStages: ['selfie'],
					// Custom "Scan" dependant parameters (for this case is Alice)
					metadata: {
						isIOS: isIOS,
						issuingCountry,
						typographyTheme: 'corporate',
						iconsTheme: 'modern',
						iconsVariant: 'shape',
						language: currentLanguage,
						documentCapturingType: 'all',
						documentAutoCapturing: true,
						customMessages: {
							es: {
								onboarding_stages_title: t('dniBiometric.document'),
								onboarding_sides_title_id_card: 'Documento de identidad',
								onboarding_stages_id_card: t('dniBiometric.document'),
								onboarding_stages_description: t('dniBiometric.title'),
								onboarding_stages_selfie: t('dniBiometric.selfie'),
								onboarding_sides_subtitle: t('dniBiometric.dniExplanation'),
								onboarding_sides_front: t('dniBiometric.frontDni'),
								onboarding_doc_capturer_front: t('dniBiometric.frontDni'),
								onboarding_sides_back: t('dniBiometric.backDni'),
								onboarding_doc_capturer_back: t('dniBiometric.backDni'),
								onboarding_alert_title_really_sure_to_exit: t('dniBiometric.exitAlice'),
								onboarding_alert_subtitle_really_sure_to_exit: t('dniBiometric.sureYouExit'),
								onboarding_doc_capturer_review_ok: t('dniBiometric.yes'),
								onboarding_country_selector_cancel: t('dniBiometric.no'),
								onboarding_doc_capturer_subtitle: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_center_face: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_subtitle: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_wrong_yaw_angle: t('dniBiometric.pleaseFaceSelfie'),
								onboarding_selfie_capturer_get_closer: t('dniBiometric.pleaseZoomInSelfie'),
								onboarding_doc_capturer_retry: t('dniBiometric.retry'),
								onboarding_credit_card_alert_retry: t('dniBiometric.retry'),
								onboarding_doc_capturer_choose_source: t('dniBiometric.chooseSource'),
								onboarding_doc_capturer_source_camera: t('dniBiometric.camara'),
								onboarding_other_trusted_docs_capturer_camera: t('dniBiometric.camara'),
								onboarding_doc_capturer_source_file: t('dniBiometric.file'),
								onboarding_close: t('dniBiometric.close'),
								onboarding_doc_capturer_processing_stay_still: t('dniBiometric.stillScaning'),
								onboarding_doc_capturer_check_position: t('dniBiometric.square'),
								onboarding_doc_capturer_alert_subtitle_generic_problem: t(
									'dniBiometric.genericProblemsDni',
								),
								onboarding_doc_capturer_alert_title_generic_problem: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_alert_title_poor_quality: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_alert_title_timeout: t('dniBiometric.problemsReadingDni'),
								onboarding_nfc_doc_capturer_error_generic_title: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_subtitle_review: t('dniBiometric.capturerReview'),
								onboarding_doc_capturer_title_review: t('dniBiometric.reviewTitle'),
								onboarding_rotate: t('dniBiometric.onboarding_rotate'),
								onboarding_doc_capturer_review_back: t(
									'dniBiometric.onboarding_doc_capturer_review_back',
								),
								onboarding_selfie_capturer_adding: t(
									'dniBiometric.onboarding_selfie_capturer_adding',
								),
								onboarding_doc_capturer_adding: t('dniBiometric.uploadingPhoto'),
								onboarding_error_title_technical: t('dniBiometric.technical'),
								onboarding_error_subtitle_technical: t('dniBiometric.technical2'),
								onboarding_doc_capturer_manual: t('dniBiometric.manualScan'),
								onboarding_doc_capturer_alert_subtitle_timeout: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_timeout',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_doc: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_doc',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_doc_residencepermit_esp: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_doc_residencepermit_esp',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_side: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_side',
								),
								onboarding_sides_done_button: t('dniBiometric.next'),
								onboarding_stages_done_button: t('dniBiometric.next'),
								onboarding_other_trusted_docs_capturer_continue: t('dniBiometric.next'),
							},
							ru: {
								onboarding_stages_title: t('dniBiometric.document'),
								onboarding_sides_title_id_card: 'Documento de identidad',
								onboarding_stages_id_card: t('dniBiometric.document'),
								onboarding_stages_description: t('dniBiometric.title'),
								onboarding_stages_selfie: t('dniBiometric.selfie'),
								onboarding_sides_subtitle: t('dniBiometric.dniExplanation'),
								onboarding_sides_front: t('dniBiometric.frontDni'),
								onboarding_doc_capturer_front: t('dniBiometric.frontDni'),
								onboarding_sides_back: t('dniBiometric.backDni'),
								onboarding_doc_capturer_back: t('dniBiometric.backDni'),
								onboarding_alert_title_really_sure_to_exit: t('dniBiometric.exitAlice'),
								onboarding_alert_subtitle_really_sure_to_exit: t('dniBiometric.sureYouExit'),
								onboarding_doc_capturer_review_ok: t('dniBiometric.yes'),
								onboarding_country_selector_cancel: t('dniBiometric.no'),
								onboarding_doc_capturer_subtitle: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_center_face: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_subtitle: t('dniBiometric.continueSelfie'),
								onboarding_selfie_capturer_wrong_yaw_angle: t('dniBiometric.pleaseFaceSelfie'),
								onboarding_doc_capturer_retry: t('dniBiometric.retry'),
								onboarding_credit_card_alert_retry: t('dniBiometric.retry'),
								onboarding_doc_capturer_choose_source: t('dniBiometric.chooseSource'),
								onboarding_doc_capturer_source_camera: t('dniBiometric.camara'),
								onboarding_other_trusted_docs_capturer_camera: t('dniBiometric.camara'),
								onboarding_doc_capturer_source_file: t('dniBiometric.file'),
								onboarding_close: t('dniBiometric.close'),
								onboarding_doc_capturer_processing_stay_still: t('dniBiometric.stillScaning'),
								onboarding_doc_capturer_check_position: t('dniBiometric.square'),
								onboarding_doc_capturer_alert_subtitle_generic_problem: t(
									'dniBiometric.genericProblemsDni',
								),
								onboarding_doc_capturer_alert_title_generic_problem: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_alert_title_poor_quality: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_alert_title_timeout: t('dniBiometric.problemsReadingDni'),
								onboarding_nfc_doc_capturer_error_generic_title: t(
									'dniBiometric.problemsReadingDni',
								),
								onboarding_doc_capturer_subtitle_review: t('dniBiometric.capturerReview'),
								onboarding_doc_capturer_title_review: t('dniBiometric.reviewTitle'),
								onboarding_rotate: t('dniBiometric.onboarding_rotate'),
								onboarding_doc_capturer_review_back: t(
									'dniBiometric.onboarding_doc_capturer_review_back',
								),
								onboarding_selfie_capturer_adding: t(
									'dniBiometric.onboarding_selfie_capturer_adding',
								),
								onboarding_doc_capturer_adding: t('dniBiometric.uploadingPhoto'),
								onboarding_error_title_technical: t('dniBiometric.technical'),
								onboarding_error_subtitle_technical: t('dniBiometric.technical2'),
								onboarding_doc_capturer_manual: t('dniBiometric.manualScan'),
								onboarding_doc_capturer_alert_subtitle_timeout: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_timeout',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_doc: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_doc',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_doc_residencepermit_esp: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_doc_residencepermit_esp',
								),
								onboarding_doc_capturer_alert_subtitle_wrong_side: t(
									'dniBiometric.onboarding_doc_capturer_alert_subtitle_wrong_side',
								),
								onboarding_sides_done_button: t('dniBiometric.next'),
								onboarding_stages_done_button: t('dniBiometric.next'),
								onboarding_other_trusted_docs_capturer_continue: t('dniBiometric.next'),
							},
							onboarding_country_selector_start: t('dniBiometric.next'),
						},
						customTheme: {
							alice_color_primary: CONFIG.colors.primary,
							alice_color_secondary: CONFIG.colors.grey,
							alice_color_accent: CONFIG.colors.tertiary,
							/*
							NOT available in BACKEND ALL IN ONE PROCESS
						alice_title_font_family: CONFIG.fonts.regular,
						alice_font_family: CONFIG.fonts.regular,
						*/
						},
					},
				}}
				onSuccess={data => {
					// Respuesta OK, continuamos con lo que venga
					const aliceDNI = data?.value?.data?.report?.documents[0]?.summary_fields?.find(
						sf => sf?.name === 'id_number',
					)?.value;
					const sameBenefDNI = target?.creationData?.details?.beneficiaries?.find(
						be => be?.dni.toUpperCase() === aliceDNI.toUpperCase(),
					);

					console.log('aliceDNI', aliceDNI);
					console.log('sameBenefDNI', sameBenefDNI);

					if (sameBenefDNI) {
						openModal(
							close => (
								<ErrorModal>
									<Text headingPrimarySt>{t('dniBiometric.errorListBenefeciaries')}</Text>
									<Text headingSecondarySt>{t('dniBiometric.cannotAddYourself')}</Text>
									<Button
										style={{ marginTop: 30, marginBottom: 0 }}
										onClick={() => {
											close();
											CONFIG.routing.identityBiometric.review(navigateTo);
										}}
									>
										Modificar
									</Button>
								</ErrorModal>
							),
							{
								executeOnClose: () => {
									CONFIG.routing.identityBiometric.review(navigateTo);
								},
							},
						);
					} else {
						CONFIG.routing.identityBiometric.next(navigateTo);
					}
				}}
				onFailure={() => {
					// Legacy: (modo iframe creado a mano)
					// Cuando falle el scaneo de DNI
					//  Para reintentar se debe crear uno nuevo
					//  En este caso el modal lleva a la pantalla anterior
					//  (al volver a esta pantalla se crea otro scan nuevo)
					//  pero en caso de querer mantener al user en la misma pantalla
					//  habría que hacer un scan nuvo
					//  (NO UN DOCUMENTO, un scan, el documento se mantiene el mismo)
					//  y meter su ID en el iframe

					openModal(
						close => (
							<S.ModalWrapper>
								<Text headingPrimarySt>{t('miscellaneous.notReadCorrectly')}</Text>
								<Button
									style={{ marginTop: 30 }}
									onClick={() => {
										// Si al tercer intento vuelve a fallar el proceso se le lleva al formulario KO de contacto. De otra forma el usuario queda aquí en bucle sin poder avanzar.
										if (intentos < 2) {
											setIntentos(intentos + 1);
											// Nuevo: (con componente ScanManager)
											// Ahora si falla se puede reintentar el scaneo sin tener que volver atras en la pantalla
											scanManaggerReference && scanManaggerReference.current.reset();

											close();
										} else {
											close();
											CONFIG.routing.identityBiometric.ko(navigateTo);
										}
									}}
								>
									{t('dniBiometric.retry')}
								</Button>
							</S.ModalWrapper>
						),
						/*{
							executeOnClose: () => back(),
						},*/
					);
				}}
				onCancel={() => {
					back();
				}}
			/>
		</Wrapper>
	);
});
