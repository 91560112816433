import routes from 'config/routes';
import React, { Component } from 'react';
import jwt from 'jsonwebtoken';

import {
	ApplicationWrapper,
	EnvelopesWrapper,
	Module,
	Page,
	VinculationWrapper,
	WithSession,
} from 'coinscrap-webapp-core';
import { startUpController } from 'libs/startUpController';

//WRAPPERS
import WrappersComponent from 'wrappers/WrappersComponent';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';

// LAYOUT
import { MainLayout } from 'layout/MainLayout';
import Animations from 'layout/Animations/Animations';

// HANDLERS
import { Continue } from 'views/_Handlers/00_AccessPoint/Continue';
import { LoadTarget } from 'views/_Handlers/01_LoadTarget/LoadTarget';

// FLUJO CLIENTE Y AGENTE
import { LoginClient } from 'views/00_Login/LoginClient';
import { LoginAgent } from 'views/00_Login/LoginAgent';

import { Landing } from './views/01_AccessPoint/Landing';
import { Dashboard } from 'views/01_AccessPoint/Dashboard';

import { UpsertProduct } from 'views/02_UpsertProduct/UpsertProduct';

import { SimulationData } from 'views/03_SimulationData/SimulationData';
import { SimulationDataAgent } from 'views/03_SimulationData/SimulationDataAgent';

import { Simulation } from 'views/04_Simulation/Simulation';
import { SimulationAgent } from 'views/04_Simulation/SimulationAgent';

import { ClientData } from 'views/05_ClientData/ClientData';
import { ClientDataAgent } from 'views/05_ClientData/ClientDataAgent';

import { FollowUp } from 'views/06_FollowUp/FollowUp';

import { Pbc } from 'views/07_Questionnaires/Pbc';
import { Health } from 'views/07_Questionnaires/Health';

import { BankAccount } from 'views/08_BankAccount/BankAccount';

import { IdentityBiometricSteps } from 'views/09_IdentityBiometricSteps/IdentityBiometricSteps';
import { IdentityBiometricSelectDoc } from 'views/10_IdentityBiometricSelectDoc/IdentityBiometricSelectDoc';
import { IdentityBiometricUpsert } from 'views/11_IdentityBiometricUpsert/IdentityBiometricUpsert';
import { IdentityBiometric } from 'views/12_IdentityBiometric/IdentityBiometric';
import { IdentityBiometricExtraDocs } from 'views/13_IdentityBiometricExtraDocs/IdentityBiometricExtraDocs';

import { ActivateProduct } from 'views/14_ActivateProduct/ActivateProduct';

import { SignatureSteps } from 'views/15_SignatureSteps/SignatureSteps';
import { SignatureRead } from 'views/16_SignatureRead/SignatureRead';
import { SignatureContract } from 'views/17_SignatureContract/SignatureContract';

import { ProductCreationFinishedB2C } from 'views/18_ProductCreationFinished/ProductCreationFinishedB2C';
import { ProductCreationFinishedManual } from 'views/18_ProductCreationFinished/ProductCreationFinishedManual';

//COMMON
import { CannotConfirm } from 'views/_Common/CannotConfirm/CannotConfirm';
import DashboardFiltersProvider from './views/01_AccessPoint/contexts/DashboardFilters';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DashboardProvider from './views/01_AccessPoint/contexts/Dashboard';

require('intersection-observer');

let lang;
//APP INIT
ApplicationWrapper.init({
	projectName: 'ck-vr',
	contextTheme: 'light',
	disableNative: true,
	onStartupParams: ({ language: receivedLang }) => {
		startUpController.params = {};
		lang = receivedLang;
	},
});

const queryClient = new QueryClient();

export default class App extends Component {
	render() {
		return (
			<>
				<WrappersComponent lang={lang}>
					{/*B2B AGENTE*/}
					<Module path={'/agent'} defaultRoute={routes.loginAgent}>
						<MainLayout>
							<Page
								name={routes.loginAgent}
								component={LoginAgent}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Module path={'/internal'} defaultRoute={routes.dashboard}>
								<WithSession
									pageNoSession={routes.loginAgent}
									//availableRoles={['AGENT', 'GLOBAL_AGENT']}
									sessionValidator={sessionData => {
										if (sessionData) {
											//console.log(sessionData);
											let decodedToken = jwt.decode(sessionData.token);
											//console.log(decodedToken);
											if (
												decodedToken?.roles?.length &&
												decodedToken.roles.find(
													role => role.identifier === 'AGENT' || role.identifier === 'GLOBAL_AGENT',
												)
											) {
												return sessionData.token;
											}
											return false;
										}
										return false;
									}}
								>
									<DashboardProvider>
										<DashboardFiltersProvider>
											<QueryClientProvider client={queryClient}>
												<Page
													path={'/Dashboard'}
													name={routes.dashboard}
													component={Dashboard}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</QueryClientProvider>
										</DashboardFiltersProvider>
									</DashboardProvider>
									<Module
										path={
											'/vinculations/:vinculationId/clients/:clientId/simulations/:simulationId'
										}
										defaultRoute={routes.simulationDataAgent}
									>
										<VinculationWrapper fromParam='vinculationId'>
											<ClientSimulationWrapper>
												<Page
													path={'/simulationDataAgent'}
													name={routes.simulationDataAgent}
													component={SimulationDataAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/ClientDataAgent'}
													name={routes.clientDataAgent}
													component={ClientDataAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/SimulationAgent'}
													name={routes.simulationAgent}
													component={SimulationAgent}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/FollowUp'}
													name={routes.followUp}
													component={FollowUp}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</ClientSimulationWrapper>
										</VinculationWrapper>
									</Module>
								</WithSession>
							</Module>
						</MainLayout>
					</Module>

					{/*
            <FLUJOS CLIENTE>
                  B2B:
                    → /continue → /loadTarget → /simulation → ...
                  B2C:
                    → /client → /loginClient → /upsertProduct → /simulationData → /simulation → ...
          */}

					{/*B2B CONTINUE LINK*/}
					<Page
						name={routes.continue}
						pushAnimation={Animations.push}
						backAnimation={Animations.back}
						component={Continue}
					/>

					{/*B2B y B2C CLIENTE*/}
					<Module path='/client' defaultRoute={routes.loginClient}>
						<MainLayout>
							<Page
								name={routes.loginClient}
								component={LoginClient}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Page
								path={'/landing'}
								name={routes.landing}
								component={Landing}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Page
								path={'/loadTarget'}
								name={routes.loadTarget}
								component={LoadTarget}
								pushAnimation={Animations.push}
								backAnimation={Animations.back}
							/>
							<Module path='/internal' defaultRoute={routes.upsertProduct}>
								<WithSession pageNoSession={routes.loginClient}>
									<Page
										name={routes.upsertProduct}
										component={UpsertProduct}
										pushAnimation={Animations.push}
										backAnimation={Animations.back}
									/>
									<Module path='/target/:targetId'>
										<Page
											path={'/simulationData'}
											name={routes.simulationData}
											component={SimulationData}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/simulation'}
											name={routes.simulation}
											component={Simulation}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Page
											path={'/clientData'}
											name={routes.clientData}
											component={ClientData}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/questionnaires'} defaultRoute={routes.pbc}>
											<Page
												path={'/pbc'}
												name={routes.pbc}
												component={Pbc}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/health'}
												name={routes.health}
												component={Health}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/bankAccount'}
											name={routes.bankAccount}
											component={BankAccount}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module
											path={'/identityDocuments'}
											defaultRoute={routes.identityBiometricUpsert}
										>
											<Page
												path={'/identityBiometricSteps'}
												name={routes.identityBiometricSteps}
												component={IdentityBiometricSteps}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometricUpsert'}
												name={routes.identityBiometricUpsert}
												component={IdentityBiometricUpsert}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometricSelectDoc'}
												name={routes.identityBiometricSelectDoc}
												component={IdentityBiometricSelectDoc}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometric'}
												name={routes.identityBiometric}
												component={IdentityBiometric}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
											<Page
												path={'/identityBiometricExtraDocs'}
												name={routes.identityBiometricExtraDocs}
												component={IdentityBiometricExtraDocs}
												pushAnimation={Animations.push}
												backAnimation={Animations.back}
											/>
										</Module>
										<Page
											path={'/activateProduct'}
											name={routes.activateProduct}
											component={ActivateProduct}
											pushAnimation={Animations.push}
											backAnimation={Animations.back}
										/>
										<Module path={'/sign/:envelopeId'} defaultRoute={routes.signatureSteps}>
											<EnvelopesWrapper>
												<Page
													path={'/signatureSteps'}
													name={routes.signatureSteps}
													component={SignatureSteps}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureRead'}
													name={routes.signatureRead}
													component={SignatureRead}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/signatureContract'}
													name={routes.signatureContract}
													component={SignatureContract}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinishedB2C'}
													name={routes.productCreationFinishedB2C}
													component={ProductCreationFinishedB2C}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
												<Page
													path={'/productCreationFinishedManual'}
													name={routes.productCreationFinishedManual}
													component={ProductCreationFinishedManual}
													pushAnimation={Animations.push}
													backAnimation={Animations.back}
												/>
											</EnvelopesWrapper>
										</Module>
									</Module>
									<Page
										path={'/cannotConfirm'}
										name={routes.cannotConfirm}
										component={CannotConfirm}
										pushAnimation={Animations.push}
										backAnimation={Animations.back}
									/>
								</WithSession>
							</Module>
						</MainLayout>
					</Module>
				</WrappersComponent>
			</>
		);
	}
}
