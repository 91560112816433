export const statusTypes = {
	TEMPORARY: 'Temporal',
	PENDING: 'Iniciando Proceso',
	PENDING_PROCESSING: 'Iniciando Póliza',
	PENDING_FAILED: 'Error Iniciando Póliza',
	ACTIVATING_PROCESSING: 'Creando Póliza',
	ACTIVATION_FAILED: 'Error Creando Póliza',
	ACTIVATING: 'Pendiente Firma',
	ACTIVE: 'Activo',
	CANCELLED: 'Firma Rechazada',
};

export const statusList = [
	{
		value: 'TEMPORARY',
		label: 'Temporal',
	},
	{
		value: 'PENDING',
		label: 'Iniciando Proceso',
	},
	{
		value: 'PENDING_PROCESSING',
		label: 'Iniciando Póliza',
	},
	{
		value: 'PENDING_FAILED',
		label: 'Error Iniciando Póliza',
	},
	{
		value: 'ACTIVATING_PROCESSING',
		label: 'Creando Póliza',
	},
	{
		value: 'ACTIVATION_FAILED',
		label: 'Error Creando Póliza',
	},
	{
		value: 'ACTIVATING',
		label: 'Pendiente Firma',
	},
	{
		value: 'ACTIVE',
		label: 'Activo',
	},
	{
		value: 'CANCELLED',
		label: 'Firma Rechazada',
	},
];

export function getStatus(status) {
	switch (status) {
		case 'TEMPORARY':
			return statusTypes.TEMPORARY;
		case 'PENDING_PROCESSING':
			return statusTypes.PENDING_PROCESSING;
		case 'PENDING_FAILED':
			return statusTypes.PENDING_FAILED;
		case 'PENDING':
			return statusTypes.PENDING;
		case 'ACTIVATING_PROCESSING':
			return statusTypes.ACTIVATING_PROCESSING;
		case 'ACTIVATION_FAILED':
			return statusTypes.ACTIVATION_FAILED;
		case 'ACTIVATING':
			return statusTypes.ACTIVATING;
		case 'ACTIVE':
			return statusTypes.ACTIVE;
		case 'CANCELLED':
			return statusTypes.CANCELLED;
		default:
			return statusTypes.TEMPORARY;
	}
}
