import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	UserWrapper,
	View,
	VinculationWrapper,
	Button,
	Text,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import BeneficiariesModal from 'components/BeneficiariesModal/BeneficiariesModal';
import BeneficiaryBox from 'components/BeneficiaryBox/BeneficiaryBox';
import BeneficiaryModal from 'components/BeneficiaryModal/BeneficiaryModal';
import InputLabel from 'components/InputLabel/InputLabel';

import TooltipSimple from 'config/components/tooltipSimple';

import { municipalities } from 'data/municipalities';
import { provinces } from 'data/provinces';

import isEmail from 'validator/lib/isEmail';
import Header from 'layout/components/Header/Header';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';

import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';

export const ClientDataAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper,
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper,
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper,
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper,
	},
})(() => {
	const { updateClient, updateSimulation, loadSimulationForClient } = ClientSimulationWrapper.use();

	const { navigateTo } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const pickerValues = PickerHOC.use();

	const { client, simulation } = pickerValues;

	useEffect(() => {
		(async () => {
			//TODO QUITAR CUANDO EN CHANGE PRODUCT AL ACTUALIZAR EL PRODUCT SE ACTUALICE BIEN EL TARGET SIN TENER QUE HACER ESTO AL VOLVER AL SUMMARY
			await loadSimulationForClient(client.id, simulation.id);
		})();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const creationData = simulation?.targetProperties?.find(x => x.name === 'creationData')?.value;

	const [name, setName] = useState(client?.name?.trim() || '');
	const [surname, setSurname] = useState(client?.surname || '');
	const [address, setAddress] = useState(client?.metadata?.address || '');
	const [postalCode, setPostalCode] = useState(client?.metadata?.postalCode || '');
	const [province, setProvince] = useState(client?.metadata?.province);
	const [municipality, setMunicipality] = useState(client?.metadata?.municipality || 0);
	const [email, setEmail] = useState(client?.email || '');

	const [beneficiaryType, setBeneficiaryType] = useState(
		creationData?.details?.beneficiaryType || 'default',
	);

	const [beneficiaries, setBeneficiaries] = useState(
		creationData?.details?.beneficiaries ? creationData?.details?.beneficiaries : [],
	);

	const [showBeneficiaries, setShowBeneficiaries] = useState(
		beneficiaries?.length > 0 ? true : false,
	);

	const totalPercentage = beneficiaries.reduce(function (acc, ben) {
		return acc + ben.percentage;
	}, 0);

	// isInvalid
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [isPostalCorrect, setIsPostalCorrect] = useState(true);

	useEffect(() => {
		const findProvince = provinces.find(
			item => item.value.toString().substr(0, 2) === postalCode.toString().substr(0, 2),
		);
		if (findProvince) {
			console.log('findProvince', findProvince);
			setProvince(findProvince.label);
		} else {
			setProvince(null);
		}
		return () => {};
	}, [postalCode]);

	let listMunicipalities = [];

	if (province) {
		const findProvince = provinces.find(item => item.label === province);
		listMunicipalities = municipalities.filter(item => item.CPRO === findProvince.value);
	}

	useEffect(() => {
		setShowBeneficiaries(beneficiaries.length > 0 ? true : false);
		return () => {};
	}, [beneficiaries.length]);

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 client', client);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.clientDataAgent.back(navigateTo);
	};

	const next = async () => {
		try {
			setLoading('Guardando datos de cliente');
			await updateClient({
				name,
				surname,
				email,
				metadata: {
					...client?.metadata,
					address,
					postalCode,
					province,
					municipality,
				},
			});
			setLoading('Guardando datos de simulación');

			const creationData = simulation?.targetProperties?.find(x => x.name === 'creationData')
				?.value;

			await updateSimulation({
				details: {
					...creationData?.details,
					beneficiaryType,
					beneficiaries,
				},
			});
			setLoading(undefined);
			CONFIG.routing.clientDataAgent.next(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un generando la simulación</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-SaveSimulationError',
				},
			);
		}
	};

	const isDisabled = () => {
		return (
			(email !== '' && !isEmail(email)) ||
			(beneficiaryType === 'designed' && beneficiaries.length === 0) ||
			(beneficiaryType !== 'default' && totalPercentage < 100)
		);
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'60%'}>
			<Text headingPrimarySt>Datos opcionales</Text>
			<Text headingSecondarySt>
				Los siguientes datos no influirán en el cálculo de la prima. Puedes rellenarlos ahora o que
				sea el propio cliente el que lo haga durante el proceso de contratación.
			</Text>
			<View inputRowSt>
				<InputLabel
					value={name}
					onChange={e => {
						setName(e.target.value);
					}}
					label='Nombre'
				/>
				<InputLabel
					value={surname}
					onChange={e => {
						setSurname(e.target.value);
					}}
					label='Apellidos'
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={address}
					onChange={e => {
						setAddress(e.target.value);
					}}
					label='Calle, Número, Piso, Puerta'
				/>
				<InputLabel
					value={postalCode}
					onChange={e => {
						if (isNaN(+e.target.value) || e.target.value.length > 5) return;

						if (!isPostalCorrect) {
							setIsEmailCorrect(e.target.value.length === 5);
						}
						setPostalCode(e.target.value);
					}}
					onBlur={() => {
						setIsPostalCorrect(postalCode.length === 5);
					}}
					label='Código postal'
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={province || ''}
					disabled
					onChange={e => {
						// console.log(e.target.value);
						// setProvince(e.target.value);
					}}
					label='Provincia'
					// type="select"
					//selectOptions={provinces}
				/>
				<InputLabel
					value={municipality || 0}
					onChange={e => {
						setMunicipality(e.target.value);
					}}
					label='Municipio'
					type='select'
					selectOptions={listMunicipalities.map(item => {
						return {
							label: item.NOMBRE,
							value: item.NOMBRE,
						};
					})}
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					onBlur={() => {
						setIsEmailCorrect(isEmail(email));
					}}
					value={email}
					onChange={e => {
						if (!isEmailCorrect) {
							setIsEmailCorrect(isEmail(e.target.value));
						}
						setEmail(e.target.value);
					}}
					label='Email'
					errorMsg={!isEmailCorrect && `Email inválido`}
				/>
				<InputLabel
					value={beneficiaryType}
					onChange={e => {
						setBeneficiaryType(e.target.value);
						if (e.target.value === 'default') {
							setBeneficiaries([]);
						} else if (e.target.value === 'designed') {
							openModal(
								close => (
									<BeneficiaryModal
										close={close}
										beneficiaries={beneficiaries}
										setBeneficiaries={setBeneficiaries}
									/>
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-first-beneficiary',
								},
							);
						}
					}}
					label='Beneficiario'
					tooltip={
						<View
							style={{ width: '10%' }}
							onClick={() => {
								openModal(
									close => (
										<BeneficiariesModal
											onClick={() => console.log('true')}
											isOpen={true}
											onClose={close}
										/>
									),
									{
										mode: 'old',
									},
								);
							}}
						>
							<TooltipSimple>
								<Text tooltipTitleSt>Información sobre designación de beneficiarios.</Text>
								<Text tooltipTextSt>Haz click para ver más información.</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: 'Designar beneficiario', value: 'designed' },
						{ label: 'Beneficiario por defecto ', value: 'default' },
					]}
				/>
			</View>
			{showBeneficiaries && (
				<View beneficiarySt>
					<Text labelInputSt style={{ alignSelf: 'flex-start' }}>
						Beneficiarios añadidos
						{totalPercentage < 100 && <Text>Debes asignar un 100 %</Text>}
					</Text>
					{beneficiaries.map((ben, index) => {
						return (
							console.log() || (
								<BeneficiaryBox
									key={`ben-${ben.dni}`}
									ben={ben}
									onDelete={() => {
										setBeneficiaries(
											beneficiaries.filter(function (benf) {
												return benf.dni !== ben.dni;
											}),
										);
									}}
									onEdit={() => {
										openModal(
											close => (
												<BeneficiaryModal
													close={close}
													beneficiaries={beneficiaries}
													setBeneficiaries={setBeneficiaries}
													beneficiary={ben}
												/>
											),
											{
												disableClickAway: true,
												closeButton: false,
												modalClass: isMobile ? 'modal-xl' : 'modal-main',
												key: 'modal-anyadir-beneficiaries',
											},
										);
									}}
								/>
							)
						);
					})}
				</View>
			)}
			{showBeneficiaries && totalPercentage < 100 && (
				<View bottomSt style={{ marginTop: 10, alignItems: 'center' }}>
					<Button
						smallSt
						disabled={beneficiaryType === 'default'}
						onClick={() => {
							openModal(
								close => (
									<BeneficiaryModal
										close={close}
										beneficiaries={beneficiaries}
										setBeneficiaries={setBeneficiaries}
									/>
								),
								{
									disableClickAway: true,
									closeButton: false,
									modalClass: isMobile ? 'modal-xl' : 'modal-main',
									key: 'modal-anyadir-new-beneficiary',
								},
							);
						}}
					>
						Añadir otro
					</Button>
				</View>
			)}

			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				justifyContent={'evenly'}
				nextText={'SIMULACION'}
			/>
		</Wrapper>
	);
});
