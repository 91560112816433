import React from 'react';

const TextSpan = ({ children, style = {}, onClick = () => console.log('CLICK TEXT SPAN') }) => {
	return (
		<span onClick={onClick} style={{ fontSize: 'inherit', fontFamily: 'inherit', ...style }}>
			{children}
		</span>
	);
};

export default TextSpan;
