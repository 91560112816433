export const smokeTypesUA = [
	{ label: 'Люлька для куріння', value: 'Pipas' },
	{ label: 'Сигари', value: 'Puros' },
	{ label: 'Сигарети', value: 'Cigarrillos' },
	{ label: 'Електронна сигарета', value: 'CigarrilloElectronico' },
];

export const alcoholTypesUA = [
	{ label: 'Херес', value: 'Jerez' },
	{ label: 'Вино', value: 'Vino' },
	{ label: 'Пиво', value: 'Cerveza' },
	{ label: 'Віскі', value: 'Whisky' },
	{ label: 'Лікер', value: 'Licor' },
];
