import React from "react";
import { ReactComponent as KeyBoardDown } from "assets/images/svgs/keyboardarrowdown.svg";

const KeyboardArrowDownIcon = () => {
  return (
    <div style={{ cursor: "pointer" }}>
      <KeyBoardDown />
    </div>
  );
};
export default KeyboardArrowDownIcon;
