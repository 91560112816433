import { CONFIG } from '../config';
import styled from 'styled-components/macro';

import { isMobile } from 'react-device-detect';

export const SpanCurrency = styled.span`
	color: ${CONFIG.colors.tertiary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${isMobile ? '18px' : '26px'};
`;

export const SpanBold = styled.span`
	font-family: ${CONFIG.fonts.bold};
	font-size: ${CONFIG.fontSizes.medium};
`;
