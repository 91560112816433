import React from 'react';
import * as S from './styles';

export const SmallButton = ({ children, disabled, style, onClick }) => {
  return (
    <S.SmallButton onClick={onClick} disabled={disabled} style={{ ...style }}>
      {children}
    </S.SmallButton>
  );
};
