import './layout';
import './view';
import './text';
import './textCurrency';
import './textField';
import './button';
import './image';
import './inputCheck';
import './inputImage';
import './imageSelector';
import './input';
import './inputDate';
import './inputSelect';
import './inputSwitch';
import './inputSuggest';
import './selectItem';
import './radioOption';
import './loading';
import './slider';
import './modal';
import './enfatize';

export default {};
