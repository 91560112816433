import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	InputCheck,
	InstitutionsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetTypesWrapper,
	Tooltip,
	UiWrapper,
	UserWrapper,
	View,
	VinculationWrapper,
	Text,
	Button,
} from 'coinscrap-webapp-core';

import { SpanCurrency } from 'config/components/span';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import SendLinkModal from 'components/SendLinkModal/SendLinkModal';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import TooltipSimple from 'config/components/tooltipSimple';

import { getCurrency } from 'libs/Currency';
import { useSendLink } from 'libs/hooks/useSendLink';
import { useSimulation } from 'libs/hooks/useSimulation';

import Header from 'layout/components/Header/Header';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';

export const SimulationAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper,
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper,
	},
	targetTypes: {
		sourcePath: 'targetTypes',
		sourceWrapper: TargetTypesWrapper,
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper,
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper,
	},
	caserInstitution: {
		arrayItemMatcher: {
			itemPropertyPath: 'identifier',
			type: 'value',
			value: 'caser',
		},
		sourcePath: 'institutions',
		sourceWrapper: InstitutionsWrapper,
	},
})(() => {
	const { updateSimulation, loadSimulationForClient } = ClientSimulationWrapper.use();

	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const pickerValues = PickerHOC.use();
	const { simulation, client, caserInstitution, targetTypes } = pickerValues;

	const targetType = targetTypes?.find(x => simulation?.targetTypeId === x?.id);

	useEffect(() => {
		(async () => {
			//TODO QUITAR CUANDO EN CHANGE PRODUCT AL ACTUALIZAR EL PRODUCT SE ACTUALICE BIEN EL TARGET SIN TENER QUE HACER ESTO AL VOLVER AL SUMMARY
			await loadSimulationForClient(client.id, simulation.id);
		})();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onError = e => {
		openModal(
			() => (
				<ErrorModal>
					{e?.payload?.caserError?.error?.message ||
						`La simulación ha fallado, recuerda los límites de capital total (${capitalMinimum}€ - ${capitalMaximum}€) cuando configures la prima mensual/trimestral/semestral/anual`}
				</ErrorModal>
			),
			{
				disableClickAway: true,
				closeButton: true,
			},
		);
	};

	const textPeriodicidad = () => {
		switch (period) {
			case 'mensual':
				return 'mes';
			case 'trimestral':
				return 'trimestre';
			case 'semestral':
				return 'semestre';
			case 'anual':
				return 'año';
			default:
				return '';
		}
	};

	// CREATION DATA
	const creationData = simulation?.targetProperties?.find(x => x.name === 'creationData')?.value;

	// CAPITAL MÁXIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMaximum, setCapitalMaximum] = useState(180000);

	// CAPITAL MÍNIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMinimum, setCapitalMinimum] = useState(20000);

	const [
		simulationLoading,
		// eslint-disable-next-line no-unused-vars
		setSimulationLoading,
		invoice,
		setInvoice,
		capital,
		setCapital,
		isValidCapital,
		setIsValidCapital,
		invalidCapitalReason,
		setInvalidCapitalReason,
		period,
		setPeriod,
		disabilityCoverage,
		setDisabilityCoverage,
		disabilityCoverageAccident,
		setDisabilityCoverageAccident,
	] = useSimulation(
		caserInstitution,
		targetType,
		creationData,
		client,
		capitalMaximum,
		capitalMinimum,
		onError,
	);

	const send = useSendLink(client.id);
	const { navigateTo } = NavigationWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const [inputWidth, setInputWidth] = useState(2);
	const [inputWidthCapital, setInputWidthCapital] = useState(2);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.simulationAgent.back(navigateTo);
	};

	const next = async () => {
		setLoading('miscellaneous.saving');
		await updateSimulation({
			price: Number(capital.replace('.', '').replace(',', '.')),
			periodicity: period,
			periodicContribution: Number(invoice.replace('.', '').replace(',', '.')),
			disabilityCoverage,
			details: creationData?.details,
		});
		setLoading(false);
		openModal(
			close => (
				<SendLinkModal
					close={close}
					user={client}
					send={send}
					next={() => {
						CONFIG.routing.simulationAgent.next(navigateTo);
					}}
				/>
			),
			{
				disableClickAway: true,
				closeButton: false,
				key: 'modal-SimulationError',
			},
		);
	};

	useEffect(() => {
		const findPeriodsAndDecimals = [...invoice.toString()].filter(
			item => item === '.' || item === ',',
		);
		setInputWidth(invoice.toString().trim().length + 2 + findPeriodsAndDecimals.length * -0.6);

		const findPeriodsAndDecimalsCapital = [...capital.toString()].filter(
			item => item === '.' || item === ',',
		);
		setInputWidthCapital(
			capital.toString().trim().length + 2 + findPeriodsAndDecimalsCapital.length * -0.6,
		);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoice, capital]);

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 client', client);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(simulationLoading || loading, {
		loadingMessage: simulationLoading || loading,
	});
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'60%'}>
			<Text headingPrimarySt>
				{isMobile ? 'Capital a asegurar' : 'Escoge el capital a asegurar'}
			</Text>
			<S.InputWrapper>
				<S.Input
					style={{
						width: inputWidthCapital + 'ch',
					}}
					value={capital}
					onChange={e => {
						if (simulationLoading) return;
						const capital = e.target.value || '';
						const val = capital.replace(/\D/g, '');
						setIsValidCapital(val !== '' && val <= capitalMaximum && val >= capitalMinimum);
						if (val > capitalMaximum) {
							setInvalidCapitalReason(
								'El capital máximo asegurable es de ' + getCurrency(capitalMaximum, 0),
							);
						} else if (val === '' || val < capitalMinimum) {
							setInvalidCapitalReason(
								'El capital mínimo asegurable es de ' + getCurrency(capitalMinimum, 0),
							);
						}
						setCapital(val);
					}}
				></S.Input>
				<SpanCurrency>€</SpanCurrency>
			</S.InputWrapper>
			<View
				invalidCapitalSt
				style={{
					display: !isValidCapital && invalidCapitalReason ? 'block' : 'none',
					visibility: !isValidCapital && invalidCapitalReason ? 'visible' : 'hidden',
				}}
			>
				{!isValidCapital && invalidCapitalReason}
			</View>
			<Text featuresPrimarySt featuresMarginBottomSt>
				{isMobile ? 'Periodicidad del pago' : 'Selecciona la periodicidad'}
			</Text>
			<View elementsAroundFlexSt>
				<Button
					simulationPeriodSt
					selectedSt={period === 'mensual'}
					onClick={() => {
						setPeriod('mensual');
					}}
				>
					Mensual
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'trimestral'}
					onClick={() => {
						setPeriod('trimestral');
					}}
				>
					Trimestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'semestral'}
					onClick={() => {
						setPeriod('semestral');
					}}
				>
					Semestral
				</Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'anual'}
					onClick={() => {
						setPeriod('anual');
					}}
				>
					Anual
				</Button>
			</View>
			<View
				conditionsWrapperSt
				marginTopMobileSt
				onClick={() => {
					if (disabilityCoverage && disabilityCoverageAccident) {
						setDisabilityCoverageAccident(!disabilityCoverageAccident);
					}
					setDisabilityCoverage(!disabilityCoverage);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={disabilityCoverage} />
				<Text marginLefConditiontSt>
					Incluir cobertura por incapacidad permanente absoluta{' '}
					<TooltipSimple>
						<Text tooltipTitleSt>Incapacidad permanente absoluta:</Text>
						<Text tooltipTextSt>
							Pago del capital garantizado, alternativo al seguro principal, si el asegurado/a sufre
							una incapacidad permanente absoluta para toda profesión durante la vigencia del
							seguro. Incapacidad Permanente Absoluta se define como la situación física
							irreversible provocada por accidente o enfermedad originados independientemente de la
							voluntad del asegurado/a, determinante de su total ineptitud para el mantenimiento
							permanente de cualquier relación laboral o actividad profesional.
						</Text>
					</TooltipSimple>
					<Tooltip open={false}></Tooltip>
				</Text>
			</View>
			<Text
				featuresPrimarySt
				featuresMarginTopSt
				isB2B
				style={{ marginTop: isMobile ? '32px' : '40px', marginBottom: 0 }}
			>
				La prima resultante es de :
			</Text>
			<S.InputWrapper>
				<S.Input
					style={{
						width: inputWidth + 'ch',
					}}
					value={invoice}
					onChange={e => {
						console.log('e.target.value', e.target.value);
						let val = e.target.value;
						if (val.includes(',')) {
							val = val.split(',')[0];
						}
						setInvoice(val);
					}}
				></S.Input>
				<SpanCurrency>{`€ / ${textPeriodicidad()}`}</SpanCurrency>
			</S.InputWrapper>

			<BackNextButton
				onClickBack={back}
				onClickNext={next}
				nextText={'ENVIAR SIMULACIÓN'}
				isBackDisabled={false}
				isBackShown={!isMobile}
				justifyContent={'evenly'}
				isNextDisabled={
					!Number(invoice.toString().replace('.', '').replace(',', '.')) ||
					!Number(capital.toString().replace('.', '').replace(',', '.'))
				}
			/>
		</Wrapper>
	);
});
