import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	Text,
	View,
	InternationalizationWrapper,
	UserWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const IdentityBiometricSteps = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
})(() => {
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('IdentityBiometricSteps');
	const [loading, setLoading] = useState();

	const { t, currentLang } = InternationalizationWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.identityBiometricSteps.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('dniBiometric.starting');
			CONFIG.routing.identityBiometricSteps.next(navigateTo);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e.message.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			<HorizontalProgressBar
				step={4}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Firma' : ''}
			/>
			<Text noT headingPrimarySt isB2B>
				{t('dniBiometric.title')}
			</Text>
			<Text noT headingSecondarySt>
				{t('dniBiometric.camaraOn')}
			</Text>

			<View wrapperStepSt>
				<View itemStepSt>
					<S.StepCircle>1</S.StepCircle>
					<Text noT stepsSt>
						{t('dniBiometric.selectCountry')}
					</Text>
				</View>
				<View itemStepSt>
					<S.StepCircle>2</S.StepCircle>
					<Text noT stepsSt>
						{t('dniBiometric.takeSelfie')}
					</Text>
				</View>
				<View itemStepSt>
					<S.StepCircle>3</S.StepCircle>
					<Text noT stepsSt>
						{t('dniBiometric.scanDni')}
					</Text>
				</View>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={'buttons.next'}
				backText={'buttons.back'}
				isBackShown={!isMobile}
				isNextDisabled={false}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
