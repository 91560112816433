import React from 'react';
import * as S from './styles';

const Input = ({ value, onChange, placeholder, disabled, type, style, onBlur, onKeyPress , min }) => {
  return (
    <S.InputWrapper
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      onBlur={onBlur}
      style={{ ...style }}
      onKeyPress={onKeyPress}
      min={min}
    />
  );
};

export default Input;
