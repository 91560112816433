import { CONFIG } from 'config/config';
import React, { useEffect } from 'react';
import moment from 'moment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import { NavigationWrapper, UiWrapper, View, Text } from 'coinscrap-webapp-core';
import { isMobile } from 'react-device-detect';

import { getCurrency } from 'libs/Currency';
import { useSendLink } from 'libs/hooks/useSendLink';
import SendLinkModal from 'components/SendLinkModal/SendLinkModal';

import { getStatus } from 'libs/targetStatus';

const getStep = (status, step) => {
	switch (status) {
		case 'ACTIVE':
			return '';
		default:
			return `${step}/10 → `;
	}
};

const SimulationRowInner = props => {
	const { openModal } = UiWrapper.use();
	const { navigateTo } = NavigationWrapper.use();

	const {
		client,
		authRequests,
		agent,
		vinculation,
		apiInstance,
		simulation,
		onContinue,
		isGlobalAgent,
		currentStep,
	} = props;
	const creationData = simulation?.targetProperties?.find(x => x.name === 'creationData')?.value;

	const send = useSendLink(client.id, apiInstance);

	//console.log({ period: creationData?.periodicity });

	const getPeriod = period => {
		switch (period) {
			case 'semestral':
				return 'sem';
			case 'trimestral':
				return 'tri';
			case 'mensual':
				return 'mens';
			case 'anual':
				return 'anual';
			default:
				return 'mes';
		}
	};

	return (
		<>
			<Text tableTextSt>
				{client?.name?.trim()
					? client?.name?.trim() !== 'NEW_USER'
						? `${client?.name?.trim()} ${client?.surname?.trim()}`
						: 'Nuevo Usuario'
					: 'Nuevo Usuario'}
			</Text>

			<Text
				tableTextSt
				style={getStatus(simulation?.status) !== 'Activo' ? { fontSize: 16 } : { fontSize: 20 }}
			>
				{simulation?.status
					? `${getStep(simulation?.status, currentStep)} ${getStatus(simulation?.status)} `
					: '---'}
				{getStatus(simulation?.status) !== 'Activo' && (
					<View
						style={{ cursor: 'pointer', justifyContent: 'flex-start' }}
						rowSt
						onClick={() => {
							CONFIG.routing.dashboard.nextFollowUp(
								navigateTo,
								vinculation?.vinculationId,
								simulation?.userId,
								simulation?.id,
							);
						}}
					>
						<VisibilityOutlinedIcon style={{ marginLeft: 3, marginRight: 10, fontSize: 22 }} />
						<Text style={{ fontSize: 14 }}>Ver detalles</Text>
					</View>
				)}
			</Text>

			<Text tableTextSt>{moment(simulation?.createDate).format('DD/MM/YYYY HH:mm')}</Text>

			<Text tableTextSt>
				{creationData?.periodicContribution
					? getCurrency(creationData?.periodicContribution, 2) +
					  '/' +
					  getPeriod(creationData?.periodicity || 'mes')
					: '---'}{' '}
			</Text>

			<Text tableTextSt>{creationData?.price ? getCurrency(creationData?.price, 2) : '---'}</Text>

			{isGlobalAgent && (
				<Text tableTextSt style={{ fontSize: 14 }}>
					{agent?.email?.trim() || agent.toString() || ''}
				</Text>
			)}

			<Text tableTextSt style={{ fontSize: 14, textAlign: 'center' }}>
				{authRequests.length
					? authRequests[0]?.source || authRequests[0]?.queryParams?.source || '---'
					: '---'}
			</Text>

			<View style={{ padding: '14px 0px' }}>
				<View>
					<View
						style={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: `${isMobile ? 'center' : 'space-evenly'}`,
							marginTop: `${isMobile ? '20px' : ''}`,
							marginBottom: 20,
						}}
					>
						{/*EDITAR*/}
						{agent.toString() !== 'B2C' &&
							simulation?.status !== 'CANCELLED' &&
							simulation?.status !== 'ACTIVE' &&
							client?.metadata?.acceptContact !== false && (
								<div
									style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
									onClick={() => onContinue(simulation?.id)}
								>
									<Tooltip title='Editar'>
										<EditIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
									</Tooltip>
								</div>
							)}

						{/*NOTIFICAR*/}
						{simulation?.status !== 'CANCELLED' &&
							creationData?.price &&
							creationData?.periodicContribution &&
							simulation?.status !== 'ACTIVE' &&
							(client?.email?.trim() || client?.phoneNumber?.trim()) &&
							client?.metadata?.acceptContact !== false && (
								<div
									style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
									onClick={() =>
										openModal(close => <SendLinkModal close={close} user={client} send={send} />, {
											disableClickAway: true,
											closeButton: true,
											key: 'modal-SimulationError',
										})
									}
								>
									<Tooltip title='Enviar'>
										<SendIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
									</Tooltip>
								</div>
							)}
					</View>
				</View>

				{client?.metadata?.acceptContact === false && (
					<View>
						<Text
							style={{ fontSize: 16, color: CONFIG.colors.danger, marginBottom: 10, marginTop: 0 }}
						>
							No quiere recibir ofertas
						</Text>
					</View>
				)}

				{client?.phoneNumber && (
					<View>
						<a
							href={`tel:${client?.phoneNumber?.trim()}`}
							style={{ color: CONFIG.colors.primary, textDecoration: 'none', fontSize: 14 }}
						>
							{client.phoneNumber}
						</a>
					</View>
				)}

				{client?.email && (
					<View style={{ marginTop: 10 }}>
						<a
							href={`mailto:${client.email}`}
							style={{ color: CONFIG.colors.primary, textDecoration: 'none', fontSize: 14 }}
						>
							{client.email}
						</a>
					</View>
				)}
			</View>
		</>
	);
};

export const SimulationRow = props => {
	useEffect(() => {
		console.log('💫 EFFECTING', props);
		return () => {};
	}, [props]);
	return (
		<SimulationRowInner
			isGlobalAgent={props.isGlobalAgent}
			apiInstance={props.apiInstance}
			vinculation={props.vinculation}
			simulation={props.simulation}
			agent={props.agent}
			client={props.client}
			authRequests={props.authRequests}
			dniDocuments={props.dniDocuments}
			testCompletions={props.testCompletions}
			currentStep={props.currentStep}
			onContinue={props.onContinue}
		/>
	);
};
