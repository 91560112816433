export const professionsES = [
	{
		value: '200000',
		label: 'ABOGADO',
	},
	{
		value: '200001',
		label: 'FISCAL',
	},
	{
		value: '200002',
		label: 'JUEZ',
	},
	{
		value: '200003',
		label: 'JURISTA',
	},
	{
		value: '200004',
		label: 'PROCURADOR',
	},
	{
		value: '200005',
		label: 'ACTOR /ACTRIZ',
	},
	{
		value: '200006',
		label: 'ACTOR DOBLAJE.',
	},
	{
		value: '200007',
		label: 'STUNTMAN',
	},
	{
		value: '200008',
		label: 'CASCADOR (CINE -ACTUACIÓN)',
	},
	{
		value: '200009',
		label: 'ACTUARIO',
	},
	{
		value: '200010',
		label: 'ADIESTRAMIENTO ANIMALES DOMÉSTICOS',
	},
	{
		value: '200011',
		label: 'ADIESTRAMIENTO PERROS DE  COMBATE',
	},
	{
		value: '200012',
		label: 'ADIESTRAMIENTO FIERAS',
	},
	{
		value: '200013',
		label: 'ADMINISTRACIÓN',
	},
	{
		value: '200014',
		label: 'ADMINISTRADOR DE EMPRESAS',
	},
	{
		value: '200015',
		label: 'EMPLEADO ADMINISTRATIVO',
	},
	{
		value: '200016',
		label: 'INGENIERO COMERCIAL',
	},
	{
		value: '200017',
		label: 'PERSONAL ADMINISTRATIVO',
	},
	{
		value: '200018',
		label: 'SECRETARIO/A',
	},
	{
		value: '200020',
		label: 'AGENTE DE CAMBIO, BOLSA',
	},
	{
		value: '200021',
		label: 'AGRICULTURA. CAMPESINO',
	},
	{
		value: '200022',
		label: 'APICULTOR',
	},
	{
		value: '200023',
		label: 'GANADERO. GANADO MANSO',
	},
	{
		value: '200024',
		label: 'GANADERO. GANADO BRAVO',
	},
	{
		value: '200025',
		label: 'GANADERO. INSEMINADORES Y VETERINARIOS',
	},
	{
		value: '200026',
		label: 'GANADERO. UTILIZACIÓN DE MAQUINARIA PESADA',
	},
	{
		value: '200027',
		label: 'PESCA. EN ALTA MAR',
	},
	{
		value: '200028',
		label: 'PESCA. RIOS, LAGOS Y COSTAS',
	},
	{
		value: '200029',
		label: 'ALCOHOL (PRODUCCION)',
	},
	{
		value: '200030',
		label: 'ALCOHOL. COMERCIALIZACIÓN (EXCEPTO CENTROS NOCTURN',
	},
	{
		value: '200031',
		label: 'AMA DE CASA',
	},
	{
		value: '200032',
		label: 'ANALISTA DE SISTEMAS',
	},
	{
		value: '200033',
		label: 'ANTICUARIO',
	},
	{
		value: '200034',
		label: 'ARMERO',
	},
	{
		value: '200035',
		label: 'ARQUEÓLOGO',
	},
	{
		value: '200036',
		label: 'ARQUITECTO',
	},
	{
		value: '200037',
		label: 'ARQUITECTO DE INTERIORES',
	},
	{
		value: '200038',
		label: 'ARSENALES, POLVORINES',
	},
	{
		value: '200039',
		label: 'AUDITOR',
	},
	{
		value: '200040',
		label: 'AUTOR',
	},
	{
		value: '200041',
		label: 'EDITOR',
	},
	{
		value: '200042',
		label: 'ESCRITOR',
	},
	{
		value: '200043',
		label: 'MÚSICO DE SINFÓNICA',
	},
	{
		value: '200044',
		label: 'CANTANTE',
	},
	{
		value: '200045',
		label: 'DECORADOR',
	},
	{
		value: '200046',
		label: 'DISEÑADOR. DISEÑADOR GRÁFICO',
	},
	{
		value: '200047',
		label: 'ESCULTOR',
	},
	{
		value: '200048',
		label: 'DISEÑADOR VESTUARIO',
	},
	{
		value: '200049',
		label: 'SASTRE',
	},
	{
		value: '200050',
		label: 'MODISTA',
	},
	{
		value: '200051',
		label: 'PAISAJISTA',
	},
	{
		value: '200052',
		label: 'ASCENSORISTA',
	},
	{
		value: '200053',
		label: 'INSTALADOR DE ELEVADORES',
	},
	{
		value: '200054',
		label: 'MONTAJE Y MANTENIMIENTO DE ASCENSORES',
	},
	{
		value: '200055',
		label: 'ASESOR',
	},
	{
		value: '200056',
		label: 'FABRICACIÓN DE ASFALTO',
	},
	{
		value: '200057',
		label: 'ASTILLERO - INGENIERO',
	},
	{
		value: '200058',
		label: 'ASTILLERO - MONTAJE',
	},
	{
		value: '200059',
		label: 'ASTILLERO ADMINISTRATIVO',
	},
	{
		value: '200060',
		label: 'ASTILLERO ARTESANAL',
	},
	{
		value: '200061',
		label: 'ASTILLERO INDUSTRIAL',
	},
	{
		value: '200062',
		label: 'AUTO-ESCUELA',
	},
	{
		value: '200063',
		label: 'AUTOMOVILISMO PROFESIONAL',
	},
	{
		value: '200064',
		label: 'AUXILIAR DE DENTISTA',
	},
	{
		value: '200065',
		label: 'AUXILIAR DE FARMACIA',
	},
	{
		value: '200066',
		label: 'AUXILIAR MÉDICO',
	},
	{
		value: '200067',
		label: 'AVIACIÓN PROFESIONAL',
	},
	{
		value: '200069',
		label: 'AZATA, AUXILIAR DE VUELO, VUELOS REGULARES',
	},
	{
		value: '200070',
		label: 'BAILARINA DE BALLET',
	},
	{
		value: '200071',
		label: 'BAILARÍN  DE BALLET',
	},
	{
		value: '200072',
		label: 'BAILARÍN ESPAÑOL',
	},
	{
		value: '200073',
		label: 'BANCA.  TAREAS ADMINISTRATIVAS',
	},
	{
		value: '200074',
		label: 'BANCA.  CHOFER DE CAMIONETA CUSTODIA  DE VALORES',
	},
	{
		value: '200075',
		label: 'BANCA.  TRANSPORTE DE VALORES CAUDALES',
	},
	{
		value: '200076',
		label: 'BARES, CANTINAS (EXCEPTO CENTROS NOCTURNOS): PROPI',
	},
	{
		value: '200077',
		label: 'BARMAN (EMPLEADO BAR O PUB, PREPARADOR DE TRAGOS)',
	},
	{
		value: '200078',
		label: 'BIBLIOTECARIO',
	},
	{
		value: '200079',
		label: 'BIÓLOGO',
	},
	{
		value: '200080',
		label: 'BIOQUÍMICO  DE (ENSAYO, INDUSTRIAL, FARMACEUTICO)',
	},
	{
		value: '200081',
		label: 'BOLSA DE COMERCIO',
	},
	{
		value: '200082',
		label: 'BOMBERO. EXTINCIÓN DE FUEGOS POR TIERRA',
	},
	{
		value: '200083',
		label: 'BOMBERO. EXTINCIÓN DE FUEGOS DESDE EL AIRE',
	},
	{
		value: '200084',
		label: 'BOMBERO. EXTINCIÓN DE FUEGOS BAJO TIERRA',
	},
	{
		value: '200085',
		label: 'BOMBERO. TAREAS ADMINISTRATIVAS SOLAMENTE',
	},
	{
		value: '200086',
		label: 'BOMBERO. PROTECCIÓN O DEFENSA CIVIL',
	},
	{
		value: '200087',
		label: 'BOMBERO. CHOFER',
	},
	{
		value: '200088',
		label: 'BOTÁNICO',
	},
	{
		value: '200089',
		label: 'BOXEO PROFESIONAL',
	},
	{
		value: '200090',
		label: 'BUCEO PROFESIONAL',
	},
	{
		value: '200091',
		label: 'CALDERERO . EDIFICIOS Y FÁBRICAS',
	},
	{
		value: '200092',
		label: 'CALDERERO . REFINERÍA',
	},
	{
		value: '200093',
		label: 'CARNICERO',
	},
	{
		value: '200094',
		label: 'CARPINTERO, EBANISTA',
	},
	{
		value: '200095',
		label: 'CATHC, PROFESIONAL',
	},
	{
		value: '200096',
		label: 'CEMENTO (FABRICACIÓN Y EMPAQUE)',
	},
	{
		value: '200097',
		label: 'CENTRO NOCTURNO (CABARETISTA, VEDETTE, EMPLEADO DE',
	},
	{
		value: '200098',
		label: 'CERÁMICA, ALFARERO, CERAMISTA',
	},
	{
		value: '200099',
		label: 'CERRAJERO',
	},
	{
		value: '200100',
		label: 'CICLISMO PROFESIONAL (BICICLETA DE COMPETCIÓN O DE',
	},
	{
		value: '200101',
		label: 'CIRUJANO',
	},
	{
		value: '200102',
		label: 'COBRADOR (EN ZONAS NO PELIGROSAS, TALES COMO CONFL',
	},
	{
		value: '200103',
		label: 'COBRADOR (EN ZONAS PELIGROSAS)',
	},
	{
		value: '200104',
		label: 'COMBUSTIBLE. EXPENDEDOR DE COMBUSTIBLES (GASOLINA,',
	},
	{
		value: '200105',
		label: 'COMBUSTIBLE. TRANSPORTE DE SUSTANCIAS INFLAMABLES',
	},
	{
		value: '200106',
		label: 'COMERCIO.EN ZONA DE CONFLICTO O PELIGROSAS',
	},
	{
		value: '200107',
		label: 'COMERCIO. TODAS LAS DEMAS',
	},
	{
		value: '200108',
		label: 'COMPUTACIÓN E INFOMÁTICA.  INGENIERO',
	},
	{
		value: '200109',
		label: 'COMPUTACIÓN E INFOMÁTICA.  PROGRAMADOR',
	},
	{
		value: '200110',
		label: 'CONDUCTOR AMBULANCIA',
	},
	{
		value: '200111',
		label: 'CONDUCTOR  AUTOBUSES',
	},
	{
		value: '200112',
		label: 'CONDUCTOR TAXI',
	},
	{
		value: '200113',
		label: 'ARQUITECTO, APAREJADOR, MAESTRO MAYOR DE OBRAS',
	},
	{
		value: '200114',
		label: 'SUPERVISOR, MAESTRO DE OBRAS',
	},
	{
		value: '200115',
		label: 'ALBAÑIL',
	},
	{
		value: '200116',
		label: 'PRODUCCIÓN, EMPAQUETADO DE CEMENTO',
	},
	{
		value: '200117',
		label: 'CARPINTERO EN CONSTRUCCIÓN',
	},
	{
		value: '200118',
		label: 'TALADRADORES',
	},
	{
		value: '200119',
		label: 'ENSAMBLADORES ESTRUCTURAS METÁLICAS',
	},
	{
		value: '200120',
		label: 'CONTABLE',
	},
	{
		value: '200121',
		label: 'CONTADOR',
	},
	{
		value: '200122',
		label: 'CONTROLADOR AÉREO',
	},
	{
		value: '200123',
		label: 'CRISTALERO',
	},
	{
		value: '200124',
		label: 'CÓNSUL',
	},
	{
		value: '200125',
		label: 'EMBAJADOR',
	},
	{
		value: '200126',
		label: 'EMPLEADO DE EMBAJADA',
	},
	{
		value: '200127',
		label: 'CUERPO DIPLOMÁTICO EN ZONAS CON PELIGROS ESPECIALE',
	},
	{
		value: '200128',
		label: 'CURTIDOR',
	},
	{
		value: '200130',
		label: 'DEMOLICION  CON USO DE EXPLOSIVOS',
	},
	{
		value: '200131',
		label: 'DEMOLICION   SIN USO DE EXPLOSIVOS',
	},
	{
		value: '200132',
		label: 'DENTISTA / ODONTÓLOGO',
	},
	{
		value: '200133',
		label: 'DOCKER',
	},
	{
		value: '200134',
		label: 'DRAGA, DRENAJE',
	},
	{
		value: '200135',
		label: 'DROGUERO',
	},
	{
		value: '200136',
		label: 'EBANISTA',
	},
	{
		value: '200137',
		label: 'EDUCADOR',
	},
	{
		value: '200138',
		label: 'EJECUTIVO DE CUENTAS',
	},
	{
		value: '200139',
		label: 'CABLEADORES - ELÉCTRICO',
	},
	{
		value: '200140',
		label: 'CONTROLADORES ELÉCTRICO',
	},
	{
		value: '200141',
		label: 'ELECTRICISTA BAJA TENSIÓN',
	},
	{
		value: '200142',
		label: 'ELECTRICISTA DE LA CONSTRUCCIÓN',
	},
	{
		value: '200143',
		label: 'ELECTRICISTA EDIFICIOS, CASAS Y CAMINOS',
	},
	{
		value: '200144',
		label: 'ELECTRISISTA ALTA TENSIÓN',
	},
	{
		value: '200145',
		label: 'MONTADOR ELÉCTRICO',
	},
	{
		value: '200146',
		label: 'OPERADORES ELÉCTRICOS',
	},
	{
		value: '200148',
		label: 'ESPECTÁCULOS CIRCENSES',
	},
	{
		value: '200149',
		label: 'ESTIBADOR',
	},
	{
		value: '200150',
		label: 'EXPLOSIVOS',
	},
	{
		value: '200151',
		label: 'CONDUCTORES - SERVICIO DE TRANSPORTE FERROVIARIO/R',
	},
	{
		value: '200152',
		label: 'FERROCARRILES (OTRA PROFESIÓN RELACIONADA)',
	},
	{
		value: '200153',
		label: 'MANTENCIÓN DE VÍAS, SEÑALES Y LÍNEAS DE PODER',
	},
	{
		value: '200154',
		label: 'MAQUINISTA DE FERROCARRIL',
	},
	{
		value: '200155',
		label: 'PERSONAL DE CARGA DE FERROCARRIL',
	},
	{
		value: '200156',
		label: 'FRESADOR/FORJADOR',
	},
	{
		value: '200157',
		label: 'FUERAS ARMADAS SOLO TAREAS ADMINISTRATIVAS',
	},
	{
		value: '200158',
		label: 'FUERAS ARMADAS RESTO',
	},
	{
		value: '200159',
		label: 'FUERZAS ESPECIALES   BRIGADA NARCOTRÁFICO',
	},
	{
		value: '200160',
		label: 'FUERZAS ESPECIALES   BRIGADA ANTITERRORISTA',
	},
	{
		value: '200161',
		label: 'FUMIGACIÓN:    TERRESTRE',
	},
	{
		value: '200162',
		label: 'FUMIGACIÓN:  AEREA',
	},
	{
		value: '200163',
		label: 'GAS. CAPATAZ SUPERVISOR',
	},
	{
		value: '200164',
		label: 'GAS. OBRERO, INSTALADOR',
	},
	{
		value: '200165',
		label: 'GEÓLOGOS:',
	},
	{
		value: '200166',
		label: 'GUARDABOSQUES',
	},
	{
		value: '200167',
		label: 'GURADAESPALDAS',
	},
	{
		value: '200168',
		label: 'GURADAVIDAS, SALVAVIDAS (OTRAS)',
	},
	{
		value: '200169',
		label: 'GURADAVIDAS, SALVAVIDAS (PISCINAS)',
	},
	{
		value: '200170',
		label: 'HOSTELERIA',
	},
	{
		value: '200171',
		label: 'HÍPICA:  DOMADOR',
	},
	{
		value: '200172',
		label: 'HÍPICA:  SALTO DE OBSTÁCULOS',
	},
	{
		value: '200173',
		label: 'HÍPICA:   JOCKEY, CARRERA DE CABALLO',
	},
	{
		value: '200174',
		label: 'HÍPICA:   POLO O PATO',
	},
	{
		value: '200175',
		label: 'HÍPICA:   RODEO',
	},
	{
		value: '200176',
		label: 'HÍPICA:  PROFESOR',
	},
	{
		value: '200177',
		label: 'HÍPICA:  CHARRERÍA',
	},
	{
		value: '200178',
		label: 'INDUSTRIA AUTOMOVILISTICA',
	},
	{
		value: '200179',
		label: 'INDUSTRIA CÁRNICA',
	},
	{
		value: '200180',
		label: 'INDUSTRIA MADERERA',
	},
	{
		value: '200181',
		label: 'INDUSTRIA MANUFACTURERA',
	},
	{
		value: '200182',
		label: 'INDUSTRIA PETROQUÍMICA:   BÚSQUEDA, EXPLORACIÓN A',
	},
	{
		value: '200183',
		label: 'INDUSTRIA PETROQUÍMICA:    VIGILANCIA DE REFINERÍA',
	},
	{
		value: '200184',
		label: 'INDUSTRIA PETROQUÍMICA:   MANTENIMIENTO DE CUBAS O',
	},
	{
		value: '200185',
		label: 'INDUSTRIA PETROQUÍMICA:    PLATAFORMAS PETROLÍFERA',
	},
	{
		value: '200186',
		label: 'INDUSTRIA QUÍMICA:     SUSTANCIAS PELIGROSAS',
	},
	{
		value: '200187',
		label: 'INDUSTRIA QUÍMICA:     OTRAS SUSTANCIAS',
	},
	{
		value: '200188',
		label: 'INDUSTRIAS BIOQUÍMICA Y FARMACÉUTICA',
	},
	{
		value: '200189',
		label: 'INGENIERÍA:   INGENIERÍA FORESTAL',
	},
	{
		value: '200190',
		label: 'INGENIERÍA:   INGENIERO AGRÓNOMO',
	},
	{
		value: '200191',
		label: 'INGENIERÍA:   INGENIERO ELÉCTRICO',
	},
	{
		value: '200192',
		label: 'INGENIERÍA:    INGENIERO ELECTRÓNICO',
	},
	{
		value: '200193',
		label: 'INGENIERÍA:    INGENIERO CIVIL',
	},
	{
		value: '200194',
		label: 'FABRICACIÓN DE INSECTICIDAS',
	},
	{
		value: '200195',
		label: 'INSTALCIÓN DE CAÑERIAS',
	},
	{
		value: '200196',
		label: 'INSTALCIÓN O COLOCACIÓN DE ANTENAS',
	},
	{
		value: '200197',
		label: 'DETECTIVE PRIVADO',
	},
	{
		value: '200198',
		label: 'LEÑADOR',
	},
	{
		value: '200199',
		label: 'MANIPULACIÓN DE RESIDUOS PATOLÓGICOS',
	},
	{
		value: '200200',
		label: 'MANIPULACIÓN DE SUSTANCIAS CORROSIVAS, TÓXICAS',
	},
	{
		value: '200201',
		label: 'MANUFACTURAS DE ARMAS',
	},
	{
		value: '200202',
		label: 'CONDUCTOR DE GRUA, EXCAVADORAS, APLANADORAS, ETC.',
	},
	{
		value: '200203',
		label: 'OPERADOR DE MÁQUINAS DE OBRA Y MOVIMIENTO DE TIERR',
	},
	{
		value: '200204',
		label: 'MARINA. MERCANTE ZONAS SIN RIESGO DE RESIDENCIA',
	},
	{
		value: '200205',
		label: 'MATADERO',
	},
	{
		value: '200206',
		label: 'MECÁNICA',
	},
	{
		value: '200207',
		label: 'METALURGIA',
	},
	{
		value: '200208',
		label: 'MINERÍA   TAREAS EN SUPERFICIES',
	},
	{
		value: '200209',
		label: 'MINERÍA   TAREAS BAJO TIERRA',
	},
	{
		value: '200210',
		label: 'MINERÍA   MANEJO DE EXPLOSIVOS',
	},
	{
		value: '200211',
		label: 'PELUQUERO, PELUQUERA',
	},
	{
		value: '200212',
		label: 'PERIODISMO EN ZONA DE CONFLICTO',
	},
	{
		value: '200213',
		label: 'PERIODISMO, TODOS LOS DEMÁS',
	},
	{
		value: '200214',
		label: 'FOTÓGRAFO EN ZONA DE CONFLICTO',
	},
	{
		value: '200215',
		label: 'FOTÓGRAFO, TODOS LOS DEMÁS',
	},
	{
		value: '200216',
		label: 'PERSONAL DE ADUANA, FRONTERA              ZONAS SI',
	},
	{
		value: '200217',
		label: 'PERSONAL DE ADUANA, FRONTERA          ZONAS DE RIE',
	},
	{
		value: '200219',
		label: 'PESCA. EN COSTAS, RÍOS Y LAGOS',
	},
	{
		value: '200220',
		label: 'PIROTECNIA, JUEGOS PIROTÉCNICOS',
	},
	{
		value: '200221',
		label: 'POLICÍA/G.CIVIL: SOLO TAREAS ADMINISTRATIVAS',
	},
	{
		value: '200222',
		label: 'POLICÍA:   MUNICIPAL',
	},
	{
		value: '200223',
		label: 'POLICÍA:   PROVINCIAL O AUTONÓMICA',
	},
	{
		value: '200224',
		label: 'POLICÍA/G.CIVIL: NACIONAL O FEDERAL',
	},
	{
		value: '200225',
		label: 'POLICÍA/G.CIVIL: DESACTIVACIÓN DE EXPLOSIVOS',
	},
	{
		value: '200226',
		label: 'POLICÍA/G.CIVIL: FRONTERAS,GENDARMERÍA',
	},
	{
		value: '200227',
		label: 'POLICÍA/G.CIVIL: BRIGADA NARCOTRÁFICO',
	},
	{
		value: '200228',
		label: 'POLICÍA/G.CIVIL: BRIGADA ANTITERRORISTA',
	},
	{
		value: '200229',
		label: 'POLICÍA/G.CIVIL: CARCELERO, GUARDIA DE CARCEL',
	},
	{
		value: '200230',
		label: 'PSIQUIATRA',
	},
	{
		value: '200231',
		label: 'PUBLICIDAD. AÉREA',
	},
	{
		value: '200232',
		label: 'PUBLICIDAD. TODOS LOS DEMÁS',
	},
	{
		value: '200233',
		label: 'RADIOLOGÍA',
	},
	{
		value: '200234',
		label: 'REPARTIDOR EN MOTO',
	},
	{
		value: '200235',
		label: 'REPARTIDOR EN AUTO, FURGONETA O CAMIÓN',
	},
	{
		value: '200236',
		label: 'SERVICIO DOMÉSTICO',
	},
	{
		value: '200237',
		label: 'SERVICIOS DE CARGA Y DESCARGA',
	},
	{
		value: '200238',
		label: 'VIGILANTE SIN PORTAR ARMAS',
	},
	{
		value: '200239',
		label: 'VIGILANTE PORTADOR DE ARMAS',
	},
	{
		value: '200240',
		label: 'TAPICERIA',
	},
	{
		value: '200241',
		label: 'CAMARÓGRAFO, ERAMAN, CÁMARA, TV:    EN ZONA DE CON',
	},
	{
		value: '200242',
		label: 'CAMARÓGRAFO, ERAMAN, CÁMARA, TV:     TODOS LOS DEM',
	},
	{
		value: '200243',
		label: 'TOPÓGRAFOS',
	},
	{
		value: '200244',
		label: 'TORERO',
	},
	{
		value: '200245',
		label: 'TRABAJOS EN ALTURA',
	},
	{
		value: '200246',
		label: 'TRANSPORTE TERRESTRE: MERCANCÍA NO PELIGROSAS',
	},
	{
		value: '200247',
		label: 'TRANSPORTE TERRESTRE:  CARGAS PESADAS, GANADAO',
	},
	{
		value: '200248',
		label: 'TRANSPORTE TERRESTRE:   SUSTANCIAS PELIGROSAS (TÓX',
	},
	{
		value: '200249',
		label: 'TRANSPORTE TERRESTRE:   VALORES CAUDALES,',
	},
	{
		value: '200250',
		label: 'TRANSPORTE TERRESTRE:  TRANSPORTE DE PERSONAS (TAX',
	},
	{
		value: '200251',
		label: 'FISIOTERAPEUTA / KINESIÓLOGO',
	},
	{
		value: '200252',
		label: 'KIROPRÁCTICO /  OSTEÓPATA',
	},
	{
		value: '200253',
		label: 'HERRERO',
	},
	{
		value: '200254',
		label: 'JUBILADO',
	},
	{
		value: '200255',
		label: 'MEDICINA GENERAL',
	},
	{
		value: '200256',
		label: 'ESTUDIANTE',
	},
	{
		value: '200257',
		label: 'FUTBOLISTA PROFESIONAL',
	},
	{
		value: '200258',
		label: 'ALUMNOPILOTO',
	},
	{
		value: '200259',
		label: 'INSTRUCTOR DE VUELO',
	},
	{
		value: '200260',
		label: 'PILOTO VUELO < 200 H/AÑOS',
	},
	{
		value: '200261',
		label: 'PILOTO VUELO 200-500 H/AÑOS',
	},
	{
		value: '200262',
		label: 'PINTOR ( NO TRABAJOS EN ALTURA)',
	},
	{
		value: '200263',
		label: 'MILITAR',
	},
	{
		value: '200264',
		label: 'TRANSPORTADOR TORO DE FUEGO',
	},
	{
		value: '200265',
		label: 'LOTERIAS, ADMINISTRADOR',
	},
	{
		value: '200266',
		label: 'BUTANO, REPARTO DE',
	},
	{
		value: '200267',
		label: 'VETERINARIO',
	},
	{
		value: '200268',
		label: 'ANTIBIOTICOS',
	},
	{
		value: '200269',
		label: 'AYUDANTE TÉCNICO SANITARIO',
	},
	{
		value: '200270',
		label: 'COMADRONA',
	},
	{
		value: '200271',
		label: 'CLÍNICA, AUXILIAR DE',
	},
	{
		value: '200272',
		label: 'OPTICO',
	},
	{
		value: '200273',
		label: 'PODOLOGO',
	},
	{
		value: '200274',
		label: 'MATEMATICO',
	},
	{
		value: '200275',
		label: 'PEDAGOGO',
	},
	{
		value: '200276',
		label: 'PROFESOR IDIOMAS',
	},
	{
		value: '200277',
		label: 'INSPECTOR DE EDUCACION',
	},
	{
		value: '200278',
		label: 'PUERICULTOR',
	},
	{
		value: '200279',
		label: 'PROFESOR DE INFORMATICA',
	},
	{
		value: '200280',
		label: 'CINEMATOGRAFIA, OPERADOR DE',
	},
	{
		value: '200281',
		label: 'PROFESOR DE BAILE',
	},
	{
		value: '200282',
		label: 'PRODUCCIÓN-REALIZACIÓN T.V.',
	},
	{
		value: '200283',
		label: 'MATADOR DE TOROS',
	},
	{
		value: '200284',
		label: 'REJONEADOR',
	},
	{
		value: '200285',
		label: 'MATADOR DE NOVILLOS',
	},
	{
		value: '200286',
		label: 'BINGO, EMPLEADO DE',
	},
	{
		value: '200287',
		label: 'PROFESOR DE GIMNASIA',
	},
	{
		value: '200288',
		label: 'PSICOLOGO',
	},
	{
		value: '200289',
		label: 'CONSEJERO',
	},
	{
		value: '200290',
		label: 'POZOS SÉPTICOS, LIMPIADOR',
	},
	{
		value: '200291',
		label: 'ESTETICISTA',
	},
	{
		value: '200292',
		label: 'CÁRCELES, DIRECTOR DE',
	},
	{
		value: '200293',
		label: 'CÁRCELES, JEFE DE SERVICIO EN',
	},
	{
		value: '200294',
		label: 'MÉDICO DE PRISIONES',
	},
	{
		value: '200295',
		label: 'JARDINERO',
	},
	{
		value: '200296',
		label: 'CHACINERO',
	},
	{
		value: '200297',
		label: 'TOCINERO CON MATANZA',
	},
	{
		value: '200298',
		label: 'ZAPATERO',
	},
	{
		value: '200299',
		label: 'FONTANERO',
	},
	{
		value: '200300',
		label: 'VIDRIERO',
	},
	{
		value: '200301',
		label: 'VIDRIO, GRABADOR DE',
	},
	{
		value: '200302',
		label: 'CONDUCTOR PROF. VEHÍCULO PARTICULAR',
	},
	{
		value: '200303',
		label: 'CONDUCTOR TRENES EN FERIAS',
	},
	{
		value: '200304',
		label: 'BASURERO',
	},
	{
		value: '200305',
		label: 'ANIMALES, CRIADOR DE',
	},
	{
		value: '200306',
		label: 'VIAJANTE',
	},
	{
		value: '200307',
		label: 'CÁRCELES, ADMINISTRATIVO EN',
	},
	{
		value: '200308',
		label: 'CAMARERO',
	},
	{
		value: '200309',
		label: 'DELINEANTE',
	},
	{
		value: '200310',
		label: 'VIVERISTA',
	},
	{
		value: '200311',
		label: 'TEJEDOR',
	},
	{
		value: '200312',
		label: 'VENDEDOR AMBULANTE',
	},
	{
		value: '200314',
		label: 'PINTOR CON TRABAJOS EN ALTURAS',
	},
	{
		value: '200315',
		label: 'PESCADERO',
	},
	{
		value: '200316',
		label: 'MOLINERO',
	},
	{
		value: '200317',
		label: 'CONDUCTOR MAQUINARIA AGRICOLA.',
	},
	{
		value: '200318',
		label: 'GRANJERO',
	},
	{
		value: '200320',
		label: 'ORTOPEDICO',
	},
	{
		value: '200321',
		label: 'MARMOLISTA',
	},
	{
		value: '200322',
		label: 'CARTERO',
	},
	{
		value: '200325',
		label: 'QUIMICO SIN SUSTANCIAS PELIGROSAS',
	},
	{
		value: '200327',
		label: 'INGENIERO,EXCLUIDOS MINAS',
	},
	{
		value: '200328',
		label: 'GEOGRAFO',
	},
	{
		value: '200331',
		label: 'DIBUJANTE TECNICO',
	},
	{
		value: '200332',
		label: 'TOPOGRAFO, CON VISITA A OBRAS',
	},
	{
		value: '200333',
		label: 'MEDICO-MEDICINA GENERAL',
	},
	{
		value: '200334',
		label: 'MEDICO ESPECIALISTA',
	},
	{
		value: '200335',
		label: 'MEDICO RADIOLOGO',
	},
	{
		value: '200336',
		label: 'MEDICO, RESTO',
	},
	{
		value: '200337',
		label: 'MEDICO ODONTOLOGO',
	},
	{
		value: '200338',
		label: 'FARMACEUTICO',
	},
	{
		value: '200339',
		label: 'SANITARIO, PERSONAL',
	},
	{
		value: '200341',
		label: 'FISIOTERAPEUTA',
	},
	{
		value: '200343',
		label: 'CONDUCTOR AMBULANCIAS',
	},
	{
		value: '200345',
		label: 'LABORATORIO, TRABAJO EN',
	},
	{
		value: '200346',
		label: 'INFORMATICO, SIN VIAJES',
	},
	{
		value: '200347',
		label: 'ECONOMISTA',
	},
	{
		value: '200349',
		label: 'NOTARIO',
	},
	{
		value: '200350',
		label: 'ASESOR JURIDICO',
	},
	{
		value: '200351',
		label: 'PROFESOR DE EDUCACION, EXCEPTO DEPORTES',
	},
	{
		value: '200354',
		label: 'PERIODISTA SIN REPORTAJES PELIGROSOS',
	},
	{
		value: '200355',
		label: 'DISEÑADOR',
	},
	{
		value: '200356',
		label: 'FOTOGRAFO EN ESTUDIO',
	},
	{
		value: '200357',
		label: 'FOTOGRAFO DE PRENSA',
	},
	{
		value: '200359',
		label: 'DEPORTISTA PROFESIONAL',
	},
	{
		value: '200361',
		label: 'ASISTENTE SOCIAL',
	},
	{
		value: '200362',
		label: 'GRADUADO SOCIAL',
	},
	{
		value: '200363',
		label: 'PUBLICISTA',
	},
	{
		value: '200364',
		label: 'RELACIONES PUBLICAS',
	},
	{
		value: '200365',
		label: 'DIRECTIVO, SIN VIAJES',
	},
	{
		value: '200366',
		label: 'APAREJADOR CON VISITA A OBRAS',
	},
	{
		value: '200367',
		label: 'ADMINISTRATIVO(NO CARCELES)SIN VIAJES',
	},
	{
		value: '200368',
		label: 'ADMINISTRATIVO(NO CARCELES)CON VIAJES',
	},
	{
		value: '200369',
		label: 'RECEPCIONISTA DE HOTEL',
	},
	{
		value: '200370',
		label: 'COMERCIANTE EN LOCAL',
	},
	{
		value: '200371',
		label: 'COMERCIANTE, FRECUENTES VIAJES',
	},
	{
		value: '200372',
		label: 'COMERCIO,PROPIETARIO DE',
	},
	{
		value: '200373',
		label: 'JEFE DE VENTAS EN PLAZA',
	},
	{
		value: '200374',
		label: 'VISITADOR MEDICO',
	},
	{
		value: '200375',
		label: 'AGENTE COMERCIAL, SIN VIAJES',
	},
	{
		value: '200376',
		label: 'AGENTE VENTAS, CON VIAJES',
	},
	{
		value: '200377',
		label: 'AGENTE COMERCIAL, CON VIAJES',
	},
	{
		value: '200378',
		label: 'AGENTE VENTAS, SIN VIAJES',
	},
	{
		value: '200379',
		label: 'AGENTE DE SEGUROS, SIN VIAJES',
	},
	{
		value: '200380',
		label: 'AGENTE DE SEGUROS, CON VIAJES',
	},
	{
		value: '200381',
		label: 'COMERCIO,EMPLEADO DE',
	},
	{
		value: '200382',
		label: 'COMERCIANTE AMBULANTE',
	},
	{
		value: '200383',
		label: 'COCINERO',
	},
	{
		value: '200385',
		label: 'EMPLEADA DE HOGAR',
	},
	{
		value: '200386',
		label: 'TRIPULACION DE VUELO',
	},
	{
		value: '200387',
		label: 'LIMPIEZA EN INTERIORES, EMPLEADO DE',
	},
	{
		value: '200388',
		label: 'PELUQUERO',
	},
	{
		value: '200390',
		label: 'BOMBERO',
	},
	{
		value: '200391',
		label: 'CARCELES, AUXILIAR DE',
	},
	{
		value: '200392',
		label: 'POLICIA NACIONAL',
	},
	{
		value: '200393',
		label: 'POLICIA MUNICIPAL',
	},
	{
		value: '200394',
		label: 'VIGILANTE, CON ARMA DE FUEGO',
	},
	{
		value: '200395',
		label: 'GUARDA JURADO',
	},
	{
		value: '200396',
		label: 'VIGILANTE, SIN ARMA DE FUEGO',
	},
	{
		value: '200397',
		label: 'AGRICOLA-GANADERO, OBRERO',
	},
	{
		value: '200399',
		label: 'GUARDA FORESTAL',
	},
	{
		value: '200400',
		label: 'PESCADOR',
	},
	{
		value: '200402',
		label: 'METALURGIA, EMPLEADO (SIN FUNDICION)',
	},
	{
		value: '200403',
		label: 'CARPINTERIA METALICA',
	},
	{
		value: '200406',
		label: 'TEXTIL,INDUSTRIA',
	},
	{
		value: '200407',
		label: 'ALIMENTICIA,INDUSTRIA',
	},
	{
		value: '200408',
		label: 'CARNICERO CON MATANZA',
	},
	{
		value: '200409',
		label: 'CARNICERO SIN MATANZA',
	},
	{
		value: '200410',
		label: 'PANADERO',
	},
	{
		value: '200411',
		label: 'TAPICERO',
	},
	{
		value: '200412',
		label: 'DECORADOR-INTERIORISTA',
	},
	{
		value: '200414',
		label: 'CALZADO,INDUSTRIA',
	},
	{
		value: '200417',
		label: 'TORNERO',
	},
	{
		value: '200418',
		label: 'AIRE ACONDICIONADO, MECANICO-MONTADOR',
	},
	{
		value: '200419',
		label: 'CADENA DE MONTAJE, OPERARIO',
	},
	{
		value: '200420',
		label: 'INFORMATICO, CON VIAJES',
	},
	{
		value: '200421',
		label: 'MECANICO',
	},
	{
		value: '200422',
		label: 'MECANICO DE VEHICULOS DE MOTOR',
	},
	{
		value: '200423',
		label: 'MANTENIMIENTO DE EDIFICIOS',
	},
	{
		value: '200424',
		label: 'MECANICO ELECTRICISTA',
	},
	{
		value: '200425',
		label: 'ELECTRICIDAD BAJA TENSION, INSTALACION',
	},
	{
		value: '200426',
		label: 'ELECTRICISTA BAJA TENSION',
	},
	{
		value: '200427',
		label: 'ELECTRICISTA ALTA TENSION',
	},
	{
		value: '200428',
		label: 'AISLANTES, MONTADOR DE',
	},
	{
		value: '200430',
		label: 'SOLDADOR AUTOGENO',
	},
	{
		value: '200431',
		label: 'SOLDADOR ELECTRICO',
	},
	{
		value: '200432',
		label: 'METAL, MONTADOR DE ESTRUCTURAS DE',
	},
	{
		value: '200433',
		label: 'IMPRENTA,TALLER DE',
	},
	{
		value: '200434',
		label: 'PINTOR EN INTERIORES',
	},
	{
		value: '200435',
		label: 'CEMENTO, FABRICACION',
	},
	{
		value: '200436',
		label: 'CONSTRUCCION',
	},
	{
		value: '200438',
		label: 'YESERO',
	},
	{
		value: '200439',
		label: 'ALMACEN, CARGA Y DESCARGA',
	},
	{
		value: '200440',
		label: 'EMBALADOR A MANO',
	},
	{
		value: '200441',
		label: 'CONDUCTOR DE GRUA',
	},
	{
		value: '200442',
		label: 'CONDUCTOR DE EXCAVADORA',
	},
	{
		value: '200443',
		label: 'REPARTIDOR SIN CICLOMOTOR/MOTOCICLETA',
	},
	{
		value: '200444',
		label: 'TAXISTA',
	},
	{
		value: '200445',
		label: 'CONDUCTOR AUTOBUSES',
	},
	{
		value: '200446',
		label: 'CONDUCTOR DE CAMIONES RESTO',
	},
	{
		value: '200447',
		label: 'CONDUCTOR MAQUINARIA PESADA',
	},
	{
		value: '200448',
		label: 'TRANSPORTISTA',
	},
	{
		value: '200452',
		label: 'GERENTE, SIN VIAJES',
	},
	{
		value: '200453',
		label: 'GERENTE, CON VIAJES',
	},
	{
		value: '200454',
		label: 'DIRECTIVO, CON VIAJES',
	},
	{
		value: '200456',
		label: 'GUARDIA CIVIL',
	},
	{
		value: '200457',
		label: 'AGRICULTOR CON EMPLEO DE MAQUINARIA',
	},
	{
		value: '200458',
		label: 'EBANISTA SIN SIERRA',
	},
	{
		value: '200459',
		label: 'MUEBLES, MONTADOR DE',
	},
	{
		value: '200460',
		label: 'CARPINTERO CON SIERRA',
	},
	{
		value: '200461',
		label: 'ALMACENERO',
	},
	{
		value: '200462',
		label: 'CONDUCTOR DE FURGONETAS',
	},
	{
		value: '200463',
		label: 'MECANICO DE AVIONES',
	},
	{
		value: '200466',
		label: 'ASTRONOMO',
	},
	{
		value: '200467',
		label: 'PROTESICO DENTAL',
	},
	{
		value: '200468',
		label: 'ARQUITECTO, SIN VISITA A OBRAS',
	},
	{
		value: '200469',
		label: 'ARQUITECTO, CON VISITA A OBRAS',
	},
	{
		value: '200470',
		label: 'TOPOGRAFO, SIN VISITA A OBRAS',
	},
	{
		value: '200471',
		label: 'ARMADOR DE BUQUES',
	},
	{
		value: '200474',
		label: 'TERAPEUTA OCUPACIONAL',
	},
	{
		value: '200475',
		label: 'MASAJISTA',
	},
	{
		value: '200479',
		label: 'INFORMATICA, ANALISTA DE SISTEMAS',
	},
	{
		value: '200480',
		label: 'ASESOR FISCAL',
	},
	{
		value: '200481',
		label: 'FRIGORISTA',
	},
	{
		value: '200483',
		label: 'PROCURADOR DE LOS TRIBUNALES',
	},
	{
		value: '200485',
		label: 'INDUSTRIAL, SIN VIAJES',
	},
	{
		value: '200486',
		label: 'INDUSTRIAL, CON VIAJES',
	},
	{
		value: '200487',
		label: 'GESTOR ADMINISTRATIVO',
	},
	{
		value: '200489',
		label: 'FILOLOGO',
	},
	{
		value: '200490',
		label: 'PROFESOR DE AUTO-ESCUELA',
	},
	{
		value: '200491',
		label: 'PROFESOR DE NATACION',
	},
	{
		value: '200492',
		label: 'DECORACIONES DE OBRA EN INTERIOR',
	},
	{
		value: '200494',
		label: 'CAMARA, OPERADOR DE',
	},
	{
		value: '200495',
		label: 'MUSICO',
	},
	{
		value: '200497',
		label: 'PROFESOR DE DEPORTES, EXCEPTO NATACION',
	},
	{
		value: '200499',
		label: 'INTERPRETE',
	},
	{
		value: '200500',
		label: 'DIRECTOR COMERCIAL, SIN VIAJES FRECUENTES',
	},
	{
		value: '200501',
		label: 'APAREJADOR SIN VISITA A OBRAS',
	},
	{
		value: '200502',
		label: 'INTERMEDIARIO FINANCIERO, SIN VIAJES',
	},
	{
		value: '200504',
		label: 'TELEFONISTA',
	},
	{
		value: '200505',
		label: 'ADMINISTRATIVO EN CARCELES',
	},
	{
		value: '200506',
		label: 'REPRESENTANTE DE COMERCIO, CON VIAJES',
	},
	{
		value: '200507',
		label: 'REPRESENTANTE DE COMERCIO, SIN VIAJES',
	},
	{
		value: '200508',
		label: 'AGENTE PROPIEDAD INMOBILIARIA',
	},
	{
		value: '200509',
		label: 'MODELO PUBLICITARIO',
	},
	{
		value: '200510',
		label: 'FRUTERIA, EMPLEADO DE',
	},
	{
		value: '200511',
		label: 'CONSERJE',
	},
	{
		value: '200512',
		label: 'LIMPIEZA EN EXTERIORES, EMPLEADO DE',
	},
	{
		value: '200513',
		label: 'AUTOMOVILES, LIMPIEZA DE',
	},
	{
		value: '200514',
		label: 'LAVANDERIA, EMPLEADO DE',
	},
	{
		value: '200515',
		label: 'POLICIA, INSPECTOR DE',
	},
	{
		value: '200516',
		label: 'CARCELES, OFICIAL DE',
	},
	{
		value: '200518',
		label: 'GUIA DE TURISMO',
	},
	{
		value: '200519',
		label: 'DESINFECCION-DESINSECTACION',
	},
	{
		value: '200520',
		label: 'AGRICOLA, TECNICO',
	},
	{
		value: '200521',
		label: 'GANADERO SIN RESES BRAVAS',
	},
	{
		value: '200522',
		label: 'GANADERO,GANADO DE CARNE Y LANA',
	},
	{
		value: '200523',
		label: 'MEDICO PSIQUIATRA',
	},
	{
		value: '200524',
		label: 'HORTICULTOR',
	},
	{
		value: '200525',
		label: 'ENCARGADO, EXCLUSIVAMENTE SUPERVISION',
	},
	{
		value: '200526',
		label: 'METALURGIA, TRABAJADOR EN HORNOS DE LA',
	},
	{
		value: '200527',
		label: 'METALURGIA, FUNDIDOR DE LA',
	},
	{
		value: '200528',
		label: 'ACERO, FABRICACION DE OBJETOS DE',
	},
	{
		value: '200529',
		label: 'ENVASES METALICOS,FABRICACION',
	},
	{
		value: '200530',
		label: 'ASERRADOR',
	},
	{
		value: '200531',
		label: 'TINTORERIA,TRABAJO EN',
	},
	{
		value: '200532',
		label: 'CELADOR',
	},
	{
		value: '200533',
		label: 'CONSERVERA, INDUSTRIA',
	},
	{
		value: '200534',
		label: 'PASTELERO',
	},
	{
		value: '200535',
		label: 'CONFECCION',
	},
	{
		value: '200537',
		label: 'MARROQUINERO',
	},
	{
		value: '200539',
		label: 'FRESADOR',
	},
	{
		value: '200540',
		label: 'AIRE ACONDICIONADO, FABRICACION APARATOS',
	},
	{
		value: '200541',
		label: 'MAQUINARIA LIGERA O PRECISION, MONTADOR DE',
	},
	{
		value: '200542',
		label: 'MAQUINARIA PESADA, MONTADOR DE',
	},
	{
		value: '200543',
		label: 'MECANICO DE MOTOCICLETAS',
	},
	{
		value: '200544',
		label: 'ASCENSORES, INSTALACION',
	},
	{
		value: '200545',
		label: 'APARATOS ELECTRONICOS, REPARACION DE',
	},
	{
		value: '200546',
		label: 'TECNICO RADIO Y TELEVISION',
	},
	{
		value: '200547',
		label: 'ELECTRODOMESTICOS, REPARACION DE',
	},
	{
		value: '200548',
		label: 'EQUIPOS TELEFONICOS, INSTALADOR DE',
	},
	{
		value: '200549',
		label: 'CABLEADOS, FABRICACION DE',
	},
	{
		value: '200550',
		label: 'ROTULOS GRAFICOS, INSTALACION',
	},
	{
		value: '200551',
		label: 'GAS, INSTALACION',
	},
	{
		value: '200552',
		label: 'CALEFACCION, INSTALACION',
	},
	{
		value: '200553',
		label: 'CALEFACCION, MANTENIMIENTO',
	},
	{
		value: '200554',
		label: 'CHAPISTA',
	},
	{
		value: '200555',
		label: 'PIZARRA, MONTADOR DE TEJADOS DE',
	},
	{
		value: '200556',
		label: 'ALUMINIO, MONTADOR DE',
	},
	{
		value: '200557',
		label: 'JOYERO',
	},
	{
		value: '200558',
		label: 'PORCELANA, FABRICACION DE',
	},
	{
		value: '200560',
		label: 'PLASTICO INYECTADO,FABRICACION',
	},
	{
		value: '200562',
		label: 'MARINERO',
	},
	{
		value: '200563',
		label: 'PINTOR DE AUTOMOVILES',
	},
	{
		value: '200564',
		label: 'CEMENTO, FABRICACION ARTICULOS',
	},
	{
		value: '200565',
		label: 'SOLADOR',
	},
	{
		value: '200566',
		label: 'HORMIGON, MONTADOR ESTRUCTURAS DE',
	},
	{
		value: '200567',
		label: 'ENCOFRADOR',
	},
	{
		value: '200568',
		label: 'FERRALLISTA',
	},
	{
		value: '200569',
		label: 'PARQUETISTA-ENTARIMADOR',
	},
	{
		value: '200570',
		label: 'ESCAYOLISTA',
	},
	{
		value: '200572',
		label: 'PERSIANAS, INSTALACION DE',
	},
	{
		value: '200573',
		label: 'ESTIBADOR PORTUARIO',
	},
	{
		value: '200574',
		label: 'REPONEDOR DE MERCANCIAS EN SUPERMERCADO',
	},
	{
		value: '200575',
		label: 'DOBLADOR-PRENSADOR',
	},
	{
		value: '200576',
		label: 'MENSAJERO',
	},
	{
		value: '200578',
		label: 'GASOLINA, EXPENDEDOR DE',
	},
	{
		value: '200579',
		label: 'EXTINTORES, INSTALACION Y RECARGA',
	},
	{
		value: '200580',
		label: 'ACTOR SIN RIESGOS ESPECIALES',
	},
	{
		value: '200581',
		label: 'SEGUROS SIN VIAJES, EMPLEADO DE',
	},
	{
		value: '200582',
		label: 'TECNICO COMERCIAL DE SEGUROS',
	},
	{
		value: '200583',
		label: 'SEGUROS CON VIAJES, EMPLEADO DE',
	},
	{
		value: '200585',
		label: 'PERITO TASADOR',
	},
	{
		value: '200587',
		label: 'AGRICULTOR SIN EMPLEO DE MAQUINARIA',
	},
	{
		value: '200588',
		label: 'LEÑADOR CON SIERRA MECANICA',
	},
	{
		value: '200589',
		label: 'ALMACEN, JEFE DE(EXCLUSIVAMENTE SUPERVISION)',
	},
	{
		value: '200590',
		label: 'MINERO SIN EXPLOSIVOS',
	},
	{
		value: '200591',
		label: 'DESTILERIA,INDUSTRIA DE',
	},
	{
		value: '200592',
		label: 'CANTERO SIN EXPLOSIVOS',
	},
	{
		value: '200593',
		label: 'MATADERO, TRABAJO EN',
	},
	{
		value: '200594',
		label: 'EBANISTA CON SIERRA',
	},
	{
		value: '200595',
		label: 'ASCENSORES, REPARACION',
	},
	{
		value: '200596',
		label: 'ANTENAS, INSTALACION',
	},
	{
		value: '200597',
		label: 'PISCINAS, MANTENIMIENTO',
	},
	{
		value: '200598',
		label: 'ARTES GRAFICAS, EMPLEADO DE',
	},
	{
		value: '200599',
		label: 'PINTOR EN EXTERIORES',
	},
	{
		value: '200600',
		label: 'CONTRATISTA DE OBRAS',
	},
	{
		value: '200601',
		label: 'CONSTRUCCION, CONTROL CALIDAD',
	},
	{
		value: '200602',
		label: 'CARPINTERO SIN SIERRA',
	},
	{
		value: '200603',
		label: 'CONSTRUCCION, PEON DE LA',
	},
	{
		value: '200604',
		label: 'FISICO, SIN RIESGO NUCLEAR',
	},
	{
		value: '200605',
		label: 'FISICO NUCLEAR',
	},
	{
		value: '200606',
		label: 'METEOROLOGO',
	},
	{
		value: '200609',
		label: 'PRACTICO DE PUERTO',
	},
	{
		value: '200610',
		label: 'ZOOLOGO',
	},
	{
		value: '200611',
		label: 'MEDICO FORENSE',
	},
	{
		value: '200613',
		label: 'MEDICO OFTALMOLOGO',
	},
	{
		value: '200618',
		label: 'SECRETARIO JUDICIAL',
	},
	{
		value: '200620',
		label: 'REGISTRADOR DE LA PROPIEDAD',
	},
	{
		value: '200622',
		label: 'SOCIOLOGO',
	},
	{
		value: '200623',
		label: 'METEOROLOGOS EN OBSERVATORIO PELIGROSO',
	},
	{
		value: '200625',
		label: 'SACERDOTE',
	},
	{
		value: '200626',
		label: 'CLERIGO',
	},
	{
		value: '200627',
		label: 'PINTOR-ARTISTA',
	},
	{
		value: '200628',
		label: 'RESTAURADOR OBRAS ARTE',
	},
	{
		value: '200629',
		label: 'RESTAURADOR DE EDIFICIOS',
	},
	{
		value: '200630',
		label: 'REPRESENTANTE ARTISTICO',
	},
	{
		value: '200631',
		label: 'PROFESIONAL ESPECTACULOS TAURINOS',
	},
	{
		value: '200632',
		label: 'TECNICO TENIS DE MESA',
	},
	{
		value: '200634',
		label: 'ENTRENADOR DEPORTIVO',
	},
	{
		value: '200640',
		label: 'ASTROLOGO',
	},
	{
		value: '200641',
		label: 'CONCEJAL DE OBRAS',
	},
	{
		value: '200646',
		label: 'TAQUILLERO/CAJERO',
	},
	{
		value: '200648',
		label: 'INTERMEDIARIO FINANCIERO, CON VIAJES',
	},
	{
		value: '200649',
		label: 'JEFE DE ESTACION',
	},
	{
		value: '200650',
		label: 'INSPECTOR DE AUTOBUSES URBANOS',
	},
	{
		value: '200651',
		label: 'ORDENANZA',
	},
	{
		value: '200652',
		label: 'BOTONES',
	},
	{
		value: '200655',
		label: 'ENCUESTADOR',
	},
	{
		value: '200658',
		label: 'CONDUCTOR LANCHA PARACAIDISMO ACUATICO',
	},
	{
		value: '200665',
		label: 'CHURRERIA,TRABAJO EN',
	},
	{
		value: '200667',
		label: 'PORTERO EN FINCAS URBANAS',
	},
	{
		value: '200670',
		label: 'LIMPIADOR DE CRISTALES',
	},
	{
		value: '200674',
		label: 'GUIA DE MONTA¥A (CON ESCALADA)',
	},
	{
		value: '200675',
		label: 'TANATORIO, EMPLEADO DE',
	},
	{
		value: '200677',
		label: 'AGRICOLA, LIMPIEZA FINCAS',
	},
	{
		value: '200679',
		label: 'REGADOR',
	},
	{
		value: '200680',
		label: 'GAS, FABRICACION',
	},
	{
		value: '200681',
		label: 'ANILINAS, FABRICACION',
	},
	{
		value: '200682',
		label: 'ESTAMPADOR TEXTIL',
	},
	{
		value: '200685',
		label: 'IMPERMEABILIZADOR FACHADAS Y TEJADOS',
	},
	{
		value: '200686',
		label: 'PASAMANERO A MANO',
	},
	{
		value: '200687',
		label: 'PASAMANERO A MAQUINA',
	},
	{
		value: '200688',
		label: 'TEJEDOR DE REDES A MANO',
	},
	{
		value: '200692',
		label: 'ALMAZARERO',
	},
	{
		value: '200693',
		label: 'ACEITE INDUSTRIAL, RECICLAJE',
	},
	{
		value: '200694',
		label: 'TABAQUERA,INDUSTRIA',
	},
	{
		value: '200695',
		label: 'PELETERO',
	},
	{
		value: '200696',
		label: 'PARAGUAS Y BASTONES,FABRICACION',
	},
	{
		value: '200699',
		label: 'GUARNICIONERO',
	},
	{
		value: '200700',
		label: 'BARNIZADOR',
	},
	{
		value: '200702',
		label: 'CUCHILLOS, FABRICACION',
	},
	{
		value: '200704',
		label: 'NAVAJAS, FABRICACION DE',
	},
	{
		value: '200707',
		label: 'FRIGORIFICOS INDUSTRIALES,INSTALACION',
	},
	{
		value: '200710',
		label: 'RELOJES, REPARACION',
	},
	{
		value: '200711',
		label: 'ORTOPEDICOS, MECANICO APARATOS',
	},
	{
		value: '200713',
		label: 'LAVADO Y ENGRASE VEHICULOS A MOTOR',
	},
	{
		value: '200720',
		label: 'SONIDO E ILUMINACION, INSTALADOR DE',
	},
	{
		value: '200725',
		label: 'HOJALATA, FABRICACION',
	},
	{
		value: '200729',
		label: 'ACERO, MONTADOR DE ESTRUCTURAS DE',
	},
	{
		value: '200731',
		label: 'TRAMOYISTA',
	},
	{
		value: '200732',
		label: 'URALITA, MONTADOR DE',
	},
	{
		value: '200734',
		label: 'ORFEBRE-PLATERO',
	},
	{
		value: '200736',
		label: 'GOMA,FABRICACION DE ARTICULOS',
	},
	{
		value: '200738',
		label: 'PLASTICO SOLDADURA, FABRICACION',
	},
	{
		value: '200739',
		label: 'NEUMATICOS, VULCANIZACION',
	},
	{
		value: '200740',
		label: 'CELULOIDE,FABRICACION ARTICULOS',
	},
	{
		value: '200741',
		label: 'CARTON, FABRICACION',
	},
	{
		value: '200742',
		label: 'PAPEL, EMPLEADO FABRICA DE',
	},
	{
		value: '200744',
		label: 'CAJAS EMBALAJES, FABRICACION',
	},
	{
		value: '200745',
		label: 'LITOGRAFO',
	},
	{
		value: '200746',
		label: 'ENCUADERNADOR',
	},
	{
		value: '200748',
		label: 'MUSICA,FABRICACION INSTRUMENTOS DE',
	},
	{
		value: '200749',
		label: 'INSTRUMENTOS MUSICALES, AFINADOR',
	},
	{
		value: '200750',
		label: 'CESTERO',
	},
	{
		value: '200751',
		label: 'JUGUETES,INDUSTRIA',
	},
	{
		value: '200752',
		label: 'CERA, FABRICACION ARTICULOS',
	},
	{
		value: '200753',
		label: 'TAXIDERMISTA',
	},
	{
		value: '200754',
		label: 'MAMPOSTERO',
	},
	{
		value: '200756',
		label: 'ALICATADOR',
	},
	{
		value: '200759',
		label: 'TECHADOR',
	},
	{
		value: '200761',
		label: 'PULIDOR',
	},
	{
		value: '200764',
		label: 'CAL, FABRICACION',
	},
	{
		value: '200769',
		label: 'EMBOTELLADOR',
	},
	{
		value: '200771',
		label: 'CHATARRERO',
	},
	{
		value: '200773',
		label: 'CAMPOS, LIMPIEZA DE',
	},
	{
		value: '200778',
		label: 'GUIA DE MONTA¥A (SIN ESCALADA)',
	},
	{
		value: '200779',
		label: 'ORGANIZADOR DE CACERIAS',
	},
	{
		value: '200782',
		label: 'ENOLOGO',
	},
	{
		value: '200783',
		label: 'PIZARRA EN FABRICA, OBRERO DE',
	},
	{
		value: '200785',
		label: 'EQUIPOS TELEGRAFICOS, INSTALADOR DE',
	},
	{
		value: '200787',
		label: 'ATRACCIONES DE FERIA CON MONTAJE',
	},
	{
		value: '200788',
		label: 'ATRACCIONES DE FERIA SIN MONTAJE',
	},
	{
		value: '200789',
		label: 'OPTICA, FABRICACION INSTRUMENTOS',
	},
	{
		value: '200794',
		label: 'POCERO SIN EXPLOSIVOS',
	},
	{
		value: '200795',
		label: 'POCERO CON EXPLOSIVOS',
	},
	{
		value: '200797',
		label: 'HORNO CREMATORIO, OPERARIO',
	},
	{
		value: '200799',
		label: 'ALCALDE',
	},
	{
		value: '200802',
		label: 'MOSSOS D¿ESQUADRA',
	},
	{
		value: '200825',
		label: 'PILOTO VUELO  > 500 H/AÑOS',
	},
	{
		value: '200826',
		label: 'CONDUCTOR DE CAMIONES DE PRODUCTOS INFLAMABLES',
	},
	{
		value: '200827',
		label: 'CONDUCTOR CAMIONES PRODUCTOS TÓXICOS O CORROSIVOS',
	},
	{
		value: '200828',
		label: 'CONDUCTOR DE CAMIONES DE PRODUCTOS EXPLOSIVOS',
	},
	{
		value: '200829',
		label: 'DESEMPLEADO',
	},
];
