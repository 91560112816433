import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	UserWrapper,
	View,
	VinculationWrapper,
	Text,
} from 'coinscrap-webapp-core';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import InputLabel from 'components/InputLabel/InputLabel';

import TooltipSimple from 'config/components/tooltipSimple';
import { alcoholTypes } from 'data/alcoholTypes';
import { smokeTypes } from 'data/smokeTypes';
import { professions } from 'data/professions';

import Header from 'layout/components/Header/Header';
import { ClientSimulationWrapper } from 'wrappers/customWrapper/ClientSimulationWrapper';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import moment from 'moment';

export const SimulationDataAgent = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
	vinculation: {
		sourcePath: 'vinculation',
		sourceWrapper: VinculationWrapper,
	},
	instance: {
		sourcePath: 'vinculationApiInstance',
		sourceWrapper: VinculationWrapper,
	},
	simulation: {
		sourcePath: 'simulation',
		sourceWrapper: ClientSimulationWrapper,
	},
	client: {
		sourcePath: 'client',
		sourceWrapper: ClientSimulationWrapper,
	},
})(() => {
	const { navigateTo, goBack } = NavigationWrapper.use();
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { updateClient, updateSimulation } = ClientSimulationWrapper.use();
	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const pickerValues = PickerHOC.use();

	const { client, simulation } = pickerValues;
	const creationData = simulation?.targetProperties?.find(x => x.name === 'creationData')?.value;

	const [gender, setGender] = useState(client?.metadata?.gender || '');

	const [birthDate, setBirthDate] = useState(
		client?.metadata?.birthDate || moment().subtract(18, 'years'),
	);

	const [weight, setWeight] = useState(client?.metadata?.weight || '');
	const [height, setHeight] = useState(client?.metadata?.height || '');

	const [profession, setProfession] = useState(client?.metadata?.profession || '');

	const [telephone, setTelephone] = useState(client?.phoneNumber || '');

	// FUMAS?
	const [smoke, setSmoke] = useState(client?.metadata?.smoke || 'no');
	const [smokeQuantity, setSmokeQuantity] = useState(client?.metadata?.smokeQuantity || null);
	const [smokeType, setSmokeType] = useState(client?.metadata?.smokeType || 'Cigarrillos');

	// ALCOHOL?
	const [alcohol, setAlcohol] = useState(client?.metadata?.alcohol || 'no');
	const [alcoholQuantity, setAlcoholQuantity] = useState(client?.metadata?.alcoholQuantity || null);
	const [alcoholType, setAlcoholType] = useState(client?.metadata?.alcoholType || 'Cerveza');

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.simulationDataAgent.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('Guardando datos de cliente');

			const professionESP = CONFIG.translations.es.professionaList.professions.find(
				espProf => espProf.value === professions?.find(p => p?.label === profession)?.value,
			).label;

			await updateClient({
				phoneNumber: telephone,
				metadata: {
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionESP,
					professionCode: professions?.find(p => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: true,
				},
			});
			setLoading('Guardando datos de simulación');
			await updateSimulation({
				details: {
					birthDate,
					gender,
					weight: parseInt(weight),
					height: parseInt(height),
					profession,
					professionESP,
					professionCode: professions?.find(p => p?.label === profession)?.value,
					smoke,
					smokeQuantity: smokeQuantity || 0,
					smokeType: smokeType || undefined,
					alcohol,
					alcoholQuantity: alcoholQuantity || 0,
					alcoholType: alcoholType || undefined,
					isB2B: true,
				},
			});
			setLoading(undefined);
			CONFIG.routing.simulationDataAgent.next(navigateTo);
		} catch (e) {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un error generando la simulación</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-SavingSimulationError',
				},
			);
		}
	};

	const isDisabled = () => {
		return (
			!birthDate ||
			moment(birthDate).unix() > moment().subtract(18, 'years').unix() ||
			!telephone ||
			!gender ||
			!weight ||
			!height ||
			(smoke === 'yes' && (smokeQuantity === null || smokeType === null)) ||
			(alcohol === 'yes' && (alcoholQuantity === null || alcoholType === null))
		);
	};

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 client', client);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper width={'60%'}>
			<Text headingPrimarySt>Datos obligatorios</Text>
			<Text headingSecondarySt>
				Para poder calcular el precio del seguro necesitamos que tu cliente conteste a unas
				preguntas obligatorias.
			</Text>
			<View inputRowSt>
				<InputLabel
					value={birthDate}
					onChange={e => {
						//console.log(e);
						setBirthDate(e);
					}}
					label='Fecha de nacimiento'
					type='date'
					errorMsg={
						moment(birthDate).unix() > moment().subtract(18, 'years').unix() &&
						`La edad mínima de contratación es de 18 años`
					}
				/>

				<InputLabel type='custom' label='¿Cuál es tu género?'>
					<View rowSt spaceBetweenSt>
						{/* //TODO: refactor */}
						<S.Button
							onClick={() => {
								setGender('Male');
							}}
							selected={gender === 'Male'}
						>
							Hombre
						</S.Button>
						<S.Button
							selected={gender === 'Female'}
							onClick={() => {
								setGender('Female');
							}}
						>
							Mujer
						</S.Button>
					</View>
				</InputLabel>
			</View>

			<View inputRowSt alignItemsSt>
				<InputLabel
					value={weight}
					onChange={e => {
						if (isNaN(+e.target.value) || '') return;
						setWeight(parseInt(e.target.value || 0));
					}}
					label='¿Cuál es tu peso? (kg)'
				/>
				<InputLabel
					value={height}
					onChange={e => {
						if (isNaN(+e.target.value) || '') return;

						setHeight(parseInt(e.target.value || 0));
					}}
					label='¿Cuál es tu altura? (cm)'
				/>
			</View>

			<View inputRowSt>
				<InputLabel
					value={telephone}
					onChange={value => {
						setTelephone(value);
					}}
					label='Teléfono'
					type='phone'
				/>

				<InputLabel
					showLabel={true}
					value={profession || ''}
					onChange={value => {
						setProfession(value);
					}}
					label={'Profesión'}
					type='autoSuggest'
					selectOptions={professions.map(item => {
						return {
							label: item.label,
							value: item.label,
						};
					})}
				/>
			</View>

			<View inputRowSt>
				<InputLabel
					value={smoke || 'no'}
					onChange={e => {
						setSmoke(e.target.value);
					}}
					label='¿Fumas?'
					tooltip={
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								{/*<S.TooltipTitle>Información sobre designación de beneficiarios.</S.TooltipTitle>*/}
								<Text tooltipTextSt>
									Si la respuesta es sí, se desplegará un combo con las opciones de cigarrillos,
									puros, pipas y cigarrillos electrónicos. Una vez seleccionado el tipo se mostrará
									un campo indicando cantidad. En el caso de que la respuesta sea no, no se
									desplegaría ningún combo.
								</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' },
					].map(item => {
						return {
							label: item.label,
							value: item.value,
						};
					})}
				/>
				{smoke === 'yes' && (
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeQuantity}
							onChange={e => {
								//console.log('10 Quantity Change', e.target.value);
								setSmokeQuantity(+e.target.value);
							}}
							label='Cantidad diaria'
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={smokeType}
							onChange={e => {
								setSmokeType(e.target.value);
							}}
							label='Tipo de tabaco'
							type='select'
							selectOptions={smokeTypes.map(item => {
								return {
									label: item.name,
									value: item.value,
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>

			<View inputRowSt>
				<InputLabel
					value={alcohol || 'no'}
					onChange={e => {
						setAlcohol(e.target.value);
					}}
					label='¿Consumes bebidas alcohólicas?'
					tooltip={
						<View style={{ width: '10%' }}>
							<TooltipSimple>
								<Text tooltipTextSt>
									Si la respuesta es sí, se solicitará la cantidad diaria y el tipo de alcohol. Las
									unidades de alcohol miden el volumen de alcohol puro de bebida alcohólica. Se
									utiliza en muchos es como guía para medir consumo de alcohol. En el caso de que la
									respuesta sea no, no se desplegaría ningún combo.
								</Text>
							</TooltipSimple>
						</View>
					}
					type='select'
					selectOptions={[
						{ label: 'Sí', value: 'yes' },
						{ label: 'No', value: 'no' },
					].map(item => {
						return {
							label: item.label,
							value: item.value,
						};
					})}
				/>
				{alcohol === 'yes' && (
					//TODO: refactor OptionsSmokeAndAlcohol
					<S.OptionsSmokeAndAlcohol>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholQuantity}
							onChange={e => {
								//console.log('11 Quantity Change', e.target.value);
								setAlcoholQuantity(+e.target.value);
							}}
							label='Cantidad diaria'
							errorMsg={true}
						/>
						<InputLabel
							style={{ width: '49%', height: '100%' }}
							value={alcoholType}
							onChange={e => {
								setAlcoholType(e.target.value);
							}}
							label='Tipo de alcohol'
							type='select'
							selectOptions={alcoholTypes.map(item => {
								return {
									label: item.name,
									value: item.value,
								};
							})}
						/>
					</S.OptionsSmokeAndAlcohol>
				)}
			</View>

			<BackNextButton
				onClickNext={next}
				nextText={'SIGUIENTE'}
				isBackShown={false}
				isBackDisabled={false}
				nextWidth={300}
				isNextDisabled={isDisabled()}
			/>
		</Wrapper>
	);
});
