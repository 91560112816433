//ESPAÑOL
import es from './translations/es/translation.json';
import { smokeTypesES, alcoholTypesES } from './translations/es/drugsTypes.js';
import { professionalSituationListES } from './translations/es/professionalSituationList';
import { professionsES } from './translations/es/professions';
import { docNamesES } from './translations/es/documentsSign';
import { documentTypesES } from 'config/translations/es/documentTypes';

//RUSO
import ru from './translations/ru/translation.json';
import { smokeTypesRU, alcoholTypesRU } from './translations/ru/drugsTypes.js';
import { professionalSituationListRU } from './translations/ru/professionalSituationList';
import { professionsRU } from './translations/ru/professions';
import { docNamesRU } from './translations/ru/documentsSign';
import { documentTypesRU } from 'config/translations/ru/documentTypes';

//UCRANIANO
import ua from './translations/ua/translation.json';
import { smokeTypesUA, alcoholTypesUA } from './translations/ua/drugsTypes.js';
import { professionalSituationListUA } from './translations/ua/professionalSituationList';
import { professionsUA } from './translations/ua/professions';
import { docNamesUA } from './translations/ua/documentsSign';
import { documentTypesUA } from 'config/translations/ua/documentTypes';

const translations = {
	es: {
		main: es,
		drugs: {
			smoke: smokeTypesES,
			alcohol: alcoholTypesES,
		},
		professionaList: {
			professionalListSituation: professionalSituationListES,
			professions: professionsES,
		},
		documentsSign: {
			docNames: docNamesES,
		},
		documentTypes: {
			docTypes: documentTypesES,
		},
	},
	ru: {
		main: ru,
		drugs: {
			smoke: smokeTypesRU,
			alcohol: alcoholTypesRU,
		},
		professionaList: {
			professionalListSituation: professionalSituationListRU,
			professions: professionsRU,
		},
		documentsSign: {
			docNames: docNamesRU,
		},
		documentTypes: {
			docTypes: documentTypesRU,
		},
	},
	ua: {
		main: ua,
		drugs: {
			smoke: smokeTypesUA,
			alcohol: alcoholTypesUA,
		},
		professionaList: {
			professionalListSituation: professionalSituationListUA,
			professions: professionsUA,
		},
		documentsSign: {
			docNames: docNamesUA,
		},
		documentTypes: {
			docTypes: documentTypesUA,
		},
	},
};

export default translations;
