import {
	FinancialWrapper,
	IdentityDocumentsWrapper,
	InstitutionAccountsWrapper,
	InstitutionsWrapper,
	OperationsWrapper,
	TargetsUtilsWrapper,
	TargetsWrapper,
	TargetTypesWrapper,
	TestResponsesWrapper,
	TestsWrapper,
	UserWrapper,
	VinculationsWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';
import React from 'react';

export const EntityWrappers = ({ children, ...props }) => {
	const transportsAnalytics = [
		{
			engine: AnalyticsWrapper.TRANSPORTS.COINSCRAP,
			initProps: {
				debugEnabled: false,
			},
		},
		{
			engine: AnalyticsWrapper.TRANSPORTS.FACEBOOK_PIXEL,
			initProps: {
				debugEnabled: false,
			},
		},
		{
			engine: AnalyticsWrapper.TRANSPORTS.HOTJAR,
			initProps: {
				debugEnabled: false,
			},
		},
		{
			engine: AnalyticsWrapper.TRANSPORTS.GOOGLE_ANALYTICS,
			initProps: {
				debugEnabled: false,
			},
		},
	];
	return (
		<UserWrapper enableRoles>
			<AnalyticsWrapper transports={transportsAnalytics}>
				<TestsWrapper>
					<TestResponsesWrapper>
						<IdentityDocumentsWrapper>
							<VinculationsWrapper>
								<OperationsWrapper>
									<InstitutionsWrapper>
										<InstitutionAccountsWrapper>
											<FinancialWrapper>
												<TargetTypesWrapper>
													<TargetsWrapper>
														<TargetsUtilsWrapper>
															<TargetsUtilsWrapper>{children}</TargetsUtilsWrapper>
														</TargetsUtilsWrapper>
													</TargetsWrapper>
												</TargetTypesWrapper>
											</FinancialWrapper>
										</InstitutionAccountsWrapper>
									</InstitutionsWrapper>
								</OperationsWrapper>
							</VinculationsWrapper>
						</IdentityDocumentsWrapper>
					</TestResponsesWrapper>
				</TestsWrapper>
			</AnalyticsWrapper>
		</UserWrapper>
	);
};
