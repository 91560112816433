import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';

export const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 20px;
`;
export const StepWrapperItem = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	justify-content: flex-start;
	margin-bottom: 40px;
`;
export const StepCircle = styled.div`
	height: 36px;
	width: 36px;
	background-color: ${CONFIG.colors.primary};
	border-radius: 50%;
	margin-right: ${isMobile ? 0 : '15px'};
	font-size: 17px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
`;
export const StepText = styled.p`
	max-width: 750px;
	font-size: 24px;
	text-align: left;
	font-weight: 200;
	color: ${CONFIG.colors.grey};
`;
