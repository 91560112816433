import { CONFIG } from 'config/config';
import React from 'react';
import * as S from './styles';
import { Button, Text, InternationalizationWrapper } from 'coinscrap-webapp-core';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';

const BeneficiaryBox = ({ ben, onDelete, onEdit }) => {
	const { t } = InternationalizationWrapper.use();
	return (
		<S.PrimaryWrapper>
			<S.QuestionnaireBoxWrapper>
				<Text questionnariesSt>
					{t('clientData.beneficiaryModal.inputName')}: {ben.name || ''}
				</Text>
				<Text questionnariesSt>
					{t('clientData.beneficiaryModal.inputDNI')}: {ben.dni || ''}
				</Text>
				<Text questionnariesSt>
					{t('clientData.beneficiaryModal.inputSurname')}: {ben.surname || ''}
				</Text>
				<Text questionnariesSt>
					{t('clientData.beneficiaryModal.inputPercentage')}: {`${ben.percentage} %` || ''}
				</Text>
			</S.QuestionnaireBoxWrapper>
			<S.QuestionnaireBoxButtonWrapper>
				<Button beneficiaryBoxSt onClick={onEdit}>
					<FiEdit style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
				<Button beneficiaryBoxSt onClick={onDelete}>
					<RiDeleteBin6Line style={{ color: CONFIG.colors.primary }} size={24} />
				</Button>
			</S.QuestionnaireBoxButtonWrapper>
		</S.PrimaryWrapper>
	);
};

export default BeneficiaryBox;
