import React, { useEffect, useState } from 'react';
import * as S from './stylesB2C';

import {
	Button,
	EnvelopesWrapper,
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	View,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const ProductCreationFinishedB2C = PickerHOC(
	{
		envelope: {
			sourcePath: 'envelopes',
			sourceWrapper: EnvelopesWrapper,
			arrayItemMatcher: {
				type: 'param',
				paramName: 'envelopeId',
				itemPropertyPath: 'id',
			},
		},
	},
	LoadingScreen,
)(() => {
	const { goBack, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('ProductCreationFinishedB2C');
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const [loading, setLoading] = useState();
	const { envelope } = PickerHOC.use();
	const { t, currentLang } = InternationalizationWrapper.use();

	const [urlSigned, setUrlSigned] = useState();
	useEffect(() => {
		if (envelope?.signStatus === 'SIGNED') {
			setUrlSigned(envelope?.combinedSignedUrl);
		}
		return () => {};
	}, [envelope]);

	const downloadDoc = url => {
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `doc.pdf`);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const next = async () => {
		setLoading(undefined);
		openModal(() => <ErrorModal>{t('productCreation.finishedProcess')}</ErrorModal>, {
			disableClickAway: true,
			closeButton: true,
			modalClass: isMobile ? 'modal-xl' : 'modal-main',
			key: 'modal-anyadir-beneficiaries',
		});
	};

	useEffect(() => {
		console.log('📌 envelope', envelope);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useHeader(
		<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} showLogoCaser={true} />,
		[],
	);
	useLoading(loading, { loadingMessage: loading });

	return (
		<Wrapper>
			<Text noT headingPrimarySt>
				{t('productCreation.title')}
			</Text>
			<S.StepWrapper>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text noT manualContractSt>
						{t('productCreation.description')}
					</Text>
				</S.StepWrapperItem>
				<S.StepWrapperItem>
					<S.StepCircle>
						<img src={arrowRigth} alt={'arrow icon'} />
					</S.StepCircle>
					<Text noT manualContractSt>
						{t('productCreation.feature')} <br></br>
						<a href='tel:+34913427859'>{t('productCreation.callUs')}</a>
					</Text>
				</S.StepWrapperItem>
			</S.StepWrapper>

			<View style={{ marginTop: 40 }}>
				<Button
					selectedSt
					onClick={() => downloadDoc(urlSigned)}
					label='productCreation.downloadContract'
				></Button>
			</View>
			<BackNextButton
				onClickNext={next}
				nextText={'productCreation.finishedContract'}
				nextWidth={300}
				isBackShown={false}
				isNextDisabled={false}
			/>
		</Wrapper>
	);
});
