import { CONFIG } from 'config/config';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';

export const HeaderPrimary = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${props => props.size || '29px'};
	text-align: ${props => props.textAlign || 'left'};
	margin-bottom: ${props => props.marginBott || '0px'};
	width: 100%;
`;

export const SubHeaderImage = styled.img`
	display: flex;
	max-height: 35vh;
	margin-top: 30px;
	width: 100%;
	@media (max-width: 420px) {
		margin-top: 35px;
	}
`;

export const TextPrimary = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${props => props.size || '29px'};
	text-align: ${props => props.textAlign || 'left'};
	margin-bottom: ${props => props.marginBott || '0px'};
	@media (max-width: 820px) {
		font-size: 29px;
		text-align: center;
	}
`;

export const TextFeatures = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${props => props.size || '29px'};
	text-align: ${props => props.textAlign || 'left'};
	margin-bottom: ${props => props.marginBott || '0px'};
	@media (max-width: 820px) {
		font-size: ${props => (props.currentLang === 'es' ? '14px' : '12px')};
		padding: 10px;
		text-align: center;
	}
`;

export const TextFeaturesSecondary = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: ${props => props.size || '20px'};
	text-align: ${props => props.textAlign || 'center'};
	margin-bottom: ${props => props.marginBott || '0px'};
	@media (max-width: 820px) {
		font-size: ${props => (props.fontSizeLang === 'es' ? '14px' : '14px')};
		padding: 10px;
		text-align: center;
	}
`;

export const TextSecondary = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.dark};
	font-family: ${props => props.font || CONFIG.fonts.light};
	font-size: ${props => props.size || '16px'};
	text-align: ${props => props.textAlign || 'left'};
	:lang(es) {
		hyphens: auto;
		word-break: normal;
	}
	@media (max-width: 820px) {
		max-width: 320px;
	}
`;

export const TextSecondaryFeatures = styled.div`
	color: ${props => props.mainColor || CONFIG.colors.dark};
	font-family: ${props => props.font || CONFIG.fonts.light};
	font-size: ${props => props.size || '16px'};
	text-align: ${props => props.textAlign || 'left'};
	word-break: break-all;
	:lang(es) {
		hyphens: auto;
		word-break: normal;
	}
	@media (max-width: 820px) {
		text-align: left;
	}
`;

export const LandingFeatures = styled.div`
  display: flex;
  justify-content: center;
  .container {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'wrapper-feature'
      'wrapper-feature-secondary '
      'wrapper-feature-articles'
      'wrapper-feature-articles-secondary'
      'wrapper-feature-articles-terciary';
    width: 85%;
	@media (max-width: 820px) {
		gap: 45px 0px;
	}
  }

  .wrapper-feature {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '.'
      'wrapper-feature-explanation';
    grid-area: wrapper-feature;
	@media (max-width: 820px){
		grid-template-rows: 0.5fr 1fr;
		gap: 15px 0px;
	}
  }

  .wrapper-feature-explanation {
    grid-area: wrapper-feature-explanation;

  }

  .wrapper-feature-secondary {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '.'
      'wrapper-feature-secondary-explanation';
    grid-area: wrapper-feature-secondary;
	@media (max-width: 820px){
		grid-template-rows: 0.5fr 1fr;
		gap: 15px 0px;
	}
  }

  .wrapper-feature-secondary-explanation {
    grid-area: wrapper-feature-secondary-explanation;

  }

  .wrapper-feature-articles {}

  .feature-articles {
	margin-top: 20px;
	padding-top: 20px;
	background-color: #F7F7F7;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.7fr;
    gap: 10px 20px;
    grid-auto-flow: row;
    grid-template-areas:
      'feature-paperless feature-documents feature-capital'
      'feature-paperless-description feature-documets-description feature-capital-description';
    grid-area: feature-articles;

	@media (max-width: 820px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1.2fr 0.2fr;
		gap: 8px 10px;
		grid-template-areas:
      'feature-paperless'
      'feature-paperless-description'
	  'feature-documents'
	  'feature-documets-description'
	  'feature-capital'
	  'feature-capital-description';
	  padding-bottom: 20px;
	  padding-top: 20px;
	}
  }

  .wrapper-feature-flex{
    display: flex;
  }

  .feature-paperless {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 7px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-paperless-title feature-paperless-title feature-paperless-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-paperless;
  }

  .feature-paperless-title {
    grid-area: feature-paperless-title;
  }

  .feature-paperless-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-paperless-description;
    max-width: 350px;
  }

  .feature-documents {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 5px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-documents-title feature-documents-title feature-documents-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-documents;
	@media (max-width: 820px) {
		padding-top: 10px;
	}
  }

  .feature-documents-title {
    grid-area: feature-documents-title;
  }

  .feature-documets-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-documets-description;
    max-width: 350px;
  }

  .feature-capital {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 7px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-capital-title feature-capital-title feature-capital-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-capital;
	@media (max-width: 820px) {
		padding-top: 10px;
	}
  }

  .feature-capital-title {
    grid-area: feature-capital-title;
  }

  .feature-capital-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-capital-description;
    max-width: 350px;
  }



  .wrapper-feature-articles-secondary {
  }


  .feature-articles-secondary {
	margin-top: 20px;
	padding-top: 40px;
	background-color: #F7F7F7;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.7fr;
    gap: 10px 20px;
    grid-auto-flow: row;
    grid-template-areas:
      'feature-decease feature-incapacity feature-repatriation'
      'feature-decease-description feature-incapacity-description feature-repatriation-description';
    grid-area: feature-articles-secondary;
	@media (max-width: 820px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1.2fr 0.2fr;
		gap: 10px 10px;
		grid-template-areas:
      'feature-decease'
	  'feature-decease-description'
	  'feature-incapacity'
	  'feature-incapacity-description'
	  'feature-repatriation'
      'feature-repatriation-description';
	  padding-bottom: 20px;
	  padding-top: 20px;
	}
  }

  .wrapper-feature-articles-secondary-description {
		margin-top: 15px;
  }

  .feature-decease {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 7px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-decease-title feature-decease-title feature-decease-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-decease;
	@media (max-width: 820px) {
		padding-top: 10px;
	}
  }

  .feature-decease-title {
    grid-area: feature-decease-title;
  }

  .feature-incapacity {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 7px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-incapacity-title feature-incapacity-title feature-incapacity-title';
    justify-items: center;
    justify-self: center;
    grid-area: feature-incapacity;
	@media (max-width: 820px) {
		padding-top: 10px;
	}
  }

  .feature-incapacity-title {
    grid-area: feature-incapacity-title;
  }

  .feature-repatriation {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 7px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      'feature-repatriation-title feature-repatriation-title feature-repatriation-title';
    grid-area: feature-repatriation;
    justify-items: center;
    justify-self: center;
	@media (max-width: 820px) {
		padding-top: 10px;
	}
  }

  .feature-repatriation-title {
    grid-area: feature-repatriation-title;
  }

  .feature-decease-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-decease-description;
    max-width: 350px;
  }

  .feature-incapacity-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-incapacity-description;f
    max-width: 350px;
  }

  .feature-repatriation-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-repatriation-description;
    max-width: 350px;
  }

  .wrapper-feature-articles-terciary {
  }

  .feature-articles-terciary {
	margin-top: 20px;
	padding-top: 40px;
	padding-bottom: 30px;
	background-color: #F7F7F7;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.4fr 0.4fr;
    gap: 20px 15px;
    grid-auto-flow: row;
    grid-template-areas:
      'feature-home-family feature-heart-rate feature-home'
      'feature-home-family-description feature-heart-rate-description feature-home-description';
    grid-area: feature-articles-terciary;

	@media(max-width: 820px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1.2fr 0.2fr;
		gap: 10px 10px;
		grid-template-areas:
      'feature-home-family'
      'feature-home-family-description'
	  'feature-heart-rate'
	  'feature-heart-rate-description'
	  'feature-home'
	  'feature-home-description';
	  padding-bottom: 20px;
	  padding-top: 20px;
	}
  }

  .titles-feature-articles-terciary-description {
		margin-top: 15px;
  }

  .feature-home-family {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-home-family;
  }

  .feature-heart-rate {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-heart-rate;
  }

  .feature-home {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      '. . .'
      '. . .'
      '. . .';
    justify-self: center;
    align-self: center;
    grid-area: feature-home;
  }

  .feature-home-family-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-home-family-description;
    max-width: 350px;
  }

  .feature-heart-rate-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-heart-rate-description;
    max-width: 350px;
  }

  .feature-home-description {
    justify-self: center;
    align-self: center;
    grid-area: feature-home-description;
    max-width: 350px;
  }
`;

export const LandingCallToAction = styled.div`
	width: 85%;
	.container {
		display: grid;
		background-color: ${CONFIG.colors.primary};
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 0.5fr 0.5fr 1fr;
		gap: 0px 0px;
		grid-auto-flow: row;
		grid-template-areas:
			'call-to-action--title call-to-action--title'
			'call-to-action--description call-to-action--buttons'
			'call-to-action--call-us call-to-action--mail-us';
	}

	.call-to-action--title {
		grid-area: call-to-action--title;
	}

	.call-to-action--description {
		align-self: center;
		grid-area: call-to-action--description;
	}

	.call-to-action--buttons {
		grid-area: call-to-action--buttons;
		align-self: center;
	}

	.flex-container--buttons {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}

	.call-to-action--call-us {
		align-self: center;
		grid-area: call-to-action--call-us;
	}

	.call-to-action--mail-us {
		align-self: center;
		grid-area: call-to-action--mail-us;
	}

	.call-to-action--call-us__flex {
		display: flex;
		gap: 6px;
	}

	.call-to-action--mail-us__flex {
		display: flex;
		gap: 6px;
	}
`;

export const LandingFAQ = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: flex-start;

	margin-top: 60px;
	width: 85%;
	.flex-faq {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		flex-flow: wrap;
		width: 100%;
		.flex-faq--title {
			align-self: auto;
			flex: 0 1 auto;
			margin-bottom: 14px;
			order: 0;
		}
		.flex-faq--list {
			align-self: auto;
			flex: 0 1 auto;
			order: 1;
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	li {
		display: block;
		background-image: url(https://svgshare.com/i/hfw.svg);
		background-repeat: no-repeat;
		background-position: left top;
		padding-left: 45px;
		padding-top: 10px;
		@media (max-width: 820px) {
			${'' /* height: 69px; */}
			margin-bottom: 15px;
		}
		@media (min-width: 850px) {
			height: 48px;
		}
	}
`;

export const LandingReview = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 70px;
	margin-top: 40px;
	width: 85%;
	@media (max-width: 820px) {
		margin-top: 42px;
	}
`;

export const AllReviewsWrapper = styled.div`
	align-content: center;
	align-items: center;
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
`;

export const NewsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	height: 50vh;
	gap: 5px;
	width: 85%;
	margin-bottom: 40px;
`;

export const NewsContainer = styled.a`
	background-image: ${props => props.imageUrl || null};
	${'' /* background-size: 100% 100%; */}
	background-size: cover;
	box-shadow: 10px 10px 80px 30px black inset;
	border-radius: 6px;
	display: flex;
	flex-basis: ${props => props.basis || '40%'};
	flex-direction: column;
	flex-grow: ${props => props.grow || 1};
	justify-content: flex-end;
	height: 100%;
	transition: transform 9.7s cubic-bezier(0.2, 1, 0.22, 1);

	:hover {
		background-size: transform 2.7s cubic-bezier(1.2, 1, 0.22, 1);
	}

	@media (max-width: 690px) {
		flex-basis: 100%;
		width: 100%;
	}
`;

export const TextUnderlined = styled.p`
	color: ${CONFIG.colors.white};
	cursor: pointer;
	display: inline-block;
	font-family: ${CONFIG.fonts.bold};
	font-size: 18px;
	font-weigth: 700;
	margin: 0 0 10px 0;
	text-align: left;
	position: relative;

	:after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: ${CONFIG.colors.white};
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}

	:hover:after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}

	a {
		color: inherit; /* blue colors for links too */
		text-decoration: inherit; /* no underline */
		font-family: ${CONFIG.fonts.bold};
		font-size: 18px;
		font-weigth: 700;

		a:hover,
		a:focus,
		a:active {
			text-decoration: none;
			color: inherit;
		}
	}
`;

export const TextHeadLine = styled.p`
	color: ${CONFIG.colors.white};
	font-family: ${CONFIG.fonts.bold};
	font-size: 26px;
	font-weight: 600;
	line-height: 36px;

	a {
		color: inherit; /* blue colors for links too */
		text-decoration: inherit; /* no underline */
		font-family: ${CONFIG.fonts.bold};
		font-size: 26px;
		font-weight: 600;
		line-height: 36px;

		a:hover,
		a:focus,
		a:active {
			text-decoration: none;
			color: inherit;
		}
	}
`;

export const ButtonToAction = styled.button`
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 15px 12px 30px;
	gap: 20px;

	width: 375px;
	height: 60px;

	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;

	flex: none;
	order: 0;
	flex-grow: 0;

	cursor: pointer;
`;

export const ArrowIcon = styled.img`
	height: 16px;
	width: auto;
	color: ${CONFIG.colors.primary};
`;

export const Wrapper = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const InputSingleRow = styled.div`
	width: 100%;
	display: grid;

	grid-template-columns: repeat(1, 1fr);
	margin-bottom: 35px;
`;

export const SubmitButton = styled.button`
	font-size: 14px;
	color: ${CONFIG.colors.primary};
	text-align: center;
	padding: 10px 15px;
	border-radius: 45px;
	outline: none;
	border: 2px solid ${CONFIG.colors.primary};
	background-color: #f7f3f8;

	:focus {
		outline: none;
	}
`;
export const TableButton = styled.button`
	font-size: 16px;
	color: ${CONFIG.colors.primary};
	text-align: center;
	padding: 7px 3px;
	border-radius: 45px;
	outline: none;
	border: 2px solid ${CONFIG.colors.primary};
	background-color: #f7f3f8;

	:focus {
		outline: none;
	}
`;
export const TableRowFilters = styled.div`
	display: grid;
	grid-template-columns: ${props =>
		props?.isGlobalAgent ? 'repeat(7, minmax(0, 1fr))' : 'repeat(6, minmax(0, 1fr))'};
	gap: 20px;
	padding: 20px 0px 20px 50px;
	width: 100%;
	align-items: center;
	margin-bottom: 30px;
`;
export const TableRowHeading = styled.div`
	display: grid;
	grid-template-columns: ${props =>
		props?.isGlobalAgent
			? 'repeat(2, minmax(0, 1fr)) 175px 175px 150px minmax(0, .5fr) 100px minmax(0, 1fr)'
			: 'repeat(2, minmax(0, 1fr)) 175px 175px 150px 100px minmax(0, 1fr)'};
	gap: 20px;
	padding: 20px 50px;
	width: 100%;
	align-items: center;
`;

export const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	.MuiSelect-select {
		width: calc(100% - 30px);
	}

	.Mui-focused {
		border-bottom: 1px solid ${CONFIG.colors.primary};
	}
	.MuiInputBase-root {
		width: calc(100% - 30px);
		height: 25px;
		border-bottom: 1px solid ${CONFIG.colors.black};

		&::before {
			border-bottom: 1px solid ${CONFIG.colors.primary} !important;
		}

		&:hover {
			&::before {
				border-bottom: 1px solid ${CONFIG.colors.primary} !important;
			}
		}
	}
`;
export const DNISearch = styled.input`
	outline: none;
	border: none;
	border-bottom: 1px solid #30353f;
	height: 25px;
	font-size: 13px;
	color: rgb(122, 122, 122);
	width: calc(100% - 30px);

	&::placeholder {
		opacity: 0.5;
	}
`;

export const CallToTest = styled.div`
	background-color: #002c90;
	display: flex;
	flex-direction: column;
	margin-top: 60px;
	padding: 20px 20px 0px 20px;
	width: 85%;
`;

export const CallToTestTitle = styled.p`
	display: flex;
	color: white;
	justify-content: flex-start;
	flex-wrap: wrap;
	font-family: Montserrat-Bold;
	font-size: 30px;
	margin-bottom: 15px;
`;

export const CallToTestFeatures = styled.div`
	align-items: center;
	display: flex;
	gap: 10px;
	justify-content: space-between;

	@media (max-width: 600px) {
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}
`;

export const CallToTestDescription = styled.p`
	color: white;
	display: flex;
	flex-basis: 70%;
	font-family: Montserrat-Light;
	font-size: 18px;
	text-align: left;
`;

export const CallToTestButtons = styled.div`
	display: flex;
	cursor: pointer;
	flex-direction: column;
	gap: 5px;
`;

export const CallToTestCompleteButton = styled.div`
	align-items: center;
	background-color: white;
	display: flex;
	cursor: pointer;
	justify-content: space-evenly;
	height: 50px;
	padding: 10px;
	@media (max-width: 600px) {
		padding: 4px;
	}
`;

export const CallToTestButton = styled.button`
	border: none;
	background: white;
	color: rgb(0, 44, 144);
	cursor: pointer;
	font-family: Montserrat-Bold;
	font-size: ${isMobile ? '14px' : '18px'};
	margin: 10px;
	text-transform: uppercase;
`;

export const CallToTestContact = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	@media (max-width: 1000px) {
		flex-direction: column;
		gap: 5px;
		margin-top: 10px;
	}
`;

export const CallToTestContactUs = styled.div`
	align-items: center;
	display: flex;
	gap: 5px;
	@media (max-width: 600px) {
		flex-wrap: wrap;
		width: 90%;
		max-width: ${props => (props.maxWidthLang !== 'es' ? '300px' : null)};
	}
`;

export const CallToTestMailUs = styled.div`
	align-items: center;
	display: flex;
	gap: 5px;
	@media (max-width: 600px) {
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
	}
`;

export const CallToTestContactPhone = styled.p`
	color: white;
	font-family: Montserrat-Light;
	font-size: 16px;
`;

export const CallToTestContactMail = styled.p`
	color: white;
	font-family: Montserrat-Light;
	font-size: 16px;
`;

export const CallToUsSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 1400px) {
		:lang(ru) {
			flex-direction: column;
			width: 70%;
		}
	}
`;
