export const professionalSituationListRU = [
	{
		value: 'CUENTA_AJENA',
		label: 'Наемный работник/рабочий',
	},
	{
		value: 'CUENTA_PROPIA',
		label: 'Самозанятое лицо/Частный предприниматель',
	},
	{
		value: 'DESEMPLEADO',
		label: 'Безработный',
	},
	{
		value: 'PENSIONISTA',
		label: 'Пенсионер',
	},
	{
		value: 'RENTISTA',
		label: 'Рантье/Инвестор',
	},
	{
		value: 'ESTUDIANTE',
		label: 'Студент',
	},
	{
		value: 'OTROS',
		label: 'Другой',
	},
];
