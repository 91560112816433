import React, { useEffect, useState } from 'react';

import { UiWrapper } from 'coinscrap-webapp-core';

export const DocuSign = ({ url, onProcessFinished }) => {
	const [loads, setLoads] = useState(0);
	const { useLoading } = UiWrapper.use();

	const [loading, setLoading] = useState();

	useEffect(() => {
		(async () => {
			setLoading('signatureContract.loadingDocument');
		})();
		return () => {};
	}, []);

	useEffect(() => {
		if (loads >= 2) {
			onProcessFinished && onProcessFinished();
		}
		return () => {};
	}, [loads, onProcessFinished]);

	useLoading(loading, { loadingMessage: loading });

	return (
		<>
			<iframe
				src={url}
				onLoad={() => {
					setLoads(prevLoads => prevLoads + 1);
					setLoading(undefined);
				}}
				title='iframe'
				style={{
					height: '70%',
					backgroundColor: 'white',
					minHeight: '70vh',
					width: '100%',
					overflow: 'auto',
					borderWidth: 0,
					marginTop: 30,
					marginBottom: 40,
				}}
			/>
		</>
	);
};
