import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	UiWrapper,
	Text,
	UserWrapper,
	PickerHOC,
	EnvelopesWrapper,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import arrowRigth from 'assets/images/svgs/arrowRigth.svg';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const SignatureSteps = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { user } = PickerHOC.use();
	const [loading, setLoading] = useState();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('SignatureSteps');
	const { loadUserEnvelopes } = EnvelopesWrapper.use();
	const { t, currentLang } = InternationalizationWrapper.use();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.signatureSteps.back(goBack);
	};

	const next = async () => {
		try {
			await loadUserEnvelopes(user.id);
			setLoading('dniBiometric.starting');
			CONFIG.routing.signatureSteps.next(navigateTo);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e.message.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useEffect(() => {
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			<HorizontalProgressBar
				step={6}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Firma' : ''}
			/>
			<Text noT headingPrimarySt isB2B>
				{t('signatureSteps.title')}
			</Text>
			<S.InnerWrapper>
				<S.StepWrapper>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text noT stepsSt>
							{t('signatureSteps.feature')}
						</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text noT stepsSt>
							{t('signatureSteps.feature1')}
						</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text noT stepsSt>
							{t('signatureSteps.feature2')}
						</Text>
					</S.StepWrapperItem>
					<S.StepWrapperItem>
						<S.StepCircle>
							<img src={arrowRigth} alt={'arrow icon'} />
						</S.StepCircle>
						<Text noT stepsSt>
							{t('signatureSteps.feature3')}
						</Text>
					</S.StepWrapperItem>
				</S.StepWrapper>
				<BackNextButton
					onClickNext={next}
					onClickBack={back}
					nextText={'signatureSteps.next'}
					backText={'buttons.back'}
					isBackShown={!isMobile}
					isNextDisabled={false}
					justifyContent={'evenly'}
				/>
			</S.InnerWrapper>
		</Wrapper>
	);
});
