import 'react-phone-number-input/style.css';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import calendarIcon from 'assets/images/svgs/calendar.svg';
import { InputDate, Text } from 'coinscrap-webapp-core';
import { countriesFlags } from 'data/countriesFlags';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import Input from '../Input/Input';
import * as S from './styles';
import KeyboardArrowDownIcon from './svgIcons/KeyboardArrowDownIcon';
import { isMobile } from 'react-device-detect';
import TextSpan from '../TextSpan/TextSpan';

const InputLabel = ({
	label,
	labelExtra = undefined,
	labelExtraStyle = {},
	onChange,
	value,
	type = 'input',
	selectOptions = [],
	children,
	disabled,
	style,
	styleLabel = {},
	onBlur,
	errorMsg,
	selectType,
	customTextLabel = false,
	tooltip = undefined,
	showLabel = true,
}) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	const suggestionRef = useRef(null);

	// custom autoSuggest
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [input, setInput] = useState(
		type === 'autoSuggest' ? selectOptions.find(item => item.value === value)?.label || '' : '',
	);

	const preventMinus = e => {
		if (e.code === 'Minus') {
			e.preventDefault();
		}
	};

	const onChangeSuggest = e => {
		const userInput = e.target.value;

		const suggestions = selectOptions.map(item => {
			return {
				label: item.label,
				id: item.value,
			};
		});

		// Filter our suggestions that don't contain the user's input
		const unLinked = suggestions.filter(
			suggestion => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
		);

		setInput(e.target.value);
		setFilteredSuggestions(unLinked);
		setShowSuggestions(true);
	};

	const onClickSuggest = e => {
		const text = e || value;
		const findSuggest = selectOptions.find(item => item.value === text);
		setFilteredSuggestions([]);
		setInput(findSuggest?.label);
		onChange(findSuggest?.value);
		setShowSuggestions(false);
	};

	useEffect(() => {
		if (suggestionRef.current && filteredSuggestions.length > 0) {
			suggestionRef.current.focus();
		}
		return () => {};
	}, [filteredSuggestions]);

	const SuggestionsListComponent = () => {
		return filteredSuggestions.length ? (
			<ul className='suggestions' id='suggestBox' tabIndex='0'>
				{filteredSuggestions.map((suggestion, index) => {
					//console.log({ suggestion });
					let className;
					// Flag the active suggestion with a class
					if (suggestion.id === value) {
						className = 'suggestion-active';
					}
					return (
						<li
							className={className}
							key={suggestion?.id || suggestion?.label}
							onClick={e => onClickSuggest(suggestion.id, index)}
						>
							{suggestion.label}
						</li>
					);
				})}
			</ul>
		) : (
			<div class='no-suggestions'>
				<p style={{ color: '#FF0E54', fontWeight: '600' }}>No hay resultados</p>
			</div>
		);
	};

	return (
		<S.InputLabelWrapper style={style}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{customTextLabel ? (
					<Text noT labelInputLanguageSt style={styleLabel}>
						{label} {labelExtra && <TextSpan style={labelExtraStyle ?? {}}>{labelExtra}</TextSpan>}
					</Text>
				) : (
					<Text noT labelInputSt style={styleLabel}>
						{label} {labelExtra && <TextSpan style={labelExtraStyle ?? {}}>{labelExtra}</TextSpan>}
					</Text>
				)}
				{tooltip && tooltip}
			</div>
			{type === 'date' && (
				<InputDate
					disabled={disabled}
					fullWidthSt
					borderBottomBlueSt
					value={moment(value)}
					onChange={onChange}
					pickOnSelect={false}
					maxDate={moment().subtract(18, 'years')}
					dateIcon={<img src={calendarIcon} alt='' />}
					placeholder={'DD/MM/YYYY'}
				/>
			)}
			{type === 'input' && (
				<Input
					disabled={disabled}
					value={value}
					onChange={onChange}
					placeholder={label}
					onBlur={onBlur}
				/>
			)}
			{type === 'number' && (
				<Input
					disabled={disabled}
					value={value}
					onChange={onChange}
					placeholder={label}
					onBlur={onBlur}
					type={'number'}
					min={1}
					onKeyPress={preventMinus}
				/>
			)}
			{type === 'phone' && (
				<>
					<PhoneInput
						disabled={disabled}
						value={value}
						onChange={onChange}
						placeholder={label}
						defaultCountry='ES'
						onBlur={onBlur}
					/>
				</>
			)}
			<p
				style={{
					color: '#FF0E54',
					display: errorMsg ? 'block' : 'none',
					visibility: errorMsg ? 'visible' : 'hidden',
					fontWeight: 600,
					marginTop: errorMsg ? 10 : 0,
				}}
			>
				{errorMsg}
			</p>
			{type === 'password' && (
				<S.PasswordInputWrapper>
					<Input
						disabled={disabled}
						type={!passwordVisibility ? 'password' : 'text'}
						value={value}
						onChange={onChange}
						placeholder={label}
						style={style}
					/>
					<S.PasswordInputIcon>
						{!passwordVisibility ? (
							<VisibilityIcon
								style={{ width: 30, height: 30, cursor: 'pointer' }}
								onClick={() => {
									setPasswordVisibility(prevState => !prevState);
								}}
							/>
						) : (
							<VisibilityOffIcon
								style={{ width: 30, height: 30, cursor: 'pointer' }}
								onClick={() => {
									setPasswordVisibility(prevState => !prevState);
								}}
							/>
						)}
					</S.PasswordInputIcon>
				</S.PasswordInputWrapper>
			)}
			{type === 'select' && (
				<S.SelectWrapper value={value}>
					<Select
						disabled={disabled}
						labelId='label'
						id='select'
						value={value}
						onChange={onChange}
						IconComponent={KeyboardArrowDownIcon}
					>
						<MenuItem value={0} disabled style={{ width: '100%' }}>
							<p style={{ fontSize: 18, color: '#7a7a7a' }}>{label}</p>
						</MenuItem>

						{selectOptions.map(option => (
							<MenuItem key={option.value} value={option.value} style={{ width: '100%' }}>
								<p style={{ fontSize: 18, color: '#7a7a7a' }}>{option.label}</p>
							</MenuItem>
						))}
					</Select>
				</S.SelectWrapper>
			)}

			{type === 'selectCountry' && (
				<S.SelectWrapper value={value}>
					<Select
						className={`select__container--languages`}
						disabled={disabled}
						labelId='label'
						id='select'
						value={value}
						onChange={onChange}
						IconComponent={KeyboardArrowDownIcon}
					>
						<MenuItem value={0} disabled style={{ width: '100%' }}>
							<p style={{ fontSize: isMobile ? 14 : 18, color: '#7a7a7a' }}>{label}</p>
						</MenuItem>

						{selectOptions.map(option => {
							const img = countriesFlags[option?.alpha2];
							return (
								<MenuItem
									key={option.value}
									value={option.value}
									style={{ width: '100%', gap: '10px' }}
								>
									<img src={img} alt='' />
									<p style={{ fontSize: isMobile ? 14 : 18, color: '#7a7a7a' }}>
										{showLabel && option.label}
									</p>
								</MenuItem>
							);
						})}
					</Select>
				</S.SelectWrapper>
			)}
			{type === 'autoSuggest' && (
				<S.InputLabelWrapper>
					<Input
						ref={suggestionRef}
						onBlur={e => {
							let related = e.relatedTarget ? e.relatedTarget.id : null;
							if (related === 'suggestBox') {
								return;
							}
							onClickSuggest(value || '');
						}}
						disabled={disabled}
						value={input}
						onChange={onChangeSuggest}
						// onKeyDown={onKeyDown}
						placeholder={label}
					/>
					{showSuggestions && input && <SuggestionsListComponent />}
				</S.InputLabelWrapper>
			)}
			{type === 'custom' && children}
		</S.InputLabelWrapper>
	);
};

export default InputLabel;
