import { CONFIG } from 'config/config';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

import {
	InputCheck,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	UiWrapper,
	UserWrapper,
	Text,
	View,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { SpanBold } from 'config/components/span';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import { cleanIBANNumber, isValidIBANNumber } from 'libs/Iban';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const BankAccount = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { useHeader, useLoading } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('BankAccount');
	const { saveTargetProperty } = TargetsWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState();
	const { t, currentLang } = InternationalizationWrapper.use();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const pickerValues = PickerHOC.use();
	const { target, user } = pickerValues;
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData')?.value;

	// iban inputs
	const [isDeclared, setIsDeclared] = useState(false);
	const [ibanValue, setIbanValue] = useState(creationData?.chargeAccount || '');
	const [ibanError, setIbanError] = useState(false);

	const isDisabled = useCallback(() => {
		return !isDeclared || isValidIBANNumber(ibanValue) !== 1;
	}, [isDeclared, ibanValue]);

	// const fullIban = '' + first + second + third + fourth + fifth;
	const fullIban = ibanValue.replace(/\s+/g, '');

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.bankAccount.back(goBack);
	};

	const next = async () => {
		await saveTargetProperty(target.id, 'creationData', {
			...creationData,
			chargeAccount: fullIban,
		});
		CONFIG.routing.bankAccount.next(navigateTo);
	};

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			<HorizontalProgressBar step={4} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text noT headingPrimarySt>
				{t('bankAccount.title')}
			</Text>
			<View bannerBankAccountSt>{t('bankAccount.description')}</View>
			<S.IbanWrapper>
				<S.IbanLabelWrapper>
					<Text noT labelInputSt>
						{t('bankAccount.iban')}
					</Text>

					<View rowSt>
						<S.IbanInput
							label={'bankAccount.iban'}
							maxlength='24'
							placeholder='ESXX XXXX XXXX XXXX XXXX XXXX'
							value={ibanValue}
							onChange={e => {
								if (ibanError) {
									setIbanError(false);
								}
								setIbanValue(cleanIBANNumber(e.target.value.toUpperCase()));
							}}
							onBlur={() => {
								const validateIban = isValidIBANNumber(ibanValue);
								setIbanError(validateIban !== 1);
							}}
						/>
						<CloseIcon
							onClick={() => {
								setIbanError(false);
								setIbanValue('');
							}}
							style={{
								marginLeft: 5,
								color: CONFIG.colors.primary,
								cursor: 'pointer',
								visibility: ibanValue ? 'visible' : 'hidden',
							}}
						/>
					</View>
				</S.IbanLabelWrapper>
			</S.IbanWrapper>

			<S.InfoWrapper style={{ marginTop: 15 }}>
				<Text noT>{t('bankAccount.manualIban')}</Text>
			</S.InfoWrapper>
			{ibanError && (
				<Text noT dangerSt>
					{t('miscellaneous.validIban')}
				</Text>
			)}
			<View conditionsWrapperSt marginTopSt onClick={() => setIsDeclared(prevState => !prevState)}>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={isDeclared} />
				<Text noT marginLefConditiontSt>
					<SpanBold>{t('bankAccount.signIban')} </SpanBold> {t('bankAccount.signIban2')}
				</Text>
			</View>
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={'buttons.next'}
				backText={'buttons.back'}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				marginBottom={40}
				marginTop={40}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
