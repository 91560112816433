import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	BackendWrapper,
	View,
} from 'coinscrap-webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import SendLinkModal from 'components/SendLinkModal/SendLinkModal';

import { useInterval } from 'libs/hooks/useInterval';
import { useSendLink } from 'libs/hooks/useSendLink';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';
import { getStatus } from 'libs/targetStatus';

import getCurrentStep from 'libs/redirectRoute';

import { isMobile } from 'react-device-detect';
export const FollowUp = PickerHOC({})(() => {
	const { getInstanceApi, getInstanceApiForVinculation } = BackendWrapper.use();
	const { navigateTo, goBack, params } = NavigationWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();

	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('vtFlow') === 'B2B' ? true : false || false,
	);

	const [loading, setLoading] = useState('Cargando');
	const [instance, setInstance] = useState(undefined);
	const [user, setUser] = useState(undefined);
	const [simulation, setSimulation] = useState(undefined);
	const [vinculation, setVinculation] = useState(undefined);
	const [dniDocuments, setDniDocuments] = useState(undefined);
	// eslint-disable-next-line no-unused-vars
	const [smsSent, setSmsSent] = useState(false);
	const [testCompletions, setTestCompletions] = useState(undefined);

	useEffect(() => {
		(async () => {
			const clientId = params?.clientId;
			const simulationId = params?.simulationId;
			const vinculationId = params?.vinculationId;

			if (clientId && simulationId && vinculationId) {
				setVinculation(vinculationId);

				let instance;
				if (simulationId !== vinculationId) {
					// ES B2B
					console.log('◻ ES B2B');
					setIsB2B(true);
					instance = await getInstanceApiForVinculation(vinculationId);
				} else {
					// ES B2C
					console.log('◾ ES B2C');
					setIsB2B(false);
					instance = await getInstanceApi();
				}
				setInstance(instance);

				const client = await instance.getUser(clientId);
				setUser(client);

				const sims = await instance.getUserTargets(clientId);
				const simulation = sims.find(t => t.id === simulationId) || undefined;
				setSimulation(simulation);
			}
		})();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const send = useSendLink(user?.id, instance);

	useInterval(async () => {
		if (simulation && user) {
			const newSimulation = await instance.getTarget(simulation.id);
			const dniDocuments = await instance.getUserIdentityDocuments(user.id);
			const testCompletions = await instance.getUserTestCompletions(user.id);
			const updatedClient = await instance.getUser(user.id);
			setSimulation(newSimulation);
			setDniDocuments(dniDocuments);
			setTestCompletions(testCompletions);
			setUser(updatedClient);
		}
	}, 1500);

	const [isLocal, setIsLocal] = useState(false);
	const [smsLink, setSmsLink] = useState(false);

	useEffect(() => {
		if (smsSent && window.location.hostname === 'webapp.localhost') {
			setIsLocal(true);
			setSmsLink('http://webapp.localhost:8080/webapp/' + localStorage.getItem('smslink'));
		}
		return () => {};
	}, [smsSent, smsLink, loading]);

	const [step, setStep] = useState(1);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.followUp.back(goBack);
	};

	useEffect(() => {
		console.log('👀 HA CAMBIADO LA SIMULACION');
		console.log('👀 user', user);
		console.log('👀 vinculation', vinculation);
		console.log('👀 simulation', simulation);
		console.log('👀 testCompletions', testCompletions);
		console.log('👀 dniDocuments', dniDocuments);
		if (user && simulation && testCompletions && dniDocuments) {
			const { step } = getCurrentStep(user, simulation, testCompletions, dniDocuments);
			console.log('📌 STEP', step);
			setLoading(undefined);
			setStep(step);
		}
		return () => {};
	}, [user, vinculation, simulation, testCompletions, dniDocuments]);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={true} />, []);

	return (
		<Wrapper>
			{isLocal && smsLink && (
				<View style={{ position: 'absolute', left: 190 }}>
					<p style={{ color: CONFIG.colors.danger, fontSize: 30, alignSelf: 'flex-start' }}>
						-----DEBUG-----
					</p>
					<a
						style={{ color: CONFIG.colors.red, fontSize: 30, alignSelf: 'flex-start' }}
						href={smsLink}
						target='_blank'
						rel='noopener noreferrer'
					>
						Link enviado al cliente
					</a>
				</View>
			)}
			<Text headingPrimarySt>Seguimiento del usuario</Text>

			<Text headingSecondarySt>Visualiza cada paso del usuario </Text>
			<View progressWrapperSt>
				<View followUpSt>
					{[...new Array(step)].map((item, index, list) => {
						return (
							<S.ProgressBarItemStep index={index} length={list.length}>
								<View activeDotsSt />
							</S.ProgressBarItemStep>
						);
					})}

					{[...new Array(10 - step)].map(item => {
						return (
							<S.ProgressBarItem>
								<View dotsSt />
							</S.ProgressBarItem>
						);
					})}
				</View>
				<View progressTextWrapperSt>
					<Text progressTextSt activeProgressTextSt={step >= 1}>
						{isB2B ? `Has enviado la simulación ` : 'Inicio del proceso '}{' '}
						{!user?.name || user?.name === 'NEW_USER' ? '' : `- ${user?.name}`}
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 2}>
						Detalles del cliente
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 3}>
						Cuestionario Prevención de Blanqueo de Capitales
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 4}>
						Cuestionario de salud
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 5}>
						Cuenta bancaria
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 6}>
						Escaneo DNI
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 7}>
						Generación del contrato - {getStatus(simulation?.status)}
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 8}>
						Lectura de documentos del contrato
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 9}>
						Firma
					</Text>
					<Text progressTextSt activeProgressTextSt={step >= 10}>
						Póliza activada
					</Text>
				</View>
			</View>

			<BackNextButton
				onClickBack={back}
				onClickNext={
					step >= 10
						? CONFIG.routing.followUp.next(navigateTo)
						: async () =>
								openModal(close => <SendLinkModal close={close} user={user} send={send} />, {
									disableClickAway: true,
									closeButton: true,
									key: 'modal-SimulationError',
								})
				}
				nextText={step >= 10 ? 'Volver al dashboard' : 'REENVIAR ENLACE'}
				isBackDisabled={false}
				isBackShown={!isMobile}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
