import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	UserWrapper,
	Text,
	InternationalizationWrapper,
	View,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import InputLabel from 'components/InputLabel/InputLabel';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const IdentityBiometricSelectDoc = PickerHOC({
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
})(() => {
	const { user } = PickerHOC.use();
	const { updateUser } = UserWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('IdentityBiometricSelectDoc');
	const [loading, setLoading] = useState();
	const { t, currentLang } = InternationalizationWrapper.use();
	const [documentType, setDocumentType] = useState(
		user?.metadata?.documentTypeIDS?.trim() || 'dni',
	);

	const [step, setStep] = useState(1);

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const [documentTypesList, setDocumentTypesList] = useState(
		CONFIG.translations[currentLang].documentTypes.docTypes,
	);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.identityBiometricSelectDoc.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('miscellaneous.saving');
			await updateUser({
				metadata: {
					...user?.metadata,
					documentTypeIDS: documentType,
					documentTypeIDSName: documentTypesList.find(type => type.VALUE === documentType).VALUE,
				},
			});
			setLoading('dniBiometric.starting');
			CONFIG.routing.identityBiometricSelectDoc.next(navigateTo);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e.message.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useEffect(() => {
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	// CAMBIO DE IDIOMA
	useEffect(() => {
		setDocumentTypesList(CONFIG.translations[currentLang].documentTypes.docTypes);
		console.log(CONFIG.translations[currentLang]);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang]);

	return (
		<Wrapper>
			<HorizontalProgressBar
				step={4}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Firma' : ''}
			/>
			<Text noT headingPrimarySt isB2B>
				{t('dniBiometric.country')}
			</Text>
			<Text noT headingSecondarySt>
				{t('dniBiometric.selectCountryDni')}
			</Text>

			<BackNextButton
				onClickNext={() => setStep(2)}
				onClickBack={() => CONFIG.routing.identityBiometricSelectDoc.ko(navigateTo)}
				nextText={t('simulationData.yes')}
				backText={t('simulationData.no')}
				isBackShown={true}
				isNextDisabled={false}
				justifyContent={'evenly'}
				marginTop={0}
				hasIcon={false}
				isNextSelected={step === 2}
				backWidth={125}
				nextWidth={125}
			/>
			{step === 2 && (
				<View rowSt style={{ maxWidth: 300 }}>
					<InputLabel
						value={documentType || 0}
						onChange={e => {
							setDocumentType(e.target.value);
						}}
						label={t('dniBiometric.inputNacionality')}
						type='select'
						style={{ width: '100%' }}
						selectOptions={documentTypesList.map(item => {
							return {
								label: item.NOMBRE,
								value: item.VALUE,
							};
						})}
					/>
				</View>
			)}
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={isB2B ? 'dniBiometric.start' : 'dniBiometric.continue'}
				isBackShown={!isMobile}
				isNextDisabled={step === 1}
				justifyContent={'evenly'}
				marginTop={isMobile ? 80 : 100}
			/>
		</Wrapper>
	);
});
