import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	TestResponsesWrapper,
	TestsWrapper,
	UiWrapper,
	UserWrapper,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import QuestionnaireBox from './Components/QuestionnaireBox/QuestionnaireBox';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

const testIdentifier = 'health';

export const Health = PickerHOC(
	{
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
		test: {
			sourceWrapper: TestsWrapper,
			sourcePath: 'tests',
			arrayItemMatcher: {
				type: 'value',
				itemPropertyPath: 'identifier',
				value: testIdentifier,
			},
		},
	},
	LoadingScreen,
)(() => {
	const {
		saveResponse,
		getResponse,
		isTestSettled,
		registerTestCompletion,
	} = TestResponsesWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('Health');
	const { updateUser } = UserWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	const { target, user, test } = PickerHOC.use();

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 target', target);
		console.log('📌 test', test);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.health.back(goBack);
	};

	const next = async () => {
		try {
			await registerTestCompletion(testIdentifier);
			setLoading('miscellaneous.saving');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					healthOK: true,
				},
			});
			CONFIG.routing.health.next(navigateTo);
		} catch (e) {
			setLoading('miscellaneous.saving');
			await updateUser({
				...user,
				metadata: {
					...user?.metadata,
					healthOK: false,
				},
			});
			CONFIG.routing.health.ko(navigateTo);
		}
	};

	const { t, currentLang } = InternationalizationWrapper.use();
	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper width={'70%'}>
			<HorizontalProgressBar
				step={3}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Datos personales' : ''}
			/>
			<Text headingPrimarySt noT>
				{t('health.title')}
			</Text>
			{test.testQuestions?.map(tq => [
				<QuestionnaireBox
					text={tq.metadata[`title${currentLang.toUpperCase()}`]}
					listWithTooltips={true}
					tooltipText={tq.metadata?.[`tooltip${currentLang.toUpperCase()}`]}
					onChange={v => saveResponse(tq.id, v)}
					value={getResponse(tq.id)}
				/>,
				<S.QuestionnaireBoxLine />,
			])}
			<BackNextButton
				onClickNext={next}
				onClickBack={back}
				nextText={'buttons.next'}
				backText={'buttons.back'}
				isBackShown={!isMobile}
				isNextDisabled={!isTestSettled(testIdentifier)}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
