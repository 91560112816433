import {
	BackendWrapper,
	NavigationWrapper,
	Text,
	UiWrapper,
	VinculationsWrapper,
} from 'coinscrap-webapp-core';
import React from 'react';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { CONFIG } from 'config/config';

export const useVinculations = (targetTypeId, setLoading) => {
	const { getInstanceApiForVinculation } = BackendWrapper.use();
	const { navigateTo } = NavigationWrapper.use();
	const { openModal } = UiWrapper.use();
	const { createMasterVinculation: createVinculation } = VinculationsWrapper.use();

	const generateSimulation = async (vinculationId, clientId) => {
		setLoading('Autenticando cliente');
		const clientApiInstance = await getInstanceApiForVinculation(vinculationId);
		//console.log('instance', clientApiInstance);

		setLoading('Generando simulación');
		const simulation = await clientApiInstance.createTargetForUser(clientId, {
			targetTypeId,
		});
		//console.log('simulation', simulation);
		const simulationId = simulation.id;
		return simulationId;
	};

	const accessSimulation = async (vinculationId, clientId, rawSimulationId, to = 'start') => {
		let simulationId = rawSimulationId;
		try {
			if (!simulationId) {
				simulationId = await generateSimulation(vinculationId, clientId);
			}
		} catch (e) {
			console.log('❌ ERROR2', e);
		}

		if (simulationId) {
			if (to === 'followUp') {
				CONFIG.routing.dashboard.nextFollowUp(navigateTo, vinculationId, clientId, simulationId);
			} else {
				CONFIG.routing.dashboard.nextVinculationDetails(
					navigateTo,
					vinculationId,
					clientId,
					simulationId,
				);
			}
		} else {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un error generando la simulación</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-AccessSimulationError',
				},
			);
		}
	};

	const newSimulation = async () => {
		let vinculationId, clientId;
		try {
			setLoading('Generando cliente');
			const vinculation = await createVinculation('AGENTE_DE', {
				name: ' ',
			});
			await new Promise(r => setTimeout(r, 500));

			vinculationId = vinculation.id;
			clientId = vinculation.secondaryUserId;
		} catch (e) {
			console.log('❌ ERROR', e);
		}

		if (vinculationId && clientId) {
			await accessSimulation(vinculationId, clientId);
		} else {
			setLoading(undefined);
			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Ha ocurrido un error generando el nuevo cliente</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					key: 'modal-NewSimulationError',
				},
			);
		}
	};

	return { generateSimulation, accessSimulation, newSimulation };
};
