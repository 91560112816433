import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/globalStyle';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div`
	${verticalFlex}
	flex-grow: 1;
	width: 90%;

	.title {
		margin-top: 40px;
	}
`;
export const PrimaryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	background-color: ${CONFIG.colors.primary};
	border-radius: 1px;
	min-height: 75px;
`;
export const QuestionnaireBoxWrapper = styled.div`
	width: 70%;
	display: grid;
	gap: 50px;
	row-gap: 20px;

	grid-template-columns: repeat(2, 1fr);
	padding: 24px;
`;
export const QuestionnaireBoxText = styled.p`
	font-size: 15px;
	color: white;
`;

export const QuestionnaireBoxButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 45px;
	flex-direction: ${isMobile ? 'column' : 'row'};
`;

export const QuestionnaireBoxButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 65px;
	height: 65px;
	margin: 5px;
	border-radius: 50px;
	background-color: ${props => {
		if (props.no) {
			if (props.selected === false) {
				return 'white';
			} else {
				return 'white';
			}
		} else {
			if (props.selected === true) {
				return 'white';
			} else {
				return 'white;';
			}
		}
	}};
	color: ${props => {
		if (props.no) {
			if (props.selected === false) {
				return 'white';
			} else {
				return 'white';
			}
		} else {
			if (props.selected === true) {
				return 'white';
			} else {
				return 'white';
			}
		}
	}};
	border: ${props => {
		if (props.no) {
			if (props.selected === false) {
				return '0px';
			} else {
				return '1px solid white';
			}
		} else {
			if (props.selected === true) {
				return '0px';
			} else {
				return '1px solid white';
			}
		}
	}};

	outline: none;
	:focus {
		outline: none;
	}
`;
export const QuestionnaireBoxLine = styled.div`
	height: 1px;
	width: 100%;
	background-color: #30353f;
	margin: ${props => {
		if (!props.top) {
			return '15px 0px 0px 0px';
		} else {
			return '0px 0px 15px 0px';
		}
	}};
`;
