import styled from 'styled-components/macro';

// MODAL
export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ModalWrapperBeneficiary = styled.div`
	padding: 32px 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(134.39deg, #002c90 43.97%, #f21a59 92.37%);
`;
