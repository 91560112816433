import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	BackendWrapper,
	NavigationWrapper,
	PickerHOC,
	SessionWrapper,
	UiWrapper,
	View,
	Text,
} from 'coinscrap-webapp-core';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';

import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const LoginClient = PickerHOC(
	{},
	LoadingScreen,
)(() => {
	const { navigateTo, query } = NavigationWrapper.use();
	const { getInstanceApi } = BackendWrapper.use();
	const { startSession, clearSession } = SessionWrapper.use();
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { i18n } = useTranslation();

	const [loading, setLoading] = useState();
	const [error, setError] = useState();

	// eslint-disable-next-line no-unused-vars
	const [token, setToken] = useState();

	useEffect(() => {
		clearSession();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(async () => {
			let response;
			try {
				if (
					query?.lang?.toLowerCase() === 'es' ||
					query?.lang?.toLowerCase() === 'ru' ||
					query?.lang?.toLowerCase() === 'ua' ||
					query?.LANG?.toLowerCase() === 'es' ||
					query?.LANG?.toLowerCase() === 'ru' ||
					query?.LANG?.toLowerCase() === 'ua'
				) {
					i18n.changeLanguage(query?.lang?.toLowerCase());
				}

				setLoading('miscellaneous.loggingOnto');

				response = await getInstanceApi().loginUser(
					{
						source: query?.source || query?.SOURCE || '000',
					},
					'anonymous',
				);

				await startSession({ token: response.accessToken });
				console.info('🚀 NAVIGATING TO landing', 'B2Flow', 'B2C');

				localStorage.removeItem('B2Flow');
				localStorage.setItem('B2Flow', 'B2C');

				CONFIG.routing.loginClient.next(navigateTo);
			} catch (e) {
				console.error('ERROR', e);
				setError(e.status || true);
				let messageError = e?.message?.toString();
				messageError = 'Ha ocurrido un error. Vuelva a acceder por favor.';
				setLoading(undefined);
				openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
					disableClickAway: true,
					closeButton: false,
				});
			}
		})();
		return () => {};
		// eslint-disable-next-line
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			<View totalCenterSt>
				<Text
					headingPrimarySt
					id='miscellaneous.loggingOnto'
					label='miscellaneous.loggingOnto'
				></Text>
				{error && (
					<>
						<Text headingPrimarySt>
							{error === 403
								? 'Ha ocurrido un error al acceder.'
								: 'Ha ocurrido un error al acceder.'}
						</Text>
					</>
				)}
				{!error && token && (
					<>
						<Text headingPrimarySt>Espera por favor...</Text>
					</>
				)}
			</View>
		</Wrapper>
	);
});
