import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';

import {
	NavigationWrapper,
	PickerHOC,
	SoftSwitcher,
	TargetsWrapper,
	TargetTypesWrapper,
	TargetsUtilsWrapper,
	UiWrapper,
	View,
	Text,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const UpsertProduct = PickerHOC(
	{
		targetType: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'CKSEGUR_VIDA_RIESGO',
			},
			sourcePath: 'targetTypes',
			sourceWrapper: TargetTypesWrapper,
		},
		targets: {
			sourcePath: 'targets',
			sourceWrapper: TargetsWrapper,
		},
	},
	LoadingScreen,
)(() => {
	const { navigateTo, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('UpsertProduct');
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const { instanciateNewTarget } = TargetsUtilsWrapper.use();
	const { targets, targetType } = PickerHOC.use();
	const { currentLang, t } = InternationalizationWrapper.use();

	const [loading, setLoading] = useState();

	const targetId = targets?.[0]?.id;

	useEffect(() => {
		if (targetId) {
			(async () => {
				console.log('USER HAS TARGET');
				setLoading('miscellaneous.loggingOnto');
				CONFIG.routing.upsertProduct.next(navigateTo, targetId);
			})();
		} else {
			(async () => {
				try {
					console.log('📌 CREATE NEW TARGET FOR USER');
					setLoading('miscellaneous.adding');
					const createdTarget = await instanciateNewTarget({
						targetType: targetType.id,
					});
					console.info('📌 createdTarget', createdTarget);
					CONFIG.routing.upsertProduct.next(navigateTo, createdTarget.id);
				} catch (e) {
					console.error('ERROR', e);
					let messageError = e?.message?.toString();
					messageError = t('miscellaneous.temporalError');
					setLoading(undefined);
					openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
						disableClickAway: true,
						closeButton: false,
					});
				}
			})();
		}
		return () => {};
		// eslint-disable-next-line
	}, [targetId]);

	useEffect(() => {
		(async () => {
			setLoading(undefined);
		})();
		return () => {};
	}, []);

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={false} />, []);

	const ev = !targets ? 0 : !targetId ? 1 : 2;

	return (
		<Wrapper>
			<View totalCenterSt>
				<SoftSwitcher evaluate={ev}>
					<>
						<Text
							headingPrimarySt
							id='miscellaneous.gettingData'
							label='miscellaneous.gettingData'
						/>
						<Text
							headingSecondarySt
							id='miscellaneous.waitingForProccess'
							label='miscellaneous.waitingForProccess'
						/>
					</>
					<>
						<Text
							headingPrimarySt
							id='miscellaneous.noPriorProcess'
							label='miscellaneous.noPriorProcess'
						/>
						<Text
							headingSecondarySt
							mainColor={true}
							id='miscellaneous.noDataFound'
							label='miscellaneous.noDataFound'
						/>
					</>
					<>
						<Text headingPrimarySt id='miscellaneous.resending' label='miscellaneous.resending' />
						<Text
							headingSecondarySt
							mainColor={true}
							id='miscellaneous.passingThrow'
							label='miscellaneous.passingThrow'
						/>
					</>
				</SoftSwitcher>
			</View>
		</Wrapper>
	);
});
