import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';

export const StepCircle = styled.div`
	height: ${isMobile ? '40px' : '46px'};
	width: ${isMobile ? '40px' : '46px'};
	background-color: ${CONFIG.colors.primary};
	border-radius: 50%;
	margin-right: 15px;
	font-size: ${isMobile ? '16px' : '17px'};
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
`;
