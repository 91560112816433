import { CONFIG } from 'config/config';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > *': {
			backgroundColor: CONFIG.colors.primary,
			color: CONFIG.colors.secondary,
			margin: theme.spacing(1),
		},
	},
}));

export const ImageAvatar = ({ name }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Avatar alt={name} src='/static/images/avatar/1.jpg' />
		</div>
	);
};
