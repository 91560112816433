import {
	Button,
	NavigationWrapper,
	PickerHOC,
	TargetTypesWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View,
} from 'coinscrap-webapp-core';
import Header from 'layout/components/Header/Header';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DashboardFilters from './components/Filters/Filters';
import { Wrapper } from '../../layout/Wrapper';
import { DashboardContext } from './contexts/Dashboard';
import { useVinculations } from '../../libs/hooks/useVinculations';
import { GlobalAgent } from './components/GlobalAgent/GlobalAgent';
import { NormalAgent } from './components/NormalAgent/NormalAgent';

export const Dashboard = PickerHOC({
	agent: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper,
	},
	targetType: {
		arrayItemMatcher: {
			itemPropertyPath: 'identifier',
			type: 'value',
			value: 'CKSEGUR_VIDA_RIESGO',
		},
		sourcePath: 'targetTypes',
		sourceWrapper: TargetTypesWrapper,
	},
})(() => {
	const { useBackRoute } = NavigationWrapper.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { agent, targetType } = PickerHOC.use();

	const [isLocalDev, setIsLocalDev] = useState(false);
	const [loading, setLoading] = useState();

	const { accessSimulation, newSimulation } = useVinculations(targetType.id, setLoading);
	const [start, setstart] = useState(false);

	const agentRole = useMemo(() => agent?.roles[0]?.identifier || undefined, [agent]);
	const { setAgent, setIsGlobal } = useContext(DashboardContext);

	useBackRoute(undefined);
	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header />, []);

	useEffect(() => {
		const startDash = async () => {
			setAgent(agent);
			setIsGlobal(agentRole);

			if (window.location.hostname === 'webapp.localhost') {
				setIsLocalDev(true);
				console.log(`💥 LOCAL DEV → ${window.location.hostname}`);
				console.log('👷‍♂️ agent', agent);
				console.log('👷‍♂️ agentRole', agentRole);
			}

			setstart(true);
		};

		startDash();
		return () => {};
	}, [agent, agentRole, setAgent, setIsGlobal]);

	return (
		<Wrapper>
			<Text headingPrimarySt>Listado de pólizas</Text>
			{isLocalDev && <Text>{agentRole}</Text>}

			<View itemsStartSt mb3St style={{ maxWidth: 1200 }}>
				<Button newSimulationSt fixedWidthSt onClick={newSimulation}>
					Nueva simulación
				</Button>
			</View>

			<DashboardFilters />

			{start && (
				<>
					{console.log() || agentRole === 'GLOBAL_AGENT' ? (
						<GlobalAgent accessSimulation={accessSimulation} />
					) : (
						<NormalAgent accessSimulation={accessSimulation} />
					)}
				</>
			)}
		</Wrapper>
	);
});
