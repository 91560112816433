import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import './styles.css';

import {
	InputCheck,
	InstitutionsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	TargetTypesWrapper,
	Tooltip,
	UiWrapper,
	UserWrapper,
	View,
	Button,
	Text,
	Input,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { SpanCurrency } from 'config/components/span';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import HorizontalProgressBar from 'components/HorizontalProgressBar/HorizontalProgressBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import TermsModal from 'components/TermsModal/TermsModal';
import TextSpan from 'components/TextSpan/TextSpan';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import TooltipSimple from 'config/components/tooltipSimple';

import { getCurrency } from 'libs/Currency';
import { useSimulation } from 'libs/hooks/useSimulation';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const Simulation = PickerHOC(
	{
		targetType: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'CKSEGUR_VIDA_RIESGO',
			},
			sourcePath: 'targetTypes',
			sourceWrapper: TargetTypesWrapper,
		},
		target: {
			sourceWrapper: TargetsWrapper,
			sourcePath: 'targets',
			arrayItemMatcher: {
				type: 'param',
				paramName: 'targetId',
				itemPropertyPath: 'id',
			},
		},
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
		caserInstitution: {
			arrayItemMatcher: {
				itemPropertyPath: 'identifier',
				type: 'value',
				value: 'caser',
			},
			sourcePath: 'institutions',
			sourceWrapper: InstitutionsWrapper,
		},
	},
	LoadingScreen,
)(() => {
	const { useHeader, openModal, useLoading } = UiWrapper.use();
	const { saveTargetProperty } = TargetsWrapper.use();
	const pickerValues = PickerHOC.use();
	const { target, user, caserInstitution, targetType } = pickerValues;

	const onError = e => {
		openModal(
			() => (
				<ErrorModal>
					{e?.message?.toString()
						? e?.message
								?.toString()
								.toLowerCase()
								.indexOf('prima fraccionada inferior a la permitida')
							? `La simulación ha fallado porque el pago fraccionado es inferior al mínimo permitido. Selecciona otro fraccionamiento.`
							: e?.message?.toString()
						: `La simulación ha fallado, recuerda los límites de capital total (${capitalMinimum}€ - ${capitalMaximum}€) cuando configures la prima mensual/trimestral/semestral/anual`}
				</ErrorModal>
			),
			{
				disableClickAway: true,
				closeButton: true,
			},
		);
	};

	const textPeriodicidadLong = () => {
		switch (period) {
			case 'mensual':
				return t('simulation.mes');
			case 'trimestral':
				return t('simulation.trimestre');
			case 'semestral':
				return t('simulation.semestre');
			case 'anual':
				return t('simulation.anho');
			default:
				return '';
		}
	};

	// CREATION DATA
	const creationData = target?.targetProperties?.find(x => x.name === 'creationData')?.value;

	// CAPITAL MÁXIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMaximum, setCapitalMaximum] = useState(180000);

	// CAPITAL MÍNIMO
	// eslint-disable-next-line no-unused-vars
	const [capitalMinimum, setCapitalMinimum] = useState(20000);

	// CHECKS
	const [termsConditions, setTermsConditions] = useState(creationData?.termsConditions || false);

	const [
		simulationLoading,
		// eslint-disable-next-line no-unused-vars
		setSimulationLoading,
		invoice,
		setInvoice,
		capital,
		setCapital,
		isValidCapital,
		setIsValidCapital,
		invalidCapitalReason,
		setInvalidCapitalReason,
		period,
		setPeriod,
		disabilityCoverage,
		setDisabilityCoverage,
		disabilityCoverageAccident,
		setDisabilityCoverageAccident,
	] = useSimulation(
		caserInstitution,
		targetType,
		creationData,
		user,
		capitalMaximum,
		capitalMinimum,
		onError,
	);

	//const send = useSendLink(user.id);
	const {
		navigateTo,
		goBack,
		params,
		routes: prevRoutes,
		getCurrentRoute,
	} = NavigationWrapper.use();

	useCoinscrapPageView('Simulation');

	const [capitalRange, setCapitalRange] = React.useState(capital);

	const { currentLang, t } = InternationalizationWrapper.use();

	const [loading, setLoading] = useState();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);

	const [inputWidth, setInputWidth] = useState(2);
	const [inputWidthCapital, setInputWidthCapital] = useState(2);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.simulation.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('miscellaneous.saving');
			await saveTargetProperty(target.id, 'creationData', {
				...creationData,
				price: Number(capital.replace('.', '').replace(',', '.')),
				periodicity: period,
				periodicContribution: Number(invoice.replace('.', '').replace(',', '.')),
				disabilityCoverage,
				disabilityCoverageAccident,
				termsConditions,
			});
			CONFIG.routing.simulation.next(navigateTo, user.metadata.isB2B);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e?.message?.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
				disableClickAway: true,
				closeButton: true,
			});
		}
	};

	useEffect(() => {
		const findPeriodsAndDecimals = [...invoice.toString()].filter(
			item => item === '.' || item === ',',
		);
		setInputWidth(invoice.toString().trim().length + 2 + findPeriodsAndDecimals.length * -0.6);

		const findPeriodsAndDecimalsCapital = [...capital.toString()].filter(
			item => item === '.' || item === ',',
		);
		setInputWidthCapital(
			capital.toString().trim().length + 2 + findPeriodsAndDecimalsCapital.length * -0.6,
		);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoice, capital]);

	useEffect(() => {
		console.log('📌 creationData', creationData);
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoading(simulationLoading || loading, {
		loadingMessage: simulationLoading || loading,
	});
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper>
			<HorizontalProgressBar step={1} total={6} styleWrapper={{ maxWidth: 450 }} />
			<Text noT headingPrimarySt isB2B>
				{isB2B ? 'Confirma tu seguro' : t('simulation.title')}
			</Text>
			<Text noT headingSecondarySt>
				{
					{
						true: (
							<>
								{t('simulation.description')}{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.primary }}>
									{invoice}
								</TextSpan>{' '}
								{textPeriodicidadLong()} vas a contratar tu seguro de vida con un capital de{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.primary }}>
									{capital} €
								</TextSpan>{' '}
							</>
						),
						false: (
							<>
								{t('simulation.description')}{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.primary }}>
									{invoice}
								</TextSpan>{' '}
								{textPeriodicidadLong()} {t('simulation.description1')}{' '}
								<TextSpan style={{ fontWeight: 800, color: CONFIG.colors.primary }}>
									{capital} €
								</TextSpan>{' '}
								{t('simulation.description2')}{' '}
							</>
						),
					}[isB2B]
				}

				<TooltipSimple>
					<Text noT tooltipTextSt>
						{t('simulation.tooltips.tooltip1')}
					</Text>
				</TooltipSimple>
			</Text>
			<Text noT featuresPrimarySt>
				{t('simulation.chooseCapital')}
			</Text>
			<S.InputWrapper>
				<Input
					style={{
						width: inputWidthCapital + 'ch',
					}}
					value={capital}
					onChange={e => {
						if (simulationLoading) return;
						const capital = e.target.value || '';
						const val = capital.replace(/\D/g, '');
						setIsValidCapital(val !== '' && val <= capitalMaximum && val >= capitalMinimum);
						if (val > capitalMaximum) {
							setInvalidCapitalReason(
								t('simulation.errors.error1') + ' ' + getCurrency(capitalMaximum, 0),
							);
						} else if (val === '' || val < capitalMinimum) {
							setInvalidCapitalReason(
								t('simulation.errors.error2') + ' ' + getCurrency(capitalMinimum, 0),
							);
						}
						setCapital(val);
						setCapitalRange(val);
					}}
				></Input>
				<SpanCurrency>€</SpanCurrency>
			</S.InputWrapper>
			<input
				type={'range'}
				min={capitalMinimum}
				max={capitalMaximum}
				step={500}
				value={capitalRange}
				onChange={({ target: { value: radius } }) => {
					setCapitalRange(radius);
					setCapital(radius);
				}}
			/>
			<View
				invalidCapitalSt
				style={{
					display: !isValidCapital && invalidCapitalReason ? 'block' : 'none',
					visibility: !isValidCapital && invalidCapitalReason ? 'visible' : 'hidden',
				}}
			>
				{!isValidCapital && invalidCapitalReason}
			</View>
			<Text noT featuresPrimarySt featuresMarginBottomSt>
				{t('simulation.periocity')}
			</Text>
			<View elementsAroundFlexSt>
				<Button
					simulationPeriodSt
					selectedSt={period === 'mensual'}
					onClick={() => {
						setPeriod('mensual');
					}}
					label='simulation.monthly'
				></Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'trimestral'}
					onClick={() => {
						setPeriod('trimestral');
					}}
					label='simulation.quarterly'
				></Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'semestral'}
					onClick={() => {
						setPeriod('semestral');
					}}
					label='simulation.semiannual'
				></Button>
				<Button
					simulationPeriodSt
					selectedSt={period === 'anual'}
					onClick={() => {
						setPeriod('anual');
					}}
					label='simulation.yearly'
				></Button>
			</View>
			<View
				conditionsWrapperSt
				marginTopMobileSt
				onClick={() => {
					if (disabilityCoverage && disabilityCoverageAccident) {
						setDisabilityCoverageAccident(!disabilityCoverageAccident);
					}
					setDisabilityCoverage(!disabilityCoverage);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={disabilityCoverage} />
				<Text noT marginLefConditiontSt>
					{t('simulation.incapacity')}{' '}
					<TooltipSimple>
						<Text noT tooltipTitleSt>
							{t('simulation.tooltips.tooltip3')}
						</Text>
						<Text noT tooltipTextSt>
							{t('simulation.tooltips.tooltip2')}
						</Text>
					</TooltipSimple>
					<Tooltip open={false}></Tooltip>
				</Text>
			</View>

			<Text
				featuresPrimarySt
				featuresMarginTopSt
				isB2B
				style={{ marginTop: isMobile ? '32px' : '40px', marginBottom: 0 }}
				noT
			>
				{t('simulation.result')}
			</Text>
			<S.InputWrapper>
				<Input
					style={{
						width: inputWidth + 'ch',
					}}
					value={invoice}
					onChange={e => {
						console.log('e.target.value', e.target.value);
						let val = e.target.value;
						if (val.includes(',')) {
							val = val.split(',')[0];
						}
						setInvoice(val);
					}}
				></Input>
				<SpanCurrency>{`${textPeriodicidadLong()}`}</SpanCurrency>
			</S.InputWrapper>

			<View
				conditionsWrapperSt
				marginBottomMobileSt
				onClick={() => {
					openModal(
						close => (
							<TermsModal onClick={() => setTermsConditions(true)} isOpen={true} onClose={close} />
						),
						{
							mode: 'old',
						},
					);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={termsConditions} />
				<Text noT marginLefConditiontSt>
					{t('simulation.terms')}
				</Text>
			</View>

			<BackNextButton
				onClickBack={back}
				onClickNext={next}
				nextText={'simulation.buttons.hire'}
				backText={'buttons.back'}
				isBackDisabled={false}
				isBackShown={!isMobile}
				isNextDisabled={
					!Number(invoice.toString().replace('.', '').replace(',', '.')) ||
					!Number(capital.toString().replace('.', '').replace(',', '.')) ||
					!termsConditions
				}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
