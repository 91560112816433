export const professionsUA = [
	{
		value: '200000',
		label: 'АДВОКАТ',
	},
	{
		value: '200001',
		label: 'ПРОКУРОР',
	},
	{
		value: '200002',
		label: 'СУДДЯ',
	},
	{
		value: '200003',
		label: 'ЮРИСТ',
	},
	{
		value: '200004',
		label: 'ЮРИСТ',
	},
	{
		value: '200005',
		label: 'АКТОР/АКТОРКА',
	},
	{
		value: '200006',
		label: 'АКТОР ДУБЛЯЖУ.',
	},
	{
		value: '200007',
		label: 'КАСКАДЕР',
	},
	{
		value: '200008',
		label: 'КАСКАДЕР (КІНО - ТЕАТРАЛЬНІ ВИСТАВИ)',
	},
	{
		value: '200009',
		label: 'АНАЛІТИК',
	},
	{
		value: '200010',
		label: 'ДРЕСИРУВАЛЬНИК ДОМАШНІХ ТВАРИН',
	},
	{
		value: '200011',
		label: 'ДРЕСИРУВАЛЬНИК БІЙЦІВСЬКИХ СОБАК',
	},
	{
		value: '200012',
		label: 'ДРЕСИРУВАЛЬНИК ЗВІРІВ',
	},
	{
		value: '200013',
		label: 'АДМІНІСТРАЦІЯ',
	},
	{
		value: '200014',
		label: 'БІЗНЕС-АДМІНІСТРАТОР',
	},
	{
		value: '200015',
		label: 'АДМІНІСТРАТИВНИЙ ПРАЦІВНИК',
	},
	{
		value: '200016',
		label: 'КОМЕРЦІЙНИЙ ІНЖЕНЕР',
	},
	{
		value: '200017',
		label: 'АДМІНІСТРАТИВНИЙ ПЕРСОНАЛ',
	},
	{
		value: '200018',
		label: 'СЕКРЕТАР',
	},
	{
		value: '200020',
		label: 'БІРЖОВИЙ АГЕНТ',
	},
	{
		value: '200021',
		label: 'ФЕРМЕР ЗЕМЛЕРОБСТВО',
	},
	{
		value: '200022',
		label: 'БДЖОДЯР',
	},
	{
		value: '200023',
		label: 'ФЕРМЕР',
	},
	{
		value: '200024',
		label: 'ФЕРМЕР ',
	},
	{
		value: '200025',
		label: 'ФЕРМЕР/ВЕТЕРИНАР',
	},
	{
		value: '200026',
		label: 'ФЕРМЕР',
	},
	{
		value: '200027',
		label: 'РИБАЛКА У ВІДКРИТОМУ МОРІ',
	},
	{
		value: '200028',
		label: 'РИБАЛКА. РІЧКИ, ОЗЕРА ТА УЗБЕРЕЖЖЯ',
	},
	{
		value: '200029',
		label: 'АЛКОГОЛЬ (ВИРОБНИЦТВО)',
	},
	{
		value: '200030',
		label: 'АЛКОГОЛЬ. ПРОДАЖ (ЗА ВИКЛЮЧЕННЯМ НІЧНИХ ЗАКЛАДІВ)',
	},
	{
		value: '200031',
		label: 'ДОМОГОСПОДАРКА',
	},
	{
		value: '200032',
		label: 'СИСТЕМНИЙ АНАЛІТИК',
	},
	{
		value: '200033',
		label: 'АНТИКВАР',
	},
	{
		value: '200034',
		label: 'ЗБРОЯР',
	},
	{
		value: '200035',
		label: 'АРХЕОЛОГ',
	},
	{
		value: '200036',
		label: 'АРХІТЕКТОР',
	},
	{
		value: '200037',
		label: 'АРХІТЕКТОР ІНТЕРЄРІВ',
	},
	{
		value: '200038',
		label: 'АРСЕНАЛИ, ПОРОХОВИЙ СКЛАД',
	},
	{
		value: '200039',
		label: 'АУДИТОР',
	},
	{
		value: '200040',
		label: 'АВТОР',
	},
	{
		value: '200041',
		label: 'РЕДАКТОР',
	},
	{
		value: '200042',
		label: 'ПИСЬМЕННИК',
	},
	{
		value: '200043',
		label: 'СИМФОНІЧНИЙ МУЗИКАНТ',
	},
	{
		value: '200044',
		label: 'СПІВАК',
	},
	{
		value: '200045',
		label: 'ДЕКОРАТОР',
	},
	{
		value: '200046',
		label: 'ДИЗАЙНЕР. ГРАФІЧНИЙ ДИЗАЙНЕР',
	},
	{
		value: '200047',
		label: 'СКУЛЬПТОР',
	},
	{
		value: '200048',
		label: 'ДИЗАЙНЕР КОСТЮМІВ',
	},
	{
		value: '200049',
		label: 'КРАВЕЦЬ',
	},
	{
		value: '200050',
		label: 'МОДИСТ',
	},
	{
		value: '200051',
		label: 'ХУДОЖНИК ПЕЙЗАЖІВ',
	},
	{
		value: '200052',
		label: 'ОБСЛУГОВУВАННЯ ЛІФТІВ',
	},
	{
		value: '200053',
		label: 'МОНТАЖНИК ЛІФТІВ',
	},
	{
		value: '200054',
		label: 'МОНТАЖ ТА ОБСЛУГОВУВАННЯ ЛІФТІВ',
	},
	{
		value: '200055',
		label: 'РАДНИК. КОНСУЛЬТАНТ',
	},
	{
		value: '200056',
		label: 'ВИРОБНИЦТВО АСФАЛЬТУ',
	},
	{
		value: '200057',
		label: 'ІНЖЕНЕР СУДНОВИЙ',
	},
	{
		value: '200058',
		label: 'МОНТАЖНИК СУДНОВИЙ',
	},
	{
		value: '200059',
		label: 'АДМІНІСТРАЦІЯ СУДНОВА',
	},
	{
		value: '200060',
		label: 'СУДНОВЕ РЕМЕСЛО',
	},
	{
		value: '200061',
		label: 'СУДНОВА ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200062',
		label: 'АВТОШКОЛА',
	},
	{
		value: '200063',
		label: 'ПРОФЕСІЙНИЙ ГОНЩИК',
	},
	{
		value: '200064',
		label: 'ПОМІЧНИК СТОМАТОЛОГА',
	},
	{
		value: '200065',
		label: 'ПОМІЧНИК АПТЕКАРЯ',
	},
	{
		value: '200066',
		label: 'МЕДИЧНИЙ ПОМІЧНИК',
	},
	{
		value: '200067',
		label: 'ПРОФЕСІЙНА АВІАЦІЯ',
	},
	{
		value: '200069',
		label: 'БОРТПРОВІДНИК, СТЮАРД, РЕГУЛЯРНІ РЕЙСІ',
	},
	{
		value: '200070',
		label: 'АРТИСТКА БАЛЕТУ',
	},
	{
		value: '200071',
		label: 'АРТИСТ БАЛЕТУ',
	},
	{
		value: '200072',
		label: 'АРТИСТ ІСПАНСЬКОГО ТАНЦЮ',
	},
	{
		value: '200073',
		label: 'БАНКІВСЬКА СПРАВА. АДМІНІСТРАЦІЯ',
	},
	{
		value: '200074',
		label: 'БАНКІВСЬКА СПРАВА. ІНКАСАТОР-ВОДІЙ',
	},
	{
		value: '200075',
		label: 'БАНКІВСЬКА СПРАВА. ІНКАСАТОР- ПЕРЕВЕЗЕННЯ ЦІНОСТЕЙ',
	},
	{
		value: '200076',
		label: 'БАРИ, ЗАКЛАДИ ХАРЧУВАННЯ (КРІМ НІЧНИХ ЗАКЛАДІВ): ВЛАСНИК',
	},
	{
		value: '200077',
		label: 'БАРМЕН (ПРАЦІВНИК БАРУ АБО ПАБУ, ГОТУВАЧ НАПОЇВ)',
	},
	{
		value: '200078',
		label: 'БІБЛІОТЕКАР',
	},
	{
		value: '200079',
		label: 'БІОЛОГ',
	},
	{
		value: '200080',
		label: 'БІОХІМІК (ДОСЛІДНИЦТВО, ПРОМИСЛОВІСТЬ, ФАРМАЦЕВТИКА)',
	},
	{
		value: '200081',
		label: 'ФОНДОВА БІРЖА',
	},
	{
		value: '200082',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ГАСІННЯ ПОЖЕЖІ НА ЗЕМЛІ',
	},
	{
		value: '200083',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ГАСІННЯ ПОЖЕЖІ З ПОВІТРЯ',
	},
	{
		value: '200084',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ГАСІННЯ ПОЖЕЖІ ПІД ЗЕМЛЕЮ',
	},
	{
		value: '200085',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ТІЛЬКИ АДМІНІСТРАТИВНІ ЗАВДАННЯ',
	},
	{
		value: '200086',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ЦИВІЛЬНO-ПРАВОВИЙ ЗАХИСТ',
	},
	{
		value: '200087',
		label: 'ПОЖЕЖНИЙ-РЯТУВАЛЬНИК. ВОДІЙ',
	},
	{
		value: '200088',
		label: 'БОТАНІК',
	},
	{
		value: '200089',
		label: 'ПРОФЕСІЙНИЙ БОКСЕР',
	},
	{
		value: '200090',
		label: 'ПРОФЕСІЙНИЙ ІНСТРУКТОР ПІДВОДНОГО ПЛАВАННЯ',
	},
	{
		value: '200091',
		label: 'КОТЕЛЬНИК. БУДІВЛІ ТА ЗАВОДИ/ФАБРИКИ',
	},
	{
		value: '200092',
		label: 'КОТЕЛЬНИК. НАФТОПЕРЕРОБНИЙ ЗАВОД',
	},
	{
		value: '200093',
		label: 'МЯСНИК',
	},
	{
		value: '200094',
		label: 'СТОЛЯР, ТЕСЛЯР',
	},
	{
		value: '200095',
		label: 'CATHC, ПРОФЕСІЙНИЙ',
	},
	{
		value: '200096',
		label: 'ЦЕМЕНТ (ВИГОТОВЛЕННЯ ТА ПАКУВАННЯ)',
	},
	{
		value: '200097',
		label: 'НІЧНИЙ ЗАКЛАД (АРТИСТ КАБАРЕ, АРТИСТ, ПРАЦІВНИК)',
	},
	{
		value: '200098',
		label: 'ВИГОТОВЛЕННЯ КЕРАМІЧНИХ ВИРОБІВ. ГОНЧАР',
	},
	{
		value: '200099',
		label: 'СЛЮСАР',
	},
	{
		value: '200100',
		label: 'ПРОФЕСІЙНИЙ ВЕЛОСИПЕДИСТ. ВЕЛОСПОРТ',
	},
	{
		value: '200101',
		label: 'ХІРУРГ',
	},
	{
		value: '200102',
		label: 'ЗБИРАЧ БОРГОВИХ ПЛАТЕЖІВ  (У БЕЗПЕЧНИХ ЗОНАХ)',
	},
	{
		value: '200103',
		label: 'ЗБИРАЧ БОРГОВИХ ПЛАТЕЖІВ  (У НЕБЕЗПЕЧНИХ ЗОНАХ)',
	},
	{
		value: '200104',
		label: 'ГАЗ. ОПЕРАТОР ЗАПРАВНИХ СТАНЦІЙ',
	},
	{
		value: '200105',
		label: 'ГАЗ. ТРАНСПОРТУВАННЯ ЛЕГКОЗАЙМИСТИХ РЕЧОВИН',
	},
	{
		value: '200106',
		label: 'ТОРГОВИЙ ПРЕДСТАВНИК В КОНФЛІКТНИХ АБО НЕБЕЗПЕЧНИХ ЗОНАХ',
	},
	{
		value: '200107',
		label: 'ТОРГОВИЙ ПРЕДСТАВНИК. ІНШІ ЗОНИ',
	},
	{
		value: '200108',
		label: 'ОБЧИСЛЕННЯ ТА ІНФОРМАТИКА. ІНЖЕНЕР',
	},
	{
		value: '200109',
		label: 'ОБЧИСЛЕННЯ ТА ІНФОРМАТИКА. ПРОГРАМІСТ',
	},
	{
		value: '200110',
		label: 'ВОДІЙ ШВИДКОЇ ДОПОМОГИ',
	},
	{
		value: '200111',
		label: 'ВОДІЙ АВТОБУСА',
	},
	{
		value: '200112',
		label: 'ВОДІЙ ТАКСІ',
	},
	{
		value: '200113',
		label: 'АРХІТЕКТОР, ГЕОДЕЗИСТ, СТАРШИЙ МАЙСТЕР/БРИГАДИР',
	},
	{
		value: '200114',
		label: 'КЕРІВНИК, БРИГАДИР',
	},
	{
		value: '200115',
		label: 'БУДІВЕЛЬНИК',
	},
	{
		value: '200116',
		label: 'ВИРОБНИЦТВО, УПАКОВКА ЦЕМЕНТУ',
	},
	{
		value: '200117',
		label: 'СТОЛЯР НА БУДІВНИЦТВІ',
	},
	{
		value: '200118',
		label: 'СВЕРДЛУВАЛЬНИК',
	},
	{
		value: '200119',
		label: 'МОНТАЖНИК МЕТАЛІЧНИХ КОНСТРУКЦІЙ',
	},
	{
		value: '200120',
		label: 'БУХГАЛТЕР',
	},
	{
		value: '200121',
		label: 'БУХГАЛТЕР',
	},
	{
		value: '200122',
		label: 'АВІАДИСПЕТЧЕР',
	},
	{
		value: '200123',
		label: 'СКЛОМАЙСТЕР',
	},
	{
		value: '200124',
		label: 'КОНСУЛ',
	},
	{
		value: '200125',
		label: 'ПОСОЛ',
	},
	{
		value: '200126',
		label: 'ПРАЦІВНИК ПОСОЛЬСТВА',
	},
	{
		value: '200127',
		label: 'ДИПЛОМАТИЧНИЙ КОРПУС В ЗОНАХ З ОСОБЛИВОЮ НЕБЕЗПЕКОЮ',
	},
	{
		value: '200128',
		label: 'ОБРОБЛЮВАЧ ШКУР',
	},
	{
		value: '200130',
		label: 'ДЕМОНТАЖ З ВИКОРИСТАННЯМ ВИБУХІВКИ',
	},
	{
		value: '200131',
		label: 'ДЕМОНТАЖ  БЕЗ ВИКОРИСТАННЯ ВИБУХІВКИ',
	},
	{
		value: '200132',
		label: 'СТОМАТОЛОГ / ДАНТИСТ',
	},
	{
		value: '200133',
		label: 'DOCKER',
	},
	{
		value: '200134',
		label: 'БУРІННЯ, ДРЕНАЖ',
	},
	{
		value: '200135',
		label: 'ПРОДАВЕЦЬ ГАЛАНТЕРЕЇ (ПОБУТОВА ХІМІЯ)',
	},
	{
		value: '200136',
		label: 'СТОЛЯР',
	},
	{
		value: '200137',
		label: 'ВИХОВАТЕЛЬ',
	},
	{
		value: '200138',
		label: 'ДІЛОВОД',
	},
	{
		value: '200139',
		label: 'ЕЛЕКТРОМОНТАЖНИК КАБЕЛЬНИХ МЕРЕЖ',
	},
	{
		value: '200140',
		label: 'КОНТРОЛЕРИ ЕЛЕКТРИКА',
	},
	{
		value: '200141',
		label: 'ЕЛЕКТРИК НИЗЬКА НАПРУГА',
	},
	{
		value: '200142',
		label: 'ЕЛЕКТРИК НА БУДІВНИЦТВІ',
	},
	{
		value: '200143',
		label: 'ЕЛЕКТРИК БУДІВЛІ, ЖИТЛОВІ ПРИМІЩЕННЯ І ДОРОГИ',
	},
	{
		value: '200144',
		label: 'ЕЛЕКТРИК ВИСОКА НАПРУГА',
	},
	{
		value: '200145',
		label: 'ЕЛЕКТРОМОНТАЖНИК',
	},
	{
		value: '200146',
		label: 'ОПЕРАТОРИ ЕЛЕКТРИЧНИХ МЕРЕЖ',
	},
	{
		value: '200148',
		label: 'ЦИРКОВІ ВИСТАВИ',
	},
	{
		value: '200149',
		label: 'МАЙСТЕР НАВАНТАЖУВАЛЬНО-РОЗВАНТАЖУВАЛЬНИХ РОБІТ',
	},
	{
		value: '200150',
		label: 'ВИБУХОВІ РЕЧОВИНИ',
	},
	{
		value: '200151',
		label: 'ВОДІЇ - СЛУЖБА ЗАЛІЗНИЧНОГО ТРАНСПОРТУ',
	},
	{
		value: '200152',
		label: 'ЗАЛІЗНИЦЯ (ІНШІ ПОВЯЗАНІ ПРОФЕСІЇ)',
	},
	{
		value: '200153',
		label: 'ОБСЛУГОВУВАННЯ ДОРІГ, ЗНАКІВ ТА ЛІНІЙ ЕЛЕКТРОПЕРЕДАЧ',
	},
	{
		value: '200154',
		label: 'МАШИНІСТ ЗАЛІЗНИЦІ',
	},
	{
		value: '200155',
		label: 'ПЕРСОНАЛ НАВАНТАЖУВАЛЬНО-РОЗВАНТАЖУВАЛЬНИХ РОБІТ НА ЗАЛІЗНИЦІ',
	},
	{
		value: '200156',
		label: 'ФРЕЗЕРУВАЛЬНИК/КОВАЛЬ',
	},
	{
		value: '200157',
		label: 'ЗБРОЙНІ СИЛИ ЛИШЕ АДМІНІСТРАТИВНА РОБОТА',
	},
	{
		value: '200158',
		label: 'ЗБРОЙНІ СИЛИ ІНШЕ',
	},
	{
		value: '200159',
		label: 'ПІДРОЗДІЛ ОСОБЛИВОГО ПРИЗНАЧЕННЯ НАРКО-ТРАФІК',
	},
	{
		value: '200160',
		label: 'ПІДРОЗДІЛ ОСОБЛИВОГО ПРИЗНАЧЕННЯ АНТИТЕРОРИСТИЧНИЙ',
	},
	{
		value: '200161',
		label: 'ФУМІГАЦІЯ: ЗЕМЛЯ',
	},
	{
		value: '200162',
		label: 'ФУМІГАЦІЯ: ПОВІТРЯНА',
	},
	{
		value: '200163',
		label: 'ГАЗ. КЕРІВНИК БРИГАДИР',
	},
	{
		value: '200164',
		label: 'ГАЗ. РОБІТНИК, МОНТАЖНИК',
	},
	{
		value: '200165',
		label: 'ГЕОЛОГИ::',
	},
	{
		value: '200166',
		label: 'ЛІСНИЧИЙ',
	},
	{
		value: '200167',
		label: 'ОХОРОНЕЦЬ',
	},
	{
		value: '200168',
		label: 'РЯТУВАЛЬНИК ІНШЕ',
	},
	{
		value: '200169',
		label: 'РЯТУВАЛЬНИК БАСЕЙНИ',
	},
	{
		value: '200170',
		label: 'ГОТЕЛЬНО-РЕСТОРАННИЙ БІЗНЕС',
	},
	{
		value: '200171',
		label: 'ВЕРХОВА ЇЗДА: ПРИБОРКУВАЧ',
	},
	{
		value: '200172',
		label: 'ВЕРХОВА ЇЗДА: СТРИБКИ ЧЕРЕЗ ПЕРЕШКОДИ',
	},
	{
		value: '200173',
		label: 'ВЕРХОВА ЇЗДА: ЖОКЕЙ ПЕРЕГОНИ',
	},
	{
		value: '200174',
		label: 'ВЕРХОВА ЇЗДА: ПОЛО АБО ПАТО',
	},
	{
		value: '200175',
		label: 'ВЕРХОВА ЇЗДА: РОДЕО',
	},
	{
		value: '200176',
		label: 'ВЕРХОВА ЇЗДА:: ВИКЛАДАЧ',
	},
	{
		value: '200177',
		label: 'ВЕРХОВА ЇЗДА: ЧАРЕРІЯ',
	},
	{
		value: '200178',
		label: 'АВТОМОБІЛЬНА ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200179',
		label: 'INDUSTRIA CÁRNICA',
	},
	{
		value: '200180',
		label: 'МЯСНА ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200181',
		label: 'ДЕРЕВООБРОБНА ПРОМИСЛОВОСТІ',
	},
	{
		value: '200182',
		label: 'НАФТОХІМІЧНА ПРОМИСЛОВІСТЬ: ПОШУК, РОЗВІДУВАЛЬНІ ОПЕРАЦІЇ',
	},
	{
		value: '200183',
		label: 'НАФТОХІМІЧНА ПРОМИСЛОВІСТЬ: НАГЛЯД ЗА НАФТОПЕРЕРОБНИМ ЗАВОДОМ',
	},
	{
		value: '200184',
		label: 'НАФТОХІМІЧНА ПРОМИСЛОВІСТЬ: ТЕХНІЧНЕ ОБСЛУГОВУВАННЯ РЕЗЕРВУАРІВ',
	},
	{
		value: '200185',
		label: 'НАФТОХІМІЧНА ПРОМИСЛОВІСТЬ: НАФТОВИДОБУВНІ ПЛАТФОРМИ',
	},
	{
		value: '200186',
		label: 'ХІМІЧНА ПРОМИСЛОВІСТЬ: НЕБЕЗПЕЧНІ РЕЧОВИНИ',
	},
	{
		value: '200187',
		label: 'ХІМІЧНА ПРОМИСЛОВІСТЬ: ІНШІ РЕЧОВИНИ',
	},
	{
		value: '200188',
		label: 'БІОХІМІЧНА ТА ФАРМАЦЕВТИЧНА ПРОМИСЛОВОСТІ',
	},
	{
		value: '200189',
		label: 'ІНЖЕНЕРІЯ: ЛІСОВА ІНЖЕНЕРІЯ',
	},
	{
		value: '200190',
		label: 'ІНЖЕНЕРІЯ: ІНЖЕНЕР СІЛЬСЬКОГО ГОСПОДАРСТВА',
	},
	{
		value: '200191',
		label: 'ІНЖЕНЕРІЯ: ІНЖЕНЕР-ЕЛЕКТРОТЕХНІК',
	},
	{
		value: '200192',
		label: 'ІНЖЕНЕРІЯ: ІНЖЕНЕР-ЕЛЕКТРОМЕХАНІК',
	},
	{
		value: '200193',
		label: 'ІНЖЕНЕРІЯ: ІНЖЕНЕР-БУДІВЕЛЬНИК',
	},
	{
		value: '200194',
		label: 'ВИГОТОВЛЕННЯ ІНСЕКТИЦИДІВ',
	},
	{
		value: '200195',
		label: 'МОНТАЖ ТРУБОПРОВОДУ',
	},
	{
		value: '200196',
		label: 'ВСТАНОВЛЕННЯ АБО РОЗМІЩЕННЯ АНТЕН',
	},
	{
		value: '200197',
		label: 'ПРИВАТНИЙ ДЕТЕКТИВ',
	},
	{
		value: '200198',
		label: 'ЛІСОРУБ',
	},
	{
		value: '200199',
		label: 'РОБОТА З ПАТОЛОГІЧНИМИ ВІДХОДАМИ',
	},
	{
		value: '200200',
		label: 'РОБОТА З ТОКСИЧНИМИ ОТРУЙНИМИ РЕЧОВИНАМИ',
	},
	{
		value: '200201',
		label: 'ВИРОБНИЦТВО ЗБРОЇ',
	},
	{
		value: '200202',
		label: 'ВОДІЙ ЕВАКУАТОРА, ЕКСКАВАТОРА, БУЛЬДОЗЕРА ТА ТОМУ ПОДІБНИХ',
	},
	{
		value: '200203',
		label: 'ОПЕРАТОР БУДІВЕЛЬНИХ ТА ЗЕМЛЕРИЙНИХ МАШИН',
	},
	{
		value: '200204',
		label: 'МОРСЬКИЙ. ТОРГОВЕЛЬНІ ЗОНИ БЕЗ РИЗИКУ ',
	},
	{
		value: '200205',
		label: 'БІЙНЯ',
	},
	{
		value: '200206',
		label: 'МЕХАНІКА',
	},
	{
		value: '200207',
		label: 'МЕТАЛУРГІЯ',
	},
	{
		value: '200208',
		label: 'ВИДОБУТОК КОРИСНИХ КОПАЛИХ: РОБОТА НА ПОВЕРХНІ',
	},
	{
		value: '200209',
		label: 'ВИДОБУТОК КОРИСНИХ КОПАЛИХ: РОБОТА ПІД ЗЕМЛЕЮ',
	},
	{
		value: '200210',
		label: 'ВИДОБУТОК КОРИСНИХ КОПАЛИХ: РОБОТА З ВИБУХІВКОЮ',
	},
	{
		value: '200211',
		label: 'ПЕРУКАР',
	},
	{
		value: '200212',
		label: 'ЖУРНАЛІСТИКА В ЗОНІ КОНФЛІКТУ',
	},
	{
		value: '200213',
		label: 'ЖУРНАЛІСТИКА, ВСІ ІНШІ',
	},
	{
		value: '200214',
		label: 'ФОТОГРАФ В ЗОНІ КОНФЛІКТУ',
	},
	{
		value: '200215',
		label: 'ФОТОГРАФ, ВСІ ІНШІ',
	},
	{
		value: '200216',
		label: 'МИТНИЙ ПЕРСОНАЛ, ПРИКОРДОННІ ЗОНИ',
	},
	{
		value: '200217',
		label: 'МИТНИЙ ПЕРСОНАЛ, ПРИКОРОННІ ЗОНИ РІЧОК',
	},
	{
		value: '200219',
		label: 'РИБАЛКА. НА УЗБЕРЕЖЯХ, РІКАХ ТА ОЗЕРАХ',
	},
	{
		value: '200220',
		label: 'ПІРОТЕХНІКА, ПІРОТЕХНІЧНІ ІГРИ',
	},
	{
		value: '200221',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: ТІЛЬКИ АДМІНІСТРАТИВНІ ЗАВДАННЯ',
	},
	{
		value: '200222',
		label: 'МУНІЦИПАЛЬНА ПОЛІЦІЯ',
	},
	{
		value: '200223',
		label: 'ПОЛІЦІЯ: РАЙОННА АБО ОБЛАСНА',
	},
	{
		value: '200224',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: НАЦІОНАЛЬНА АБО ФЕДЕРАЛЬНА',
	},
	{
		value: '200225',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНИЙ ГВАРДІЯ: ДЕАКТИВАЦІЯ ВИБУХІВКИ',
	},
	{
		value: '200226',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: КОРДОНИ, ЖАНДАРМЕРІЯ',
	},
	{
		value: '200227',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: НАРКОТРАФІК',
	},
	{
		value: '200228',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: АНТИТЕРОРИСТИЧНА ГРУПА',
	},
	{
		value: '200229',
		label: 'ПОЛІЦІЯ/ЦИВІЛЬНА ГВАРДІЯ: ОХОРОНА ВЯЗНИЦІ',
	},
	{
		value: '200230',
		label: 'ПСИХІАТР',
	},
	{
		value: '200231',
		label: 'РЕКЛАМА. ПОВІТРЯНА',
	},
	{
		value: '200232',
		label: 'РЕКЛАМА. ВСІ ІНШІ',
	},
	{
		value: '200233',
		label: 'РАДІОЛОГІЯ',
	},
	{
		value: '200234',
		label: 'КУР´ЄР НА МОТОЦИКЛІ',
	},
	{
		value: '200235',
		label: 'КУР´ЄР НА АВТОМОБІЛІ, МІКРОАВТОБУСІ АБО ГРУЗОВОМУ АВТОМОБІЛІ',
	},
	{
		value: '200236',
		label: 'ОБСЛУГОВУВАННЯ БУДИНКІВ',
	},
	{
		value: '200237',
		label: 'ПОСЛУГИ З НАВАНТАЖЕННЯ ТА РОЗВАНТАЖЕННЯ',
	},
	{
		value: '200238',
		label: 'СТОРОЖ БЕЗ ЗБРОЇ',
	},
	{
		value: '200239',
		label: 'СТОРОЖ ІЗ ЗБРОЄЮ',
	},
	{
		value: '200240',
		label: 'ОББИВКА',
	},
	{
		value: '200241',
		label: 'ОПЕРАТОР, ВЕДУЧИЙ, КАМЕРА, ТЕЛЕБАЧЕННЯ В ЗОНІ КОНФЛІКТУ',
	},
	{
		value: '200242',
		label: 'ОПЕРАТОР, ВЕДУЧИЙ, КАМЕРА, ТЕЛЕБАЧЕННЯ ІНШІ ЗОНИ',
	},
	{
		value: '200243',
		label: 'ГЕОДЕЗИСТ',
	},
	{
		value: '200244',
		label: 'ТОРЕОДОР',
	},
	{
		value: '200245',
		label: 'РОБОТА НА ВИСОТІ',
	},
	{
		value: '200246',
		label: 'НАЗЕМНИЙ ТРАНСПОРТ: БЕЗПЕЧНІ ПЕРЕВЕЗЕННЯ',
	},
	{
		value: '200247',
		label: 'НАЗЕМНИЙ ТРАНСПОРТ: ВАЖКІ ВАНТАЖІ',
	},
	{
		value: '200248',
		label: 'НАЗЕМНИЙ ТРАНСПОРТ: НЕБЕЗПЕЧНІ РЕЧОВИНИ (ТОКСИЧНІ)',
	},
	{
		value: '200249',
		label: 'НАЗЕМНИЙ ТРАНСПОРТ: ',
	},
	{
		value: '200250',
		label: 'НАЗЕМНИЙ ПЕРЕВЕЗЕННЯ: ПЕРЕВЕЗЕННЯ ЛЮДЕЙ',
	},
	{
		value: '200251',
		label: 'ФІЗІОТЕРАПЕВТ / КІНЕЗІОЛОГІЯ',
	},
	{
		value: '200252',
		label: 'МАНУАЛЬНИЙ ТЕРАПЕВТ / ОСТЕОПАТ',
	},
	{
		value: '200253',
		label: 'КОВАЛЬ',
	},
	{
		value: '200254',
		label: 'ПЕНСІОНЕР',
	},
	{
		value: '200255',
		label: 'ЗАГАЛЬНА МЕДИЦИНА',
	},
	{
		value: '200256',
		label: 'СТУДЕНТ',
	},
	{
		value: '200257',
		label: 'ПРОФЕСІЙНИЙ ФУТБОЛІСТ',
	},
	{
		value: '200258',
		label: 'СТУДЕНТ-ПІЛОТ',
	},
	{
		value: '200259',
		label: 'ІНСТРУКТОР ПОЛЬОТІВ',
	},
	{
		value: '200260',
		label: 'ПІЛОТ < СТАЖ РОКІВ',
	},
	{
		value: '200261',
		label: 'ПІЛОТ  - СКІЛЬКИ РОКІВ',
	},
	{
		value: '200262',
		label: 'МАЛЯР (НЕ ПРАЦЮЄ НА ВИСОТІ)',
	},
	{
		value: '200263',
		label: 'ВІЙСЬКОВИЙ',
	},
	{
		value: '200264',
		label: 'ПЕРЕВЕЗЕННЯ ВОГНЯНОГО БИКА',
	},
	{
		value: '200265',
		label: 'ЛОТЕРЕЇ, АДМІНІСТРАТОР',
	},
	{
		value: '200266',
		label: 'БУТАН, ДОСТАВКА',
	},
	{
		value: '200267',
		label: 'ВЕТЕРИНАР',
	},
	{
		value: '200268',
		label: 'АНТИБІОТИКИ',
	},
	{
		value: '200269',
		label: 'САНІТАРНО-ТЕХНІЧНИЙ ПОМІЧНИК',
	},
	{
		value: '200270',
		label: 'АКУШЕР',
	},
	{
		value: '200271',
		label: 'КЛІНІКА, ПОМІЧНИК',
	},
	{
		value: '200272',
		label: 'ОПТИК',
	},
	{
		value: '200273',
		label: 'ПОДОЛОГ',
	},
	{
		value: '200274',
		label: 'МАТЕМАТИК',
	},
	{
		value: '200275',
		label: 'ПЕДАГОГ',
	},
	{
		value: '200276',
		label: 'ВЧИТЕЛЬ МОВ',
	},
	{
		value: '200277',
		label: 'ІНСПЕКТОР ОСВІТНІЙ',
	},
	{
		value: '200278',
		label: 'ВИХОВАТЕЛЬ',
	},
	{
		value: '200279',
		label: 'ПРОФЕСОР ІНФОРМАТИКИ',
	},
	{
		value: '200280',
		label: 'КІНЕМАТОГРАФІЯ, ОПЕРАТОР ',
	},
	{
		value: '200281',
		label: 'ВЧИТЕЛЬ ТАНЦІВ',
	},
	{
		value: '200282',
		label: 'ТВ ВИРОБНИЦТВО-ПРОДАЖ',
	},
	{
		value: '200283',
		label: 'ЗАБИВАЧ БИКІВ',
	},
	{
		value: '200284',
		label: 'REJONEER КОРИДА',
	},
	{
		value: '200285',
		label: 'ЗАБИВАЧ ТЕЛЯТ',
	},
	{
		value: '200286',
		label: 'БІНГО, ПРАЦІВНИК',
	},
	{
		value: '200287',
		label: 'ВЧИТЕЛЬ ГІМНАСТИКИ',
	},
	{
		value: '200288',
		label: 'ПСИХОЛОГ',
	},
	{
		value: '200289',
		label: 'РАДНИК',
	},
	{
		value: '200290',
		label: 'СЕПТИКИ, ПРИБИРАЛЬНИК',
	},
	{
		value: '200291',
		label: 'КОСМЕТОЛОГ',
	},
	{
		value: '200292',
		label: 'ВЯЗНИЦІ, ДИРЕКТОР',
	},
	{
		value: '200293',
		label: 'ВЯЗНИЦІ, НАЧАЛЬНИК СЛУЖБИ',
	},
	{
		value: '200294',
		label: 'ЛІКАР У ВЯЗНИЦІ',
	},
	{
		value: '200295',
		label: 'САДІВНИК',
	},
	{
		value: '200296',
		label: 'МЯCНИК СВИНИНА',
	},
	{
		value: '200297',
		label: 'МЯCНИК, ЗАБІЙ ТВАРИН',
	},
	{
		value: '200298',
		label: 'ШВЕЦЬ ВЗУТТЯ',
	},
	{
		value: '200299',
		label: 'СЛЮСАР-САНТЕХНІК',
	},
	{
		value: '200300',
		label: 'СКЛЯР',
	},
	{
		value: '200301',
		label: 'СКЛО, ГРАВЕР СКЛА',
	},
	{
		value: '200302',
		label: 'ПРОФЕСІЙНИЙ ВОДІЙ, ПРИВАТНИЙ АВТОМОБІЛЬ',
	},
	{
		value: '200303',
		label: 'МАШИНІСТ НА ЯРМАРКАХ',
	},
	{
		value: '200304',
		label: 'ПРИБИРАЛЬНИК СМІТТЄПРОВОДІВ',
	},
	{
		value: '200305',
		label: 'ТВАРИНИ, РОЗПЛІДНИК',
	},
	{
		value: '200306',
		label: 'МАНДРІВНИК',
	},
	{
		value: '200307',
		label: 'ВЯЗНИЦІ, АДМІНІСТРАТОР',
	},
	{
		value: '200308',
		label: 'ОФІЦІАНТ',
	},
	{
		value: '200309',
		label: 'КРЕСЛЯР',
	},
	{
		value: '200310',
		label: 'ДОГЛЯДАЧ',
	},
	{
		value: '200311',
		label: 'ТКАЧ',
	},
	{
		value: '200312',
		label: 'ВУЛИЧНИЙ ПРОДАВЕЦЬ',
	},
	{
		value: '200314',
		label: 'МАЛЯР (ПРАЦЮЄ НА ВИСОТІ)',
	},
	{
		value: '200315',
		label: 'ПРАЦІВНИК РИБНОГО МАГАЗИНУ',
	},
	{
		value: '200316',
		label: 'ПРАЦІВНИК',
	},
	{
		value: '200317',
		label: 'ВОДІЙ СІЛЬСЬКОГОСПОДАРСЬКОЇ ТЕХНІКИ.',
	},
	{
		value: '200318',
		label: 'ФЕРМЕР',
	},
	{
		value: '200320',
		label: 'ОРТОПЕД',
	},
	{
		value: '200321',
		label: 'ЛИЦЮВАЛЬНИК-МАРМУРНИК',
	},
	{
		value: '200322',
		label: 'ЛИСТОНОША',
	},
	{
		value: '200325',
		label: 'ХІМІК БЕЗ НЕБЕЗПЕЧНИХ РЕЧОВИН',
	},
	{
		value: '200327',
		label: 'ІНЖЕНЕР, ОКРІМ МІН',
	},
	{
		value: '200328',
		label: 'ГЕОГРАФ',
	},
	{
		value: '200331',
		label: 'ХУДОЖНИК ТЕХНІЧНИЙ',
	},
	{
		value: '200332',
		label: 'ТОПОГРАФ, З ВІДВІДУВАННЯМ ОБЄКТІВ',
	},
	{
		value: '200333',
		label: 'ЛІКАР-ЗАГАЛЬНА МЕДИЦИНА',
	},
	{
		value: '200334',
		label: 'МЕДИЧНИЙ СПЕЦІАЛІСТ',
	},
	{
		value: '200335',
		label: 'РАДІОЛОГ',
	},
	{
		value: '200336',
		label: 'ЛІКАР ІНШЕ',
	},
	{
		value: '200337',
		label: 'ЛІКАР-СТОМАТОЛОГ',
	},
	{
		value: '200338',
		label: 'ФАРМАЦЕВТ',
	},
	{
		value: '200339',
		label: 'САНІТАРНИЙ ПЕРСОНАЛ',
	},
	{
		value: '200341',
		label: 'ФІЗІОТЕРАПЕВТ',
	},
	{
		value: '200343',
		label: 'ВОДІЙ ШВИДКОЇ ДОПОМОГИ',
	},
	{
		value: '200345',
		label: 'ЛАБОРАНТ, РОБОТА В',
	},
	{
		value: '200346',
		label: 'СИСТЕМНИЙ АДМІНІСТРАТОР, БЕЗ ВИЇЗДІВ',
	},
	{
		value: '200347',
		label: 'ЕКОНОМІСТ',
	},
	{
		value: '200349',
		label: 'НОТАРІУС',
	},
	{
		value: '200350',
		label: 'ЮРИСТКОНСУЛЬТ',
	},
	{
		value: '200351',
		label: 'ВЧИТЕЛЬ ОСВІТНІЙ, ОКРІМ СПОРТУ',
	},
	{
		value: '200354',
		label: 'ЖУРНАЛІСТ БЕЗ НЕБЕЗПЕЧНИХ РЕПОРТАЖІВ',
	},
	{
		value: '200355',
		label: 'ДИЗАЙНЕР',
	},
	{
		value: '200356',
		label: 'ФОТОГРАФ В СТУДІЇ',
	},
	{
		value: '200357',
		label: 'ФОТОГРАФ ПРЕСИ',
	},
	{
		value: '200359',
		label: 'ПРОФЕСІЙНИЙ СПОРТСМЕН',
	},
	{
		value: '200361',
		label: 'СОЦІАЛЬНИЙ ПОМІЧНИК',
	},
	{
		value: '200362',
		label: 'ЕКСПЕРТ З СОЦІАЛЬНО-ТРУДОВИХ ВІДНОСИН',
	},
	{
		value: '200363',
		label: 'МЕНЕДЖЕР З РЕКЛАМИ',
	},
	{
		value: '200364',
		label: 'ЗВЯЗКИ З ГРОМАДСЬКІСТЮ',
	},
	{
		value: '200365',
		label: 'МЕНЕДЖЕР, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200366',
		label: 'КОШТОРИСНИК З ВІЗИТАМИ НА ОБЄКТИ ',
	},
	{
		value: '200367',
		label: 'АДМІНІСТРАТОР (НЕ ВЯЗНИЦІ) БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200368',
		label: 'АДМІНІСТРАТОР (НЕ ВЯЗНИЦІ) БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200369',
		label: 'РЕСЕПШІОНІСТ',
	},
	{
		value: '200370',
		label: 'ПРОДАВЕЦЬ В МАГАЗИНІ',
	},
	{
		value: '200371',
		label: 'ПРОДАВЕЦЬ, ЧАСТІ ПОЇЗДКИ',
	},
	{
		value: '200372',
		label: 'ТОРГІВЛЯ, ВЛАСНИК',
	},
	{
		value: '200373',
		label: 'МЕНЕДЖЕР З ПРОДАЖІВ НА МІСЦІ',
	},
	{
		value: '200374',
		label: 'МЕДИЧНИЙ ВІДВІДУВАЧ',
	},
	{
		value: '200375',
		label: 'КОМЕРЦІЙНИЙ АГЕНТ, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200376',
		label: 'АГЕНТ З ПРОДАЖУ, З ПОДОРОЖАМИ',
	},
	{
		value: '200377',
		label: 'КОМЕРЦІЙНИЙ АГЕНТ, З ПОДОРОЖАМИ',
	},
	{
		value: '200378',
		label: 'АГЕНТ З ПРОДАЖУ, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200379',
		label: 'СТРАХОВИЙ АГЕНТ, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200380',
		label: 'СТРАХОВИЙ АГЕНТ, З ПОДОРОЖАМИ',
	},
	{
		value: '200381',
		label: 'ТОРГІВЛЯ, ПРАЦІВНИК ',
	},
	{
		value: '200382',
		label: 'ВУЛИЧНИЙ ПРОДАВЕЦЬ',
	},
	{
		value: '200383',
		label: 'КУХАР',
	},
	{
		value: '200385',
		label: 'ХАТНЯ РОБІТНИЦЯ',
	},
	{
		value: '200386',
		label: 'ПОВІТРЯНИЙ ЕКІПАЖ',
	},
	{
		value: '200387',
		label: 'ПРИБИРАННЯ ВНУТРІШНІХ ПРИМІЩЕНЬ, ПРАЦІВНИК',
	},
	{
		value: '200388',
		label: 'ПЕРУКАР',
	},
	{
		value: '200390',
		label: 'ПОЖЕЖНИЙ',
	},
	{
		value: '200391',
		label: 'ВЯЗНИЦІ, ДОПОМІЖНИЙ ПЕРСОНАЛ',
	},
	{
		value: '200392',
		label: 'НАЦІОНАЛЬНА ПОЛІЦІЯ',
	},
	{
		value: '200393',
		label: 'МІСЦЕВА ПОЛІЦІЯ',
	},
	{
		value: '200394',
		label: 'СТОРОЖ, З ВОГНЕЛЬНОЮ ЗБРОЄЮ',
	},
	{
		value: '200395',
		label: 'ОХОРОНА',
	},
	{
		value: '200396',
		label: 'СТОРОЖ, БЕЗ ВОГНЕЛЬНОЇ ЗБРОЇ',
	},
	{
		value: '200397',
		label: 'СІЛЬСЬКОГОСПОДАРСЬКА ФЕРМА ТВАРИННИЦТВО, РОБІТНИК',
	},
	{
		value: '200399',
		label: 'ОХОРОНА ЛІСУ',
	},
	{
		value: '200400',
		label: 'РИБАЛКА',
	},
	{
		value: '200402',
		label: 'МЕТАЛУРГІЯ, ПРАЦІВНИК (БЕЗ ЛИТТЯ)',
	},
	{
		value: '200403',
		label: 'МЕТАЛЕВІ СТОЛЯРНІ РОБОТИ',
	},
	{
		value: '200406',
		label: 'ТЕКСТИЛЬ, ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200407',
		label: 'ХАРЧОВА ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200408',
		label: 'М’ЯСНИК З ЗАБОЄМ',
	},
	{
		value: '200409',
		label: 'МЯСНИК БЕЗ ЗАБОЮ',
	},
	{
		value: '200410',
		label: 'ПЕКАР',
	},
	{
		value: '200411',
		label: 'ОББИВАЛЬНИК',
	},
	{
		value: '200412',
		label: 'ДЕКОРАТОР-ІНТЕРЄРІВ',
	},
	{
		value: '200414',
		label: 'ВЗУТТЯ, ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200417',
		label: 'ТОКАР',
	},
	{
		value: '200418',
		label: 'КОНДИЦІОНЕР, МЕХАНІК-МОНТАЖНИК',
	},
	{
		value: '200419',
		label: 'МОНТАЖНА ЛІНІЯ, ОПЕРАТОР',
	},
	{
		value: '200420',
		label: 'СИСТЕМНИЙ АДМІНІСТРАТОР, З ПОДОРОЖАМИ',
	},
	{
		value: '200421',
		label: 'МЕХАНІК',
	},
	{
		value: '200422',
		label: 'АВТОМОБІЛЬНИЙ МЕХАНІК',
	},
	{
		value: '200423',
		label: 'ОБСЛУГОВУВАННЯ БУДІВЕЛЬ',
	},
	{
		value: '200424',
		label: 'ЕЛЕКТРОМЕХАНІК',
	},
	{
		value: '200425',
		label: 'ЕЛЕКТРОЕНЕРГІЯ НИЗЬКОВОЛЬТНА, ВСТАНОВЛЕННЯ',
	},
	{
		value: '200426',
		label: 'НИЗЬКОВОЛЬТНИЙ ЕЛЕКТРИК ',
	},
	{
		value: '200427',
		label: 'ВИСОКОВОЛЬТНИЙ ЕЛЕКТРИК',
	},
	{
		value: '200428',
		label: 'ІЗОЛЯЦІЯ, ВСТАНОВЛЕННЯ',
	},
	{
		value: '200430',
		label: 'ГАЗОЗВАРНИК',
	},
	{
		value: '200431',
		label: 'ЕЛЕКТРОЗВАРНИК',
	},
	{
		value: '200432',
		label: 'МЕТАЛ, ЗБІР КОНСТРУКЦІЙ ',
	},
	{
		value: '200433',
		label: 'ДРУК, МАЙСТЕРНЯ',
	},
	{
		value: '200434',
		label: 'ХУДОЖНИК ІНТЕРЄРІВ',
	},
	{
		value: '200435',
		label: 'ЦЕМЕНТ, ВИРОБНИЦТВО',
	},
	{
		value: '200436',
		label: 'БУДІВНИЦТВО',
	},
	{
		value: '200438',
		label: 'ШТУКАТУР',
	},
	{
		value: '200439',
		label: 'СКЛАД, ЗАВАНТАЖУВАННЯ ТА РОЗВАНТАЖУВАННЯ',
	},
	{
		value: '200440',
		label: 'РУЧНЕ ПАКУВАННЯ',
	},
	{
		value: '200441',
		label: 'ВОДІЙ ЕВАКУАТОРА',
	},
	{
		value: '200442',
		label: 'ВОДІЙ ЕКСКАВАТОРА',
	},
	{
		value: '200443',
		label: 'ДОСТАВКА БЕЗ МОПЕДА/МОТОЦИКЛА',
	},
	{
		value: '200444',
		label: 'ВОДІЙ ТАКСІ',
	},
	{
		value: '200445',
		label: 'ВОДІЙ АВТОБУСА',
	},
	{
		value: '200446',
		label: 'ВОДІЙ ВАНТАЖНОГО АВТОМОБІЛЯ ІНШІ',
	},
	{
		value: '200447',
		label: 'ВОДІЙ ВАЖКОЇ ТЕХНІКИ',
	},
	{
		value: '200448',
		label: 'ПЕРЕВІЗНИК',
	},
	{
		value: '200452',
		label: 'ПЕРЕВІЗНИКПЕРЕВІЗНИКПЕРЕВІЗНИК',
	},
	{
		value: '200453',
		label: 'МЕНЕДЖЕР, З ПОДОРОЖАМИ',
	},
	{
		value: '200454',
		label: 'ДИРЕКЦІЯ, З ПОДОРОЖАМИ',
	},
	{
		value: '200456',
		label: 'НАЦІОНАЛЬНА ГВАРДІЯ',
	},
	{
		value: '200457',
		label: 'ФЕРМЕР РОБОТА З ТЕХНІКОЮ',
	},
	{
		value: '200458',
		label: 'СТОЛЯР БЕЗ ПИЛИ',
	},
	{
		value: '200459',
		label: 'МЕБЛІ, ЗБІР І МОНТУВАННЯ',
	},
	{
		value: '200460',
		label: 'СТОЛЯР З ПИЛОЮ',
	},
	{
		value: '200461',
		label: 'ПРАЦІВНИК СКЛАДУ',
	},
	{
		value: '200462',
		label: 'ВОДІЙ МІКРОАВТОБУСА',
	},
	{
		value: '200463',
		label: 'АВІАЦІЙНИЙ МЕХАНІК',
	},
	{
		value: '200466',
		label: 'АСТРОНОМ',
	},
	{
		value: '200467',
		label: 'СТОМАТОЛОГІЧНИЙ ПРОТЕЗИСТ',
	},
	{
		value: '200468',
		label: 'АРХІТЕКТОР, БЕЗ ВІДВІДУННЯ ОБЄКТІВ',
	},
	{
		value: '200469',
		label: 'АРХІТЕКТОР, З ВІДВІДУВАННЯМ ОБЄКТІВ',
	},
	{
		value: '200470',
		label: 'ТОПОГРАФ, БЕЗ ВІДВІДУВАННЯ ОБЄКТІВ',
	},
	{
		value: '200471',
		label: 'СУДНОВЛАСНИК',
	},
	{
		value: '200474',
		label: 'ЕРГОТЕРАПЕВТ',
	},
	{
		value: '200475',
		label: 'МАСАЖИСТ',
	},
	{
		value: '200479',
		label: 'ІТ, СИСТЕМНИЙ АНАЛІТИК',
	},
	{
		value: '200480',
		label: 'ПОДАТКОВИЙ КОНСУЛЬТАНТ',
	},
	{
		value: '200481',
		label: 'МАЙСТЕР ХОЛОДИЛЬНИКІВ',
	},
	{
		value: '200483',
		label: 'АДВОКАТ СУДДІВ',
	},
	{
		value: '200485',
		label: 'ПРОМИСЛОВІСТЬ, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200486',
		label: 'ПРОМИСЛОВІСТЬ, З ПОДОРОЖАМИ',
	},
	{
		value: '200487',
		label: 'АДМІНІСТРАТИВНИЙ МЕНЕДЖЕР',
	},
	{
		value: '200489',
		label: 'ФІЛОЛОГ',
	},
	{
		value: '200490',
		label: 'ІНСТРУКТОР З ВОДІННЯ',
	},
	{
		value: '200491',
		label: 'ІНСТРУКТОР З ПЛАВАННЯ',
	},
	{
		value: '200492',
		label: 'ДЕКОРУВАННЯ ОБЄКТА В СЕРЕДИНІ',
	},
	{
		value: '200494',
		label: 'КАМЕРА, ОПЕРАТОР',
	},
	{
		value: '200495',
		label: 'МУЗИКАНТ',
	},
	{
		value: '200497',
		label: 'ВЧИТЕЛЬ СПОРТУ, ОКРІМ ПЛАВАННЯ',
	},
	{
		value: '200499',
		label: 'ПЕРЕКЛАДАЧ',
	},
	{
		value: '200500',
		label: 'КОМЕРЦІЙНИЙ ДИРЕКТОР, БЕЗ ЧАСТИХ ПОЇЗДОК',
	},
	{
		value: '200501',
		label: 'КОШТОРИСНИК БЕЗ ВІДВІДУВАННЯ ОБЄКТІВ ',
	},
	{
		value: '200502',
		label: 'ФІНАНСОВИЙ ПОСЕРЕДНИК, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200504',
		label: 'ТЕЛЕФОНІСТ',
	},
	{
		value: '200505',
		label: 'АДМІНІСТРАТОР У ВЯЗНИЦЯХ',
	},
	{
		value: '200506',
		label: 'ТОРГОВИЙ ПРЕДСТАВНИК, З ПОДОРОЖАМИ',
	},
	{
		value: '200507',
		label: 'ТОРГОВИЙ ПРЕДСТАВНИК, БЕЗ ПОДОРОЖЕЙ',
	},
	{
		value: '200508',
		label: 'АГЕНТ З НЕРУХОМОСТІ',
	},
	{
		value: '200509',
		label: 'МОДЕЛЬ РЕКЛАМИ',
	},
	{
		value: '200510',
		label: 'ФРУКТОВИЙ МАГАЗИН, ПРАЦІВНИК',
	},
	{
		value: '200511',
		label: 'КОНСЬЄРЖ',
	},
	{
		value: '200512',
		label: 'ПРИБИРАННЯ ЗЗОВНІ, ПРАЦІВНИК',
	},
	{
		value: '200513',
		label: 'АВТОМОБІЛІ, ЧИСТКА',
	},
	{
		value: '200514',
		label: 'ПРАЛЬНЯ, ПРАЦІВНИК',
	},
	{
		value: '200515',
		label: 'ПОЛІЦІЯ, ІНСПЕКТОР',
	},
	{
		value: '200516',
		label: 'ВЯЗНИЦЯ, ОФІЦЕР',
	},
	{
		value: '200518',
		label: 'ТУРИСТИЧНИЙ ГІД',
	},
	{
		value: '200519',
		label: 'ДЕЗІНФЕКЦІЯ-ДЕЗІНСЕКЦІЯ',
	},
	{
		value: '200520',
		label: 'СІЛЬСЬКОГОСПОДАРСЬКИЙ ТЕХНІК',
	},
	{
		value: '200521',
		label: 'ТВАРИННА ФЕРМА БЕЗ ДИКОЇ ХУДОБИ',
	},
	{
		value: '200522',
		label: 'ТВАРИННА ФЕРМА, МЯСО ТА БАВОВНА',
	},
	{
		value: '200523',
		label: 'МЕДИК-ПСИХІАТР',
	},
	{
		value: '200524',
		label: 'САДІВНИК',
	},
	{
		value: '200525',
		label: 'ВІДПОВІДАЛЬНИЙ КЕРІВНИК, ВИКЛЮЧНО НАГЛЯД',
	},
	{
		value: '200526',
		label: 'МЕТАЛУРГІЯ, РОБІТНИК У ПЕЧІ',
	},
	{
		value: '200527',
		label: 'МЕТАЛУРГІЯ, ВИПЛАВКА',
	},
	{
		value: '200528',
		label: 'СТАЛЬ, ВИРОБНИЦТВО ПРОДУКТІВ ІЗ СТАЛІ',
	},
	{
		value: '200529',
		label: 'ВИРОБНИЦТВО МЕТАЛЕВОЇ ТАРИ',
	},
	{
		value: '200530',
		label: 'ПРАЦІВНИК ПИЛОРАМИ',
	},
	{
		value: '200531',
		label: 'ХІМЧИСТКА, РОБОТА В',
	},
	{
		value: '200532',
		label: 'СТОРОЖ',
	},
	{
		value: '200533',
		label: 'КОНСЕРВНА ПРОМИСЛОВОСТЬ',
	},
	{
		value: '200534',
		label: 'ПЕКАР-КОНДИТЕР',
	},
	{
		value: '200535',
		label: 'ВИГОТОВЛЕННЯ',
	},
	{
		value: '200537',
		label: 'МАЙСТЕР ШКІРЯНИХ ВИРОБІВ',
	},
	{
		value: '200539',
		label: 'ФРЕЗЕРНИЙ ВЕРСТАТ',
	},
	{
		value: '200540',
		label: 'ВИГОТОВЛЕННЯ КОНДИЦІОНЕРІВ',
	},
	{
		value: '200541',
		label: 'ВСТАНОВЛЕННЯ ЛЕГКОЇ  АБО ТОЧНОЇ ТЕХНІКИ',
	},
	{
		value: '200542',
		label: 'ВСТАНОВЛЕННЯ ВАЖКОЇ ТЕХНІКИ',
	},
	{
		value: '200543',
		label: 'МЕХАНІК МОТОЦИКЛІВ',
	},
	{
		value: '200544',
		label: 'ЛІФТИ, МОНТАЖ',
	},
	{
		value: '200545',
		label: 'ЕЛЕКТРОННІ АПАРАТИ, РЕМОНТ',
	},
	{
		value: '200546',
		label: 'ТЕХНІК РАДІО ТА ТЕЛЕБАЧЕННЯ',
	},
	{
		value: '200547',
		label: 'ДОМАШНЯ ТЕХНІКА, РЕМОНТ',
	},
	{
		value: '200548',
		label: 'ТЕЛЕФОННЕ ОБЛАДНАННЯ, МОНТАЖ ВСТАНОВЛЕННЯ',
	},
	{
		value: '200549',
		label: 'ВИГОТОВЛЕННЯ ЕЛЕКТРОКАБЕЛІВ',
	},
	{
		value: '200550',
		label: 'ВСТАНОВЛЕННЯ ВИВІСОК',
	},
	{
		value: '200551',
		label: 'ГАЗ, ВСТАНОВЛЕННЯ',
	},
	{
		value: '200552',
		label: 'ОПАЛЕННЯ, ВСТАНОВЛЕННЯ',
	},
	{
		value: '200553',
		label: 'ОПАЛЕННЯ, ОБСЛУГОВУВАННЯ',
	},
	{
		value: '200554',
		label: 'РОБОТА З ЛИСТОВИМ МЕТАЛОМ',
	},
	{
		value: '200555',
		label: 'PIZARRA, MONTADOR DE TEJADOS DE',
	},
	{
		value: '200556',
		label: 'ШИФЕР, ВСТАНОВЛЕННЯ ТА МОНТАЖ ДАХУ',
	},
	{
		value: '200557',
		label: 'АЛЮМІНІЙ, ВСТАНОВЛЕННЯ',
	},
	{
		value: '200558',
		label: 'ЮВЕЛІР',
	},
	{
		value: '200560',
		label: 'ВИРОБНИЦТВО ПОРЦЕЛЯНИ',
	},
	{
		value: '200562',
		label: 'МОРЯК',
	},
	{
		value: '200563',
		label: 'АВТОМАЛЯР',
	},
	{
		value: '200564',
		label: 'ЦЕМЕНТ, ВИРОБНИЦТВО',
	},
	{
		value: '200565',
		label: 'ПЛИТОЧНИК',
	},
	{
		value: '200566',
		label: 'БЕТОН, ЗБІР КОНСТРУКЦІЙ',
	},
	{
		value: '200567',
		label: 'ОПАЛУБНИК',
	},
	{
		value: '200568',
		label: 'МОНТАЖ БУДІВЕЛЬНИХ МЕТАЛОКОНСТРУКЦІЙ',
	},
	{
		value: '200569',
		label: 'МАЙСТЕР ПО ПАРКЕТУ І ЛАМІНАТУ',
	},
	{
		value: '200570',
		label: 'ЛІПНИК АРХІТЕКТУРНИХ КОНСТРУКЦІЙ З ГІПСУ',
	},
	{
		value: '200572',
		label: 'ЖАЛЮЗІ ВСТАНОВЛЕННЯ',
	},
	{
		value: '200573',
		label: 'ВАНТАЖНИК ПОРТОВИЙ',
	},
	{
		value: '200574',
		label: 'ВИКЛАДКА ТОВАРІВ У СУПЕРМАРКЕТІ',
	},
	{
		value: '200575',
		label: 'СКЛАДАННЯ-ПРЕСУВАННЯ',
	},
	{
		value: '200576',
		label: 'MENКУРЄР ДОСТАВКИSAJERO',
	},
	{
		value: '200578',
		label: 'БЕНЗИН, ПРОДАВЕЦЬ',
	},
	{
		value: '200579',
		label: 'ВОГНЕГАСНИКИ, ВСТАНОВЛЕННЯ ТА ПЕРЕЗАРЯДКА',
	},
	{
		value: '200580',
		label: 'АКТОР БЕЗ ОСОБЛИВИХ РИЗИКІВ',
	},
	{
		value: '200581',
		label: 'СТРАХУВАННЯ БЕЗ ПОДОРОЖЕЙ, ПРАЦІВНИК',
	},
	{
		value: '200582',
		label: 'СТРАХОВИЙ КОМЕРЦІЙНИЙ ТЕХНІК',
	},
	{
		value: '200583',
		label: 'СТРАХУВАННЯ З ПОДОРОЖАМИ, ПРАЦІВНИК',
	},
	{
		value: '200585',
		label: 'ЕКСПЕРТ З ОЦІНКИ',
	},
	{
		value: '200587',
		label: 'ФЕРМЕР БЕЗ ЗАСТОСУВАННЯ ТЕХНІКИ',
	},
	{
		value: '200588',
		label: 'ЛІСОРУБ З МЕХАНІЧНОЮ ПИЛОЮ',
	},
	{
		value: '200589',
		label: 'СКЛАД, КЕРІВНИК (ВИКЛЮЧНО НАГЛЯД)',
	},
	{
		value: '200590',
		label: 'ШАХТАР БЕЗ ВИБУХІВКИ',
	},
	{
		value: '200591',
		label: 'ВИННИЙ ЗАВОД, ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200592',
		label: 'КАМЕНЯР БЕЗ ВИБУХІВКИ',
	},
	{
		value: '200593',
		label: 'БІЙНЯ, РОБОТА В',
	},
	{
		value: '200594',
		label: 'СТОЛЯР З ПИЛОЮ',
	},
	{
		value: '200595',
		label: 'ЛІФТИ, РЕМОНТ',
	},
	{
		value: '200596',
		label: 'АНТЕНИ, МОНТАЖ ВСТАНОВЛЕННЯ',
	},
	{
		value: '200597',
		label: 'БАССЕЙНИ, ОБСЛУГОВУВАННЯ',
	},
	{
		value: '200598',
		label: 'ХУДОЖНЯ ГРАФІКА, ПРАЦІВНИК',
	},
	{
		value: '200599',
		label: 'МАЛЯР ЗОВНІШНІЙ',
	},
	{
		value: '200600',
		label: 'ПІДРЯДНИК',
	},
	{
		value: '200601',
		label: 'БУДІВНИЦТВО, КОНТРОЛЬ ЯКОСТІ',
	},
	{
		value: '200602',
		label: 'ТЕСЛЯР БЕЗ ПИЛИ',
	},
	{
		value: '200603',
		label: 'БУДІВНИЦТВО, ПОМІЧНИК',
	},
	{
		value: '200604',
		label: 'ФІЗИК, БЕЗ ЯДЕРНОГО РИЗИКУ',
	},
	{
		value: '200605',
		label: 'ЯДЕРНИЙ ФІЗИК',
	},
	{
		value: '200606',
		label: 'МЕТЕОРОЛОГ',
	},
	{
		value: '200609',
		label: 'БОЦМАН',
	},
	{
		value: '200610',
		label: 'ЗООЛОГ',
	},
	{
		value: '200611',
		label: 'СУДОВИЙ МЕДИЧНИЙ ЕКСПЕРТ',
	},
	{
		value: '200613',
		label: 'ОФТАЛЬМОЛОГ',
	},
	{
		value: '200618',
		label: 'СУДОВИЙ СЕКРЕТАР',
	},
	{
		value: '200620',
		label: 'РЕЄСТРАТОР МАЙНА',
	},
	{
		value: '200622',
		label: 'СОЦІОЛОГ',
	},
	{
		value: '200623',
		label: 'МЕТЕОРОЛОГИ В НЕБЕЗПЕЧНИХ ОБСЕРВАТОРІЯХ',
	},
	{
		value: '200625',
		label: 'СВЯЩЕНИК',
	},
	{
		value: '200626',
		label: 'СВЯЩЕННОСЛУЖИТЕЛЬ',
	},
	{
		value: '200627',
		label: 'ХУДОЖНИК',
	},
	{
		value: '200628',
		label: 'РЕСТАВРАТОР ТВОРІВ МИСТЕЦА',
	},
	{
		value: '200629',
		label: 'РЕСТАВРАТОР БУДІВЕЛЬ',
	},
	{
		value: '200630',
		label: 'ХУДОЖНІЙ ПРЕДСТАВНИК',
	},
	{
		value: '200631',
		label: 'ПРОФЕСІОНАЛ КОРИДА',
	},
	{
		value: '200632',
		label: 'ТЕХНІК НАСТІЛЬНОГО ТЕНІСУ',
	},
	{
		value: '200634',
		label: 'СПОРТИВНИЙ ТРЕНЕР',
	},
	{
		value: '200640',
		label: 'АСТРОЛОГ',
	},
	{
		value: '200641',
		label: 'РАДНИК ПО РОБОТІ',
	},
	{
		value: '200646',
		label: 'КАСИР/КАСИР',
	},
	{
		value: '200648',
		label: 'ФІНАНСОВИЙ ПОСЕРЕДНИК, З ПОДОРОЖАМИ',
	},
	{
		value: '200649',
		label: 'НАЧАЛЬНИК СТАНЦІЇ',
	},
	{
		value: '200650',
		label: 'ІНСПЕКТОР МІСЬКИХ АВТОБУСІВ',
	},
	{
		value: '200651',
		label: 'РОЗПОРЯДНИК',
	},
	{
		value: '200652',
		label: 'РОЗСИЛЬНИЙ',
	},
	{
		value: '200655',
		label: 'ІНТЕРВЮЕР',
	},
	{
		value: '200658',
		label: 'ВОДІЙ ЧОВНА ДЛЯ ПАРАСЕЙЛІНГУ',
	},
	{
		value: '200665',
		label: 'ПРАЦІВНИК ЧУРЕРІЇ (CHURRERIA)',
	},
	{
		value: '200667',
		label: 'КОНСЬЄРЖ ',
	},
	{
		value: '200670',
		label: 'КОНСЬЄРЖ',
	},
	{
		value: '200674',
		label: 'ГІРСЬКИЙ ПРОВІДНИК (З АЛЬПІНІЗМОМ)',
	},
	{
		value: '200675',
		label: 'ПРАЦІВНИК ПОХОРОННОГО БЮРО',
	},
	{
		value: '200677',
		label: 'ФЕРМА, ПРИБИРАННЯ БУДИНКІВ',
	},
	{
		value: '200679',
		label: 'СПЕЦІАЛІСТ ПО ЗРОШЕННЮ',
	},
	{
		value: '200680',
		label: 'ВИРОБНИЦТВО ГАЗУ',
	},
	{
		value: '200681',
		label: 'ВИРОБНИЦТВО АНІЛІНУ',
	},
	{
		value: '200682',
		label: 'СПЕЦІАЛІСТ З ТЕКСТИЛЬНОГО ДРУКУ',
	},
	{
		value: '200685',
		label: 'СПЕЦІАЛІСТ З ГІДРОІЗОЛЯЦІЇ ФАСАДІВ ТА ДАХІВ',
	},
	{
		value: '200686',
		label: 'PASAMANERO A MANO',
	},
	{
		value: '200687',
		label: 'PASAMANERO A MAQUINA',
	},
	{
		value: '200688',
		label: 'ТКАЧ РУЧНОГО ТКАЦТВА',
	},
	{
		value: '200692',
		label: 'ПРАЦІВНИК ОЛІЙНОЇ ПРОМИСЛОВОСТІ',
	},
	{
		value: '200693',
		label: 'ПЕРЕРОБКА ПРОМИСЛОВИХ МАСТИЛ',
	},
	{
		value: '200694',
		label: 'ТЮТЮНОВА ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200695',
		label: 'СПЕЦІАЛІСТ ПО ВИГОТОВЛЕННЮ ВИРОБІВ З ХУТРА',
	},
	{
		value: '200696',
		label: 'ВИГОТОВЛЕННЯ ПАРАСОЛЬ ТА ТРОСТИН ',
	},
	{
		value: '200699',
		label: 'СПЕЦІАЛІСТ ПО ВИГОТОВЛЕННЮ ВИРОБІВ З ШКІРИ',
	},
	{
		value: '200700',
		label: 'ЛАКУВАЛЬНИК',
	},
	{
		value: '200702',
		label: 'ВИГОТОВЛЕННЯ НОЖІВ',
	},
	{
		value: '200704',
		label: 'ВИГОТОВЛЕННЯ КИШЕНЬКОВИХ НОЖІВ ',
	},
	{
		value: '200707',
		label: 'ВСТАНОВЛЕННЯ ПРОМИСЛОВИХ ХОЛОДИЛЬНИКІВ',
	},
	{
		value: '200710',
		label: 'РЕМОНТ ГОДИННИКІВ',
	},
	{
		value: '200711',
		label: 'МЕХАНІК ОРТОПЕДИЧНИХ АПАРАТІВ',
	},
	{
		value: '200713',
		label: 'МИТТЯ ТА ЗМАЩУВАННЯ АВТОМОБІЛІВ',
	},
	{
		value: '200720',
		label: 'ОПЕРАТОР З ЗВУКУ ТА ОСВІТЛЕННЯ',
	},
	{
		value: '200725',
		label: 'ВИРОБНИЦТВО ОЛОВА',
	},
	{
		value: '200729',
		label: 'СПЕЦІАЛІСТ З ВСТАВЛЕННЯ СТАЛЕВИХ КОНСТРУКЦІЙ',
	},
	{
		value: '200731',
		label: 'СЦЕНАРИСТ',
	},
	{
		value: '200732',
		label: 'ВСТАНОВЛЕННЯ ШИФЕРУ',
	},
	{
		value: '200734',
		label: 'КОВАЛЬ ДОРОГОЦІННИХ МЕТАЛІВ ( ЗОЛОТО І СРІБЛО)',
	},
	{
		value: '200736',
		label: 'ВИГОТОВЛЕННЯ ВИРОБІВ З ГУМИ',
	},
	{
		value: '200738',
		label: 'ВИРОБНИЦТВО ТА ЗВАРЮВАННЯ ПЛАСТИКУ',
	},
	{
		value: '200739',
		label: 'ВУЛКАНІЗАЦІЯ ШИН',
	},
	{
		value: '200740',
		label: 'ВИРОБНИЦТВО ЦЕЛУЛОЇДУ',
	},
	{
		value: '200741',
		label: 'ВИРОБНИЦТВО КАРТОНУ',
	},
	{
		value: '200742',
		label: 'ПРАЦІВНИК ФАБРИКИ З ВИГОТОВЛЕННЯ ПАПЕРУ ',
	},
	{
		value: '200744',
		label: 'ВИГОТОВЛЕННЯ ПАКУВАЛЬНИХ ЯЩИКІВ ',
	},
	{
		value: '200745',
		label: 'ФАХІВЕЦЬ З ЛІТОГРАФІЇ (ЛІТОГРАФ)',
	},
	{
		value: '200746',
		label: 'ПАЛІТУРНИК',
	},
	{
		value: '200748',
		label: 'ВИРОБНИЦТВО МУЗИКАЛЬНИК ІНСТРУМЕНТІВ',
	},
	{
		value: '200749',
		label: 'СПЕЦІАЛІСТ З НАЛАШТУВАННЯ МУЗИЧНИХ ІНСТРУМЕНТІВ',
	},
	{
		value: '200750',
		label: 'РЕМІСНИК (ВИГОТОВЛЕННЯ ТА ПРОДАЖ КОШИКІВ)',
	},
	{
		value: '200751',
		label: 'ІГРАШКИ, ПРОМИСЛОВІСТЬ',
	},
	{
		value: '200752',
		label: 'ВІСК, ВИРОБНИЦТВО ПРОДУКЦІЇ',
	},
	{
		value: '200753',
		label: 'ТАКСИДЕРМІСТ',
	},
	{
		value: '200754',
		label: 'МУЛЯР',
	},
	{
		value: '200756',
		label: 'ПЛИТОЧНИК',
	},
	{
		value: '200759',
		label: 'БУДІВЕЛЬНИЙ ПОКРІВЕЛЬНИК',
	},
	{
		value: '200761',
		label: 'ПОЛІРУВАЛЬНИК',
	},
	{
		value: '200764',
		label: 'ВАПНО, ВИРОБНИЦТВО',
	},
	{
		value: '200769',
		label: 'ОПЕРАТОР МАШИНИ РОЗЛИВУ НАПОЇВ',
	},
	{
		value: '200771',
		label: 'ПРИЙМАЛЬНИК МЕТАЛОБРУХТУ',
	},
	{
		value: '200773',
		label: 'ПРИБИРАННЯ ПОЛІВ',
	},
	{
		value: '200778',
		label: 'ГІРСЬКИЙ ПРОВІДНИК (БЕЗ АЛЬПІНІЗМУ)',
	},
	{
		value: '200779',
		label: 'ОРГАНІЗАТОР ПОЛЮВАННЯ ( ПРОФЕСІЙНИЙ МИСЛИВЕЦЬ) ',
	},
	{
		value: '200782',
		label: 'ЕНОЛОГ',
	},
	{
		value: '200783',
		label: 'ДОШКИ НА ЗАВОДІ, РОБІТНИК',
	},
	{
		value: '200785',
		label: 'ФАХІВЕЦЬ З МОНТАЖУ ТЕЛЕГРАФІЧНОГО ОБЛАДНАННЯ',
	},
	{
		value: '200787',
		label: 'АТРАКЦІОНИ НА ЯРМАРКАХ З ВСТАНОВЛЕННЯМ',
	},
	{
		value: '200788',
		label: 'АТРАКЦІОНИ НА ЯРМАРКАХ БЕЗ ВСТАНОВЛЕННЯ',
	},
	{
		value: '200789',
		label: 'ОПТИКА, ВИРОБНИЦТВО ОПТИЧНИХ ПРИЛАДІВ',
	},
	{
		value: '200794',
		label: 'ТОЙ ХТО ПРОБИВАЄ ТРУБИ АБО КОПАЄ КОЛОДЯЗІ',
	},
	{
		value: '200795',
		label: 'POCERO CON EXPLOSIVOS',
	},
	{
		value: '200797',
		label: 'ОПЕРАТОР КРЕМАТОРНОЇ ПЕЧІ',
	},
	{
		value: '200799',
		label: 'МЕР',
	},
	{
		value: '200802',
		label: 'ПОЛІЦІЯ КАТАЛОНІЇ',
	},
	{
		value: '200825',
		label: 'ПІЛОТ> СТАЖ РОКИ',
	},
	{
		value: '200826',
		label: 'ВОДІЙ ВАНТАЖІВКИ З ЛЕГКОЗАЙМИСТИМИ РЕЧОВИНАМИ',
	},
	{
		value: '200827',
		label: 'ВОДІЙ ВАНТАЖІВКИ З ТОКСИЧНИМИ АБО КОРОЗІЙНИМИ РЕЧОВИНАМИ ',
	},
	{
		value: '200828',
		label: 'ВОДІЙ ВАНТАЖІВКИ З ВИБУХОНЕБЕЗПЕЧНИМИ РЕЧОВИНАМИ',
	},
	{
		value: '200829',
		label: 'БЕЗРОБІТНИЙ',
	},
];
