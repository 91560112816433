import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import {
	InputCheck,
	NavigationWrapper,
	PickerHOC,
	UiWrapper,
	UserWrapper,
	Text,
	View,
	InternationalizationWrapper,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import BackNextButton from 'components/BackNextButton/BackNextButton';
import InputLabel from 'components/InputLabel/InputLabel';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import { provinces } from 'data/provinces';

import { useSendEmailContact } from 'libs/hooks/useSendEmailContact';
import isEmail from 'validator/lib/isEmail';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../../libs/hooks/useCoinscrapPageView';

export const CannotConfirm = PickerHOC(
	{
		user: {
			sourceWrapper: UserWrapper,
			sourcePath: 'user',
		},
	},
	LoadingScreen,
)(() => {
	const { openModal, useHeader, useLoading } = UiWrapper.use();
	const { goBack, query, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('CannotConfirm');
	const { updateUser } = UserWrapper.use();
	const [loading, setLoading] = useState();
	const { user } = PickerHOC.use();
	const sendContactEmail = useSendEmailContact(user.id);
	const { t, currentLang } = InternationalizationWrapper.use();
	// eslint-disable-next-line no-unused-vars
	const [isB2B, setIsB2B] = useState(
		localStorage.getItem('B2Flow') === 'B2B' ? true : false || false,
	);
	const [prevPage, setPrevPage] = useState(query?.from || 'No detectada');
	const [emailSent, setEmailSent] = useState(false);

	useEffect(() => {
		if (query?.from) {
			setPrevPage(query?.from);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query?.from]);

	// FORM
	const [name, setName] = useState(
		user?.name?.trim() === 'NEW_USER' ? '' : user?.name?.trim() || '',
	);
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [email, setEmail] = useState(user?.email?.trim() || '');
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [province, setProvince] = useState(user?.metadata?.province);
	const [provinceCode, setProvinceCode] = useState(
		provinces.find(item => item.label === user?.metadata?.province)?.value,
	);

	// CHECKS
	const [wantsInformation, setWantsInformation] = useState(user?.metadata?.acceptContact || false);
	const [privacityAccepted, setPrivacityAccepted] = useState(false);

	// NAVIGATION
	const back = async () => {
		CONFIG.routing.cannotConfirm.back(goBack);
	};

	const next = async () => {
		try {
			setLoading('miscellaneous.saving');
			await updateUser({
				username: {
					name,
					surname: user?.surname || '',
				},
				email,
				phoneNumber,
				metadata: {
					...user?.metadata,
					province,
					acceptContact: wantsInformation,
				},
			});

			setLoading('miscellaneous.sending');
			const screenNames = {
				SALUD: 'Preguntas de salud',
				PBC: 'Preguntas de blanqueo de capitales',
				SIGN_CONTRACT: 'Firma del contrato',
				SIMULATION_DETAILS: 'Detalles previos a la simulación',
				CALL: 'Solicita una llamada',
				DNI: 'Documento de identidad',
				NO_ESP_DOC: 'Documento de identidad no español',
			};
			const paginaPrevia = screenNames[prevPage];
			await sendContactEmail(name, phoneNumber, email, paginaPrevia);
			setEmailSent(true);

			openModal(
				() => (
					<ErrorModal>
						<Text headingPrimarySt>Solicitud realizada</Text>
					</ErrorModal>
				),
				{
					disableClickAway: true,
					closeButton: true,
					modalClass: isMobile ? 'modal-xl' : 'modal-main',
					key: 'modal-cannot-confirm',
				},
			);
			setLoading(undefined);
		} catch (e) {
			console.error('ERROR', e);
			let messageError = e?.message?.toString();
			messageError = t('miscellaneous.temporalError');
			setLoading(undefined);
			openModal(<ErrorModal>{messageError}</ErrorModal>);
		}
	};

	const isDisabled = () => {
		return emailSent || !name || !phoneNumber || !email || !province || !privacityAccepted;
	};

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={isMobile ? back : undefined} mobile={isMobile} isB2B={false} />, []);

	return (
		<Wrapper width={'70%'}>
			<Text headingPrimarySt isB2B>
				{isB2B
					? t('cannotConfirm.weCallYou')
					: prevPage === 'PBC'
					? t('cannotConfirm.weSorry')
					: prevPage === 'SALUD'
					? t('cannotConfirm.weSorryHealth')
					: prevPage === 'CALL'
					? t('landing.features.callUs.title')
					: t('cannotConfirm.weSorry')}
			</Text>
			<Text headingSecondarySt isB2B>
				{t('landing.features.callUs.description')}
			</Text>
			<View inputRowSt>
				<InputLabel
					value={name}
					onChange={e => {
						setName(e.target.value);
					}}
					label={t('landing.features.callUs.name')}
				/>
				<InputLabel
					onBlur={() => {
						setIsEmailCorrect(isEmail(email));
					}}
					value={email}
					onChange={e => {
						if (!isEmailCorrect) {
							setIsEmailCorrect(isEmail(e.target.value));
						}
						setEmail(e.target.value);
					}}
					label={t('landing.features.callUs.email')}
					errorMsg={!isEmailCorrect && t('miscellaneous.wrongEmail')}
				/>
			</View>
			<View inputRowSt>
				<InputLabel
					value={phoneNumber}
					onChange={value => {
						setPhoneNumber(value);
					}}
					label={t('landing.features.callUs.phone')}
					type='phone'
				/>
				<InputLabel
					value={provinceCode || 0}
					onChange={e => {
						setProvince(provinces.find(item => item.value === e.target.value)?.label);
						setProvinceCode(e.target.value);
					}}
					label={t('landing.features.callUs.city')}
					type='select'
					selectOptions={provinces.map(item => {
						return {
							label: item.label,
							value: item.value,
						};
					})}
				/>
			</View>
			{!isB2B && <Text labelInputSt>{t('landing.features.callUs.mandatory')}</Text>}

			<View
				conditionsWrapperSt
				marginTopSt
				onClick={() => {
					setWantsInformation(!wantsInformation);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={wantsInformation} />
				<Text marginLefConditiontSt>
					<S.ConditionsTextSpan>{t('landing.features.callUs.iWant')}</S.ConditionsTextSpan>{' '}
					{t('landing.features.callUs.iWant2')}
				</Text>
			</View>

			<View
				conditionsWrapperSt
				onClick={() => {
					setPrivacityAccepted(!privacityAccepted);
				}}
			>
				<InputCheck checkedIcon={<CheckBoxOutlinedIcon />} value={privacityAccepted} />
				<Text marginLefConditiontSt>
					<S.ConditionsTextSpan>{t('landing.features.callUs.iConfirm')} </S.ConditionsTextSpan>{' '}
					{t('landing.features.callUs.iConfirm2')}
				</Text>
			</View>

			<BackNextButton
				onClickBack={back}
				onClickNext={next}
				nextText={t('buttons.sent')}
				isBackShown={!isMobile}
				isNextDisabled={isDisabled()}
				justifyContent={'evenly'}
			/>
		</Wrapper>
	);
});
