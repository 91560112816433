export const alcoholTypes = [
	{
		name: 'Jerez',
		value: 'Jerez',
	},
	{
		name: 'Vino',
		value: 'Vino',
	},
	{
		name: 'Cerveza',
		value: 'Cerveza',
	},
	{
		name: 'Whisky',
		value: 'Whisky',
	},
	{
		name: 'Licor',
		value: 'Licor',
	},
];
