import React, { useEffect, useState } from 'react';

import {
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	AnalyticsWrapper,
	InternationalizationWrapper,
} from 'coinscrap-webapp-core';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import Header from 'layout/components/Header/Header';
import { Wrapper } from 'layout/Wrapper';

import { ErrorModal } from 'components/ErrorModal/ErrorModal';
import { isMobile } from 'react-device-detect';
import { useCoinscrapPageView } from '../../libs/hooks/useCoinscrapPageView';

export const ProductCreationFinishedManual = PickerHOC(
	{},
	LoadingScreen,
)(() => {
	const { goBack, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	useCoinscrapPageView('ProductCreationFinishedManual');
	const { useHeader, useLoading, openModal } = UiWrapper.use();
	const [loading, setLoading] = useState();
	const { currentLang } = InternationalizationWrapper.use();

	const next = async () => {
		let messageError = 'El proceso ha terminado, puedes cerrar la ventana.';
		setLoading(undefined);
		openModal(() => <ErrorModal>{messageError}</ErrorModal>, {
			disableClickAway: true,
			closeButton: true,
			modalClass: isMobile ? 'modal-xl' : 'modal-main',
		});
	};

	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	useLoading(loading, { loadingMessage: loading });

	return (
		<Wrapper>
			<Text headingPrimarySt style={{ marginBottom: '40px' }}>
				Crearemos tu contrato en diferido
			</Text>
			<div style={{ width: '80%' }}>
				<Text headingSecondarySt style={{ marginBottom: 25, textAlign: 'left' }}>
					Recibirás un correo electrónico donde podrás firmar los documentos de tu póliza.
				</Text>
				<Text headingSecondarySt style={{ marginBottom: 25, textAlign: 'left' }}>
					Dispones de un plazo de 30 días naturales, a contar desde la fecha de contratación, para
					desistir del presente seguro de vida, en cuyo caso se te reintegrará el importe de la
					prima cobrada sin aplicación de penalización alguna.
				</Text>
				<Text headingSecondarySt style={{ marginBottom: 25, textAlign: 'left' }}>
					Para ello, puedes llamar al <a href='tel:+34913427859'>913 427 859</a>
				</Text>
			</div>
			<BackNextButton
				onClickNext={next}
				nextText={'FINALIZAR'}
				isBackShown={false}
				isNextDisabled={false}
			/>
		</Wrapper>
	);
});
