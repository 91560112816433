import React from 'react';

import * as S from './styles';

import { View } from 'coinscrap-webapp-core';
import { Button } from 'coinscrap-webapp-core';
import { isMobile } from 'react-device-detect';

import rigthArrowBlue from 'assets/images/svgs/rigthArrowBlue.svg';
import leftArrowBlue from 'assets/images/svgs/leftArrowBlue.svg';

const BackNextButton = ({
	marginTop = isMobile ? '20px' : '50px',
	marginBottom = isMobile ? '20px' : '50px',
	marginBetween = isMobile ? '10px' : '30px',
	onClickBack = () => console.log('BACK'),
	onClickNext = () => console.log('NEXT'),
	isBackDisabled = false,
	isNextDisabled = false,
	backText = 'buttons.back',
	nextText = 'buttons.next',
	backWidth = 'initial',
	nextWidth = 'initial',
	isBackShown = true,
	isNextShown = true,
	//Los loaders mejor que sean undefined por defecto para que los gestione el boton si el usuario no especifica false o true
	isBackLoading,
	isNextLoading,
	isBackClickable = true,
	isNextClickable = true,
	justifyContent = 'between',
	hasIcon = true,
	isBackSelected = false,
	isNextSelected = false,
}) => {
	// const backCursor = isBackDisabled ? 'not-allowed' : 'pointer';
	// const nextCursor = isNextDisabled ? 'not-allowed' : 'pointer';

	if (!isBackShown || !isNextShown) {
		justifyContent = 'center';
	}

	return (
		<View
			rowSt
			itemsCenterSt
			justifyBetweenSt={justifyContent === 'between' ? true : false}
			justifyEndSt={justifyContent === 'end' ? true : false}
			justifyStartSt={justifyContent === 'start' ? true : false}
			justifyEvenlySt={justifyContent === 'evenly' ? true : false}
			wInitialSt
			style={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				width: '100%',
				maxWidth: '450px',
			}}
		>
			{isBackShown && (
				<View backNextWrapperSt style={{ width: backWidth }}>
					<Button
						backSt
						selectedSt={isBackSelected}
						disabled={isBackDisabled}
						loading={isBackLoading}
						onClick={() => isBackClickable && onClickBack()}
						icon={
							hasIcon ? (
								<S.ArrowIcon src={leftArrowBlue} alt={'left arrow'} marginRight={'20px'} />
							) : null
						}
						iconPosition='left'
						id={backText}
						label={backText}
					/>
				</View>
			)}
			{isNextShown && (
				<View backNextWrapperSt style={{ width: nextWidth }}>
					<Button
						nextSt
						selectedSt={isNextSelected}
						disabled={isNextDisabled}
						loading={isNextLoading}
						onClick={isNextClickable && onClickNext}
						icon={
							hasIcon ? (
								<S.ArrowIcon src={rigthArrowBlue} alt={'rigth arrow'} marginLeft={'20px'} />
							) : null
						}
						iconPosition='right'
						id={nextText}
						label={nextText}
					/>
				</View>
			)}
		</View>
	);
};

export default BackNextButton;
