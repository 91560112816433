import { BackendWrapper } from 'coinscrap-webapp-core';
import { useCallback } from 'react';

export const useGetAllVinculations = () => {
	const { getInstanceApi } = BackendWrapper.use();
	const apiInstance = getInstanceApi();
	const vinculations = useCallback(async () => {
		if (apiInstance) {
			const vinculations = await apiInstance.microserviceCall('Us')(
				`/vinculations?filter=${encodeURIComponent(
					JSON.stringify({
						fields: ['id', 'mainUserId', 'secondaryUserId'],
						order: 'createDate DESC',
					}),
				)}`,
				'GET',
				{},
			);
			//console.log(vinculations);
			return vinculations;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return vinculations;
};
