import React, { useEffect } from 'react';

import {
	AnalyticsWrapper,
	NavigationWrapper,
	InternationalizationWrapper,
} from 'coinscrap-webapp-core';

export const useCoinscrapPageView = name => {
	const { pageView } = AnalyticsWrapper.use();
	const { currentLang } = InternationalizationWrapper.use();
	const { params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();

	const coinscrapPageView = async name => {
		try {
			await pageView({
				date: new Date(),
				name,
				route: getCurrentRoute(),
				params,
				lang: currentLang || 'es',
				prevRoutes,
			});
		} catch (error) {
			console.log('❌ coinscrapPageView error', error);
		}
	};

	useEffect(() => {
		coinscrapPageView(name);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return coinscrapPageView;
};
