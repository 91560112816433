import { CONFIG } from 'config/config';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputDate', {
	styles: {
		default: {
			containerComponent: {
				border: '1px solid #B1B1B1',
				borderRadius: '1px',
				height: '50px',
				color: '#30353F',
				fontSize: '18px',
				padding: '25px 18px',
				background: 'white',
				display: 'flex',
				justifyContent: 'center',
				marginTop: 0,
			},

			inputContainerComponent: {
				border: 'none',
				boxSizing: 'border-box',
				background: 'white',
			},
			inputComponent: {
				boxSizing: 'border-box',
				paddingRight: 0,
				fontFamily: CONFIG.fonts.regular,
				color: '#30353F',
				fontSize: '18px',
				paddingBottom: 20,
				background: 'white',
			},
			iconContainerComponent: {
				padding: 0,
				height: 48,
				width: 26,
				background: 'white',
			},
			dayComponent: {
				color: CONFIG.colors.tertiary,
				fontFamily: CONFIG.fonts.regular,
			},

			daySelectedComponent: {
				backgroundColor: CONFIG.colors.primaryLighter,
				color: CONFIG.colors.white,
			},
			actionTextComponent: {
				// display: 'none',
				fontFamily: CONFIG.fonts.regular,
			},
		},
		fullWidthSt: {
			containerComponent: {
				width: '100%',
			},
		},

		filledMode: {
			inputContainerComponent: {
				background: 'white',
			},
		},
		dateWithBorderSt: {
			inputContainerComponent: {
				backgroundColor: '#FAFBFC',
				borderBottom: `1px solid ${CONFIG.colors.red}`,
				padding: 0,
				paddingRight: 6,
			},
			containerComponent: {
				height: '25px',
				border: 'none',
				borderRadius: 0,
				boxSizing: 'border-box',
				padding: 0,
				marginTop: 0,
				marginBottom: 0,
				borderBottom: 'none',
				width: '100%',
				color: 'black',
				backgroundColor: '#FAFBFC',
				display: 'flex',
			},
			inputComponent: {
				// paddingBottom: '25px',
				boxSizing: 'border-box',
				paddingRight: 0,
				height: '100%',
				// paddingLeft: 5,
				fontSize: 12,
				color: 'black',
				lineHeight: '20px',
				fontFamily: CONFIG.fonts.regular,
				padding: 0,
				paddingBottom: 0,
			},
			labelComponent: {
				color: '#727272',
				fontFamily: CONFIG.fonts.regular,
				fontWeight: 'normal',
				marginTop: '-2px',
				padding: 0,
				fontSize: 17,
			},
			iconContainerComponent: {
				height: '25px',
			},
			iconComponent: {
				padding: 0,

				height: '25px',
				boxSizing: 'content-box',
				width: '24px',
			},
		},
	},
});
