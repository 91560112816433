import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const ArrowIcon = styled.img`
	height: 18px;
	width: 20px;
	color: ${CONFIG.colors.primary};
	flex: 0.2 1 auto;
	align-self: center;
	margin-left: ${props => props.marginLeft || '0px'};
	margin-right: ${props => props.marginRight || '0px'};
	vertical-align: bottom;
	order: 0;
`;
