import React, { useState } from 'react';
import { View, Button, Text, InternationalizationWrapper } from 'coinscrap-webapp-core';

import InputLabel from 'components/InputLabel/InputLabel';
import '../../config/components/modal.css';

import { isMobile } from 'react-device-detect';

const BeneficiaryModal = ({ close, beneficiaries, setBeneficiaries, beneficiary = undefined }) => {
	let availablePercentage = 0;

	const totalPercentage = beneficiary
		? beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0) - beneficiary.percentage
		: beneficiaries.reduce(function (acc, ben) {
				return acc + ben.percentage;
		  }, 0);
	//console.log(totalPercentage);

	availablePercentage = 100 - totalPercentage;
	//console.log(availablePercentage);

	const [state, setState] = useState(
		beneficiary || {
			name: '',
			surname: '',
			dni: '',
			percentage: availablePercentage,
		},
	);

	const [percentageInput, setPercentageInput] = useState(
		state?.percentage ? `${state.percentage} %` : '',
	);

	// CHECKS
	// eslint-disable-next-line no-unused-vars
	const [isValidDNI, setIsValidDNI] = useState(true);
	const [existsDNI, setExistsDNI] = useState(true);
	const [invalidDNIreason, setInvalidDNIreason] = useState('DNI inválido');
	const [isValidPercentage, setIsValidPercentage] = useState(true);
	const { t } = InternationalizationWrapper.use();

	const isDisabled = () => {
		return (
			!state?.name ||
			!state?.surname ||
			!state?.dni ||
			!state?.percentage ||
			!isValidDNI ||
			!existsDNI ||
			!isValidPercentage
		);
	};

	const noAnyadido = dni => {
		if (beneficiaries.some(ben => ben.dni === dni)) {
			setExistsDNI(false);
			setInvalidDNIreason(t('miscellaneous.dniAlredyExists'));
			return false;
		}
		setExistsDNI(true);
	};

	return (
		<View
			style={{
				padding: isMobile ? 28 : 20,
				background: 'white',
			}}
		>
			<Text headingPrimarySt>{t('clientData.beneficiaryModal.addBeneficiary')}</Text>
			<View inputRowSt>
				<InputLabel
					value={state?.name || ''}
					onChange={e => {
						setState({ ...state, name: e.target.value });
					}}
					label={t('clientData.beneficiaryModal.inputName')}
					styleLabel={{ color: '#002C90' }}
				/>
				<InputLabel
					value={state?.surname || ''}
					onChange={e => {
						setState({ ...state, surname: e.target.value });
					}}
					label={t('clientData.beneficiaryModal.inputSurname')}
					styleLabel={{ color: '#002C90' }}
				/>
				<InputLabel
					value={state?.dni || ''}
					onChange={e => {
						setState({ ...state, dni: e.target.value });
						noAnyadido(e.target.value);
					}}
					label={t('clientData.beneficiaryModal.inputDNI')}
					styleLabel={{ color: '#002C90' }}
					errorMsg={!existsDNI && invalidDNIreason}
				/>
				<InputLabel
					value={percentageInput || ''}
					onChange={e => {
						if (!isValidPercentage) {
							setIsValidPercentage(
								parseFloat(e.target.value) <= availablePercentage && e.target.value > 0,
							);
						}
						if (e.target.value === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({
								...state,
								percentage: e.target.value.replace(/\D/g, ''),
							});
							setPercentageInput(e.target.value.replace(/\D/g, ''));
						}
					}}
					onBlur={() => {
						setIsValidPercentage(state?.percentage <= availablePercentage && state?.percentage > 0);
						if (state.percentage === '') {
							setState({ ...state, percentage: 0 });
							setPercentageInput('');
						} else {
							setState({ ...state, percentage: state.percentage });
							setPercentageInput(`${state.percentage} %`);
						}
					}}
					label={t('clientData.beneficiaryModal.inputPercentage')}
					styleLabel={{ color: '#002C90' }}
					errorMsg={
						!isValidPercentage && `${t('miscellaneous.maximumQuantity')}: ${availablePercentage} %`
					}
				/>
			</View>
			<View inputRowSt marginTopSt fullWidthMobileSt>
				<Button
					smallSt
					onClick={() => {
						close();
					}}
				>
					{t('miscellaneous.close')}
				</Button>
				<Button
					smallSt
					disabled={isDisabled()}
					onClick={() => {
						console.log(beneficiaries);
						const newBen = {
							source: 'designed',
							name: state.name,
							surname: state.surname,
							dni: state.dni,
							percentage: parseFloat(state.percentage),
						};
						if (beneficiary) {
							const benAmodificar = beneficiaries.find(ben => ben.dni === newBen.dni);
							const index = beneficiaries.indexOf(benAmodificar);
							beneficiaries[index] = newBen;
						} else {
							beneficiaries.push(newBen);
						}
						setBeneficiaries(beneficiaries);
						close();
					}}
				>
					{beneficiary ? t('miscellaneous.update') : t('miscellaneous.add')}
				</Button>
			</View>
		</View>
	);
};

export default BeneficiaryModal;
