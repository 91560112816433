import { CONFIG } from 'config/config';
import React, { useState } from 'react';
import * as S from './styles';

import {
	NavigationWrapper,
	Text,
	View,
	Button,
	InternationalizationWrapper,
} from 'coinscrap-webapp-core';

import { Icon } from 'components/Icon/Icon';

import logoCaser from 'assets/images/svgs/logoCaser.svg';
import logoCKSegurSvg from 'assets/images/svgs/logoCKHD.svg';
import logoCKSegurSvgMobile from 'assets/images/svgs/logoMobile.svg';

import BackNextButton from 'components/BackNextButton/BackNextButton';
import InputLabel from 'components/InputLabel/InputLabel';
import { isMobile } from 'react-device-detect';

const Header = ({ goBack, mobile, isB2B, showButton = true, showLogoCaser = false }) => {
	const { navigateTo } = NavigationWrapper.use();
	const { t, changeLang, currentLang } = InternationalizationWrapper.use();
	const [language, setLanguage] = useState(
		currentLang === 'es-ES' ? 'es' : currentLang === 'es-CA' ? 'ca' : currentLang || 'es',
	);

	const listLanguages = {
		es: { nativeName: t('miscellaneous.spanish') },
		ru: { nativeName: t('miscellaneous.russian') },
		ua: { nativeName: t('miscellaneous.ukrainian') },
	};

	return (
		<View headerSt>
			<div
				className={'logos'}
				style={{ alignItems: 'center', display: 'flex', gap: showLogoCaser ? '12px' : '15px' }}
			>
				{isMobile ? (
					<S.Logo
						alt='CK Segur logo'
						src={logoCKSegurSvgMobile}
						style={{ width: showLogoCaser ? '110px' : '100%' }}
						onClick={() => CONFIG.routing.landing.reLogin(navigateTo)}
					/>
				) : (
					<S.Logo
						alt='CK Segur logo'
						src={logoCKSegurSvg}
						style={{ width: '100%' }}
						onClick={() => CONFIG.routing.landing.reLogin(navigateTo)}
					/>
				)}
				{showLogoCaser && <S.Logo alt='CK Caser logo' src={logoCaser} style={{ width: '70px' }} />}
			</div>
			{!showButton && <Text />}
			{isB2B && showButton && (
				<BackNextButton
					onClickNext={() => CONFIG.routing.header.back(navigateTo)}
					onClickBack={false}
					isBackShown={false}
					isBackDisabled={false}
					nextText={'Dashboard'}
					marginBottom={0}
					marginTop={0}
				/>
			)}
			{!isB2B && !mobile && showButton && (
				<div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
					<InputLabel
						style={{ color: CONFIG.colors.tertiary, border: 0, minWidth: 115 }}
						value={language || 'es'}
						onChange={e => {
							setLanguage(e.target.value);
							changeLang(e.target.value);
						}}
						customTextLabel={true}
						showLabel
						showHeadingOptionsList={false}
						label={t('miscellaneous.language')}
						type='selectCountry'
						selectOptions={Object.keys(listLanguages).map(item => {
							return {
								label: listLanguages[item].nativeName,
								value: item,
								alpha2: item,
							};
						})}
					/>
					<Button
						headerSt
						onClick={() => {
							CONFIG.routing.header.ko(navigateTo);
						}}
						id='landing.features.callToAction.buttonAction'
						label='landing.features.callToAction.buttonAction'
					></Button>
				</div>
			)}
			{!isB2B && mobile && showButton && (
				<S.MobileHeaderWrapper style={{ display: 'flex', alignItems: 'center' }}>
					<InputLabel
						style={{ color: CONFIG.colors.tertiary, border: 0, minWidth: 115 }}
						value={language || 'es'}
						onChange={e => {
							setLanguage(e.target.value);
							changeLang(e.target.value);
						}}
						customTextLabel={true}
						showLabel
						showHeadingOptionsList={false}
						label={t('miscellaneous.language')}
						type='selectCountry'
						selectOptions={Object.keys(listLanguages).map(item => {
							return {
								label: listLanguages[item].nativeName,
								value: item,
								alpha2: item,
							};
						})}
					/>
					<Icon
						onClick={() => CONFIG.routing.header.ko(navigateTo)}
						color={'#002C90'}
						icon={`las la-phone la-2x`}
						className={`icon__logo--phone`}
					/>
				</S.MobileHeaderWrapper>
			)}
		</View>
	);
};

export default Header;
