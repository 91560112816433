import { CONFIG } from 'config/config';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import Rating from '@material-ui/lab/Rating';
import {
	NavigationWrapper,
	UiWrapper,
	SessionWrapper,
	InternationalizationWrapper,
	Var,
	UserWrapper,
	PickerHOC,
	AnalyticsWrapper,
} from 'coinscrap-webapp-core';

import { ReviewProduct } from 'components/ReviewProduct/ReviewProduct';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import capitalIcon from 'assets/images/svgs/capital.svg';
import deceaseIcon from 'assets/images/svgs/decease.svg';
import documentsIcon from 'assets/images/svgs/documents.svg';
import heartRateIcon from 'assets/images/svgs/heartRating.svg';
import homeIcon from 'assets/images/svgs/home.svg';
import homeFamilyIcon from 'assets/images/svgs/homeFamily.svg';
import incapacityIcon from 'assets/images/svgs/incapacity.svg';
import paperlessIcon from 'assets/images/svgs/paperless.svg';
import repatriationIcon from 'assets/images/svgs/repatriation.svg';
import subHeader from 'assets/images/pngs/subHeader.png';

import { Wrapper } from 'layout/Wrapper';

import { Icon } from 'components/Icon/Icon';

import { isMobile } from 'react-device-detect';

import Header from 'layout/components/Header/Header';

export const Landing = PickerHOC(
	{},
	LoadingScreen,
)(() => {
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, params, routes: prevRoutes, getCurrentRoute } = NavigationWrapper.use();
	const { transports, pageView } = AnalyticsWrapper.use();

	const { sessionData, sessionStatus } = SessionWrapper.use();
	const { user, loadSessionUser } = UserWrapper.use();
	const { currentLang, t } = InternationalizationWrapper.use();
	const [loading, setLoading] = useState('miscellaneous.loggingOnto');

	useLoading(loading, { loadingMessage: loading });
	useHeader(<Header goBack={undefined} mobile={isMobile} isB2B={false} />, []);

	const FAQ = [
		t('landing.features.faq.list'),
		t('landing.features.faq.list2'),
		t('landing.features.faq.list3'),
		t('landing.features.faq.list4'),
		t('landing.features.faq.list5'),
	];

	const listItems = FAQ.map((item, idx) => {
		return (
			<S.TextSecondary>
				<li key={item[idx]}>{item}</li>
			</S.TextSecondary>
		);
	});

	useEffect(() => {
		// console.log('📌 sessionData', sessionData);
		// console.log('📌 sessionStatus', sessionStatus);
		console.log('📌 user', user);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const removeLoading = async () => {
			try {
				await new Promise(r => setTimeout(r, 5000));
				setLoading(undefined);
			} catch (error) {
				console.log('❌ error', error);
			}
		};
		removeLoading();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!sessionData || sessionStatus === 'NOT_CREATED') {
			CONFIG.routing.landing.reLogin(navigateTo);
		} else if (!user) {
			(async () => {
				await loadSessionUser();
			})();
		} else {
			const lastEngine = transports[transports?.length - 1 || 0]?.engine || '';
			// ESPERAMOS QUE SE HAYAN CARGADO TODOS LOS ANALYTICS
			if (user?.metadata?.analytics[lastEngine]) {
				setLoading(undefined);

				(async () => {
					try {
						await pageView({
							date: new Date(),
							name: 'Landing',
							route: getCurrentRoute(),
							params,
							lang: currentLang,
							prevRoutes,
						});
					} catch (error) {
						console.log('❌ error', error);
					}
				})();
			}
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionData, sessionStatus, user]);

	return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<S.SubHeaderImage src={subHeader} />
				<S.TextPrimary size={'42px'} style={{ margin: '30px 20px 0px 20px' }} textAlign={'center'}>
					{t('landing.title')}
				</S.TextPrimary>
				<S.TextPrimary
					size={'18px'}
					mainColor={'#333333'}
					style={{ margin: '5px 20px 30px 20px' }}
					textAlign={'center'}
				>
					{t('landing.titleDescription')}
				</S.TextPrimary>
				<S.LandingFeatures>
					<div className='container'>
						<div
							className='wrapper-feature'
							style={{ gap: currentLang !== 'es' ? '5px 0px' : '15px 0px' }}
						>
							<S.HeaderPrimary>{t('landing.features.feature.title1')}</S.HeaderPrimary>
							<div className='wrapper-feature-explanation'>
								<S.TextSecondary>{t('landing.features.feature.description1')}</S.TextSecondary>
							</div>
						</div>

						<div className='wrapper-feature-secondary'>
							<S.HeaderPrimary>{t('landing.features.feature.title2')}</S.HeaderPrimary>
							<div className='wrapper-feature-secondary-explanation'>
								<S.TextSecondary>{t('landing.features.feature.description2')}</S.TextSecondary>
							</div>
						</div>

						<div className='wrapper-feature-articles'>
							<div className='wrapper-feature-flex'>
								<S.HeaderPrimary>{t('landing.features.feature.title3')}</S.HeaderPrimary>
							</div>
							<div className='feature-articles'>
								<div className='feature-paperless'>
									<img alt={'no papers needed'} src={paperlessIcon} />
									<div className='feature-paperless-title'>
										<S.TextFeaturesSecondary lang={currentLang}>
											{t('landing.features.feature.titleIcon')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-paperless-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature.titleIconDescription')}
									</S.TextSecondary>
								</div>
								<div className='feature-documents'>
									<img alt={'documents'} src={documentsIcon} />
									<div className='feature-documents-title'>
										<S.TextFeaturesSecondary
											fontSizeLang={currentLang}
											textAlign={'center'}
											lang={currentLang}
										>
											{t('landing.features.feature.titleIcon2')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-documets-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature.titleIconDescription2')}
									</S.TextSecondary>
								</div>
								<div className='feature-capital'>
									<img alt={'capital'} src={capitalIcon} />
									<div className='feature-capital-title'>
										<S.TextFeaturesSecondary fontSizeLang={currentLang} lang={currentLang}>
											{t('landing.features.feature.titleIcon3')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-capital-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature.titleIconDescription3')}
									</S.TextSecondary>
								</div>
							</div>
						</div>

						<div className='wrapper-feature-articles-secondary'>
							<div className='wrapper-feature-flex'>
								<S.HeaderPrimary>
									<Var>{t('landing.features.feature2.title')}</Var>
								</S.HeaderPrimary>
							</div>
							<div className='wrapper-feature-articles-secondary-description'>
								<S.TextSecondary lang={currentLang}>
									{t('landing.features.feature2.description')}
								</S.TextSecondary>
							</div>
							<div className='feature-articles-secondary'>
								<div className='feature-decease'>
									<img alt={'decease'} src={deceaseIcon} />
									<div className='feature-decease-title'>
										<S.TextFeaturesSecondary lang={currentLang} fontSizeLang={'es'}>
											{t('landing.features.feature2.titleIcon')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-incapacity'>
									<img alt={'permanent and absolute incapacity'} src={incapacityIcon} />
									<div className='feature-incapacity-title'>
										<S.TextFeaturesSecondary
											textAlign={'center'}
											lang={currentLang}
											fontSizeLang={'es'}
										>
											{t('landing.features.feature2.titleIcon2')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-repatriation'>
									<img alt={'repatriation'} src={repatriationIcon} />
									<div className='feature-repatriation-title'>
										<S.TextFeaturesSecondary lang={currentLang} fontSizeLang={'es'}>
											{' '}
											{t('landing.features.feature2.titleIcon3')}
										</S.TextFeaturesSecondary>
									</div>
								</div>
								<div className='feature-decease-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature2.titleIconDescription')}
									</S.TextSecondary>
								</div>
								<div className='feature-incapacity-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature2.titleIconDescription2')}
									</S.TextSecondary>
								</div>
								<div className='feature-repatriation-description'>
									<S.TextSecondary textAlign={'center'} lang={currentLang}>
										{t('landing.features.feature2.titleIconDescription3')}
									</S.TextSecondary>
								</div>
							</div>
						</div>

						<div className='wrapper-feature-articles-terciary'>
							<div className='wrapper-feature-flex'>
								<S.HeaderPrimary> {t('landing.features.feature3.title')}</S.HeaderPrimary>
							</div>
							<div className='titles-feature-articles-terciary-description'>
								<S.TextSecondary lang={currentLang}>
									{t('landing.features.feature3.description')}
								</S.TextSecondary>
							</div>
							<div className='feature-articles-terciary'>
								<div className='feature-home-family'>
									<img alt={'life insurance for your family'} src={homeFamilyIcon} />
								</div>
								<div className='feature-heart-rate'>
									<img alt={'heart rate'} src={heartRateIcon} />
								</div>
								<div className='feature-home'>
									<img alt={'insured capital'} src={homeIcon} />
								</div>
								<div className='feature-home-family-description'>
									<S.TextSecondary lang={currentLang} textAlign={'center'}>
										{t('landing.features.feature3.titleIconDescription')}
									</S.TextSecondary>
								</div>
								<div className='feature-heart-rate-description'>
									<S.TextSecondary lang={currentLang} textAlign={'center'}>
										{t('landing.features.feature3.titleIconDescription2')}
									</S.TextSecondary>
								</div>
								<div className='feature-home-description'>
									<S.TextSecondary lang={currentLang} textAlign={'center'}>
										{t('landing.features.feature3.titleIconDescription3')}
									</S.TextSecondary>
								</div>
							</div>
						</div>
						<div className='wrapper-feature-text'>
							<S.TextPrimary textAlign={'center'}>
								{t('landing.features.feature4.title')}
							</S.TextPrimary>
						</div>
					</div>
				</S.LandingFeatures>
				<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
					<S.CallToTest>
						<div style={{ alignItems: 'center', display: 'flex', gap: '50px' }}>
							<S.CallToTestTitle>{t('landing.features.callToAction.title')}</S.CallToTestTitle>
							<div>
								<svg
									width='43'
									height='43'
									viewBox='0 0 53 53'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									style={{ position: 'relative' }}
								>
									<path
										d='M53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM11.8416 26.5C11.8416 34.5956 18.4044 41.1584 26.5 41.1584C34.5956 41.1584 41.1584 34.5956 41.1584 26.5C41.1584 18.4044 34.5956 11.8416 26.5 11.8416C18.4044 11.8416 11.8416 18.4044 11.8416 26.5Z'
										fill='white'
									/>
								</svg>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='43'
									height='43'
									viewBox='0 0 53 53'
									fill='none'
									style={{ position: 'relative', top: '10px', right: '24px' }}
								>
									<path
										d='M53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM11.8416 26.5C11.8416 34.5956 18.4044 41.1584 26.5 41.1584C34.5956 41.1584 41.1584 34.5956 41.1584 26.5C41.1584 18.4044 34.5956 11.8416 26.5 11.8416C18.4044 11.8416 11.8416 18.4044 11.8416 26.5Z'
										fill='#B7E9FF'
									/>
								</svg>
							</div>
						</div>
						<S.CallToTestFeatures>
							<S.CallToTestDescription>
								{t('landing.features.callToAction.description')}
								<br />
								{t('landing.features.callToAction.description2')}
							</S.CallToTestDescription>
							<S.CallToTestButtons>
								<S.CallToTestCompleteButton onClick={() => CONFIG.routing.landing.ko(navigateTo)}>
									<S.CallToTestButton>
										{t('landing.features.callToAction.buttonAction')}
									</S.CallToTestButton>
									<Icon icon={`las la-arrow-right la-2x`} color={'#002C90'} />
								</S.CallToTestCompleteButton>
								<S.CallToTestCompleteButton onClick={() => CONFIG.routing.landing.next(navigateTo)}>
									<S.CallToTestButton>
										{t('landing.features.callToAction.buttonAction2')}
									</S.CallToTestButton>
									<Icon icon={`las la-arrow-right la-2x`} color={'#002C90'} />
								</S.CallToTestCompleteButton>
							</S.CallToTestButtons>
						</S.CallToTestFeatures>
						<S.CallToUsSection lang={currentLang}>
							<S.CallToTestContact>
								<S.CallToTestContactUs maxWidthLang={currentLang}>
									<Icon icon={`las la-phone la-2x`} />
									<a href='tel:+34913427859'>
										<S.CallToTestContactPhone style={{ fontFamily: 'Montserrat-Bold' }}>
											{t('landing.features.callToAction.callUs')}
										</S.CallToTestContactPhone>
									</a>
									<a href='tel:+34913427859'>
										<S.CallToTestContactPhone>
											{t('landing.features.callToAction.callUs2')}
										</S.CallToTestContactPhone>
									</a>
								</S.CallToTestContactUs>
								<S.CallToTestContactUs maxWidthLang={currentLang}>
									<Icon icon={`las la-envelope la-2x`} />
									<a href='mailto:info@cksegur.com'>
										<S.CallToTestContactMail style={{ fontFamily: 'Montserrat-Bold' }}>
											{t('landing.features.callToAction.emailUs')}
										</S.CallToTestContactMail>
									</a>
									<a href='mailto:info@cksegur.com'>
										<S.CallToTestContactMail>
											{t('landing.features.callToAction.emailUs2')}
										</S.CallToTestContactMail>
									</a>
								</S.CallToTestContactUs>
							</S.CallToTestContact>
							<div className={'call-to-us--svg'} style={{ position: 'relative' }}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='73'
									height='63'
									viewBox='0 0 53 13'
									fill='none'
									style={{ position: 'relative', top: '4px' }}
								>
									<path
										d='M53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM11.8416 26.5C11.8416 34.5956 18.4044 41.1584 26.5 41.1584C34.5956 41.1584 41.1584 34.5956 41.1584 26.5C41.1584 18.4044 34.5956 11.8416 26.5 11.8416C18.4044 11.8416 11.8416 18.4044 11.8416 26.5Z'
										fill='#B7E9FF'
									/>
								</svg>
								<svg
									width='83'
									height='93'
									viewBox='0 0 53 13'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									style={{ position: 'relative', right: '50px', top: '4px' }}
								>
									<path
										d='M53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM11.8416 26.5C11.8416 34.5956 18.4044 41.1584 26.5 41.1584C34.5956 41.1584 41.1584 34.5956 41.1584 26.5C41.1584 18.4044 34.5956 11.8416 26.5 11.8416C18.4044 11.8416 11.8416 18.4044 11.8416 26.5Z'
										fill='white'
									/>
								</svg>
							</div>
						</S.CallToUsSection>
					</S.CallToTest>

					<S.LandingFAQ>
						<div className='flex-faq'>
							<div className='flex-faq--title'>
								<S.HeaderPrimary marginBott={'20px'}>
									{t('landing.features.faq.title')}
								</S.HeaderPrimary>
							</div>
							<div className='flex-faq--list'>
								<ul>{listItems}</ul>
							</div>
						</div>
					</S.LandingFAQ>
					<S.LandingReview>
						<S.HeaderPrimary marginBott={'4px'}>
							{t('landing.features.reviews.title')}
						</S.HeaderPrimary>
						<a
							href={
								'https://www.google.com/maps/place/CK+SEGUR/@40.4167207,-3.7072565,17z/data=!3m1!5s0xd42287de44f066b:0xe4058882cd3b0211!4m7!3m6!1s0xd422977b484e49d:0xb1f6cfd5f0697b82!8m2!3d40.4167166!4d-3.7050678!9m1!1b1'
							}
						>
							<S.AllReviewsWrapper>
								<S.TextSecondary mainColor={CONFIG.colors.tertiary} font={CONFIG.fonts.bold}>
									5.0
								</S.TextSecondary>
								<Rating name='read-only' value={5} readOnly />
								<S.TextSecondary mainColor={CONFIG.colors.tertiary}>230 reviews</S.TextSecondary>
							</S.AllReviewsWrapper>
						</a>
						<ReviewProduct />
					</S.LandingReview>

					<S.HeaderPrimary marginBott={'20px'} style={{ width: '85%' }}>
						{t('landing.features.news.title')}
					</S.HeaderPrimary>
					<S.NewsWrapper>
						<S.NewsContainer
							href={'https://www.cksegur.com/que-es-un-seguro-de-vida/'}
							target='_blank'
							basis={'25%'}
							grow={0.5}
							imageUrl={'url(https://www.cksegur.com/xen_media/seguro-de-vida-800x800.jpg)'}
							style={{ cursor: 'pointer' }}
						>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									marginLeft: '15px',
									marginBottom: '15px',
								}}
							>
								<S.TextUnderlined>
									<a
										href={'https://www.cksegur.com/categoria/actualidad/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Actualidad
									</a>
								</S.TextUnderlined>{' '}
								<p style={{ color: 'white' }}> &nbsp; | &nbsp; </p>
								<S.TextUnderlined>
									<a
										href={'https://www.cksegur.com/categoria/particulares/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Particulares
									</a>
								</S.TextUnderlined>
								<S.TextHeadLine>
									<a
										href={'https://www.cksegur.com/que-es-un-seguro-de-vida/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										¿Qué es un seguro de vida?
									</a>
								</S.TextHeadLine>
							</div>
						</S.NewsContainer>
						<S.NewsContainer
							basis={'25%'}
							grow={0.5}
							imageUrl={'url(https://www.cksegur.com/xen_media/seg-vida-hipoteca.jpg)'}
							href={'https://www.cksegur.com/como-desvincular-el-seguro-de-vida-de-la-hipoteca/'}
							target='_blank'
							without
							rel='noopener noreferrer'
						>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									marginLeft: '15px',
									marginBottom: '15px',
								}}
							>
								<S.TextUnderlined>
									<a
										href={'https://www.cksegur.com/categoria/particulares/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Particulares
									</a>
								</S.TextUnderlined>
								<S.TextHeadLine>
									<a
										href={
											'https://www.cksegur.com/como-desvincular-el-seguro-de-vida-de-la-hipoteca/'
										}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Cómo desvincular el seguro de Vida de la hipoteca
									</a>
								</S.TextHeadLine>
							</div>
						</S.NewsContainer>
						<S.NewsContainer
							basis={'40%'}
							imageUrl={'url(https://www.cksegur.com/xen_media/salud-autonomos.jpg)'}
							href={'https://www.cksegur.com/seguros-de-salud-para-autonomos/'}
							target='_blank'
							without
							rel='noopener noreferrer'
						>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									marginLeft: '15px',
									marginBottom: '15px',
								}}
							>
								<S.TextUnderlined>
									<a
										href={'https://www.cksegur.com/categoria/empresas/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Empresas
									</a>
								</S.TextUnderlined>{' '}
								<p style={{ color: 'white' }}> &nbsp; | &nbsp; </p>
								<S.TextUnderlined>
									<a
										href={'https://www.cksegur.com/categoria/particulares/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Particulares
									</a>
								</S.TextUnderlined>
								<S.TextHeadLine>
									<a
										style={{ fontSize: '30px' }}
										href={'https://www.cksegur.com/seguros-de-salud-para-autonomos/'}
										target='_blank'
										without
										rel='noopener noreferrer'
									>
										Seguros de salud para autónomos
									</a>
								</S.TextHeadLine>
							</div>
						</S.NewsContainer>
					</S.NewsWrapper>
				</div>
			</div>
	);
});
