import React, { useState } from 'react';
import * as S from './styles';

import { Modal } from 'components/Modal/Modal';

import { Text, View, InternationalizationWrapper } from 'coinscrap-webapp-core';
import { isMobile } from 'react-device-detect';
import InputLabel from '../InputLabel/InputLabel';
import isEmail from 'validator/lib/isEmail';
import BackNextButton from '../BackNextButton/BackNextButton';

const ExtraDataModal = ({
	close,
	initialName,
	initialSurname,
	initialPhoneNumber,
	initialEmail,
	next,
}) => {
	const { t } = InternationalizationWrapper.use();

	const [name, setName] = useState(initialName || '');
	const [surname, setSurname] = useState(initialSurname || '');

	const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');
	const [email, setEmail] = useState(initialEmail || '');

	// eslint-disable-next-line no-unused-vars
	const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);

	const isDisabled = () => {
		return !name || !surname || !email || !isEmailCorrect;
	};

	return (
		<Modal>
			<S.ModalWrapper>
				<S.Close onClick={() => close()}>X</S.Close>
				<Text headingPrimarySt style={{ fontSize: 20, paddingTop: 10 }}>
					{t('simulationData.verOferta')}
				</Text>
				<View emailAndPhoneModalWrapperSt>
					<InputLabel
						style={{ width: '100%' }}
						value={name}
						onChange={e => {
							setName(e.target.value);
						}}
						label={t('simulationData.inputName')}
					/>
					<InputLabel
						style={{ width: '100%' }}
						value={surname}
						onChange={e => {
							setSurname(e.target.value);
						}}
						label={t('simulationData.inputSurname')}
					/>
					<InputLabel
						style={{ width: '100%' }}
						value={phoneNumber}
						type={'phone'}
						onChange={value => {
							setPhoneNumber(value);
							setIsPhoneCorrect(() => (phoneNumber?.length >= 12 ? false : true));
						}}
						label={t('simulationData.inputMobile')}
						labelExtra={t('simulationData.inputMobileExtra')}
						labelExtraStyle={{ fontStyle: 'italic' }}
						// errorMsg={isPhoneCorrect ? null : 'este numero tiene demasiados caracteres'}
					/>

					<InputLabel
						style={{ width: '100%' }}
						value={email}
						onChange={e => {
							if (!isEmailCorrect) {
								setIsEmailCorrect(isEmail(e.target.value));
							}
							setEmail(e.target.value);
						}}
						onBlur={() => {
							setIsEmailCorrect(isEmail(email));
						}}
						label={t('simulationData.inputEmail')}
						labelExtra={t('simulationData.inputEmailExtra')}
						labelExtraStyle={{ fontStyle: 'italic' }}
						errorMsg={!isEmailCorrect && t('miscellaneous.wrongEmail')}
					/>

					<BackNextButton
						marginTop={isMobile ? 30 : 35}
						marginBottom={isMobile ? 30 : 20}
						onClickNext={async () => {
							next && next(name, surname, email, phoneNumber);
							close();
						}}
						nextText={'simulationData.verOfertaNext'}
						isBackShown={false}
						isNextDisabled={isDisabled()}
						justifyContent={'evenly'}
					/>
				</View>
			</S.ModalWrapper>
		</Modal>
	);
};

export default ExtraDataModal;
