import React from 'react';
import { Button as ButtonCore } from 'coinscrap-webapp-core';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import * as S from './styles';
import './index.css';

import { ImageAvatar } from './ImageAvatar';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiMobileStepper-dotActive': { color: 'red' },
		'& .MuiMobileStepper-dot': { color: 'green' },
		alignSelf: 'center',
		maxWidth: 400,
		flexGrow: 1,
	},
}));

const openInNewTab = url => {
	window.open(url, '_blank', 'noopener,noreferrer');
};

export const ReviewProduct = () => {
	// eslint-disable-next-line no-unused-vars
	const [value, setValue] = React.useState(5);
	const classes = useStyles();
	const theme = useTheme();
	// console.log(theme);
	const [activeStep, setActiveStep] = React.useState(0);
	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	return (
		<>
			<S.ReviewWrapper>
				{activeStep === 0 && (
					<>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Oskana Rymareva'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/107075197105927871587/photos/@40.4167166,-3.7050678,17z/data=!4m3!8m2!3m1!1e1?hl=en-US'
										}
									>
										<S.NameText>Oksana Rymareva</S.NameText>

										<S.CVText>1 review</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220610', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Дуже вдячна нашій землячці Катерині, за її професіоналізм та швидкому вирішенню
								проблем. Оформлення на місяць безкоштовної страховки на авто було вирішено за 5 хв.
								Наша соловїна мова,якою Катерина спілкується , мешкаючи у Испанії, було як бальзам
								на душу. Дякую.
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Sara Bonitos'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/112906948486244729011/photos/@40.4798643,-3.3809631,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US'
										}
									>
										<S.NameText>Sara Bonitos</S.NameText>
										<S.CVText>4 reviews · 1 photo</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220607', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Todo bien eficacia y responsabilidad con buen trato además un buen precio{' '}
								<span role='img' aria-label='Bien!'>
									😉 👌
								</span>
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Cristina Castillo'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/106392267179141855570?hl=en-US&sa=X&ved=2ahUKEwjtgJ7ViK34AhXKzIUKHTbgC7IQvvQBegQIARAx'
										}
									>
										<S.NameText>Cristina Castillo</S.NameText>
										<S.CVText>1 review</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220601', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Félix el comercial es un hombre muy afable y dispuesto a colaborar y gestionar
								,llevo ya varios años con él y estamos mi esposo y yo encantados así q le doy 5
								estrellas ,pq no hay más ,es un excelente trabajador mil gracias Félix bendiciones
							</S.ReviewText>
						</Box>
					</>
				)}

				{activeStep === 1 && (
					<>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Taras Kosmy'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/113825981516097659050?hl=en-US&sa=X&ved=2ahUKEwjtgJ7ViK34AhXKzIUKHTbgC7IQvvQBegQIARBI'
										}
									>
										<S.NameText>Taras Kosmy</S.NameText>
										<S.CVText>1 review</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220525', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								It was necessary to urgently make car insurance and recommended Catherine! Very
								pleased with the speed and quality of service. I recommend !!!
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Тетяна Акімова'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/110349588680166341588?hl=en-US&sa=X&ved=2ahUKEwjtgJ7ViK34AhXKzIUKHTbgC7IQvvQBegQIARBx'
										}
									>
										<S.NameText>Тетяна Акімова</S.NameText>
										<S.CVText>1 review</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220514', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								EКатерина підібрала оптимальну програму страхування із врахуванням індивідуальних
								особливостей. Дуже якісний сервіс, швидкі відповіді на усі запитання.
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Maxim Zmaznev'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/117033656734010169969?hl=en-US&sa=X&ved=2ahUKEwjtgJ7ViK34AhXKzIUKHTbgC7IQvvQBegUIARCHAQ'
										}
									>
										<S.NameText>Maxim Zmaznev</S.NameText>
										<S.CVText>1 review · 1 photo</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220512', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Нам допомогли та зробили на місяць безкоштовну страховку. Все дуже швидко та
								професійно. Молодці, дякую.
							</S.ReviewText>
						</Box>
					</>
				)}

				{activeStep === 2 && (
					<>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Алена Радкевич'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/113400915105402106134?hl=en-US&sa=X&ved=2ahUKEwjAhuX4la34AhUL_4UKHRmPDrMQvvQBegUIARC-AQ'
										}
									>
										<S.NameText>Алена Радкевич</S.NameText>
										<S.CVText>2 reviews</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220508', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Дуже дякую. Мені дуже швидко оформили зелену карту на місяці. Безкоштовною. Дякую за
								розуміння та допомогу.
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Re Mix'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/118429902471724935675?hl=en-US&sa=X&ved=2ahUKEwjAhuX4la34AhUL_4UKHRmPDrMQvvQBegUIARDUAQ'
										}
									>
										<S.NameText>Re Mix</S.NameText>
										<S.CVText>3 reviews · 20 photos</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220506', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Дуже вдячний за швидкий і якісний сервіс. Любі варіанти страхування та просто
								допомога в різних питаннях пов’язаних з оформленням у Іспанії. Мій рекомендасіон
								<span role='img' aria-label='Bien!'>
									🙌🏻💙💛
								</span>
							</S.ReviewText>
						</Box>
						<Box component='fieldset' mb={3} borderColor='transparent'>
							<S.AvatarWrapper>
								<ImageAvatar name={'Nataly Litvinova'} />
								<div className={'person'}>
									<a
										href={
											'https://www.google.com/maps/contrib/103512382902478981339?hl=en-US&sa=X&ved=2ahUKEwja6qmzlq34AhWvQvEDHXu-AUkQvvQBegQIARAk'
										}
									>
										<S.NameText>Nataly Litvinova</S.NameText>
										<S.CVText>2 reviews</S.CVText>
									</a>
								</div>
							</S.AvatarWrapper>
							<S.AvatarWrapper>
								<Rating name='read-only' value={value} readOnly />
								<S.CVText>{moment('20220502', 'YYYYMMDD').fromNow()}</S.CVText>
							</S.AvatarWrapper>
							<S.ReviewText>
								Дуже задоволені оперативною роботою з менеджером страхової компанії. Вчора ввечері
								подали документи, сьогодні все готово! Якщо потрібна зелена карта для авто у Європі,
								раджу звертатись сюди!
							</S.ReviewText>
						</Box>
					</>
				)}
			</S.ReviewWrapper>
			<MobileStepper
				variant='dots'
				steps={3}
				position='static'
				activeStep={activeStep}
				className={classes.root}
				nextButton={
					<Button size='small' onClick={handleNext} disabled={activeStep === 2}>
						{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
					</Button>
				}
				backButton={
					<Button size='small' onClick={handleBack} disabled={activeStep === 0}>
						{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
					</Button>
				}
			/>

			<ButtonCore
				selectedSt
				style={{ alignSelf: 'center', marginTop: '30px', width: '70%' }}
				id='landing.features.reviews.button'
				label='landing.features.reviews.button'
				onClick={() => openInNewTab('https://www.cksegur.com/')}
			></ButtonCore>
		</>
	);
};
