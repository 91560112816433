import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputSuggest', {
	styles: {
		default: {
			containerComponent: {
				border: '1px solid white',
				borderRadius: '49px',
				height: '62px',
				color: '#30353F',
				fontSize: '24px',
				// padding: '28px 18px',
				background: 'white',
				position: 'relative',
				marginTop: 0,
			},

			textFieldComponent: {
				containerComponent: {
					left: '50%',
					transform: 'translateX(-50%)',
					margin: 0,
					padding: 0,
					paddingBottom: 15,
					backgroundColor: 'transparent',
				},
				inputContainerComponent: {
					borderRadius: '0px',
					padding: 0,
					backgroundColor: 'transparent',
				},
				inputComponent: {
					color: 'black',
					fontSize: 18,
				},
			},
			suggestionsListComponent: {
				border: '1px solid #B1B1B1',
				padding: 20,
				width: '100%',
				overflowX: 'hidden',
				maxWidth: 400,
				height: 'auto',
				maxHeight: 100,
				// position: 'absolute',
				backgroundColor: 'white',
				overflowY: 'auto',
				borderRadius: 2,
				boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px',
			},
			suggestionsContainerComponent: {
				// zIndex: 50,
			},
			suggestionTextComponent: {
				paddingTop: 5,
				color: 'black',
				cursor: 'pointer',
			},
			suggestionSelectedTextComponent: {
				color: '#B1B1B1',
			},
			sectionTitleTextComponent: {
				fontWeight: 'bold',
				fontStyle: 'italic',
				borderBottom: 'solid 1px',
				marginBottom: 5,
			},
			sectionContainerComponent: {
				background: 'red',
			},
		},
	},
});
