export const smokeTypesES = [
	{ label: 'Pipas', value: 'Pipas' },
	{ label: 'Puros', value: 'Puros' },
	{ label: 'Cigarrillos', value: 'Cigarrillos' },
	{ label: 'Cigarrillo electronico', value: 'CigarrilloElectronico' },
];

export const alcoholTypesES = [
	{ label: 'Jerez', value: 'Jerez' },
	{ label: 'Vino', value: 'Vino' },
	{ label: 'Cerveza', value: 'Cerveza' },
	{ label: 'Whisky', value: 'Whisky' },
	{ label: 'Licor', value: 'Licor' },
];
