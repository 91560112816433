export const professionsRU = [
	{
		value: '200000',
		label: 'АДВОКАТ',
	},
	{
		value: '200001',
		label: 'ПРОКУРОР',
	},
	{
		value: '200002',
		label: 'СУДЬЯ',
	},
	{
		value: '200003',
		label: 'ЮРИСТ',
	},
	{
		value: '200004',
		label: 'ЮРИСТ',
	},
	{
		value: '200005',
		label: 'АКТЕР/АКТРИСА',
	},
	{
		value: '200006',
		label: 'АКТЕР ДУБЛЯЖА',
	},
	{
		value: '200007',
		label: 'КАСКАДЕР',
	},
	{
		value: '200008',
		label: 'КАСКАДЕР (КИНО - ТЕАТРАЛЬНЫЕ СПЕКТАКЛИ)',
	},
	{
		value: '200009',
		label: 'АНАЛИТИК',
	},
	{
		value: '200010',
		label: 'ДРЕССИРОВЩИК ДОМАШНИХ ЖИВОТНЫХ',
	},
	{
		value: '200011',
		label: 'ДРЕССИРОВЩИК БОЙЦОВСКИХ СОБАК',
	},
	{
		value: '200012',
		label: 'ДРЕССИРОВЩИК ЗВЕРЕЙ',
	},
	{
		value: '200013',
		label: 'АДМИНИСТРАЦИЯ',
	},
	{
		value: '200014',
		label: 'БИЗНЕС-АДМИНИСТРАТОР',
	},
	{
		value: '200015',
		label: 'АДМИНИСТРАТИВНЫЙ РАБОТНИК',
	},
	{
		value: '200016',
		label: 'КОММЕРЧЕСКИЙ ИНЖЕНЕР',
	},
	{
		value: '200017',
		label: 'АДМИНИСТРАТИВНЫЙ ПЕРСОНАЛ',
	},
	{
		value: '200018',
		label: 'СЕКРЕТАРЬ',
	},
	{
		value: '200020',
		label: 'БИРЖЕВОЙ АГЕНТ',
	},
	{
		value: '200021',
		label: 'ФЕРМЕР ЗЕМЛЕДЕЛИЕ',
	},
	{
		value: '200022',
		label: 'ПЧЕЛОВОД',
	},
	{
		value: '200023',
		label: 'ФЕРМЕР',
	},
	{
		value: '200024',
		label: 'ФЕРМЕР',
	},
	{
		value: '200025',
		label: 'ФЕРМЕР/ВЕТЕРИНАР',
	},
	{
		value: '200026',
		label: 'ФЕРМЕР',
	},
	{
		value: '200027',
		label: 'РЫБАК В ОТКРЫТОМ МОРЕ',
	},
	{
		value: '200028',
		label: 'РЫБАК. РЕКИ, ОЗЕРА И ПОБЕРЕЖЬЕ',
	},
	{
		value: '200029',
		label: 'АЛКОГОЛЬ (ПРОИЗВОДСТВО)',
	},
	{
		value: '200030',
		label: 'АЛКОГОЛЬ. ПРОДАЖА (ЗА ИСКЛЮЧЕНИЕМ НОЧНЫХ ЗАВЕДЕНИЙ)',
	},
	{
		value: '200031',
		label: 'ДОМОХОЗЯЙКА',
	},
	{
		value: '200032',
		label: 'СИСТЕМНЫЙ АНАЛИТИК',
	},
	{
		value: '200033',
		label: 'АНТИКВАР',
	},
	{
		value: '200034',
		label: 'ОРУЖЕЙНИК',
	},
	{
		value: '200035',
		label: 'АРХЕОЛОГ',
	},
	{
		value: '200036',
		label: 'АРХИТЕКТОР',
	},
	{
		value: '200037',
		label: 'АРХИТЕКТОР ИНТЕРЬЕРОВ',
	},
	{
		value: '200038',
		label: 'АРСЕНАЛЫ, ПОРОХОВОЙ СКЛАД',
	},
	{
		value: '200039',
		label: 'АУДИТОР',
	},
	{
		value: '200040',
		label: 'АВТОР',
	},
	{
		value: '200041',
		label: 'РЕДАКТОР',
	},
	{
		value: '200042',
		label: 'ПИСАТЕЛЬ',
	},
	{
		value: '200043',
		label: 'СИМФОНИЧЕСКИЙ МУЗЫКАНТ',
	},
	{
		value: '200044',
		label: 'ПЕВЕЦ',
	},
	{
		value: '200045',
		label: 'ДЕКОРАТОР',
	},
	{
		value: '200046',
		label: 'ДИЗАЙНЕР. ГРАФИЧЕСКИЙ ДИЗАЙНЕР',
	},
	{
		value: '200047',
		label: 'СКУЛЬПТОР',
	},
	{
		value: '200048',
		label: 'ДИЗАЙНЕР КОСТЮМОВ',
	},
	{
		value: '200049',
		label: 'ПОРТНОЙ',
	},
	{
		value: '200050',
		label: 'МОДИСТ',
	},
	{
		value: '200051',
		label: 'ХУДОЖНИК ПЕЙЗАЖЕЙ',
	},
	{
		value: '200052',
		label: 'ОБСЛУЖИВАНИЕ ЛИФТОВ',
	},
	{
		value: '200053',
		label: 'МОНТАЖНИК ЛИФТОВ',
	},
	{
		value: '200054',
		label: 'МОНТАЖ И ОБСЛУЖИВАНИЕ ЛИФТОВ',
	},
	{
		value: '200055',
		label: 'СОВЕТНИК. КОНСУЛЬТАНТ',
	},
	{
		value: '200056',
		label: 'ПРОИЗВОДСТВО АСФАЛЬТА',
	},
	{
		value: '200057',
		label: 'ИНЖЕНЕР СУДОВОЙ',
	},
	{
		value: '200058',
		label: 'МОНТАЖНИК СУДОВОЙ',
	},
	{
		value: '200059',
		label: 'АДМИНИСТРАЦИЯ СУДОВАЯ',
	},
	{
		value: '200060',
		label: 'СУДОВОЕ РЕМЕСЛО',
	},
	{
		value: '200061',
		label: 'СУДОВАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200062',
		label: 'АВТОШКОЛА',
	},
	{
		value: '200063',
		label: 'ПРОФЕССИОНАЛЬНЫЙ ГОНЩИК',
	},
	{
		value: '200064',
		label: 'ПОМОЩНИК СТОМАТОЛОГА',
	},
	{
		value: '200065',
		label: 'ПОМОЩНИК АПТЕКАРЯ',
	},
	{
		value: '200066',
		label: 'МЕДИЦИНСКИЙ ПОМОЩНИК',
	},
	{
		value: '200067',
		label: 'ПРОФЕССИОНАЛЬНАЯ АВИАЦИЯ',
	},
	{
		value: '200069',
		label: 'БОРТПРОВОДНИК, СТЮАРТ, РЕГУЛЯРНЫЕ РЕЙСЫ',
	},
	{
		value: '200070',
		label: 'АРТИСТКА БАЛЕТА',
	},
	{
		value: '200071',
		label: 'АРТИСТ БАЛЕТА',
	},
	{
		value: '200072',
		label: 'АРТИСТ ИСПАНСКОГО ТАНЦА',
	},
	{
		value: '200073',
		label: 'БАНКОВСКОЕ ДЕЛО. АДМИНИСТРАЦИЯ',
	},
	{
		value: '200074',
		label: 'БАНКОВСКОЕ ДЕЛО. ИНКАССАТОР-ВОДИТЕЛЬ',
	},
	{
		value: '200075',
		label: 'БАНКОВСКОЕ ДЕЛО. ИНКАССАТОР- ПЕРЕВОЗКА ЦЕННОСТЕЙ',
	},
	{
		value: '200076',
		label: 'БАРЫ, ЗАВЕДЕНИЯ ПИТАНИЯ (КРОМЕ НОЧНЫХ ЗАВЕДЕНИЙ): ВЛАДЕЛЕЦ',
	},
	{
		value: '200077',
		label: 'БАРМЕН (РАБОТНИК БАРА ИЛИ ПАБА, ПРИГОТОВЛЕНИЕ НАПИТКОВ)',
	},
	{
		value: '200078',
		label: 'БИБЛИОТЕКАРЬ',
	},
	{
		value: '200079',
		label: 'БИОЛОГ',
	},
	{
		value: '200080',
		label: 'БИОХИМИК (ИССЛЕДОВАНИЯ, ПРОМЫШЛЕННОСТЬ, ФАРМАЦЕВТИКА)',
	},
	{
		value: '200081',
		label: 'ФОНДОВАЯ БИРЖА',
	},
	{
		value: '200082',
		label: 'ПОЖАРНЫЙ-СПАСАТЕЛЬ. ТУШЕНИЕ ПОЖАРА НА ЗЕМЛЕ',
	},
	{
		value: '200083',
		label: 'ПОЖАРНЫЙ-СПАСАТЕЛЬ. ТУШЕНИЕ ПОЖАРА С ВОЗДУХА',
	},
	{
		value: '200084',
		label: 'ПОЖАРНЫЙ-СПАСАТЕЛЬ. ТУШЕНИЕ ПОЖАРА ПОД ЗЕМЛЕЙ',
	},
	{
		value: '200085',
		label: 'BOMBERO. TAREAS ADMINISTRATIVAS SOLAMENTE',
	},
	{
		value: '200086',
		label: 'ПОЖАРНЫЙ-СПАСАТЕЛЬ. ТОЛЬКО АДМИНИСТРАТИВНЫЕ ЗАДАЧИ',
	},
	{
		value: '200087',
		label: 'ПОЖАРНЫЙ-СПАСАТЕЛЬ. ВОДИТЕЛЬ',
	},
	{
		value: '200088',
		label: 'БОТАНИК',
	},
	{
		value: '200089',
		label: 'ПРОФЕССИОНАЛЬНЫЙ БОКСЕР',
	},
	{
		value: '200090',
		label: 'ПРОФЕССИОНАЛЬНЫЙ ИНСТРУКТОР ПОДВОДНОГО ПЛАВАНИЯ',
	},
	{
		value: '200091',
		label: 'КОТЕЛЬЩИК. ЗДАНИЯ И ЗАВОДЫ/ФАБРИКИ',
	},
	{
		value: '200092',
		label: 'КОТЕЛЬЩИК. НЕФТЕПЕРЕРАБАТЫВАЮЩИЙ ЗАВОД',
	},
	{
		value: '200093',
		label: 'МЯСНИК',
	},
	{
		value: '200094',
		label: 'СТОЛЯР, ПЛОТНИК',
	},
	{
		value: '200095',
		label: 'CATHC, ПРОФЕССИОНАЛЬНЫЙ',
	},
	{
		value: '200096',
		label: 'ЦЕМЕНТ (ИЗГОТОВЛЕНИЕ И УПАКОВКА)',
	},
	{
		value: '200097',
		label: 'НОЧНОЕ ЗАВЕДЕНИЕ (АРТИСТ КАБАРЕ, АРТИСТ, РАБОТНИК)',
	},
	{
		value: '200098',
		label: 'ИЗГОТОВЛЕНИЕ КЕРАМИЧЕСКИХ ИЗДЕЛИЙ. ГОНЧАР',
	},
	{
		value: '200099',
		label: 'СЛЕСАРЬ',
	},
	{
		value: '200100',
		label: 'ПРОФЕССИОНАЛЬНЫЙ ВЕЛОСИПЕДИСТ. ВЕЛОСПОРТ',
	},
	{
		value: '200101',
		label: 'ХИРУРГ',
	},
	{
		value: '200102',
		label: 'СБОРЩИК ДОЛГОВЫХ ПЛАТЕЖЕЙ (В БЕЗОПАСНЫХ ЗОНАХ)',
	},
	{
		value: '200103',
		label: 'СБОРЩИК ДОЛГОВЫХ ПЛАТЕЖЕЙ (В ОПАСНЫХ ЗОНАХ)',
	},
	{
		value: '200104',
		label: 'ГАЗ. ОПЕРАТОР ЗАПРАВОЧНЫХ СТАНЦИЙ',
	},
	{
		value: '200105',
		label: 'ГАЗ. ТРАНСПОРТИРОВКА ЛЕГКОВОСПЛАМЕНЯЮЩИХСЯ ВЕЩЕСТВ',
	},
	{
		value: '200106',
		label: 'ТОРГОВЫЙ ПРЕДСТАВИТЕЛЬ В КОНФЛИКТНЫХ ИЛИ ОПАСНЫХ ЗОНАХ',
	},
	{
		value: '200107',
		label: 'ТОРГОВЫЙ ПРЕДСТАВИТЕЛЬ. ДРУГИЕ ЗОНЫ',
	},
	{
		value: '200108',
		label: 'ВЫЧИСЛЕНИЕ И ИНФОРМАТИКА. ИНЖЕНЕР',
	},
	{
		value: '200109',
		label: 'ВЫЧИСЛЕНИЕ И ИНФОРМАТИКА. ПРОГРАММИСТ',
	},
	{
		value: '200110',
		label: 'ВОДИТЕЛЬ СКОРОЙ ПОМОЩИ',
	},
	{
		value: '200111',
		label: 'ВОДИТЕЛЬ АВТОБУСА',
	},
	{
		value: '200112',
		label: 'ВОДИТЕЛЬ ТАКСИ',
	},
	{
		value: '200113',
		label: 'АРХИТЕКТОР, ГЕОДЕЗИСТ, СТАРШИЙ МАСТЕР/БРИГАДИР',
	},
	{
		value: '200114',
		label: 'РУКОВОДИТЕЛЬ, БРИГАДИР',
	},
	{
		value: '200115',
		label: 'СТРОИТЕЛЬ',
	},
	{
		value: '200116',
		label: 'ПРОИЗВОДСТВО, УПАКОВКА ЦЕМЕНТА',
	},
	{
		value: '200117',
		label: 'СТОЛЯР НА СТРОИТЕЛЬСТВЕ',
	},
	{
		value: '200118',
		label: 'СВЕРЛОВЩИК',
	},
	{
		value: '200119',
		label: 'МОНТАЖНИК МЕТАЛЛИЧЕСКИХ КОНСТРУКЦИЙ',
	},
	{
		value: '200120',
		label: 'БУХГАЛТЕР',
	},
	{
		value: '200121',
		label: 'CONTADOR',
	},
	{
		value: '200122',
		label: 'CONTROLADOR AÉREO',
	},
	{
		value: '200123',
		label: 'АВИАДИСПЕТЧЕР',
	},
	{
		value: '200124',
		label: 'КОНСУЛ',
	},
	{
		value: '200125',
		label: 'ПОСОЛ',
	},
	{
		value: '200126',
		label: 'РАБОТНИК ПОСОЛЬСТВА',
	},
	{
		value: '200127',
		label: 'ДИПЛОМАТИЧЕСКИЙ КОРПУС В ЗОНАХ С ОСОБОЙ ОПАСНОСТЬЮ',
	},
	{
		value: '200128',
		label: 'ОБРАБОТЧИК ШКУР',
	},
	{
		value: '200130',
		label: 'ДЕМОНТАЖ С ИСПОЛЬЗОВАНИЕМ ВЗРЫВЧАТКИ',
	},
	{
		value: '200131',
		label: 'ДЕМОНТАЖ БЕЗ ИСПОЛЬЗОВАНИЯ ВЗРЫВЧАТКИ',
	},
	{
		value: '200132',
		label: 'СТОМАТОЛОГ/ДАНТИСТ',
	},
	{
		value: '200133',
		label: 'DOCKER',
	},
	{
		value: '200134',
		label: 'БУРЕНИЕ, ДРЕНАЖ',
	},
	{
		value: '200135',
		label: 'ПРОДАВЕЦ ГАЛАНТЕРЕИ (БЫТОВАЯ ХИМИЯ)',
	},
	{
		value: '200136',
		label: 'СТОЛЯР',
	},
	{
		value: '200137',
		label: 'ВОСПИТАТЕЛЬ',
	},
	{
		value: '200138',
		label: 'ДЕЛОПРОИЗВОДИТЕЛЬ',
	},
	{
		value: '200139',
		label: 'ЭЛЕКТРОМОНТАЖНИК КАБЕЛЬНЫХ СЕТЕЙ',
	},
	{
		value: '200140',
		label: 'КОНТРОЛЛЕРЫ ЭЛЕКТРИЧЕСТВО',
	},
	{
		value: '200141',
		label: 'ЭЛЕКТРИК НИЗКОЕ НАПРЯЖЕНИЕ',
	},
	{
		value: '200142',
		label: 'ЭЛЕКТРИК НА СТРОИТЕЛЬСТВЕ',
	},
	{
		value: '200143',
		label: 'ЭЛЕКТРИК ЗДАНИЯ, ЖИЛЫЕ ПОМЕЩЕНИЯ И ДОРОГИ',
	},
	{
		value: '200144',
		label: 'ЭЛЕКТРИК ВЫСОКОЕ НАПРЯЖЕНИЕ',
	},
	{
		value: '200145',
		label: 'ЭЛЕКТРОМОНТАЖНИК',
	},
	{
		value: '200146',
		label: 'ОПЕРАТОРЫ ЭЛЕКТРИЧЕСКИХ СЕТЕЙ',
	},
	{
		value: '200148',
		label: 'ЦИРКОВЫЕ ПРЕДСТАВЛЕНИЯ',
	},
	{
		value: '200149',
		label: 'МАСТЕР ПОГРУЗОЧНО-РАЗГРУЗОЧНЫХ РАБОТ',
	},
	{
		value: '200150',
		label: 'ВЗРЫВЧАТЫЕ ВЕЩЕСТВА',
	},
	{
		value: '200151',
		label: 'ВОДИТЕЛИ - СЛУЖБА ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА',
	},
	{
		value: '200152',
		label: 'ЖЕЛЕЗНЫЕ ДОРОГИ (ДРУГИЕ СВЯЗАННЫЕ ПРОФЕССИИ)',
	},
	{
		value: '200153',
		label: 'ОБСЛУЖИВАНИЕ ДОРОГ, ЗНАКОВ И ЛИНИЙ ЭЛЕКТРОПЕРЕДАЧ',
	},
	{
		value: '200154',
		label: 'МАШИНИСТ ЖЕЛЕЗНОЙ ДОРОГИ',
	},
	{
		value: '200155',
		label: 'ПЕРСОНАЛ ПОГРУЗОЧНО-РАЗГРУЗОЧНЫХ РАБОТ НА ЖЕЛЕЗНОЙ ДОРОГЕ',
	},
	{
		value: '200156',
		label: 'ФРЕЗЕРОВЩИК/КУЗНЕЦ',
	},
	{
		value: '200157',
		label: 'ВООРУЖЕННЫЕ СИЛЫ ТОЛЬКО АДМИНИСТРАТИВНАЯ РАБОТА',
	},
	{
		value: '200158',
		label: 'ВООРУЖЕННЫЕ СИЛЫ ДРУГОЕ',
	},
	{
		value: '200159',
		label: 'ПОДРАЗДЕЛЕНИЕ ОСОБОГО НАЗНАЧЕНИЯ НАРКОТРАФИК',
	},
	{
		value: '200160',
		label: 'ПОДРАЗДЕЛЕНИЕ ОСОБОГО НАЗНАЧЕНИЯ АНТИТЕРОРИСТИЧЕСКОЕ',
	},
	{
		value: '200161',
		label: 'ФУМИГАЦИЯ: ЗЕМЛЯ',
	},
	{
		value: '200162',
		label: 'ФУМИГАЦИЯ: ВОЗДУШНАЯ',
	},
	{
		value: '200163',
		label: 'ГАЗ. РУКОВОДИТЕЛЬ БРИГАДИР',
	},
	{
		value: '200164',
		label: 'ГАЗ. РАБОТНИК, МОНТАЖНИК',
	},
	{
		value: '200165',
		label: 'ГЕОЛОГИ::',
	},
	{
		value: '200166',
		label: 'ЛЕСНИК',
	},
	{
		value: '200167',
		label: 'ОХРАННИК',
	},
	{
		value: '200168',
		label: 'СПАСАТЕЛЬ ДРУГОЕ',
	},
	{
		value: '200169',
		label: 'СПАСАТЕЛЬ БАСЕЙНЫ',
	},
	{
		value: '200170',
		label: 'ОТЕЛЬНО-РЕСТОРАННЫЙ БИЗНЕС',
	},
	{
		value: '200171',
		label: 'ВЕРХОВАЯ ЕЗДА: УКРОТИТЕЛЬ',
	},
	{
		value: '200172',
		label: 'ВЕРХОВАЯ ЕЗДА: ПРЫЖКИ ЧЕРЕЗ ПРЕПЯТСТВИЯ',
	},
	{
		value: '200173',
		label: 'ВЕРХОВАЯ ЕЗДА: ЖОКЕЙ ГОНКИ',
	},
	{
		value: '200174',
		label: 'ВЕРХОВАЯ ЕЗДА: ПОЛО ИЛИ ПАТО',
	},
	{
		value: '200175',
		label: 'ВЕРХОВАЯ ЕЗДА: РОДЕО',
	},
	{
		value: '200176',
		label: 'ВЕРХОВАЯ ЕЗДА: ПРЕПОДАВАТЕЛЬ',
	},
	{
		value: '200177',
		label: 'ВЕРХОВАЯ ЕЗДА: ЧАРЕРИЯ',
	},
	{
		value: '200178',
		label: 'АВТОМОБИЛЬНАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200179',
		label: 'МЯСНАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200180',
		label: 'ДЕРЕВООБРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200181',
		label: 'ПРОИЗВОДСТВЕННАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200182',
		label: 'НЕФТЕХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: ПОИСК, РАЗВЕДЫВАТЕЛЬНЫЕ ОПЕРАЦИИ',
	},
	{
		value: '200183',
		label: 'НЕФТЕХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: НАДЗОР ЗА НЕФТЕПЕРЕРАБАТЫВАЮЩИМ ЗАВОДОМ',
	},
	{
		value: '200184',
		label: 'НЕФТЕХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ РЕЗЕРВУАРОВ',
	},
	{
		value: '200185',
		label: 'НЕФТЕХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: НЕФТЕДОБЫВАЮЩИЕ ПЛАТФОРМЫ',
	},
	{
		value: '200186',
		label: 'ХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: ОПАСНЫЕ ВЕЩЕСТВА',
	},
	{
		value: '200187',
		label: 'ХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ: ДРУГИЕ ВЕЩЕСТВА',
	},
	{
		value: '200188',
		label: 'БИОХИМИЧЕСКАЯ И ФАРМАЦЕВТИЧЕСКАЯ ПРОМЫШЛЕННОСТИ',
	},
	{
		value: '200189',
		label: 'ИНЖЕНЕРИЯ: ЛЕСНАЯ ИНЖЕНЕРИЯ',
	},
	{
		value: '200190',
		label: 'ИНЖЕНЕРИЯ: ИНЖЕНЕР СЕЛЬСКОГО ХОЗЯЙСТВА',
	},
	{
		value: '200191',
		label: 'ИНЖЕНЕРИЯ: ИНЖЕНЕР-ЭЛЕКТРОТЕХНИК',
	},
	{
		value: '200192',
		label: 'ИНЖЕНЕРИЯ: ИНЖЕНЕР-ЭЛЕКТРОМЕХАНИК',
	},
	{
		value: '200193',
		label: 'ИНЖЕНЕРИЯ: ИНЖЕНЕР-СТРОИТЕЛЬ',
	},
	{
		value: '200194',
		label: 'ИЗГОТОВЛЕНИЕ ИНСЕКТИЦИДОВ',
	},
	{
		value: '200195',
		label: 'МОНТАЖ ТРУБОПРОВОДА',
	},
	{
		value: '200196',
		label: 'УСТАНОВКА ИЛИ РАЗМЕЩЕНИЕ АНТЕНН',
	},
	{
		value: '200197',
		label: 'ЧАСТНЫЙ ДЕТЕКТИВ',
	},
	{
		value: '200198',
		label: 'ЛЕСОРУБ',
	},
	{
		value: '200199',
		label: 'РАБОТА С ПАТОЛОГИЧЕСКИМИ ОТХОДАМИ',
	},
	{
		value: '200200',
		label: 'РАБОТА С ТОКСИЧНЫМИ ЯДОВИТЫМИ ВЕЩЕСТВАМИ',
	},
	{
		value: '200201',
		label: 'ПРОИЗВОДСТВО ОРУЖИЯ',
	},
	{
		value: '200202',
		label: 'ВОДИТЕЛЬ ЭВАКУАТОРА, ЭКСКАВАТОРА, БУЛЬДОЗЕРА И ТОМУ ПОДОБНЫХ',
	},
	{
		value: '200203',
		label: 'ОПЕРАТОР СТРОИТЕЛЬНЫХ И ЗЕМЛЕРОЙНЫХ МАШИН',
	},
	{
		value: '200204',
		label: 'МОРСКОЙ. ТОРГОВЫЕ ЗОНЫ БЕЗ РИСКА',
	},
	{
		value: '200205',
		label: 'БОЙНЯ',
	},
	{
		value: '200206',
		label: 'МЕХАНИКА',
	},
	{
		value: '200207',
		label: 'МЕТАЛЛУРГИЯ',
	},
	{
		value: '200208',
		label: 'ДОБЫЧА ПОЛЕЗНЫХ ИСКОПАЕМЫХ: РАБОТА НА ПОВЕРХНОСТИ',
	},
	{
		value: '200209',
		label: 'ДОБЫЧА ПОЛЕЗНЫХ ИСКОПАЕМЫХ: РАБОТА ПОД ЗЕМЛЕЙ',
	},
	{
		value: '200210',
		label: 'ДОБЫЧА ПОЛЕЗНЫХ ИСКОПАЕМЫХ: РАБОТА СО ВЗРЫВЧАТКОЙ',
	},
	{
		value: '200211',
		label: 'ПАРИКМАХЕР',
	},
	{
		value: '200212',
		label: 'ЖУРНАЛИСТИКА В ЗОНЕ КОНФЛИКТА',
	},
	{
		value: '200213',
		label: 'ЖУРНАЛИСТИКА, ВСЕ ОСТАЛЬНЫЕ',
	},
	{
		value: '200214',
		label: 'ФОТОГРАФ В ЗОНЕ КОНФЛИКТА',
	},
	{
		value: '200215',
		label: 'ФОТОГРАФ,ВСЕ ОСТАЛЬНЫЕ',
	},
	{
		value: '200216',
		label: 'ТАМОЖЕННЫЙ ПЕРСОНАЛ, ПОГРАНИЧНЫЕ ЗОНЫ',
	},
	{
		value: '200217',
		label: 'ТАМОЖЕННЫЙ ПЕРСОНАЛ, ПОГРАНИЧНЫЕ ЗОНЫ РЕК',
	},
	{
		value: '200219',
		label: 'РЫБАЛКА. НА ПОБЕРЕЖЬЯХ, РЕКАХ И ОЗЕРАХ',
	},
	{
		value: '200220',
		label: 'ПИРОТЕХНИКА, ПИРОТЕХНИЧЕСКИЕ ИГРЫ',
	},
	{
		value: '200221',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: ТОЛЬКО АДМИНИСТРАТИВНЫЕ ЗАДАНИЯ',
	},
	{
		value: '200222',
		label: 'МУНИЦИПАЛЬНАЯ ПОЛИЦИЯ',
	},
	{
		value: '200223',
		label: 'ПОЛИЦИЯ: РАЙОННАЯ ИЛИ ОБЛАСТНАЯ',
	},
	{
		value: '200224',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: НАЦИОНАЛЬНАЯ ИЛИ ФЕДЕРАЛЬНАЯ',
	},
	{
		value: '200225',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКИЙ ГВАРДИЯ: ДЕАКТИВАЦИЯ ВЗРЫВЧАТКИ',
	},
	{
		value: '200226',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: ГРАНИЦЫ, ЖАНДАРМЕРИЯ',
	},
	{
		value: '200227',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: НАРКОТРАФИК',
	},
	{
		value: '200228',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: АНТИТЕРРОРИСТИЧЕСКАЯ ГРУППА',
	},
	{
		value: '200229',
		label: 'ПОЛИЦИЯ/ГРАЖДАНСКАЯ ГВАРДИЯ: ОХРАНА ТЮРЬМЫ',
	},
	{
		value: '200230',
		label: 'ПСИХИАТР',
	},
	{
		value: '200231',
		label: 'РЕКЛАМА. ВОЗДУШНАЯ',
	},
	{
		value: '200232',
		label: 'РЕКЛАМА. ВСЕ ОСТАЛЬНЫЕ',
	},
	{
		value: '200233',
		label: 'РАДИОЛОГИЯ',
	},
	{
		value: '200234',
		label: 'КУРЬЕР НА МОТОЦИКЛЕ',
	},
	{
		value: '200235',
		label: 'КУРЬЕР НА АВТОМОБИЛЕ, МИКРОАВТОБУСЕ ИЛИ ГРУЗОВОМ АВТОМОБИЛЕ',
	},
	{
		value: '200236',
		label: 'ОБСЛУЖИВАНИЕ ДОМОВ',
	},
	{
		value: '200237',
		label: 'УСЛУГИ ПО ПОГРУЗКЕ И РАЗГРУЗКЕ',
	},
	{
		value: '200238',
		label: 'СТОРОЖ БЕЗ ОРУЖИЯ',
	},
	{
		value: '200239',
		label: 'СТОРОЖ С ОРУЖИЕМ',
	},
	{
		value: '200240',
		label: 'ОБИВКА',
	},
	{
		value: '200241',
		label: 'ОПЕРАТОР, ВЕДУЩИЙ, КАМЕРА, ТЕЛЕВИДЕНИЕ В ЗОНЕ КОНФЛИКТА',
	},
	{
		value: '200242',
		label: 'ОПЕРАТОР, ВЕДУЩИЙ, КАМЕРА, ТЕЛЕВИДЕНИЕ ДРУГИЕ ЗОНЫ',
	},
	{
		value: '200243',
		label: 'ГЕОДЕЗИСТ',
	},
	{
		value: '200244',
		label: 'ТОРЕОДОР',
	},
	{
		value: '200245',
		label: 'РАБОТА НА ВЫСОТЕ',
	},
	{
		value: '200246',
		label: 'НАЗЕМНЫЙ ТРАНСПОРТ: БЕЗОПАСНЫЕ ПЕРЕВОЗКИ',
	},
	{
		value: '200247',
		label: 'НАЗЕМНЫЙ ТРАНСПОРТ: ТЯЖЕЛЫЕ ГРУЗЫ',
	},
	{
		value: '200248',
		label: 'НАЗЕМНЫЙ ТРАНСПОРТ: ОПАСНЫЕ ВЕЩЕСТВА (ТОКСИЧЕСКИЕ)',
	},
	{
		value: '200249',
		label: 'НАЗЕМНЫЙ ТРАНСПОРТ:',
	},
	{
		value: '200250',
		label: 'НАЗЕМНЫЙ ПЕРЕВОЗКИ: ПЕРЕВОЗКА ЛЮДЕЙ',
	},
	{
		value: '200251',
		label: 'ФИЗИОТЕРАПЕВТ/КИНЕЗИОЛОГИЯ',
	},
	{
		value: '200252',
		label: 'МАНУАЛЬНЫЙ ТЕРАПЕВТ/ОСТЕОПАТ',
	},
	{
		value: '200253',
		label: 'КУЗНЕЦ',
	},
	{
		value: '200254',
		label: 'ПЕНСИОНЕР',
	},
	{
		value: '200255',
		label: 'ОБЩАЯ МЕДИЦИНА',
	},
	{
		value: '200256',
		label: 'СТУДЕНТ',
	},
	{
		value: '200257',
		label: 'ПРОФЕССИОНАЛЬНЫЙ ФУТБОЛИСТ',
	},
	{
		value: '200258',
		label: 'СТУДЕНТ-ПИЛОТ',
	},
	{
		value: '200259',
		label: 'ИНСТРУКТОР ПОЛЕТОВ',
	},
	{
		value: '200260',
		label: 'ПИЛОТ < СТАЖ ЛЕТ',
	},
	{
		value: '200261',
		label: 'ПИЛОТ - СКОЛЬКО ЛЕТ',
	},
	{
		value: '200262',
		label: 'МАЛЯР (НЕ РАБОТАЕТ НА ВЫСОТЕ)',
	},
	{
		value: '200263',
		label: 'ВОЕННЫЙ',
	},
	{
		value: '200264',
		label: 'ПЕРЕВОЗКА ОГНЕННОГО БЫКА',
	},
	{
		value: '200265',
		label: 'ЛОТЕРЕИ, АДМИНИСТРАТОР',
	},
	{
		value: '200266',
		label: 'БУТАН, ДОСТАВКА',
	},
	{
		value: '200267',
		label: 'ВЕТЕРИНАР',
	},
	{
		value: '200268',
		label: 'АНТИБИОТИКИ',
	},
	{
		value: '200269',
		label: 'САНИТАРНО-ТЕХНИЧЕСКИЙ ПОМОЩНИК',
	},
	{
		value: '200270',
		label: 'АКУШЕР',
	},
	{
		value: '200271',
		label: 'КЛИНИКА, ПОМОЩНИК',
	},
	{
		value: '200272',
		label: 'ОПТИК',
	},
	{
		value: '200273',
		label: 'ПОДОЛОГ',
	},
	{
		value: '200274',
		label: 'МАТЕМАТИК',
	},
	{
		value: '200275',
		label: 'ПЕДАГОГ',
	},
	{
		value: '200276',
		label: 'УЧИТЕЛЬ ЯЗЫКОВ',
	},
	{
		value: '200277',
		label: 'ИНСПЕКТОР ОБРАЗОВАТЕЛЬНЫЙ',
	},
	{
		value: '200278',
		label: 'ВОСПИТАТЕЛЬ',
	},
	{
		value: '200279',
		label: 'ПРОФЕССОР ИНФОРМАТИКИ',
	},
	{
		value: '200280',
		label: 'КИНЕМАТОГРАФИЯ, ОПЕРАТОР',
	},
	{
		value: '200281',
		label: 'УЧИТЕЛЬ ТАНЦЕВ',
	},
	{
		value: '200282',
		label: 'ТВ ПРОИЗВОДСТВО-ПРОДАЖА',
	},
	{
		value: '200283',
		label: 'БОЕЦ БЫКОВ',
	},
	{
		value: '200284',
		label: 'REJONEER КОРРИДА',
	},
	{
		value: '200285',
		label: 'БОЕЦ ТЕЛЯТ',
	},
	{
		value: '200286',
		label: 'БИНГО, РАБОТНИК',
	},
	{
		value: '200287',
		label: 'УЧИТЕЛЬ ГИМНАСТИКИ',
	},
	{
		value: '200288',
		label: 'ПСИХОЛОГ',
	},
	{
		value: '200289',
		label: 'СОВЕТНИК',
	},
	{
		value: '200290',
		label: 'СЕПТИКИ, УБОРЩИК',
	},
	{
		value: '200291',
		label: 'КОСМЕТОЛОГ',
	},
	{
		value: '200292',
		label: 'ТЮРЬМЫ, ДИРЕКТОР',
	},
	{
		value: '200293',
		label: 'ТЮРЬМЫ, НАЧАЛЬНИК СЛУЖБЫ',
	},
	{
		value: '200294',
		label: 'ВРАЧ В ТЮРЬМЕ',
	},
	{
		value: '200295',
		label: 'САДОВНИК',
	},
	{
		value: '200296',
		label: 'МЯСНИК СВИНИНА',
	},
	{
		value: '200297',
		label: 'МЯСНИК, УБОЙ ЖИВОТНЫХ',
	},
	{
		value: '200298',
		label: 'ОБУВЩИК',
	},
	{
		value: '200299',
		label: 'СЛЕСАРЬ-САНТЕХНИК',
	},
	{
		value: '200300',
		label: 'СТЕКОЛЬЩИК',
	},
	{
		value: '200301',
		label: 'СТЕКЛО, ГРАВЕР СТЕКЛА',
	},
	{
		value: '200302',
		label: 'ПРОФЕССИОНАЛЬНЫЙ ВОДИТЕЛЬ, ЧАСТНЫЙ АВТОМОБИЛЬ',
	},
	{
		value: '200303',
		label: 'МАШИНИСТ НА ЯРМАРКАХ',
	},
	{
		value: '200304',
		label: 'УБОРЩИК МУСОРОПРОВОДОВ',
	},
	{
		value: '200305',
		label: 'ЖИВОТНЫЕ, ПИТОМНИК',
	},
	{
		value: '200306',
		label: 'ПУТЕШЕСТВЕННИК',
	},
	{
		value: '200307',
		label: 'ТЮРЬМЫ, АДМИНИСТРАТОР',
	},
	{
		value: '200308',
		label: 'ОФИЦИАНТ',
	},
	{
		value: '200309',
		label: 'ЧЕРТЕЖНИК',
	},
	{
		value: '200310',
		label: 'СМОТРИТЕЛЬ',
	},
	{
		value: '200311',
		label: 'ТКАЧ',
	},
	{
		value: '200312',
		label: 'УЛИЧНЫЙ ПРОДАВЕЦ',
	},
	{
		value: '200314',
		label: 'МАЛЯР (РАБОТАЕТ НА ВЫСОТЕ)',
	},
	{
		value: '200315',
		label: 'РАБОТНИК РЫБНОГО МАГАЗИНА',
	},
	{
		value: '200316',
		label: 'РАБОТНИК МЕЛЬНИЦЫ',
	},
	{
		value: '200317',
		label: 'ВОДИТЕЛЬ СЕЛЬСКОХОЗЯЙСТВЕННОЙ ТЕХНИКИ',
	},
	{
		value: '200318',
		label: 'ФЕРМЕР',
	},
	{
		value: '200320',
		label: 'ОРТОПЕД',
	},
	{
		value: '200321',
		label: 'ОБЛИЦОВЩИК-МРАМОРЩИК',
	},
	{
		value: '200322',
		label: 'ПОЧТАЛЬОН',
	},
	{
		value: '200325',
		label: 'ХИМИК БЕЗ ОПАСНЫХ ВЕЩЕСТВ',
	},
	{
		value: '200327',
		label: 'ИНЖЕНЕР, КРОМЕ МИН',
	},
	{
		value: '200328',
		label: 'ГЕОГРАФ',
	},
	{
		value: '200331',
		label: 'ХУДОЖНИК ТЕХНИЧЕСКИЙ',
	},
	{
		value: '200332',
		label: 'ТОПОГРАФ, С ПОСЕЩЕНИЕМ ОБЪЕКТОВ',
	},
	{
		value: '200333',
		label: 'ВРАЧ-ОБЩАЯ МЕДИЦИНА',
	},
	{
		value: '200334',
		label: 'МЕДИЦИНСКИЙ СПЕЦИАЛИСТ',
	},
	{
		value: '200335',
		label: 'РАДИОЛОГ',
	},
	{
		value: '200336',
		label: 'ВРАЧ ДРУГОЕ',
	},
	{
		value: '200337',
		label: 'ВРАЧ-СТОМАТОЛОГ',
	},
	{
		value: '200338',
		label: 'ФАРМАЦЕВТ',
	},
	{
		value: '200339',
		label: 'САНИТАРНЫЙ ПЕРСОНАЛ',
	},
	{
		value: '200341',
		label: 'ФИЗИОТЕРАПЕВТ',
	},
	{
		value: '200343',
		label: 'ВОДИТЕЛЬ СКОРОЙ ПОМОЩИ',
	},
	{
		value: '200345',
		label: 'ЛАБОРАНТ, РАБОТА В',
	},
	{
		value: '200346',
		label: 'СИСТЕМНЫЙ АДМИНИСТРАТОР, БЕЗ ВЫЕЗДОВ',
	},
	{
		value: '200347',
		label: 'ЭКОНОМИСТ',
	},
	{
		value: '200349',
		label: 'НОТАРИУС',
	},
	{
		value: '200350',
		label: 'ЮРИСТКОНСУЛЬТ',
	},
	{
		value: '200351',
		label: 'УЧИТЕЛЬ ОБРАЗОВАТЕЛЬНЫЙ, КРОМЕ СПОРТА',
	},
	{
		value: '200354',
		label: 'ЖУРНАЛИСТ БЕЗ ОПАСНЫХ РЕПОРТАЖЕЙ',
	},
	{
		value: '200355',
		label: 'ДИЗАЙНЕР',
	},
	{
		value: '200356',
		label: 'ФОТОГРАФ В СТУДИИ',
	},
	{
		value: '200357',
		label: 'ФОТОГРАФ ПРЕССЫ',
	},
	{
		value: '200359',
		label: 'ПРОФЕССИОНАЛЬНЫЙ СПОРТСМЕН',
	},
	{
		value: '200361',
		label: 'СОЦИАЛЬНЫЙ ПОМОЩНИК',
	},
	{
		value: '200362',
		label: 'ЭКСПЕРТ ПО СОЦИАЛЬНО-ТРУДОВЫМ ОТНОШЕНИЯМ',
	},
	{
		value: '200363',
		label: 'МЕНЕДЖЕР ПО РЕКЛАМЕ',
	},
	{
		value: '200364',
		label: 'СВЯЗИ С ОБЩЕСТВЕННОСТЬЮ',
	},
	{
		value: '200365',
		label: 'МЕНЕДЖЕР, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200366',
		label: 'СМЕТЧИК С ВИЗИТАМИ НА ОБЪЕКТ',
	},
	{
		value: '200367',
		label: 'АДМИНИСТРАТОР (НЕ ТЮРЬМЫ) БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200368',
		label: 'АДМИНИСТРАТОР (НЕ ТЮРЬМЫ) С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200369',
		label: 'РЕСЕПШИОНИСТ',
	},
	{
		value: '200370',
		label: 'ПРОДАВЕЦ В МАГАЗИНЕ',
	},
	{
		value: '200371',
		label: 'ПРОДАВЕЦ, ЧАСТЫЕ ПОЕЗДКИ',
	},
	{
		value: '200372',
		label: 'COMERCIO,PROPIETARIO DE',
	},
	{
		value: '200373',
		label: 'ТОРГОВЛЯ, СОБСТВЕННИК',
	},
	{
		value: '200374',
		label: 'МЕНЕДЖЕР ПО ПРОДАЖАМ НА МЕСТЕ',
	},
	{
		value: '200375',
		label: 'МЕДИЦИНСКИЙ ПОСЕТИТЕЛЬ',
	},
	{
		value: '200376',
		label: 'КОММЕРЧЕСКИЙ АГЕНТ, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200377',
		label: 'АГЕНТ ПО ПРОДАЖАМ, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200378',
		label: 'КОММЕРЧЕСКИЙ АГЕНТ, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200379',
		label: 'АГЕНТ ПО ПРОДАЖАМ, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200380',
		label: 'СТРАХОВОЙ АГЕНТ, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200381',
		label: 'ТОРГОВЛЯ, РАБОТНИК',
	},
	{
		value: '200382',
		label: 'УЛИЧНЫЙ ПРОДАВЕЦ',
	},
	{
		value: '200383',
		label: 'ПОВАР',
	},
	{
		value: '200385',
		label: 'ДОМРАБОТНИЦА',
	},
	{
		value: '200386',
		label: 'ВОЗДУШНЫЙ ЭКИПАЖ',
	},
	{
		value: '200387',
		label: 'УБОРКА ВНУТРЕННИХ ПОМЕЩЕНИЙ, РАБОТНИК',
	},
	{
		value: '200388',
		label: 'ПАРИКМАХЕР',
	},
	{
		value: '200390',
		label: 'ПОЖАРНЫЙ',
	},
	{
		value: '200391',
		label: 'ТЮРЬМЫ ВСПОМОГАТЕЛЬНЫЙ ПЕРСОНАЛ',
	},
	{
		value: '200392',
		label: 'НАЦИОНАЛЬНАЯ ПОЛИЦИЯ',
	},
	{
		value: '200393',
		label: 'МЕСТНАЯ ПОЛИЦИЯ',
	},
	{
		value: '200394',
		label: 'СТОРОЖ, С ОГНЕСТРЕЛЬНЫМ ОРУЖИЕМ',
	},
	{
		value: '200395',
		label: 'ОХРАНА',
	},
	{
		value: '200396',
		label: 'СТОРОЖ, БЕЗ ОГНЕСТРЕЛЬНОГО ОРУЖИЯ',
	},
	{
		value: '200397',
		label: 'СЕЛЬСКОХОЗЯЙСТВЕННАЯ ФЕРМА ЖИВОТНОВОДСТВО, РАБОЧИЙ',
	},
	{
		value: '200399',
		label: 'ОХРАНА ЛЕСА',
	},
	{
		value: '200400',
		label: 'РЫБАЛКА',
	},
	{
		value: '200402',
		label: 'МЕТАЛЛУРГИЯ, РАБОТНИК (БЕЗ ЛИТЬЯ)',
	},
	{
		value: '200403',
		label: 'МЕТАЛЛИЧЕСКИЕ СТОЛЯРНЫЕ РАБОТЫ',
	},
	{
		value: '200406',
		label: 'ТЕКСТИЛЬ, ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200407',
		label: 'ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200408',
		label: 'МЯСНИК С УБОЕМ',
	},
	{
		value: '200409',
		label: 'МЯСНИК БЕЗ УБОЯ',
	},
	{
		value: '200410',
		label: 'ПЕКАРЬ',
	},
	{
		value: '200411',
		label: 'ОБОЙЩИК',
	},
	{
		value: '200412',
		label: 'ДЕКОРАТОР-ИНТЕРЬЕРОВ',
	},
	{
		value: '200414',
		label: 'ОБУВЬ, ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200417',
		label: 'ТОКАРЬ',
	},
	{
		value: '200418',
		label: 'КОНДИЦИОНЕР, МЕХАНИК-МОНТАЖНИК',
	},
	{
		value: '200419',
		label: 'МОНТАЖНАЯ ЛИНИЯ, ОПЕРАТОР',
	},
	{
		value: '200420',
		label: 'СИСТЕМНЫЙ АДМИНИСТРАТОР, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200421',
		label: 'МЕХАНИК',
	},
	{
		value: '200422',
		label: 'АВТОМОБИЛЬНЫЙ МЕХАНИК',
	},
	{
		value: '200423',
		label: 'ОБСЛУЖИВАНИЕ ЗДАНИЙ',
	},
	{
		value: '200424',
		label: 'ЭЛЕКТРОМЕХАНИК',
	},
	{
		value: '200425',
		label: 'ЭЛЕКТРОЭНЕРГИЯ НИЗКОВОЛЬТНАЯ, УСТАНОВКА',
	},
	{
		value: '200426',
		label: 'НИЗКОВОЛЬТНЫЙ ЭЛЕКТРИК',
	},
	{
		value: '200427',
		label: 'ВЫСОКОВОЛЬТНЫЙ ЭЛЕКТРИК',
	},
	{
		value: '200428',
		label: 'ИЗОЛЯЦИЯ, УСТАНОВКА',
	},
	{
		value: '200430',
		label: 'ГАЗОСВАРЩИК',
	},
	{
		value: '200431',
		label: 'ЭЛЕКТРОСВАРЩИК',
	},
	{
		value: '200432',
		label: 'МЕТАЛЛ, СБОРКА КОНСТРУКЦИЙ',
	},
	{
		value: '200433',
		label: 'ПЕЧАТЬ, МАСТЕРСКАЯ',
	},
	{
		value: '200434',
		label: 'ХУДОЖНИК ИНТЕРЬЕРОВ',
	},
	{
		value: '200435',
		label: 'ЦЕМЕНТ, ПРОИЗВОДСТВО',
	},
	{
		value: '200436',
		label: 'СТРОИТЕЛЬСТВО',
	},
	{
		value: '200438',
		label: 'ШТУКАТУР',
	},
	{
		value: '200439',
		label: 'СКЛАД, ПОГРУЗКИ И РАЗГРУЗКИ',
	},
	{
		value: '200440',
		label: 'РУЧНАЯ УПАКОВКА',
	},
	{
		value: '200441',
		label: 'ВОДИТЕЛЬ ЭВАКУАТОРА',
	},
	{
		value: '200442',
		label: 'ВОДИТЕЛЬ ЭКСКАВАТОРА',
	},
	{
		value: '200443',
		label: 'ДОСТАВКА БЕЗ МОПЕДА/МОТОЦИКЛА',
	},
	{
		value: '200444',
		label: 'ВОДИТЕЛЬ ТАКСИ',
	},
	{
		value: '200445',
		label: 'ВОДИТЕЛЬ АВТОБУСА',
	},
	{
		value: '200446',
		label: 'ВОДИТЕЛЬ ГРУЗОВОГО АВТОМОБИЛЯ ДРУГИЕ',
	},
	{
		value: '200447',
		label: 'ВОДИТЕЛЬ ТЯЖЕЛОЙ ТЕХНИКИ',
	},
	{
		value: '200448',
		label: 'ПЕРЕВОЗЧИК',
	},
	{
		value: '200452',
		label: 'МЕНЕДЖЕР, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200453',
		label: 'МЕНЕДЖЕР, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200454',
		label: 'ДИРЕКЦИЯ, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200456',
		label: 'НАЦИОНАЛЬНАЯ ГВАРДИЯ',
	},
	{
		value: '200457',
		label: 'ФЕРМЕР РАБОТА С ТЕХНИКОЙ',
	},
	{
		value: '200458',
		label: 'СТОЛЯР БЕЗ ПИЛЫ',
	},
	{
		value: '200459',
		label: 'МЕБЕЛЬ, СБОР И МОНТАЖ',
	},
	{
		value: '200460',
		label: 'СТОЛЯР С ПИЛОЙ',
	},
	{
		value: '200461',
		label: 'РАБОТНИК СКЛАДА',
	},
	{
		value: '200462',
		label: 'ВОДИТЕЛЬ МИКРОАВТОБУСА',
	},
	{
		value: '200463',
		label: 'АВИАЦИОННЫЙ МЕХАНИК',
	},
	{
		value: '200466',
		label: 'АСТРОНОМ',
	},
	{
		value: '200467',
		label: 'СТОМАТОЛОГИЧЕСКИЙ ПРОТЕЗИСТ',
	},
	{
		value: '200468',
		label: 'АРХИТЕКТОР, БЕЗ ПОСЕЩЕНИЯ ОБЪЕКТОВ',
	},
	{
		value: '200469',
		label: 'АРХИТЕКТОР, С ПОСЕЩЕНИЕМ ОБЪЕКТОВ',
	},
	{
		value: '200470',
		label: 'ТОПОГРАФ, БЕЗ ПОСЕЩЕНИЯ ОБЪЕКТОВ',
	},
	{
		value: '200471',
		label: 'СУДОВЛАДЕЛЕЦ',
	},
	{
		value: '200474',
		label: 'ЭРГОТЕРАПЕВТ',
	},
	{
		value: '200475',
		label: 'МАССАЖИСТ',
	},
	{
		value: '200479',
		label: 'ИТ, СИСТЕМНЫЙ АНАЛИТИК',
	},
	{
		value: '200480',
		label: 'НАЛОГОВЫЙ КОНСУЛЬТАНТ',
	},
	{
		value: '200481',
		label: 'МАСТЕР ХОЛОДИЛЬНИКОВ',
	},
	{
		value: '200483',
		label: 'АДВОКАТ СУДЕЙ',
	},
	{
		value: '200485',
		label: 'ПРОМЫШЛЕННОСТЬ, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200486',
		label: 'ПРОМЫШЛЕННОСТЬ, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200487',
		label: 'АДМИНИСТРАТИВНЫЙ МЕНЕДЖЕР',
	},
	{
		value: '200489',
		label: 'ФИЛОЛОГ',
	},
	{
		value: '200490',
		label: 'ИНСТРУКТОР ПО ВОЖДЕНИЮ',
	},
	{
		value: '200491',
		label: 'ИНСТРУКТОР ПО ПЛАВАНИЮ',
	},
	{
		value: '200492',
		label: 'ДЕКОРИРОВАНИЕ ОБЪЕКТА ВНУТРИ',
	},
	{
		value: '200494',
		label: 'КАМЕРА, ОПЕРАТОР',
	},
	{
		value: '200495',
		label: 'МУЗЫКАНТ',
	},
	{
		value: '200497',
		label: 'УЧИТЕЛЬ СПОРТА, КРОМЕ ПЛАВАНИЯ',
	},
	{
		value: '200499',
		label: 'ПЕРЕВОДЧИК',
	},
	{
		value: '200500',
		label: 'КОММЕРЧЕСКИЙ ДИРЕКТОР, БЕЗ ЧАСТЫХ ПОЕЗДОК',
	},
	{
		value: '200501',
		label: 'СМЕТЧИК БЕЗ ПОСЕЩЕНИЯ ОБЪЕКТОВ',
	},
	{
		value: '200502',
		label: 'ФИНАНСОВЫЙ ПОСРЕДНИК, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200504',
		label: 'ТЕЛЕФОНИСТ',
	},
	{
		value: '200505',
		label: 'АДМИНИСТРАТОР В ТЮРЬМАХ',
	},
	{
		value: '200506',
		label: 'ТОРГОВЫЙ ПРЕДСТАВИТЕЛЬ, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200507',
		label: 'ТОРГОВЫЙ ПРЕДСТАВИТЕЛЬ, БЕЗ ПУТЕШЕСТВИЙ',
	},
	{
		value: '200508',
		label: 'АГЕНТ ПО НЕДВИЖИМОСТИ',
	},
	{
		value: '200509',
		label: 'МОДЕЛЬ РЕКЛАМЫ',
	},
	{
		value: '200510',
		label: 'ФРУКТОВЫЙ МАГАЗИН, РАБОТНИК',
	},
	{
		value: '200511',
		label: 'КОНСЬЕРЖ',
	},
	{
		value: '200512',
		label: 'УБОРКА СНАРУЖИ, РАБОТНИК',
	},
	{
		value: '200513',
		label: 'АВТОМОБИЛИ, ЧИСТКА',
	},
	{
		value: '200514',
		label: 'ПРАЧЕЧНАЯ, РАБОТНИК',
	},
	{
		value: '200515',
		label: 'ПОЛИЦИЯ, ИНСПЕКТОР',
	},
	{
		value: '200516',
		label: 'ТЮРЬМА, ОФИЦЕР',
	},
	{
		value: '200518',
		label: 'ТУРИСТИЧЕСКИЙ ГИД',
	},
	{
		value: '200519',
		label: 'ДЕЗИНФЕКЦИЯ-ДЕЗИНСЕКЦИЯ',
	},
	{
		value: '200520',
		label: 'СЕЛЬСКОХОЗЯЙСТВЕННЫЙ ТЕХНИК',
	},
	{
		value: '200521',
		label: 'ЖИВОТНАЯ ФЕРМА БЕЗ ДИКОГО СКОТА',
	},
	{
		value: '200522',
		label: 'ЖИВОТНАЯ ФЕРМА, МЯСО И ХЛОПОК',
	},
	{
		value: '200523',
		label: 'МЕДИК-ПСИХИАТР',
	},
	{
		value: '200524',
		label: 'САДОВНИК',
	},
	{
		value: '200525',
		label: 'ОТВЕТСТВЕННЫЙ РУКОВОДИТЕЛЬ, ИСКЛЮЧИТЕЛЬНОЕ НАДЗОР',
	},
	{
		value: '200526',
		label: 'МЕТАЛЛУРГИЯ, РАБОЧИЙ В ПЕЧИ',
	},
	{
		value: '200527',
		label: 'МЕТАЛЛУРГИЯ, ВЫПЛАВКА',
	},
	{
		value: '200528',
		label: 'СТАЛЬ, ПРОИЗВОДСТВО ПРОДУКТОВ ИЗ СТАЛИ',
	},
	{
		value: '200529',
		label: 'ПРОИЗВОДСТВО МЕТАЛЛИЧЕСКОЙ ТАРЫ',
	},
	{
		value: '200530',
		label: 'РАБОТНИК ПИЛОРАМЫ',
	},
	{
		value: '200531',
		label: 'ХИМЧИСТКА, РАБОТА В',
	},
	{
		value: '200532',
		label: 'СТОРОЖ',
	},
	{
		value: '200533',
		label: 'КОНСЕРВНАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200534',
		label: 'ПЕКАРЬ-КОНДИТЕР',
	},
	{
		value: '200535',
		label: 'ИЗГОТОВЛЕНИЕ',
	},
	{
		value: '200537',
		label: 'МАСТЕР КОЖАНЫХ ИЗДЕЛИЙ',
	},
	{
		value: '200539',
		label: 'ФРЕЗЕРНЫЙ СТАНОК',
	},
	{
		value: '200540',
		label: 'ИЗГОТОВЛЕНИЕ КОНДИЦИОНЕРОВ',
	},
	{
		value: '200541',
		label: 'УСТАНОВКА ЛЕГКОЙ ИЛИ ТОЧНОЙ ТЕХНИКИ',
	},
	{
		value: '200542',
		label: 'УСТАНОВКА ТЯЖЕЛОЙ ТЕХНИКИ',
	},
	{
		value: '200543',
		label: 'МЕХАНИК МОТОЦИКЛОВ',
	},
	{
		value: '200544',
		label: 'ЛИФТЫ, МОНТАЖ',
	},
	{
		value: '200545',
		label: 'ЭЛЕКТРОННЫЕ АПАРАТЫ, РЕМОНТ',
	},
	{
		value: '200546',
		label: 'ТЕХНИК РАДИО И ТЕЛЕВИДЕНИЕ',
	},
	{
		value: '200547',
		label: 'ДОМАШНЯЯ ТЕХНИКА, РЕМОНТ',
	},
	{
		value: '200548',
		label: 'ТЕЛЕФОННОЕ ОБОРУДОВАНИЕ, МОНТАЖ УСТАНОВКА',
	},
	{
		value: '200549',
		label: 'ИЗГОТОВЛЕНИЕ ЭЛЕКТРОКАБЕЛЕЙ',
	},
	{
		value: '200550',
		label: 'УСТАНОВКА ВЫВЕСОК',
	},
	{
		value: '200551',
		label: 'ГАЗ, УСТАНОВКА',
	},
	{
		value: '200552',
		label: 'ОТОПЛЕНИЕ, УСТАНОВКА',
	},
	{
		value: '200553',
		label: 'ОТОПЛЕНИЕ, ОБСЛУЖИВАНИЕ',
	},
	{
		value: '200554',
		label: 'РАБОТА С ЛИСТОВЫМ МЕТАЛЛОМ',
	},
	{
		value: '200555',
		label: 'ШИФЕР, УСТАНОВКА И МОНТАЖ КРЫШИ',
	},
	{
		value: '200556',
		label: 'АЛЮМИНИЙ, УСТАНОВКА',
	},
	{
		value: '200557',
		label: 'ЮВЕЛИР',
	},
	{
		value: '200558',
		label: 'ПРОИЗВОДСТВО ФАРФОРА',
	},
	{
		value: '200560',
		label: 'ПРОИЗВОДСТВО ПЛАСТИКА',
	},
	{
		value: '200562',
		label: 'МОРЯК',
	},
	{
		value: '200563',
		label: 'АВТОМАЛЯР',
	},
	{
		value: '200564',
		label: 'ЦЕМЕНТ, ПРОИЗВОДСТВО',
	},
	{
		value: '200565',
		label: 'ПЛИТОЧНИК',
	},
	{
		value: '200566',
		label: 'БЕТОН, СБОР КОНСТРУКЦИЙ',
	},
	{
		value: '200567',
		label: 'ОПАЛУБЩИК',
	},
	{
		value: '200568',
		label: 'МОНТАЖ СТРОИТЕЛЬНЫХ МЕТАЛЛОКОНСТРУКЦИЙ',
	},
	{
		value: '200569',
		label: 'МАСТЕР ПО ПАРКЕТУ И ЛАМИНАТУ',
	},
	{
		value: '200570',
		label: 'ЛЕПНИК АРХИТЕКТУРНЫХ КОНСТРУКЦИЙ ИЗ ГИПСА',
	},
	{
		value: '200572',
		label: 'ЖАЛЮЗИ УСТАНОВКИ',
	},
	{
		value: '200573',
		label: 'ГРУЗЧИК ПОРТОВОЙ',
	},
	{
		value: '200574',
		label: 'ВЫКЛАДКА ТОВАРОВ В СУПЕРМАРКЕТЕ',
	},
	{
		value: '200575',
		label: 'СБОРКА-ПРЕССОВАНИЕ',
	},
	{
		value: '200576',
		label: 'КУРЬЕР ДОСТАВКИ',
	},
	{
		value: '200578',
		label: 'БЕНЗИН, ПРОДАВЕЦ',
	},
	{
		value: '200579',
		label: 'ОГНЕТУШИТЕЛИ, УСТАНОВКА И ПЕРЕЗАРЯДКА',
	},
	{
		value: '200580',
		label: 'АКТЕР БЕЗ ОСОБЫХ РИСКОВ',
	},
	{
		value: '200581',
		label: 'СТРАХОВАНИЕ БЕЗ ПУТЕШЕСТВИЙ, РАБОТНИК',
	},
	{
		value: '200582',
		label: 'СТРАХОВОЙ КОММЕРЧЕСКИЙ ТЕХНИК',
	},
	{
		value: '200583',
		label: 'СТРАХОВАНИЕ С ПУТЕШЕСТВИЯМИ, РАБОТНИК',
	},
	{
		value: '200585',
		label: 'ЭКСПЕРТ ПО ОЦЕНКЕ',
	},
	{
		value: '200587',
		label: 'ФЕРМЕР БЕЗ ПРИМЕНЕНИЯ ТЕХНИКИ',
	},
	{
		value: '200588',
		label: 'ЛЕСОРУБ С МЕХАНИЧЕСКОЙ ПИЛОЙ',
	},
	{
		value: '200589',
		label: 'СКЛАД, РУКОВОДИТЕЛЬ (ИСКЛЮЧИТЕЛЬНО НАДЗОР)',
	},
	{
		value: '200590',
		label: 'ШАХТЕР БЕЗ ВЗРЫВЧАТКИ',
	},
	{
		value: '200591',
		label: 'ВИННЫЙ ЗАВОД, ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200592',
		label: 'КАМЕНЩИК БЕЗ ВЗРЫВЧАТКИ',
	},
	{
		value: '200593',
		label: 'БОЙНЯ, РАБОТА В',
	},
	{
		value: '200594',
		label: 'СТОЛЯР С ПИЛОЙ',
	},
	{
		value: '200595',
		label: 'ЛИФТЫ, РЕМОНТ',
	},
	{
		value: '200596',
		label: 'АНТЕННЫ, МОНТАЖ УСТАНОВКА',
	},
	{
		value: '200597',
		label: 'БАССЕЙНЫ, ОБСЛУЖИВАНИЕ',
	},
	{
		value: '200598',
		label: 'ХУДОЖЕСТВЕННАЯ ГРАФИКА, РАБОТНИК',
	},
	{
		value: '200599',
		label: 'МАЛЯР ВНЕШНИЙ',
	},
	{
		value: '200600',
		label: 'ПОДРЯДЧИК',
	},
	{
		value: '200601',
		label: 'СТРОИТЕЛЬСТВО, КОНТРОЛЬ КАЧЕСТВА',
	},
	{
		value: '200602',
		label: 'ПЛОТНИК БЕЗ ПИЛЫ',
	},
	{
		value: '200603',
		label: 'СТРОИТЕЛЬСТВО, ПОМОЩНИК',
	},
	{
		value: '200604',
		label: 'ФИЗИК, БЕЗ ЯДЕРНОГО РИСКА',
	},
	{
		value: '200605',
		label: 'ЯДЕРНЫЙ ФИЗИК',
	},
	{
		value: '200606',
		label: 'МЕТЕОРОЛОГ',
	},
	{
		value: '200609',
		label: 'БОЦМАН',
	},
	{
		value: '200610',
		label: 'ЗООЛОГ',
	},
	{
		value: '200611',
		label: 'СУДЕБНЫЙ МЕДИЦИНСКИЙ ЭКСПЕРТ',
	},
	{
		value: '200613',
		label: 'ОФТАЛЬМОЛОГ',
	},
	{
		value: '200618',
		label: 'СУДЕБНЫЙ СЕКРЕТАРЬ',
	},
	{
		value: '200620',
		label: 'РЕГИСТРАТОР ИМУЩЕСТВА',
	},
	{
		value: '200622',
		label: 'СОЦИОЛОГ',
	},
	{
		value: '200623',
		label: 'МЕТЕОРОЛОГИ В ОПАСНЫХ ОБСЕРВАТОРИЯХ',
	},
	{
		value: '200625',
		label: 'СВЯЩЕННИК',
	},
	{
		value: '200626',
		label: 'СВЯЩЕННОСЛУЖИТЕЛЬ',
	},
	{
		value: '200627',
		label: 'ХУДОЖНИК',
	},
	{
		value: '200628',
		label: 'РЕСТАВРАТОР ПРОИЗВЕДЕНИЙ ИСКУССТВА',
	},
	{
		value: '200629',
		label: 'РЕСТАВРАТОР СТРОИТЕЛЬ',
	},
	{
		value: '200630',
		label: 'ХУДОЖЕСТВЕННЫЙ ПРЕДСТАВИТЕЛЬ',
	},
	{
		value: '200631',
		label: 'ПРОФЕССИОНАЛ КОРРИДА',
	},
	{
		value: '200632',
		label: 'ТЕХНИК НАСТОЛЬНОГО ТЕННИСА',
	},
	{
		value: '200634',
		label: 'СПОРТИВНЫЙ ТРЕНЕР',
	},
	{
		value: '200640',
		label: 'АСТРОЛОГ',
	},
	{
		value: '200641',
		label: 'СОВЕТНИК ПО РАБОТЕ',
	},
	{
		value: '200646',
		label: 'КАССИР/КАССИР',
	},
	{
		value: '200648',
		label: 'ФИНАНСОВЫЙ ПОСРЕДНИК, С ПУТЕШЕСТВИЯМИ',
	},
	{
		value: '200649',
		label: 'НАЧАЛЬНИК СТАНЦИИ',
	},
	{
		value: '200650',
		label: 'ИНСПЕКТОР ГОРОДСКИХ АВТОБУСОВ',
	},
	{
		value: '200651',
		label: 'РАСПОРЯДИТЕЛЬ',
	},
	{
		value: '200652',
		label: 'РАССЫЛЬНЫЙ',
	},
	{
		value: '200655',
		label: 'ИНТЕРВЬЮЕР',
	},
	{
		value: '200658',
		label: 'ВОДИТЕЛЬ ЛОДКИ ДЛЯ ПАРАСЕЙЛИНГА',
	},
	{
		value: '200665',
		label: 'РАБОТНИК ЧУРЕРИИ (CHURRERIA)',
	},
	{
		value: '200667',
		label: 'КОНСЬЕРЖ ',
	},
	{
		value: '200670',
		label: 'МОЙЩИК СТЕКОЛ',
	},
	{
		value: '200674',
		label: 'ГОРНЫЙ ПРОВОДНИК (С АЛЬПИНИЗМОМ)',
	},
	{
		value: '200675',
		label: 'РАБОТНИК ПОХОРОННОГО БЮРО',
	},
	{
		value: '200677',
		label: 'ФЕРМА, УБОРКА ДОМОВ',
	},
	{
		value: '200679',
		label: 'СПЕЦИАЛИСТ ПО ОРОШЕНИЮ',
	},
	{
		value: '200680',
		label: 'ПРОИЗВОДСТВО ГАЗА',
	},
	{
		value: '200681',
		label: 'ПРОИЗВОДСТВО АНИЛИНА',
	},
	{
		value: '200682',
		label: 'СПЕЦИАЛИСТ ПО ТЕКСТИЛЬНОЙ ПЕЧАТИ',
	},
	{
		value: '200685',
		label: 'СПЕЦИАЛИСТ ПО ГИДРОИЗОЛЯЦИИ ФАСАДОВ И КРЫШ',
	},
	{
		value: '200686',
		label: 'PASAMANERO A MANO',
	},
	{
		value: '200687',
		label: 'PASAMANERO A MAQUINA',
	},
	{
		value: '200688',
		label: 'ТКАЧ РУЧНОГО ТКАЧЕСТВА',
	},
	{
		value: '200692',
		label: 'РАБОТНИК МАСЛИЧНОЙ ПРОМЫШЛЕННОСТИ',
	},
	{
		value: '200693',
		label: 'ПЕРЕРАБОТКА ПРОМЫШЛЕННЫХ СМАЗОК',
	},
	{
		value: '200694',
		label: 'ТАБАЧНАЯ ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200695',
		label: 'СПЕЦИАЛИСТ ПО ИЗГОТОВЛЕНИЮ ИЗДЕЛИЙ ИЗ МЕХА',
	},
	{
		value: '200696',
		label: 'ИЗГОТОВЛЕНИЕ ЗОНТОВ И ТРОСТЕЙ',
	},
	{
		value: '200699',
		label: 'СПЕЦИАЛИСТ ПО ИЗГОТОВЛЕНИЮ ИЗДЕЛИЙ ИЗ КОЖИ',
	},
	{
		value: '200700',
		label: 'ЛАКИРОВЩИК',
	},
	{
		value: '200702',
		label: 'ИЗГОТОВЛЕНИЕ НОЖЕЙ',
	},
	{
		value: '200704',
		label: 'ИЗГОТОВЛЕНИЕ КАРМАННЫХ НОЖЕЙ',
	},
	{
		value: '200707',
		label: 'УСТАНОВКА ПРОМЫШЛЕННЫХ ХОЛОДИЛЬНИКОВ',
	},
	{
		value: '200710',
		label: 'РЕМОНТ ЧАСОВ',
	},
	{
		value: '200711',
		label: 'МЕХАНИК ОРТОПЕДИЧЕСКИХ АППАРАТОВ',
	},
	{
		value: '200713',
		label: 'МОЙКА И СМАЗКА АВТОМОБИЛЕЙ',
	},
	{
		value: '200720',
		label: 'ОПЕРАТОР ПО ЗВУКУ И ОСВЕЩЕНИЮ',
	},
	{
		value: '200725',
		label: 'ПРОИЗВОДСТВО ОЛОВА',
	},
	{
		value: '200729',
		label: 'СПЕЦИАЛИСТ ПО УСТАНОВКЕ СТАЛЬНЫХ КОНСТРУКЦИЙ',
	},
	{
		value: '200731',
		label: 'СЦЕНАРИСТ',
	},
	{
		value: '200732',
		label: 'УСТАНОВКА ШИФЕРА',
	},
	{
		value: '200734',
		label: 'КУЗНЕЦ ДРАГОЦЕННЫХ МЕТАЛЛОВ ( ЗОЛОТО И СЕРЕБРО)',
	},
	{
		value: '200736',
		label: 'ИЗГОТОВЛЕНИЕ ИЗДЕЛИЙ ИЗ РЕЗИНЫ',
	},
	{
		value: '200738',
		label: 'ПРОИЗВОДСТВО И СВАРКА ПЛАСТИКА',
	},
	{
		value: '200739',
		label: 'ВУЛКАНИЗАЦИЯ ШИН',
	},
	{
		value: '200740',
		label: 'ПРОИЗВОДСТВО ЦЕЛУЛОИДА',
	},
	{
		value: '200741',
		label: 'ПРОИЗВОДСТВО КАРТОНА',
	},
	{
		value: '200742',
		label: 'РАБОТНИК ФАБРИКИ ПО ИЗГОТОВЛЕНИЮ БУМАГИ',
	},
	{
		value: '200744',
		label: 'ИЗГОТОВЛЕНИЕ УПАКОВОЧНЫХ ЯЩИКОВ',
	},
	{
		value: '200745',
		label: 'СПЕЦИАЛИСТ ПО ЛИТОГРАФИИ (ЛИТОГРАФ)',
	},
	{
		value: '200746',
		label: 'ПЕРЕПЛЕТЧИК',
	},
	{
		value: '200748',
		label: 'ПРОИЗВОДСТВО МУЗЫКАЛЬНИХ ИНСТРУМЕНТОВ',
	},
	{
		value: '200749',
		label: 'СПЕЦИАЛИСТ ПО НАСТРОЙКЕ МУЗЫКАЛЬНЫХ ИНСТРУМЕНТОВ',
	},
	{
		value: '200750',
		label: 'РЕМЕСТНИК (ИЗГОТОВЛЕНИЕ И ПРОДАЖА КОРЗИН)',
	},
	{
		value: '200751',
		label: 'ИГРУШКИ, ПРОМЫШЛЕННОСТЬ',
	},
	{
		value: '200752',
		label: 'ВОСК, ПРОИЗВОДСТВО ПРОДУКЦИИ',
	},
	{
		value: '200753',
		label: 'ТАКСИДЕРМИСТ',
	},
	{
		value: '200754',
		label: 'КАМЕНЩИК',
	},
	{
		value: '200756',
		label: 'ПЛИТОЧНИК',
	},
	{
		value: '200759',
		label: 'СТРОИТЕЛЬНЫЙ КРОВЕЛЬЩИК',
	},
	{
		value: '200761',
		label: 'ПОЛИРОВЩИК',
	},
	{
		value: '200764',
		label: 'ИЗВЕСТЬ, ПРОИЗВОДСТВО',
	},
	{
		value: '200769',
		label: 'ОПЕРАТОР МАШИНЫ РАЗЛИВА НАПИТКОВ',
	},
	{
		value: '200771',
		label: 'ПРИЕМНИК МЕТАЛЛОЛОМА',
	},
	{
		value: '200773',
		label: 'УБОРКА ПОЛЕЙ',
	},
	{
		value: '200778',
		label: 'ГОРНЫЙ ПРОВОДНИК (БЕЗ АЛЬПИНИЗМА)',
	},
	{
		value: '200779',
		label: 'ОРГАНИЗАТОР ОХОТЫ ( ПРОФЕССИОНАЛЬНЫЙ ОХОТНИК)',
	},
	{
		value: '200782',
		label: 'ЭНОЛОГ',
	},
	{
		value: '200783',
		label: 'ДОСКИ НА ЗАВОДЕ, РАБОЧИЙ',
	},
	{
		value: '200785',
		label: 'СПЕЦИАЛИСТ ПО МОНТАЖУ ТЕЛЕГРАФИЧЕСКОГО ОБОРУДОВАНИЯ',
	},
	{
		value: '200787',
		label: 'АТТРАКЦИОНЫ НА ЯРМАРКАХ С УСТАНОВКОЙ',
	},
	{
		value: '200788',
		label: 'АТТРАКЦИОНЫ НА ЯРМАРКАХ БЕЗ УСТАНОВКИ',
	},
	{
		value: '200789',
		label: 'ОПТИКА, ПРОИЗВОДСТВО ОПТИЧЕСКИХ ПРИБОРОВ',
	},
	{
		value: '200794',
		label: 'ТОТ КТО ПРОБИВАЕТ ТРУБЫ ИЛИ КОПАЕТ КОЛОДЦЫ',
	},
	{
		value: '200795',
		label: 'ОПЕРАТОР КРЕМАТОРНОЙ ПЕЧИ',
	},
	{
		value: '200797',
		label: 'ОПЕРАТОР КРЕМАТОРНОЙ ПЕЧИ',
	},
	{
		value: '200799',
		label: 'МЕР',
	},
	{
		value: '200802',
		label: 'ПОЛИЦИЯ КАТАЛОНИИ',
	},
	{
		value: '200825',
		label: 'ПИЛОТ> СТАЖ ГОДЫ',
	},
	{
		value: '200826',
		label: 'ВОДИТЕЛЬ ГРУЗОВИКА С ЛЕГКОВОСПЛАМЕНЯЮЩИМИСЯ ВЕЩЕСТВАМИ',
	},
	{
		value: '200827',
		label: 'ВОДИТЕЛЬ ГРУЗОВИКА С ТОКСИЧНЫМИ ИЛИ КОРРОЗИОННЫМИ ВЕЩЕСТВАМИ',
	},
	{
		value: '200828',
		label: 'ВОДИТЕЛЬ ГРУЗОВИКА СО ВЗРИВООПАСНЫМИ ВЕЩЕСТВАМИ',
	},
	{
		value: '200829',
		label: 'БЕЗРАБОТНЫЙ',
	},
];
