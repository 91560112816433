import { CONFIG } from 'config/config';
import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('loading', {
	styles: {
		default: {
			loaderColor: CONFIG.colors.white,
			backgroundColor: CONFIG.colors.white,
		},
	},
});
