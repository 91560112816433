import { UiWrapper } from 'coinscrap-webapp-core';

UiWrapper.defineComponent('inputImage', {
	styles: {
		default: {
			backgroundSize: 'cover',
			marginBottom: '10px',
			textComponent: {
				fontWeight: 600,
			},
			containerComponent: {
				// border: "1px solid #fff",
				height: 120,
				width: 200,
			},
		},
	},
});
