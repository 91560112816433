import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	background-color: white;
	border-radius: 10px;
`;

export const Loader = styled.div`
	z-index: 600;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: ${CONFIG.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
`;
