import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

import { isMobile } from 'react-device-detect';

export const IbanWrapper = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
`;

export const IbanInput = styled.input`
	font-family: ${CONFIG.fonts.regular};
	font-size: ${isMobile ? '14px' : '20px'};
	text-align: center;
	border: 1px solid #b1b1b1;
	padding: 12px 25px;
	border-radius: 1px;
	min-width: ${isMobile ? '100%' : '400px'};
	letter-spacing: 1px;
`;

export const IbanLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const InfoWrapper = styled.div`
	display: flex;
`;
