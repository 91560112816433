import { CONFIG } from 'config/config';
import InfoRedImg from 'assets/images/svgs/InfoRedImg';
import { Text, Tooltip, View } from 'coinscrap-webapp-core';
import React, { useState } from 'react';

export default function TooltipSimple({ ...props }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<span
			onClick={() => setIsOpen(!isOpen)}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<Tooltip
				open={isOpen}
				withArrow
				placement='top'
				style={{
					containerComponent: {
						display: 'inline-block',
						cursor: 'pointer',
					},
					popperContentComponent: {
						backgroundColor: '#ffffff',
						borderRadius: 5,
						padding: 18,
						boxShadow: `${CONFIG.colors.primary} 0px 0px 2px 1px`,
						margin: '12px 0',
						border: 0,
					},
					arrowComponent: {
						color: CONFIG.colors.primary,
					},
				}}
				title={''}
				content={
					<View fullWidthSt>
						<Text exampleNormalSt style={{ color: CONFIG.colors.primary }} noT>
							{props.children}
						</Text>
					</View>
				}
			>
				<InfoRedImg />
			</Tooltip>
		</span>
	);
}
