import styled from 'styled-components/macro';

export const QuestionnaireBoxLine = styled.div`
	height: 0.5px;
	width: 80%;
	margin: 15px 0;
	border: 1px solid rgba(102, 102, 102, 0.5);
	transform: rotate(0.19deg);
	opacity: 0.2;
`;
