import styled from 'styled-components/macro';

export const Logo = styled.img`
	cursor: pointer;
	transition: all 0.6s;
	:hover {
		transform: rotate(-2deg);
	}

	@media only screen and (max-width: 728px) {
		margin-right: auto;
		margin-left: auto;
		padding-top: 13px;
	}
`;

export const MobileHeaderWrapper = styled.div`
	display: flex;
	alignitems: center;

	.icon__logo--phone {
		padding-top: 17px;
	}
`;
