import { CONFIG } from 'config/config';
import styled from 'styled-components/macro';

export const ReviewWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	gap: 20px;
	@media (max-width: 820px) {
		flex-wrap: wrap;
	}
`;

export const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const NameText = styled.p`
	color: ${CONFIG.colors.primary};
	font-family: ${CONFIG.fonts.bold};
	font-size: 13px;
`;

export const CVText = styled.p`
	color: ${CONFIG.colors.tertiary};
	font-family: ${CONFIG.fonts.light};
	font-size: 13px;
`;

export const ReviewText = styled.p`
	font-family: ${CONFIG.fonts.light};
	font-size: 13px;
	line-height: 22px;
	word-wrap: break-word;
	max-width: calc(79em * 0.5);
`;
