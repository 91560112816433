import { CONFIG } from 'config/config';
import React, { useState } from 'react';
import * as S from './styles';

import { Modal } from 'components/Modal/Modal';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

import ErrorIcon from '@material-ui/icons/Error';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';

import { Text, View, Button, UiWrapper } from 'coinscrap-webapp-core';

const SendLinkModal = ({ close, user, send, next }) => {
	const { openModal, showAlert } = UiWrapper.use();
	const [loading, setLoading] = useState();

	return (
		<Modal>
			<S.ModalWrapper>
				<ErrorIcon
					style={{ marginBottom: 15, color: CONFIG.colors.primary, height: 45, width: 45 }}
				/>

				<Text headingPrimarySt>¿Cómo quieres enviar el enlace al cliente?</Text>
				<View notificationWrapperSt>
					<Button
						backSt
						disabled={!user?.email?.trim()}
						onClick={async () => {
							if (loading) {
								return;
							}
							try {
								setLoading(true);
								await send('USER_RECOVERY');
								setLoading(false);
								showAlert('EMAIL enviado');
								close();
								next && next();
							} catch (e) {
								setLoading(false);
								close();
								console.error('ERROR', e);
								let messageError = e?.message?.toString();
								messageError = 'No se ha podido enviar el EMAIL';
								openModal(
									() => (
										<ErrorModal>
											<Text headingPrimarySt>{messageError}</Text>
										</ErrorModal>
									),
									{
										disableClickAway: true,
										closeButton: true,
										key: 'modal-SimulationError',
									},
								);
							}
						}}
					>
						<EmailIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
						Email
					</Button>
					<Button
						nextSt
						disabled={!user?.phoneNumber?.trim()}
						onClick={async () => {
							if (loading) {
								return;
							}
							try {
								setLoading(true);
								await send('SMS_UNIQUE_ACCESS');
								setLoading(false);
								showAlert('SMS enviado');
								close();
								next && next();
							} catch (e) {
								setLoading(false);
								close();
								console.error('ERROR', e);
								let messageError = e?.message?.toString();
								messageError = 'No se ha podido enviar el SMS';
								openModal(
									() => (
										<ErrorModal>
											<Text headingPrimarySt>{messageError}</Text>
										</ErrorModal>
									),
									{
										disableClickAway: true,
										closeButton: true,
										key: 'modal-SimulationError',
									},
								);
							}
						}}
					>
						<TextsmsIcon style={{ marginLeft: 3, marginRight: 3, fontSize: 22 }} />
						SMS
					</Button>
				</View>
			</S.ModalWrapper>
		</Modal>
	);
};

export default SendLinkModal;
